import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CommonContext } from "../../context/common-context";
import { TaskContext } from "../../context/task-context";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import {
  USER_GUIDE_CLICK_EVENTS,
  USER_PROFILE_SETTING_CLICK_EVENTS,
  USER_SEARCH_TASK_USING_SEARCH_BAR_EVENTS,
  USE_JETBOT_FROM_TOP_BAR_EVENTS,
} from "../../customer-io/events";
import BackarrowHovered from "../../images/backarrow-hovered.svg";
import BackArrowNormal from "../../images/backarrow.svg";
import JetBotIcon from "../../images/jet-bot.svg";
import HelpInfoHover from "../../images/shortcuts-info-hover.svg";
import HelpInfo from "../../images/shortcuts-info-normal.svg";
import UserGuideHover from "../../images/userguide-hover.svg";
import UserGuide from "../../images/userguide-normal.svg";
import { SearchProps, Team } from "../../types";
import {
  USETIFULL_JETBOT_SEARCH_BAR,
  USETIFULL_SEARCH_TASK_SEARCH_BAR,
  USETIFULL_SHORTCUTS_SEARCH_BAR,
  USETIFULL_USER_GUIDE_SEARCH_BAR,
  USETIFULL_USER_PROFILE_SEARCH_BAR,
} from "../../usetifull/class-names";
import { isValidEmail, parseTags } from "../../utils/utils";
import JetBotSuggestionPopup from "../jet-bot/jet-bot-suggestion-popup";
import { GPT_3_OPENAI } from "../utils/app-feature-list";
import { checkIfFeatureOpen } from "../utils/utills";
import myAxios from "../../utils/myaxios";
import { UserProfileContext } from "../../context/user-profile-context";
declare var _cio: any;

const UserGuideTag = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
const UserGuideDiv = styled.div`
  margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  // position: absolute;
  // right: 13%;
  // top: 17px;
  /* padding-left: 24px; */
`;

const UserHelp = styled.div`
  margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  // position: absolute;
  // right: 9%;
  // top: 17px;
  padding-left: 24px;
  padding-right: 24px;
`;
const TeamSwitchDropdownDiv = styled.div`
  margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  // position: absolute;
  // right: 9%;
  // top: 17px;
  padding-left: 24px;
  padding-right: 24px;
`;
interface SidebarWidth {
  isOpen?: boolean;
  isClickOnSearch?: boolean;
  showUserProfile?: boolean;
  isOnSidebar?: boolean;
  isTask?: boolean;
  showSearchBox?: boolean;
}

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1350px) and (min-width: 920px) {
    margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
  }
`;
const SearchboxContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const UserProfileDiv = styled.div`
  margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  // position: absolute;
  // right: 13%;
  // top: 17px;
  padding-left: 1%;
`;

const Inputbox = styled(Field)`
  border: none;
  border-radius: 50px;
  width: 500px;
  height: 30px;
  padding: 13px;
  /* z-index: 9; */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARaSURBVHgB7VnBchNHEO2eFc4lB/0BOqcSsf4CzI0CFxH5ActH4lBGX2D5CwSV2FxSFfkLoiRAuHnzBdkEiivLF7CcKCjtND2Sy+XZnV3NzK4ErtKr8mFHml49T093v26ANdZYow4QGsQ3t47CALIQBXYQW1fVGgGlQNk7yiDOUCSvnu3FsETUJvTt9s9bAbZ2+Ef32Fx74QaEBCREGcqTl0/uR9AwvAl9d+uoLwI44CPogC+YnMzg8MWzvTE0BGdC4c1Rh1pf/cbOtAWNASOcftiNnw8SqAknQt07R/soaVjhWin/RQTwRhAlQDIFFG2JfKeArvG+kD8v3SslDOqeljWh7vbRAX95aPyQYIx8J2KLOxHynZOEfUTcMZsSw/+f3DsET1gRqiAzwakYxM/vJeCI8ObjjgyyoYlYHVILCc3dDB7mllNEGMR/1b/M4e1fHhDiKL+Ombwb/31/Ao6oJDQPABuvc8spE7wRN5hPQs5fJOAU9PuV4vTjpmugEFUf0pWN09xS42QUlD0EeTe33J5HUzeUElJ5Jp9jkOgwXlKmVwGF7Q/0VdpSQQQcUEpoljQ12zCOn/70EJaIuX2M9NcGBw4mzIRUOVM4nUx4h1IXIGS597idkpFQQEIPpep0PEKzD+a5TD8lCaJnu9/scgiaAZU0YYXInxKniO8ttxYJqRAKufAZL6EqrkRrqgJPev7M7r/5w69XbbYWCGVCdi4+E8E/sGLEkwGTQS2a0vv3mzZ7C4Q404b6glyqICsDkXxz8VmyaLTZZ7pDejVMmMLnAHK1rj8vFo9gIkS6NCAlAS4RioSQNALIegYuEUwup58IizP4HKDcey1dv0CIZM536/QMaoA9QwvTAskqOBUIBfJKpBuG67BihL1Ru9CzmLYSm70FQmclzsXjbbtWvHWRfdzQSx3CxLb0MpY+BHqpwxXvPqwQQuRlOUVgCVGymJO+1FPqFVaA+Xt0d0OCR5bbzYRMFa+PevRBUSVj5CIqSwWeSZd0bx8/gCVCdZcKOqzwO6pR2SS5tn18Wjh+kDeWUX137xzvcBNzrC2yDvvv6d4uOKCySYJTVMZS/R3i9zOJ0RjMZDDxUcmVhFSo5C5PrnHB3RgB/3a3Hztp/TLMmph5MqDyH534qGSrzinnoSGfjIGAf5N9NoaZNUDKm/48Z+rHf/7opJate9vlpGZmJjLLTl4s6HSGW6P29OtWuIiIZtmRlNP0gUn1iIIRJ4ZOhcmIEGI1tTtfUdM8KcP5vvLJBUr5iETxn+ZCymM+VN5k9wex6wa76s5wwOnzHS3kPFtS3hO8+Ytp/2zm4wkmAtyNzaWBOqRqz1jP5z0grle74hkIEnbJPwTISVU+8yXV6BRcuSO3oEIlzuSFHoBQ4kz1CFqtOJ7Yh2IfUo0SWgZKSZXMjyoT65cALkzHnNwL5Y8Uom/6/hdPSMFEil3rrem7l4KQwozUbCg2E3uTVU1D1lhjjUuOTx1k6EhRsPPTAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 465px;
  background-size: 20px;

  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 5px;
  }
`;

const Error = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  padding: 3px;
`;

const UserCircle = styled.div`
  padding: 4px;
  font-size: 14px;
  text-align: center;
  height: 30px;
  width: 30px;
  font-weight: 600;
  background-color: ${(props: SidebarWidth) =>
    props.showUserProfile ? "#fdf0e2" : "#D0D0D0"};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
`;

/* const SearchContainer = styled.div`
  width: 100vw;
  background-color: #ffe6cc;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: fixed;
`; */

const SearchInnerContainer = styled.div`
  display: flex;
  justify-content: start;
  /* justify-content: ${(props: SidebarWidth) =>
    props.isOpen ? "flex-start" : "center"}; */
  align-items: center;
`;
const JetBotImgTag = styled.img`
  width: 29px;
  margin-left: 15px;
  top: 4px;
  right: 16px;
  cursor: pointer;
`;

const MainRow = styled(Row)`
  width: 100vw;
  background-color: #ffe6cc;
  height: 40px;
  padding-left: ${(props: SidebarWidth) =>
    props.isOpen && !props.showSearchBox ? "2%" : "6%"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: fixed;
  margin: 0;
`;

const BackArrowTag = styled.img`
  width: 40px;
  padding-left: 10px;
  padding-right: 15px;
  cursor: pointer;
`;
const Heading = styled.span`
  color: #2f4c95;
  font-size: 16px;
  font-weight: 600;
`;
const TopBar = ({
  isOpen,
  quickHelpModal,
  setQuickHelpModal,
  isOnSidebar,
  isTask,
}: any) => {
  let navigate = useNavigate();
  const { searchText, setSearchText, setSearchCriteria, projectTempTsk } =
    useContext(TaskContext);
  const { showSearchBox, topBarText } = useContext(CommonContext);
  const { userTeams, setSelectedTeam, selectedTeam } = useContext(TeamContext);
  const { setPreferenceData } = useContext(UserProfileContext);
  const { userState } = useContext(UserContext);
  const [showHelp, setShowHelp] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [showUserProfile, setUserProfile] = useState(true);
  const [showJetBotPopup, setShowJetBotPopup] = useState<boolean>(false);
  const [toShowSubmitBtnJetbotPopup, setToShowSubmitBtnJetbotPopup] =
    useState<boolean>(false);
  const [gptSuggestionsText, setGptSuggestionsText] = useState<
    string | undefined
  >(undefined);

  const validateSearchBox = (values: SearchProps) => {
    const searchCriteria = parseTags(
      values.searchText ? values.searchText : ""
    );

    setSearchText(values.searchText ? values.searchText : "");

    const errors: SearchProps = {};
    if (
      searchCriteria.fromEmail !== undefined &&
      !isValidEmail(searchCriteria.fromEmail)
    ) {
      errors.searchText = "Invalid email against 'from:' tag";
    }

    if (
      searchCriteria.toEmail !== undefined &&
      !isValidEmail(searchCriteria.toEmail)
    ) {
      errors.searchText = "Invalid email against 'to:' tag";
    }

    if (searchCriteria.groupEmails !== undefined) {
      if (searchCriteria.groupEmails.length === 0) {
        errors.searchText = "No email added against group: tag ";
      } else {
        if (searchCriteria.groupEmails.indexOf(",") > -1) {
          const invalidEmails = searchCriteria.groupEmails
            .split(",")
            .filter((e) => {
              if (!isValidEmail(e)) return true;
              return false;
            });

          if (invalidEmails.length > 0) {
            if (invalidEmails.length > 1)
              errors.searchText =
                "Following emails against group: tag [" +
                invalidEmails.join(", ") +
                "] are not valid.";
            else
              errors.searchText =
                "Following email against group: tag [" +
                invalidEmails[0] +
                "] is not valid.";
          }
        } else {
          if (!isValidEmail(searchCriteria.groupEmails)) {
            errors.searchText =
              "Following email against group: tag [" +
              searchCriteria.groupEmails +
              "] is not valid.";
          }
        }
      }
    }

    if (
      !errors.searchText &&
      searchCriteria.status &&
      (searchCriteria.status < 0 || searchCriteria.status > 5)
    )
      errors.searchText = "'s:' status tag values should be in range 0-5";

    if (
      !errors.searchText &&
      searchCriteria.priority &&
      (searchCriteria.priority < 1 || searchCriteria.priority > 5)
    )
      errors.searchText = "'p:' priority tag values should be in range 1-5";

    return errors;
  };

  const submitSearch = (
    values: SearchProps,
    setSubmitting: any,
    resetForm: any
  ) => {
    if (values.searchText && values.searchText.length > 0) {
      _cio.track(USER_SEARCH_TASK_USING_SEARCH_BAR_EVENTS, {
        name: "user search task using search bar",
      });
    }

    setSearchCriteria(parseTags(values.searchText ? values.searchText : ""));

    resetForm();
    setSubmitting(false);
  };

  const BackArrow = () => {
    const [selectedImage, setSelectedImage] = useState<any>(BackArrowNormal);
    return (
      <>
        <BackArrowTag
          src={selectedImage}
          onMouseEnter={() => {
            setSelectedImage(BackarrowHovered);
          }}
          onMouseLeave={() => {
            setSelectedImage(BackArrowNormal);
          }}
          onClick={() => {
            navigate(`/`);
          }}
        />
      </>
    );
  };

  const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(-1);

  useEffect(() => {
    if (userTeams && userState && userTeams.length > 0) {
      const ownedTeams = userTeams.filter(
        (team) => team.owner.id === userState.user?.id
      );
      // console.log("Owned Teams:-- ", ownedTeams);
      if (ownedTeams.length > 0) {
        // console.log("Owned Team ID:-- ", ownedTeams[0].id);
        // console.log("User Teams:-- ", userTeams);
        const index = userTeams.findIndex(
          (team) => team.id === ownedTeams[0].id
        );
        // console.log("Index:- ", index);
        if (!selectedTeam) {
          // console.log("Selected Team:-- ", selectedTeam);
          // console.log("Owned Team of 0 :-- ", ownedTeams[0]);
          setDefaultSelectedIndex(0);
          setSelectedTeam(ownedTeams[0]);
        }
      }
    }
  }, [userTeams, userState, selectedTeam]);

  const updatePersonalTeamPreferences = (
    isPersonal: any,
    selectedTeamId: any
  ) => {
    myAxios
      .post(`/personal-team-preference`, {
        isPersonal,
        selectedTeamId,
      })
      .then((response: any) => {
        setPreferenceData(response.data.data);

        // console.log("respone post  topbar:--", response.data.data);
      })
      .catch((error) => {
        console.error("error post:---", error);
      });
  };
  return (
    <>
      <div>
        <MainRow isOpen={isOpen} showSearchBox={showSearchBox}>
          {!showSearchBox && isOpen && <Col md={2} />}
          <Col md={!showSearchBox && isOpen ? 2 : 3}>
            {!showSearchBox && (
              <>
                <BackArrow />
                <Heading>{topBarText}</Heading>
              </>
            )}
          </Col>
          <Col md={!showSearchBox && isOpen ? 4 : 5}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={{
                searchText,
              }}
              validate={(values: SearchProps) => {
                return validateSearchBox(values);
              }}
              onSubmit={(values: SearchProps, { setSubmitting, resetForm }) => {
                submitSearch(values, setSubmitting, resetForm);
              }}
            >
              {({ errors, values, submitForm }) => {
                return (
                  <Form
                    style={{ display: " flex", justifyContent: "flex-end" }}
                  >
                    {/*  <SearchContainer> */}
                    <SearchInnerContainer
                      isOpen={isOpen}
                      isOnSidebar={isOnSidebar}
                      isTask={isTask}
                    >
                      <SearchWrapper isOpen={isOpen}>
                        <SearchboxContainer>
                          {showSearchBox && (
                            <Inputbox
                              className={USETIFULL_SEARCH_TASK_SEARCH_BAR}
                              name="searchText"
                              type="text"
                              placeholder="Search Task"
                              value={values.searchText}
                            />
                          )}
                        </SearchboxContainer>
                        {/* {errors && errors.searchText ? (
                          <Error>{errors.searchText}</Error>
                        ) : null} */}

                        {errors && errors.searchText ? (
                          <Error>{errors.searchText}</Error>
                        ) : null}
                      </SearchWrapper>
                    </SearchInnerContainer>
                    {/*  </SearchContainer> */}
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col md={4}>
            <SearchInnerContainer
              isOpen={isOpen}
              isOnSidebar={isOnSidebar}
              isTask={isTask}
            >
              <UserGuideDiv
                title="User guide"
                onClick={() => {
                  // Send track event to customer.io
                  _cio.track(USER_GUIDE_CLICK_EVENTS, {
                    name: "user-guide",
                  });
                }}
                onMouseOver={() => setShowUserGuide(true)}
                onMouseOut={() => setShowUserGuide(false)}
              >
                <a target={"_new"} href="https://help.myjetlist.com/">
                  <UserGuideTag
                    className={USETIFULL_USER_GUIDE_SEARCH_BAR}
                    src={showUserGuide ? UserGuideHover : UserGuide}
                    alt="user"
                  />
                </a>
              </UserGuideDiv>
              <UserHelp
                title="Quick help"
                onMouseOver={() => setShowHelp(true)}
                onMouseOut={() => setShowHelp(false)}
              >
                <a target={"_new"} href="https://quickhelp.myjetlist.com/">
                  <UserGuideTag
                    className={USETIFULL_SHORTCUTS_SEARCH_BAR}
                    src={showHelp ? HelpInfoHover : HelpInfo}
                    alt="user"
                  />
                </a>
              </UserHelp>
              <UserProfileDiv title="User profile">
                <UserCircle
                  className={USETIFULL_USER_PROFILE_SEARCH_BAR}
                  showUserProfile={showUserProfile}
                  onClick={() => {
                    // Send track event to customer.io
                    _cio.track(USER_PROFILE_SETTING_CLICK_EVENTS, {
                      name: "user-profile-setting-button",
                    });
                    navigate("user-profile");
                  }}
                  onMouseOver={() => setUserProfile(false)}
                  onMouseOut={() => setUserProfile(true)}
                >
                  {userState.user && userState.user.shortName
                    ? userState.user.shortName
                    : ""}
                </UserCircle>
              </UserProfileDiv>
              {checkIfFeatureOpen(
                userState?.user?.appFeatures,
                GPT_3_OPENAI
              ) && (
                <UserProfileDiv>
                  <JetBotImgTag
                    className={USETIFULL_JETBOT_SEARCH_BAR}
                    src={JetBotIcon}
                    alt="robo"
                    title="Ask Suggestion to Jet-Bot"
                    onClick={() => {
                      //searchSuggestion(values.detail);
                      _cio.track(USE_JETBOT_FROM_TOP_BAR_EVENTS, {
                        name: "use jetbot from top bar",
                      });
                      setGptSuggestionsText("");
                      setShowJetBotPopup(true);
                      setToShowSubmitBtnJetbotPopup(true);
                    }}
                  />
                </UserProfileDiv>
              )}

              <TeamSwitchDropdownDiv>
                <select
                  className="team-switch-dropdown"
                  onChange={(e) => {
                    if (userTeams) {
                      const index: number = Number(e.target.value);
                      setSelectedTeam(userTeams[index]);

                      // this condition handing perfernce dropdown
                      if (userTeams[index].type === "company") {
                        updatePersonalTeamPreferences(
                          false,
                          userTeams[index].id
                        );
                      } else {
                        updatePersonalTeamPreferences(true, undefined);
                      }
                    }

                    if (userTeams) {
                      const index: number = Number(e.target.value);
                      setSelectedTeam(userTeams[index]);
                      localStorage.setItem(
                        "userTeams",
                        JSON.stringify(userTeams[index])
                      );

                      // to unmounte the subscription component
                      if (
                        (userTeams[index].type !== "company" &&
                          !projectTempTsk) ||
                        !userTeams[index].isOwner
                      ) {
                        navigate("/");
                      }
                    }
                  }}
                >
                  {userTeams?.map((team: Team, index: number) =>
                    team.id === selectedTeam?.id ? (
                      <option key={team.id} value={index} selected>
                        {team.name}
                      </option>
                    ) : (
                      <option key={team.id} value={index}>
                        {team.name}
                      </option>
                    )
                  )}
                </select>
              </TeamSwitchDropdownDiv>
            </SearchInnerContainer>
          </Col>
        </MainRow>

        {showJetBotPopup && (
          <JetBotSuggestionPopup
            gptSuggestionsText={gptSuggestionsText}
            setShowJetBotPopup={setShowJetBotPopup}
            showSuggestionPopUpInTaskDetail={true}
            setGptSuggestionsText={setGptSuggestionsText}
            toShowSubmitBtnJetbotPopup={toShowSubmitBtnJetbotPopup}
          />
        )}
      </div>
    </>
  );
};

export default TopBar;
