export const USER_GUIDE_CLICK_EVENTS = "user-guide-click";
export const USER_PROFILE_SETTING_CLICK_EVENTS = "profile-setting-click";
export const SHORTCUT_LINK_CLICK_EVENTS = "shortcut-link-click";
export const USE_JETBOT_IN_BASIC_TASK_EVENTS = "use-jetbot-in-basic-task";
export const USE_JETBOT_IN_EXPANDED_TASK_EVENTS = "use-jetbot-in-expanded-task"; 
export const USE_JETBOT_FROM_TOP_BAR_EVENTS = "use-jetbot-from-top-bar"; 
export const USE_JETBOT_FOR_SELECTED_TEXT_IN_TASK_DETAILS_EVENTS = "use-jetbot-for-selected-text-in-task-deatils";  
export const USER_ASSIGN_GROUP_TASK_EVENTS = "user-assign-group-task"; 
export const USER_ASSIGN_SELF_A_TASK_EVENTS = "user-assign-self-a-task";  
export const USER_ASSIGN_SOMEONE_ELSE_A_TASK_EVENTS = "user-assign-someone-else-a-task";   
export const USER_COMPLETE_TASK_EVENTS = "user-complete-task";
export const USER_VIEW_ARCHIVED_TASK_EVENTS = "user-view-archived-task";
export const USER_USE_PRIORITY_TAG_FEATURE_EVENTS = "user-use-priority-tag-feature";
export const USER_USE_CATEGORY_TAG_FEATURE_EVENTS = "user-use-category-tag-feature";  
export const USER_START_A_TASK_EVENTS = "user-start-a-task"; 
export const USER_USE_PERCENT_COMPLETE_TO_MOVE_PROGRESS_OF_TASK_EVENTS = "user-use-%-complete-to-move-progress-of-task";
export const USER_ADD_TEXT_COMMENTS_EVENTS = "user-add-text-comments";
export const USER_ADD_IMAGE_COMMENTS_EVENTS = "user-add-image-comments";  
export const USER_ADD_VIDEO_COMMENTS_EVENTS = "user-add-video-comments"; 
export const USER_ADD_FILE_ATTACHMENT_COMMENTS_EVENTS = "user-add-file-attachment-comments";    
export const USER_USE_NUDGE_FEATURE_EVENTS = "user-use-nudge-feature"; 
export const USER_TURN_OF_TASK_NOTIFICATION_EVENTS = "user-turn-off-task-notification"; 
export const USER_USE_HAND_OVER_FEATURE_EVENTS = "user-use-hand-over-feature"; 
//export const USER_USE_ADD_GROUP_USER_TO_TASK_FEATURE_EVENTS = "user-use-add-group-user-to-task-feature"; 
//export const USER_USE_DELEGATE_TASK_FEATURE_EVENTS = "user-use-delegate-task-feature"; 
export const USER_CREATE_TEMPLATE_EVENTS = "user-create-template";
export const USER_SHARE_TEMPLATE_EVENTS = "user-share-template"; 
export const USER_USE_TEMPLATE_IN_A_TASK_EVENTS = "user-use-template-in-a-task"; 
export const USER_COPY_DUPLICATE_A_TASK_EVENTS = "user-copy-duplicate-a-task"; 
export const USER_SEARCH_TASK_USING_SEARCH_BAR_EVENTS = "user-search-task-using-search-bar"; 
export const USER_FILTER_TASKS_BY_CATEGORY_EVENTS = "user-filter-tasks-by-category"; 
export const USER_FILTER_TASKS_BY_ASSIGNEE_EVENTS = "user-filter-tasks-by-assignee"; 
export const USER_FILTER_TASKS_BY_ASSIGNER_EVENTS = "user-filter-task-by-assigner"; 
export const USER_FILTER_TASKS_BY_DUE_DATE_EVENTS = "user-filter-tasks-by-due-date"; 
export const USER_ASSIGN_TEAM_MULTIPLE_ASSIGNEE_TASK_EVENTS = "user-assign-team_multiple_assignee_task";

