import { AxiosResponse } from "axios";
import $ from "jquery";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import ReactQuill from "react-quill";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { CommonContext } from "../../context/common-context";
import { TaskContext } from "../../context/task-context";
import { UserContext } from "../../context/user-context";
import AddCategorySvg from "../../images/add-category.svg";
import CrossSvg from "../../images/black-cross.svg";
import CalenderSvg from "../../images/calendarfordue.svg";
import CloseTaskHoverIcon from "../../images/close-task-hover.svg";
import CloseTaskNormalIcon from "../../images/close-task-normal.svg";
import Cross from "../../images/cross.svg";
import DeleteCrossSvg from "../../images/delete-cross.svg";
import IconForEdit from "../../images/editPencil.svg";
import GrayCircleSvg from "../../images/gray-circle.svg";
import GreenCircleSvg from "../../images/green-circle.svg";
import GreenCompletedSvg from "../../images/green-completed.svg";
import GreyCrossSvg from "../../images/grey-cross.svg";
import JetBotWithImage from "../../images/jetbot-with-text.gif";
import jetlist from "../../images/JetList.svg";
import NextTaskDisabledIcon from "../../images/next-task-disabled.svg";
import NextTaskHoverIcon from "../../images/next-task-hover.svg";
import NextTaskNormalIcon from "../../images/next-task-normal.svg";
import PictureSvg from "../../images/picture.svg";
import PrevTaskDisabledIcon from "../../images/prev-task-disabled.svg";
import PrevTaskHoverIcon from "../../images/prev-task-hover.svg";
import PrevTaskNormalIcon from "../../images/prev-task-normal.svg";
import SubTaskIndicatorSvg from "../../images/subtask-indicator.svg";
import GlobalStyle from "../../style/style";
import { SubTask } from "../../types";
import myAxios from "../../utils/myaxios";
import {
  cloneSubTasks,
  extractTextContent,
  getFullName,
  removeLineBreakAtStart,
} from "../../utils/utils";
import { GPT_3_OPENAI } from "../utils/app-feature-list";
import UrlToLink from "../utils/url-to-link";

import { TeamContext } from "../../context/team-context";
import {
  USER_ADD_FILE_ATTACHMENT_COMMENTS_EVENTS,
  USER_ADD_IMAGE_COMMENTS_EVENTS,
  USER_ADD_TEXT_COMMENTS_EVENTS,
  USER_ADD_VIDEO_COMMENTS_EVENTS,
  USER_COMPLETE_TASK_EVENTS,
  USER_START_A_TASK_EVENTS,
  USER_USE_PERCENT_COMPLETE_TO_MOVE_PROGRESS_OF_TASK_EVENTS,
  USE_JETBOT_FOR_SELECTED_TEXT_IN_TASK_DETAILS_EVENTS,
} from "../../customer-io/events";
import JetBotSuggestionPopup from "../jet-bot/jet-bot-suggestion-popup";
import {
  acceptedDocumentTypes,
  quillFormats,
  regRestrictSpecialCharacters,
} from "../utils/constants";
import {
  checkIfFeatureOpen,
  parsedURlsFromImgStrings,
  parseErrorResponse,
  removeTagsFromTaskDetail,
} from "../utils/utills";
declare var _cio: any;

interface SidebarWidth {
  isopen?: boolean;
  // value?:boolean;
}

const MainDiv = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1111;
`;
const EmptyDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1111;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-left: ${(props: SidebarWidth) => (props.isopen ? "9%" : "0px")}; */
  width: 85%;
  height: 91vh;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 89%;
    height: 94%;
    border-radius: 5px;
  }
`;
const HeaderDiv = styled.div`
  width: 100%;
  height: 60px;
  background: #fdf0e2;
  border-radius: 10px 10px 0 0;
`;

const CloseTaskImg = styled.img`
  width: 40px;
  position: absolute;
  right: 6%;
  bottom: 92%;
  cursor: pointer;
  z-index: 111111;
  @media (max-width: 1199px) and (min-width: 320px) {
    right: 4%;
    top: 0%;
    width: 35px;
  }
`;

const LogoImg = styled.img`
  /* padding:0 26px; */
  width: 56px;
  height: 64px;
  margin-left: 20px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 50px;
    height: 43px;
  }
`;

const ParaFirst = styled.span`
  font-weight: 500px;
  size: 16px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  line-height: 24px;
  color: #fc6601;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 18px;
    margin-top: 4px;
  }
`;
const ParaSecond = styled.span`
  font-weight: 400px;
  size: 11px;
  line-height: 20px;
  color: #2f4c95;
  text-transform: capitalize;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
    /* padding-left: 18px; */
  }
`;
const ParaSecondMobile = styled.span`
  font-weight: 400px;
  size: 11px;
  line-height: 20px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
    display: flex;
    justify-content: center;
  }
`;
const ParaThird = styled.span`
  font-weight: 400px;
  size: 11px;
  margin: 0px 20px 0px 20px;
  line-height: 20px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
  }
`;
// const ParaThirdMobile = styled.span`
//   font-weight: 400px;
//   size: 11px;
//   margin: 0px 20px 0px 20px;
//   line-height: 20px;
//   color: #2f4c95;
//   @media (max-width: 1199px) and (min-width: 320px) {
//     font-size: 10px;
//     line-height: 20px;
//   }
// `;

const CategoryCrossIcon = styled.img`
  font-weight: 400px;
  size: 12px;
  margin-left: 10px;
  line-height: 20px;
  width: 10px;
  color: #2f4c95;
  cursor: pointer;
`;

const EditIcon = styled.img`
  font-weight: 400px;
  size: 12px;
  line-height: 20px;
  width: 16px;
  height: 16px;
  color: #2f4c95;
  cursor: pointer;
  margin-right: 25px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    /* left: 96%; */
    /* top: 10%; */
    right: 0;
    cursor: pointer;
  }
`;
const CrossTag = styled.img`
  font-weight: 400px;
  size: 12px;
  line-height: 20px;
  width: 16px;
  height: 16px;
  color: #2f4c95;
  cursor: pointer;
  margin-right: 25px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    right: -5%;
    top: 13%;
    cursor: pointer;
  }
`;

const Heading = styled.div`
  padding: 10px 16px;
  /* border: 1px solid #e1e1e1; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  color: #2f4c95;
  width: auto;
  margin: 0;
  border-radius: 5px;
  /* overflow-x: scroll; */
  img {
    width: 100px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    margin: 0;
    padding: 1px 13px;
  }
`;

const SpanForDetail = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #2f4c95;
`;

const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #2f4c95;
  }

  & img {
    /* width: 50%; */
    width: 8%;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    img {
      width: 100%;
    }
  }
`;

const Paragraph = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
  margin: 0 auto;
  padding: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 15px;
  }
`;
const ProgressContainer = styled.div`
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    align-items: center;
    justify-content: center;
  }
`;

const TemplateKeyTag = styled.div`
  display: flex;
  background: #fdf0e2;
  width: 100%;
  /* margin: 0px 10px 10px 10px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    align-items: center;
    justify-content: center;
  }
`;

const ColColorBlue = styled.div`
  background: #2f4c95;
  width: 25px;
  height: 26px;
  border: 2px solid #2f4c95;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    background: #2f4c95;
    width: 20px;
    height: 20px;
    border: 2px solid #2f4c95;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
  }
`;
const ColColor = styled.div`
  background: #ffe6cc;
  width: 25px;
  height: 26px;
  border: 2px solid #ffe6cc;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background: #2f4c95;
    border: 2px solid #2f4c95;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    background: #ffe6cc;
    width: 20px;
    height: 20px;
    border: 2px solid #ffe6cc;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
  }
`;

const Button = styled.button`
  width: 190px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  border: 2px solid #2f4c95;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 120px;
    height: 30px;
    margin: 2%;
    font-size: 10px;
    line-height: 15px;
  }
`;
const CommentsContainer = styled.div`
  /* max-height: 300px; */
`;

const SpanTag = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 14px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-weight: 600;
    font-size: 9px;
    line-height: 13px;
  }
`;

const DivCommentpara = styled.div`
  display: flex;
  justify-content: start;
  border: 1px solid;
  padding: 3px 7px;
  margin: 10px 13px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

const CommentParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #2f4c95;
  margin-top: 8px;
  padding: 5px 0px;
  width: 100%;
  height: auto;
  cursor: pointer;
  img {
    /* width: 50%; */
    width: 8%;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    img {
      width: 100%;
    }
  }
`;
const DateSpan = styled.span`
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  margin-top: 8px;
  color: #8e8e8e;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-weight: 400;
    font-size: 9px;
    line-height: 13px;
  }
`;
const DivforBottom = styled.div`
  background: #f6f9ff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex: auto;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    text-align: center;
  }
`;

const DivForEnterComments = styled.div`
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`;
const EnterCommentSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  margin: 0 13px;
`;

const BottomRow = styled(Row)`
  margin: 0;
  flex: auto;
`;
const BottomLeftCol = styled(Col)`
  padding: 10px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const BottomRightCol = styled(Col)``;

const ProgressActionParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0;
  }
`;

const CommentImageIcon = styled.img`
  height: 32px;
`;

CommentImageIcon.defaultProps = {
  src: PictureSvg,
};

const CommentImage = styled.img`
  display: block;
  width: 50%;
  align-self: center;
  height: auto;
`;

const InputShowHeading = styled.input`
  padding: 10px 20px;
  border: 1px solid #e1e1e1;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  color: #2f4c95;
  width: 98%;
  margin: 5px 10px;
  border-radius: 5px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 15px;
    width: 269px;
    height: 31px;
  }
`;

const DatePickerParent = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #2f4c95;
  position: relative;

  & input {
    border: 1px solid #e1e1e1;
    padding: 7px 17px;
    border-radius: 5px;
    width: 100%;
  }

  /* margin: 5px 10px; */
`;

const DivForToolTip = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: 1199px) and (min-width: 320px) {
    right: 240px;
  }
`;

const ToolText = styled.p`
  position: absolute;
  content: "";
  /* background: #000000c9; */
  background-color: #313131;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: -5px;
  width: 400px;
  height: auto;
  bottom: 0px;
  padding: 10px 5px;
  left: 43px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 313px;
  }
`;

const CrossImgTagToolTip = styled.img`
  position: absolute;
  right: -10px;
  top: -12px;
  display: ${(props) => (props.className === "BlackCross" ? "block" : "none")};
`;

const DivForCross = styled.div`
  &:hover img.BlackCross {
    display: none;
  }
  &:hover img.GreyCross {
    display: block;
  }
`;

const TooltipBtn = styled.button`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  border: 1px solid white;
  background-color: #313131;
  color: white;
  padding: 2px 8px;
  margin: 8px;
  &:hover {
    background: #777;
  }
`;

const TooltipbtnDiv = styled.div``;

const DivForTaskAndComment = styled.div`
  overflow-y: scroll;
  /* min-height: 320px; */
  min-height: 49%;
  padding: 0 2px;
`;

const TaskContainer = styled.div``;

const TaskContainerEdit = styled.div`
  padding: 10px;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  /* height: 21px; */
  align-items: baseline;
`;

const TaskParagraph = styled.span`
  font-size: 13px;
  color: rgba(60, 59, 91, 0.8);
  font-weight: bolder;
  font-style: normal;
  /* margin: 0; */
  max-width: 85%;
  min-width: 1px;
  display: flex;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
  }
`;

const TaskSubTask = styled.p`
  border-bottom: 0.3px solid #e6e6e6;
  margin-bottom: 0;
  font-size: 13px;
  color: #3c3b5bcc !important;
  font-weight: 400;
  font-style: normal;
  // margin: 0;
  line-height: 1.2;
  margin-top: 10px;
  max-width: 100%;
  min-width: 1px;
  display: flex;
  // height: 38px;
  // margo=
  /*  padding-left:10px; */
  & :p {
    color: #3c3b5bcc !important;
    margin-bottom: 0rem;
  }
`;
const ParagraphCheckBox = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 20px;
  color: #a9a9a9;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryDiv = styled.div`
  display: flex;
  position: relative;
`;

const CategoryInputBox = styled.input`
  background: #ffffff;
  border: 1px solid rgba(47, 76, 149, 0.3);
  border-radius: 5px;
  font-size: 13px;
  padding: 6px 7px;
  padding-right: 30px;
  /* position: relative; */
  width: 100%;
  /* @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  } */
`;

const AddCategoryImage = styled.img`
  position: absolute;
  /* right: 65px; */
  right: 5px;
  top: 6px;
  cursor: pointer;
  /* @media (max-width: 1199px) and (min-width: 320px) {
    left: 232%;
  } */
`;

const HeadingParagraph = styled.p`
  margin-bottom: 0;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;

const ProjectTemplateKeyText = styled.span`
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #5e5d77;
  padding: 0 5px;
`;
const SubTaskIndicatorImgTag = styled.img`
  padding: 0 13px;
  // width:10px;
  width: 30px !important;
  padding-left: 0px;
  /* padding-top: 5px; */
`;
const NextPrevTaskDiv = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-right: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-top: 10px;
    margin-right: 24px;
  }
`;

const MarkCompleteImgTag = styled.img`
  // padding: 0 13px;
  // width:10px;
  width: 42px !important;
  padding-left: 0px;
`;

const PriorityTag = styled.p`
  font-size: 15px;
  padding: 0px 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background: ${(props) => (props.color ? props.color : "none")};
  color: #fff;
  border-radius: 5px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 1px 3px;
    margin-bottom: 0px;
    font-size: 12px;
    border-radius: 3px;
  }
`;

const CategoryPTag = styled.p`
  margin: 0px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const CategoryEditTag = styled(Col)`
  padding: 2px 15px;
  margin: 10px;
  max-width: 100px;
  background: #2f4c951a;
  color: #2f4c95;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColPriorityCategoryTag = styled(Col)`
  display: flex;
  flex-wrap: inherit;
  padding-left: 20px;
`;
const DivForScroll = styled.div`
  display: flex;
  overflow: scroll;
`;
const ColPriorityCategoryTagForMobile = styled(Col)`
  display: flex;
  flex-wrap: inherit;
`;
const ColAssignedTag = styled(Col)`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;

  padding-left: ${(props) => (props.isDesktopScreen ? "0" : "28px")};
  justify-content: ${(props) =>
    props.isDesktopScreen ? "flex-end" : "flex-start"};
`;
const ColAssignedTagMobile = styled(Col)`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

const DivForDropDown = styled.div`
  margin-top: 0px;
`;

const CalenderImgTag = styled.img`
  position: absolute;
  z-index: 111;
  width: 25px;
  height: 29px;
  /* right: 8px; */
  right: 5px;
  top: 9%;
  cursor: pointer;
`;
const RowForPriority = styled(Row)`
  margin-left: 2px;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

const PrevNextImg = styled.img`
  margin: 0px 12px;
  width: 10px;
  border-radius: 0;
  cursor: pointer;

  @media (min-width: 320px) and (max-width: 1199px) {
    width: 10px;
  }
`;

const PrevNextDisabledImg = styled.img`
  margin: 0px 12px;
  width: 10px;
  border-radius: 0;
  cursor: not-allowed;
  @media (min-width: 320px) and (max-width: 1199px) {
    width: 10px;
  }
`;

const TaskRemainText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #8f8f8f;
  /* margin-top: 5px; */
  padding-right: 10px;
`;
const WatchParent = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const JetBotAnimationGifTag = styled.img`
  width: 300px;
  cursor: pointer;
`;
const LI = styled.li`
  list-style: none;
`;

const TaskDetails = ({ isopen }: { isopen: boolean }) => {
  const {
    setModel,
    showModel,
    selectedTask,
    setSelectedTask,
    updateTask,
    taskComments,
    setTaskComments,
    addAssignee,
    addAssignor,
    addDayFilters,
    addCategories,
    addTasks,
    taskCount,
    currentPaginationTaskInput,
    setTaskCount,
    currentTaskNumber,
    setCurrentTaskNumber,
    setShowLoaderForSearchSuggestion,
    updateSubTasks,
  } = useContext(TaskContext);
  const { userState } = useContext(UserContext);
  const navigate = useNavigate();
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const { selectedTeam } = useContext(TeamContext);
  const [closeIcon, setCloseIcon] = useState(CloseTaskNormalIcon);

  const [prevIcon, setPrevIcon] = useState(PrevTaskNormalIcon);
  const [nextIcon, setNextIcon] = useState(NextTaskNormalIcon);
  const [isPrevInActive, setPrevInActive] = useState<boolean>(true);
  const [isNextInActive, setNextInActive] = useState<boolean>(true);
  const [taskRemainText, setTaskRemainText] = useState<string>("1 of 89 Tasks");

  const [loding, setLoding] = useState(false);
  const [showToolTip, setToolTip] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const [subTasks, setSubstasks] = useState<SubTask[] | undefined>(undefined);

  const [categories, setCategories] = useState<any[]>([]);

  const [priority, setPriority] = useState<number>(0);

  const [comment, setComment] = useState<string>();
  const [tickHover, setTickHover] = useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [taskImages, setTaskImages] = useState<any[]>([]);
  const { hideLoader, showLoader, setUserSettings, userSettings } =
    useContext(CommonContext);

  const [duedate, setDueDate] = useState<Date>(
    moment(selectedTask?.duedateText).toDate()
  );
  const [commentQuillObj, setCommentQuillObj] = useState<any>();
  const [commentVideoUrl, setCommentVideoUrl] = useState<any>();

  const [editDescriptionQuillObj, setEditDescriptionQuillObj] = useState<any>();
  const [editDescriptionUploadImageURL, setEditDescriptionUploadImageURL] =
    useState<any>();
  const [descriptionUploadedDocumentURL, setDescriptionUploadedDocumentURL] =
    useState<any>();
  const [commentUploadedDocumentURL, setCommentUploadedDocumentURL] =
    useState<any>();
  const [editDescriptionUploadVideoURL, setEditDescriptionUploadVideoURL] =
    useState<any>();
  const [commentUploadImageURL, setCommentUploadImageURL] = useState<
    string | undefined
  >(undefined);
  const [showSuggestionPopUpInTaskDetail, setShowSuggestionPopUpInTaskDetail] =
    useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<any>(0);
  const [openLightBox, setOpenLightBox] = useState(false);

  const [hoverCol, setHoverCol] = useState(false);
  const [hoverColNum, setHoverColNum] = useState(0);
  const [taskIdCol, setTaskId] = useState("");

  const handleHoverOnCol = (index: number, taskId: any) => {
    setTaskId(taskId);
    setHoverCol(true);
    setHoverColNum(index);
  };
  const [removedCategories, setRemovedCategories] = useState<string[]>([]);
  const [addedCategories, setAddedCategories] = useState<string[]>([]);

  const [Inputshow, setInputshow] = useState(true);

  const handleChange = (event: any) => {
    base64CommentImageConversion(event);
  };

  const [progress, setProgress] = useState<number>(
    selectedTask && selectedTask.progress && selectedTask.progress > 0
      ? selectedTask.progress
      : 0
  );
  const [editDetail, setEditDetail] = useState<any>(null);
  const [editDescription, setEditDescription] = useState<any>(null);
  const [screenX, setScreenX] = useState<any>();
  const [screenY, setScreenY] = useState<any>();
  const [showJetBotAnimation, setShowJetbotAnimation] =
    useState<boolean>(false);
  const [showSuggestionPopUp, setShowSuggestionPopUp] =
    useState<boolean>(false);
  const [SelectionSearchText, setSelectionSearchText] = useState<string>("");

  //const [searchText, setSearchText] = useState<string>("");
  //const [showJetBotLoader, setShowJetBotLoader] = useState<boolean>(false);

  const [gptSuggestionsText, setGptSuggestionsText] = useState<
    string | undefined
  >(undefined);
  //const [showJetBotPopup, setShowJetBotPopup] = useState<boolean>(false);

  const [isCommentImageAdded, setCommentImageAdded] = useState<boolean>(false);
  const [isCommentVideoAdded, setCommentVideoAdded] = useState<boolean>(false);
  const [isCommentDocAdded, setCommentDocAdded] = useState<boolean>(false);

  const closeTaskDetailsForm = () => {
    setModel(false);
    navigate(`/`);
    setInputshow(true);
    setToolTip(false);
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        closeTaskDetailsForm();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTaskRemainText(`${currentTaskNumber} of ${taskCount} Tasks`);

    if (currentTaskNumber === 1 && taskCount === 1) {
      setNextInActive(true);
      setPrevInActive(true);
    } else if (currentTaskNumber === 1) {
      setNextInActive(false);
      setPrevInActive(true);
    } else if (currentTaskNumber === taskCount) {
      setNextInActive(true);
      setPrevInActive(false);
    } else {
      setNextInActive(false);
      setPrevInActive(false);
    }
  }, [taskCount, currentTaskNumber]);

  useEffect(() => {
    // setTaskImages([]);
    setProgress(
      selectedTask && selectedTask.progress && selectedTask.progress > 0
        ? selectedTask.progress
        : 0
    );

    setComment(undefined);
    setDueDate(moment(selectedTask?.duedateText).toDate());
    if (selectedTask)
      setSubstasks(
        selectedTask.subTasks ? cloneSubTasks(selectedTask.subTasks) : undefined
      );
    setCategories(
      selectedTask?.categories ? [...selectedTask?.categories] : []
    );
    setPriority(selectedTask?.priority ? selectedTask?.priority : 0);
    setAddedCategories([]);
    setRemovedCategories([]);
    setNewCategoryName("");
    setEditDetail(null);
    setEditDescription(null);
    // Extract all images for task
    if (selectedTask) {
      // Description Images
      let imageUrls = [];
      //let commentImageUrls [];
      if (selectedTask.isDescription) {
        const descriptionImageUrls = parsedURlsFromImgStrings(
          selectedTask.isDescription
        );
        if (descriptionImageUrls && descriptionImageUrls.length > 0) {
          imageUrls.push(...descriptionImageUrls);
        }
      }
      if (selectedTask.comments && selectedTask.comments.length > 0) {
        selectedTask.comments.forEach((com) => {
          if (com.comment && com.comment.length > 0) {
            const commentImgUrls = parsedURlsFromImgStrings(com.comment);
            if (commentImgUrls && commentImgUrls.length > 0) {
              imageUrls.push(...commentImgUrls);
            }
          }
        });
      }

      setTaskImages([...imageUrls]);
      setCommentImageAdded(false);
      setCommentVideoAdded(false);
      setCommentDocAdded(false);
    }
  }, [selectedTask, showModel]);
  // eslint-disable-next-line
  const [commentImageFile, setCommentImageFile] = useState<any>(null);

  const editTask = () => {
    setInputshow(!Inputshow);
  };

  const handleDetail = (e: any) => {
    setEditDetail(e.target.value);
  };

  const handleDescription = (e: any) => {
    setEditDescription(e);
  };

  const updateTaskClick = () => {
    if (selectedTask && selectedTask._id) {
      let commentToSend = comment;

      if (commentImageFile && !commentToSend) commentToSend = "See Attached:";

      let duedateText = moment(selectedTask.duedate).format("YYYY-MM-DD HH:mm");

      if (moment(duedate).isValid()) {
        duedateText = moment(duedate).format("YYYY-MM-DD HH:mm");
      }
      setLoding(true);

      if (selectedTask.progress === 0 && progress > 0) {
        // Task completed, send event for task completed
        _cio.track(USER_START_A_TASK_EVENTS, {
          name: "user started task on task details",
        });
      }

      if (progress >= 100) {
        // Task completed, send event for task completed
        _cio.track(USER_COMPLETE_TASK_EVENTS, {
          name: "user mark task completed on task details",
        });
      }

      if (selectedTask && progress && progress !== selectedTask.progress) {
        // Task completed, send event for task completed
        _cio.track(USER_USE_PERCENT_COMPLETE_TO_MOVE_PROGRESS_OF_TASK_EVENTS, {
          name: "User update task progress from task details",
        });
      }

      const commentText = extractTextContent(comment);

      if (commentText && commentText.trim().length > 0) {
        // Task completed, send event for task completed
        _cio.track(USER_ADD_TEXT_COMMENTS_EVENTS, {
          name: "user added text in task comment",
        });
      }

      if (isCommentImageAdded) {
        // Task completed, send event for task completed
        _cio.track(USER_ADD_IMAGE_COMMENTS_EVENTS, {
          name: "user added image in task comment",
        });
        setCommentImageAdded(false);
      }

      if (isCommentVideoAdded) {
        // Task completed, send event for task completed
        _cio.track(USER_ADD_VIDEO_COMMENTS_EVENTS, {
          name: "user added video in task comment",
        });
        setCommentVideoAdded(false);
      }

      if (isCommentDocAdded) {
        // Task completed, send event for task completed
        _cio.track(USER_ADD_FILE_ATTACHMENT_COMMENTS_EVENTS, {
          name: "user added document in task comment",
        });
        setCommentDocAdded(false);
      }

      myAxios
        .put(`tasks-update/${selectedTask._id}`, {
          detail: editDetail ? editDetail : selectedTask.detail,
          isDescription: editDescription
            ? editDescription
            : selectedTask.isDescription,
          duedateText,
          duedate,
          progress,
          comment: commentToSend,
          subTasks: subTasks,
          priority,
          addedCategories,
          removedCategories,
        })
        .then((response: AxiosResponse<any>) => {
          NotificationManager.success("Changes saved successfully!");
          setComment("");
          setSubstasks(undefined);
          if (commentQuillObj) {
            commentQuillObj.getEditor().clipboard.dangerouslyPasteHTML("");
          }
          setSelectedTask(response.data);
          updateTask(response.data);
          setInputshow(true);

          // Need to refresh Menu Filter after addding new task

          myAxios
            .get(`menu-filters/${selectedTeam?.id}`)
            .then((response: any) => {
              addAssignee(response.data.data.assigneeList);
              addAssignor(response.data.data.assignorList);
              addDayFilters(response.data.data.daysMenuArray);
              addCategories(response.data.data.categories);
            })
            .catch((error) => {});

          //  Also fetch latest task as selected pagination

          myAxios
            .post(`tasks/paginated-tasks`, currentPaginationTaskInput)
            .then((response: any) => {
              if (response.data.data.tasks) addTasks(response.data.data.tasks);
              else addTasks([]);
              if (response.data.data.count)
                setTaskCount(response.data.data.count);
              else setTaskCount(0);
            })
            .catch((error) => {
              console.error("error ", error);
            });

          setLoding(false);
          // setModel(false);
          // navigate(`/`);
        })
        .catch((error) => {
          NotificationManager.error(parseErrorResponse(error));
        });
    }
  };

  useEffect(() => {
    // get method call to get the comments--
    if (selectedTask && selectedTask._id) {
      myAxios
        .get(`/comments/task/${selectedTask._id}`)
        .then((response) => {
          setTaskComments(response.data);
        })
        .catch((error) => {
          console.error("error at the time of getting comments --- ", error);
        });
    }
  }, [selectedTask, setTaskComments]);

  // uplaoding comment Image url
  useEffect(() => {
    if (commentUploadImageURL && commentQuillObj) {
      commentQuillObj
        .getEditor()
        .insertEmbed(commentQuillObj.index, "image", commentUploadImageURL);
    }
    setCommentUploadImageURL(undefined);
  }, [commentUploadImageURL, commentQuillObj]);

  // Setting uploaded comment document in to quill

  useEffect(() => {
    if (commentUploadedDocumentURL && commentQuillObj) {
      try {
        commentQuillObj
          .getEditor()
          .clipboard.dangerouslyPasteHTML(
            commentQuillObj.getEditor().getSelection().index + 10,
            `</br></br>`
          ); // used this for extra spacing before placing pdf
      } catch (error) {
        console.error(error);
      }

      commentQuillObj
        .getEditor()
        .insertEmbed(
          commentQuillObj.getEditor().getSelection().index + 10,
          "document",
          commentUploadedDocumentURL
        );

      commentQuillObj
        .getEditor()
        .clipboard.dangerouslyPasteHTML(
          commentQuillObj.getEditor().getSelection().index + 10,
          `</br></br></br></br>`
        ); // used this for extra spacing after placing pdf
    }
    setCommentUploadedDocumentURL(undefined);
  }, [commentUploadedDocumentURL, commentQuillObj]);

  const [commentUploadFilesNoPreview, setCommentFilesNoPreview] = useState();

  useEffect(() => {
    if (commentUploadFilesNoPreview && commentQuillObj) {
      commentQuillObj
        .getEditor()
        .insertEmbed(
          commentQuillObj.index,
          "file",
          commentUploadFilesNoPreview
        );
    }
    setCommentFilesNoPreview(undefined);
  }, [commentQuillObj, commentUploadFilesNoPreview]);

  // uplaoding description image url
  useEffect(() => {
    if (editDescriptionUploadImageURL && editDescriptionQuillObj) {
      editDescriptionQuillObj
        .getEditor()
        .insertEmbed(
          editDescriptionQuillObj.index,
          "image",
          editDescriptionUploadImageURL
        );
    }
    setEditDescriptionUploadImageURL(undefined);
  }, [editDescriptionQuillObj, editDescriptionUploadImageURL]);

  // setting uploaded document in to the Quill
  useEffect(() => {
    if (descriptionUploadedDocumentURL && editDescriptionQuillObj) {
      try {
        editDescriptionQuillObj
          .getEditor()
          .clipboard.dangerouslyPasteHTML(
            editDescriptionQuillObj.getEditor().getSelection().index + 10,
            `</br></br>`
          ); // used this for extra spacing before placing pdf
      } catch (error) {
        console.error(error);
      }

      editDescriptionQuillObj
        .getEditor()
        .insertEmbed(
          editDescriptionQuillObj.getEditor().getSelection().index + 10,
          "document",
          descriptionUploadedDocumentURL
        );

      editDescriptionQuillObj
        .getEditor()
        .clipboard.dangerouslyPasteHTML(
          editDescriptionQuillObj.getEditor().getSelection().index + 10,
          `</br></br></br></br>`
        );
    }
    setDescriptionUploadedDocumentURL(undefined);
  }, [editDescriptionQuillObj, descriptionUploadedDocumentURL]);

  // uplaoding comment video url
  useEffect(() => {
    if (commentVideoUrl && commentQuillObj) {
      commentQuillObj
        .getEditor()
        .insertEmbed(commentQuillObj.index, "video", commentVideoUrl);
    }
    setCommentVideoUrl(undefined);
  }, [commentQuillObj, commentVideoUrl]);

  // uplaoding description video url
  useEffect(() => {
    if (editDescriptionUploadVideoURL && editDescriptionQuillObj) {
      editDescriptionQuillObj
        .getEditor()
        .insertEmbed(
          editDescriptionQuillObj.index,
          "video",
          editDescriptionUploadVideoURL
        );
    }
    setEditDescriptionUploadVideoURL(undefined);
  }, [editDescriptionUploadVideoURL, editDescriptionQuillObj]);

  useEffect(() => {
    setComment("");
  }, []);

  const saveUserSettingsOnServer = useCallback(() => {
    let values = {
      name: "comment-popup",
      value: "true",
    };
    myAxios
      .post(`/user-settings-save`, values)
      .then((response) => {
        if (response.data && response.data.success) {
          setUserSettings({
            ...userSettings,
            stopProgressHelp: true,
          });

          setToolTip(false);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
    // eslint-disable-next-line
  }, [userSettings]);

  useEffect(() => {
    if (
      showModel &&
      userSettings.stopProgressHelp !== undefined &&
      userSettings.stopProgressHelp === false
    ) {
      setToolTip(true);
      setTimeout(() => {
        setToolTip(false);
      }, 10000);
    }
  }, [showModel, userSettings]);

  const base64CommentImageConversion = async (base64: any) => {
    if (base64.includes('<img src="data:image/png;base64')) {
      let converttedBase64 = base64.substring(
        base64.indexOf('<img src="data:image/png;base64')
      );
      converttedBase64 = converttedBase64.substring(
        0,
        converttedBase64.indexOf(">") + 1
      );
      let remaingText = base64.replace(converttedBase64, "");

      converttedBase64 = converttedBase64
        .replace("<p>", "")
        .replace("</p>", "")
        .replace("<img", "")
        .replace("src=", "")
        .replace(">", "")
        .replace(/"/g, "");

      const base64Response = await fetch(converttedBase64);
      const file = await base64Response.blob();
      const formData = new FormData();
      formData.append("files", file);
      sendBase64CommentImageToServer(formData, remaingText);
    } else {
      setComment(base64);
    }
  };

  const sendBase64CommentImageToServer = (formData: any, commentText: any) => {
    showLoader("Uploading image ...");
    myAxios
      .post("/upload", formData)
      .then((res) => {
        hideLoader();
        setCommentImageAdded(true);
        setComment(commentText);
        setCommentUploadImageURL(res.data[0].url);
      })
      .catch((error) => {});
  };

  // comment image uploaders
  const uploadCommentImage = useCallback(async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/files/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      showLoader("Uploading image ...");

      return myAxios.post("/upload", formData).then((res) => {
        if (res.data && res.data[0].ext && res.data[0].ext.length > 0) {
          if (res.data[0].ext === ".docx") {
            setCommentFilesNoPreview(res.data[0].url);
            setCommentDocAdded(true);
            hideLoader();
          }
        }

        if (res.data && res.data.length > 0) {
          setCommentImageAdded(true);
          setCommentUploadImageURL(res.data[0].url);
          hideLoader();
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  // Upload comment documents

  const uploadCommentDocument = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", acceptedDocumentTypes);
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      console.log("file ", file);
      console.log("file path ", URL.createObjectURL(file));
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading description document...");
      }
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();
        setCommentDocAdded(true);
        if (res.data && res.data.length > 0) {
          setCommentUploadedDocumentURL(res.data[0].url);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  // video uploader in comment field-
  const uplaodVideo = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      showLoader("Uploading video ...");

      return myAxios.post("/upload", formData).then((res) => {
        if (res.data && res.data.length > 0) {
          setCommentVideoAdded(true);
          setCommentVideoUrl(res.data[0].url);
          hideLoader();
        }
      });
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["video"],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: [] }],
          ["clean"],
        ],

        handlers: {
          image: uploadCommentImage,

          video: () => {
            uplaodVideo();
          },
          link: uploadCommentDocument,
        },
        table: true,
      },
    }),
    // eslint-disable-next-line
    []
  );

  // uplaod description image-
  const uploadDescriptionImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading image ...");
      }
      // const xhr = new XMLHttpRequest();
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();
        // xhr.onload = () => {
        if (res.data && res.data.length > 0) {
          setEditDescriptionUploadImageURL(res.data[0].url);
        }
        // xhr.send(formData);
        // }
      });
    };
  };

  // Upload description documents

  const uploadDescriptionDocuments = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", acceptedDocumentTypes);
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading description document...");
      }
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();

        if (res.data && res.data.length > 0) {
          setDescriptionUploadedDocumentURL(res.data[0].url);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  // uplaod description video editng time
  const uploadDescriptionVideo = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading image ...");
      }
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();

        if (res.data && res.data.length > 0) {
          setEditDescriptionUploadVideoURL(res.data[0].url);
        }
      });
    };
  };

  const modulesForDescription = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["video"],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: [] }],
          ["clean"],
        ],

        handlers: {
          image: uploadDescriptionImage,
          video: () => {
            uploadDescriptionVideo();
          },
          link: uploadDescriptionDocuments,
        },
        table: true,
      },
    }),
    // eslint-disable-next-line
    []
  );

  const checkIfAnySubTasIskNotCompleted = () => {
    if (subTasks && subTasks.length > 0) {
      const subTask = subTasks.find((o) => !o.isCompleted);
      if (subTask) return true;
    }
    return false;
  };

  const onCompletedChange = (index: number) => {
    if (subTasks) {
      subTasks[index].isCompleted = !subTasks[index].isCompleted;
      setSubstasks([...subTasks]);
    }
    if (checkIfAnySubTasIskNotCompleted() && progress >= 100) {
      setProgress(80);
    }
  };
  const [categoryHoverID, setCatHoverID] = useState();
  const [showCategory, setShowCategory] = useState(false);
  const handleCategoryHover = (index: any) => {
    setCatHoverID(index);
    setShowCategory(true);
  };

  useEffect(() => {
    if (showModel) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  });

  useEffect(() => {
    if (currentTaskNumber > 0 && showModel) {
      setLoding(true);
      myAxios
        .post(`tasks/paginated-tasks`, {
          ...currentPaginationTaskInput,
          pagination: { skip: currentTaskNumber - 1, limit: 1 },
        })
        .then((response: any) => {
          setLoding(false);
          if (response.data.data.tasks) {
            setSelectedTask(response.data.data.tasks[0]);
            navigate(`/${response.data.data.tasks[0]._id}`);
          }
        })
        .catch((error) => {
          setLoding(false);
          console.error("error ", error);
        });
    }
    // eslint-disable-next-line
  }, [currentTaskNumber, showModel]);

  const prevClick = () => {
    setNextIcon(NextTaskNormalIcon);
    if (currentTaskNumber > 1) {
      setCurrentTaskNumber(currentTaskNumber - 1);
    }
  };
  const nextClick = () => {
    setPrevIcon(PrevTaskNormalIcon);
    if (currentTaskNumber < taskCount) {
      setCurrentTaskNumber(currentTaskNumber + 1);
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      $("img").on("click", function () {
        const url = $(this).attr("src");
        if (url && url.length > 0 && url.startsWith("http")) {
          const imagIndex = taskImages.findIndex((img) => img === url);
          setPhotoIndex(imagIndex);
          setOpenLightBox(true);
        }
      });
    });
  });

  const textSelection = () => {
    // eslint-disable-next-line
    var selObj: any = (selObj = window.getSelection()?.toString());

    if (selObj && selObj.length > 3) {
      setShowJetbotAnimation(true);
      //var image: any = document.getElementById("myImage");
      setSelectionSearchText(selObj);
    } else {
      setShowJetbotAnimation(false);
      setSelectionSearchText("");
    }
  };

  const searchSuggestion = (searchText: string) => {
    searchText = removeTagsFromTaskDetail(searchText);

    if (searchText === "" || searchText.trim() === "") {
      NotificationManager.error(
        "Please enter task details to search suggestion...",
        "",
        3000
      );
      return;
    }
    //setSearchText(searchText);
    //setShowJetBotLoader(true);
    setGptSuggestionsText(undefined);

    setShowLoaderForSearchSuggestion(true);
    myAxios
      .post(`gpt-3/search-suggestion`, {
        searchText: searchText,
      })
      .then((response) => {
        if (response.data.success) {
          let suggestionText = "";
          if (response.data.data) {
            response.data.data.forEach((dt: any) => {
              suggestionText = suggestionText + "\n" + dt.text;
            });
          }
          suggestionText = removeLineBreakAtStart(suggestionText);

          suggestionText = suggestionText.replaceAll("\n", "<br>");
          setGptSuggestionsText(suggestionText);
          //setShowJetBotPopup(true);

          setShowLoaderForSearchSuggestion(false);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to search...",
            "",
            5000
          );
        }
        setShowLoaderForSearchSuggestion(false);
        //setShowJetBotLoader(false);
      })
      .catch((err) => {
        NotificationManager.error(parseErrorResponse(err));
        // setShowJetBotLoader(false);
      });
  };

  const [updateSubTaskOrderOnServer, setUpdateSubTaskOrderOnServer] =
    useState<boolean>(false);

  useEffect(() => {
    if (updateSubTaskOrderOnServer) {
      // Need to Update  sub tasks order on server

      myAxios
        .post(
          "/update-sub-tasks-order",
          subTasks?.map((st) => ({ id: st._id, order: st.order }))
        )
        .then((res: any) => {
          setUpdateSubTaskOrderOnServer(false);

          if (
            res.data.success &&
            selectedTask &&
            selectedTask._id &&
            subTasks
          ) {
            updateSubTasks(selectedTask._id, subTasks);
          }
        })
        .catch((error) => {
          setUpdateSubTaskOrderOnServer(false);
          console.error(
            "Getting Error at the time of Updating order of sub tasks ---- \n ",
            error
          );
        });
    }
    // eslint-disable-next-line
  }, [updateSubTaskOrderOnServer]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    if (subTasks) {
      let items: any = Array.from(subTasks);

      const [removedItem]: any = items?.splice(result.source.index, 1);

      items?.splice(result.destination.index, 0, removedItem);
      items = items.map((it: any, index: number) => ({
        ...it,
        order: index + 1,
      }));

      setSubstasks(items);
      setUpdateSubTaskOrderOnServer(true);
    }
  };

  return (
    <>
      <GlobalStyle />

      {showModel && selectedTask ? (
        <MainDiv>
          {loding && (
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          )}

          <EmptyDiv onClick={closeTaskDetailsForm}>
            <CloseTaskImg
              src={closeIcon}
              alt="cross"
              onMouseEnter={() => {
                setCloseIcon(CloseTaskHoverIcon);
                setShowJetbotAnimation(false);
              }}
              onMouseLeave={() => {
                setCloseIcon(CloseTaskNormalIcon);
              }}
              onClick={closeTaskDetailsForm}
            />
          </EmptyDiv>
          <Container
            isopen={isopen}
            onMouseUp={(e: any) => {
              const x = (e.screenX / e.view.screen.width) * 100;
              const y = (e.screenY / e.view.screen.height) * 100;

              if (x > 80) {
                setScreenX(x - 25);
                setScreenY(y - 30);
              } else {
                setScreenX(x - 10);
                setScreenY(y - 30);
              }

              textSelection();
            }}
          >
            {checkIfFeatureOpen(userState?.user?.appFeatures, GPT_3_OPENAI) && (
              <>
                {showJetBotAnimation && (
                  <JetBotAnimationGifTag
                    src={JetBotWithImage}
                    alt="jetbot"
                    id="myImage"
                    style={{
                      display: "block",
                      position: "absolute",
                      left: `${screenX}%`,
                      top: `${screenY}%`,
                    }}
                    onClick={(e) => {
                      _cio.track(
                        USE_JETBOT_FOR_SELECTED_TEXT_IN_TASK_DETAILS_EVENTS,
                        {
                          name: "use jetbot in task details",
                        }
                      );
                      setShowSuggestionPopUpInTaskDetail(
                        !showSuggestionPopUpInTaskDetail
                      );

                      setShowSuggestionPopUp(true);
                      setShowJetbotAnimation(false);

                      searchSuggestion(SelectionSearchText);
                      // for removing selected content via mouse--
                      window.getSelection()?.removeAllRanges();
                    }}
                  />
                )}
              </>
            )}

            {showSuggestionPopUp && (
              <JetBotSuggestionPopup
                gptSuggestionsText={gptSuggestionsText}
                setShowJetBotPopup={setShowSuggestionPopUp}
                // keepInDescription={keepInDescription}
                searchText={SelectionSearchText}
                setGptSuggestionsText={setGptSuggestionsText}
                showSuggestionPopUpInTaskDetail={showSuggestionPopUp}
                setShowTextSelection={setShowJetbotAnimation}
              />
            )}

            <HeaderDiv>
              <Row>
                <Col xs={6}>
                  <div style={{ display: "flex", justifyContent: "left" }}>
                    <LogoImg src={jetlist} alt="jetlist" />
                  </div>
                </Col>
                <Col xs={6}>
                  {/* Show pagination indicator only if task is loaded 
                  from task list on task list items click, 
                  in the case of direct refresh (hard refresh) pagination indicator would not be shown */}
                  {currentTaskNumber > 0 && (
                    <NextPrevTaskDiv>
                      <TaskRemainText>{taskRemainText}</TaskRemainText>
                      {isPrevInActive ? (
                        <PrevNextDisabledImg
                          src={PrevTaskDisabledIcon}
                          alt="Previous"
                        />
                      ) : (
                        <PrevNextImg
                          src={prevIcon}
                          alt="Previous"
                          onMouseEnter={() => {
                            setPrevIcon(PrevTaskHoverIcon);
                          }}
                          onMouseLeave={() => {
                            setPrevIcon(PrevTaskNormalIcon);
                          }}
                          onClick={prevClick}
                        />
                      )}

                      {isNextInActive ? (
                        <PrevNextDisabledImg
                          src={NextTaskDisabledIcon}
                          alt="Next"
                        />
                      ) : (
                        <PrevNextImg
                          src={nextIcon}
                          alt="Next"
                          onMouseEnter={() => {
                            setNextIcon(NextTaskHoverIcon);
                          }}
                          onMouseLeave={() => {
                            setNextIcon(NextTaskNormalIcon);
                          }}
                          onClick={nextClick}
                        />
                      )}
                    </NextPrevTaskDiv>
                  )}
                </Col>
              </Row>
            </HeaderDiv>
            {Inputshow ? (
              <>
                <Row
                  style={{
                    borderBottom: "1px solid #f4f4f4",
                    alignItems: "center",
                  }}
                >
                  {/* this section visible in desktop view */}
                  {isDesktopScreen && (
                    <ColPriorityCategoryTag>
                      {selectedTask.priority && selectedTask.priority > 0 ? (
                        <>
                          {selectedTask.priority === 5 ? (
                            <PriorityTag color="#11b753">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 4 ? (
                            <PriorityTag color="#eccc22">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 3 ? (
                            <PriorityTag color="#dd8426">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 2 ? (
                            <PriorityTag color="#ff4004">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 1 ? (
                            <PriorityTag color="#a80000">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                        </>
                      ) : null}

                      <ParaFirst>
                        {categories && categories.length > 0
                          ? categories
                              .map((c) => {
                                return c.name;
                              })
                              .join(", ")
                          : ""}{" "}
                      </ParaFirst>
                    </ColPriorityCategoryTag>
                  )}

                  {/* this section visible in mobile view */}
                  {!isDesktopScreen && (
                    <ColPriorityCategoryTag sm={4}>
                      {selectedTask.priority && selectedTask.priority > 0 ? (
                        <>
                          {selectedTask.priority === 5 ? (
                            <PriorityTag color="#11b753">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 4 ? (
                            <PriorityTag color="#eccc22">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 3 ? (
                            <PriorityTag color="#dd8426">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 2 ? (
                            <PriorityTag color="#ff4004">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                          {selectedTask.priority === 1 ? (
                            <PriorityTag color="#a80000">
                              P:{selectedTask.priority}
                            </PriorityTag>
                          ) : null}
                        </>
                      ) : null}

                      <ParaFirst>
                        {isDesktopScreen && categories && categories.length > 0
                          ? categories
                              .map((c) => {
                                return c.name;
                              })
                              .join(", ")
                          : ""}{" "}
                      </ParaFirst>
                    </ColPriorityCategoryTag>
                  )}
                  {isDesktopScreen && (
                    <Col sm={4} style={{ flex: "1" }}>
                      <ParaSecond>
                        Assigned To:{" "}
                        {selectedTask.assignedTo?.firstName &&
                        selectedTask.assignedTo?.lastName
                          ? `${selectedTask.assignedTo?.firstName} ${selectedTask.assignedTo?.lastName}`
                          : selectedTask.assignedTo?.email}
                      </ParaSecond>
                    </Col>
                  )}
                  {/* this section visible in desktop screen */}
                  {isDesktopScreen && (
                    <ColAssignedTag isDesktopScreen={isDesktopScreen} sm={4}>
                      <ParaSecond>
                        Assigned By:
                        {/* <b> */}{" "}
                        {selectedTask.assignedFrom?.firstName &&
                        selectedTask.assignedFrom?.lastName
                          ? `${selectedTask.assignedFrom?.firstName} ${selectedTask.assignedFrom?.lastName}`
                          : selectedTask.assignedFrom?.email}
                        {/* </b> */}
                      </ParaSecond>
                      <ParaThird>{selectedTask.timeRemaining}</ParaThird>
                      {selectedTask.assignedFrom!._id ===
                        userState.user?.id && (
                        <div style={{ position: "relative" }}>
                          <EditIcon
                            src={IconForEdit}
                            alt="icon"
                            onClick={() => {
                              editTask();
                            }}
                          />
                        </div>
                      )}
                    </ColAssignedTag>
                  )}
                  {/* **************** this section visible in mobile screen ********************/}
                  {!isDesktopScreen && (
                    <Col sm={4}>
                      <ParaSecondMobile>
                        Assigned To:
                        {selectedTask.assignedTo?.firstName &&
                        selectedTask.assignedTo?.lastName
                          ? `${selectedTask.assignedTo?.firstName} ${selectedTask.assignedTo?.lastName}`
                          : selectedTask.assignedTo?.email}
                      </ParaSecondMobile>
                    </Col>
                  )}

                  {!isDesktopScreen && (
                    <ColAssignedTagMobile sm={4}>
                      <ParaSecondMobile>
                        Assigned By:
                        {selectedTask.assignedFrom?.firstName &&
                        selectedTask.assignedFrom?.lastName
                          ? `${selectedTask.assignedFrom?.firstName} ${selectedTask.assignedFrom?.lastName}`
                          : selectedTask.assignedFrom?.email}
                      </ParaSecondMobile>
                      {selectedTask.assignedFrom!._id ===
                        userState.user?.id && (
                        <div>
                          <EditIcon
                            src={IconForEdit}
                            alt="icon"
                            onClick={() => {
                              editTask();
                            }}
                          />
                        </div>
                      )}
                    </ColAssignedTagMobile>
                  )}
                </Row>
                {/* this section visible on mobile view */}
                {!isDesktopScreen && (
                  <ParaFirst>
                    {categories && categories.length > 0
                      ? categories
                          .map((c) => {
                            return c.name;
                          })
                          .join(", ")
                      : ""}
                  </ParaFirst>
                )}
              </>
            ) : (
              <>
                <Row>
                  {isDesktopScreen && (
                    <ColPriorityCategoryTagForMobile>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((c, index) => (
                          <CategoryEditTag>
                            <CategoryPTag
                              data-tip
                              data-for="registerTip"
                              onMouseOver={() => {
                                handleCategoryHover(index + 1);
                              }}
                            >
                              {c.name}
                            </CategoryPTag>

                            {showCategory && categoryHoverID === index + 1 && (
                              <ReactTooltip
                                id="registerTip"
                                place="top"
                                effect="solid"
                              >
                                {c.name}
                              </ReactTooltip>
                            )}

                            <CategoryCrossIcon
                              src={DeleteCrossSvg}
                              alt="icon"
                              onClick={() => {
                                if (c._id) {
                                  setRemovedCategories([
                                    ...removedCategories,
                                    c._id,
                                  ]);
                                }

                                categories.splice(index, 1);

                                setCategories([...categories]);

                                if (
                                  addedCategories &&
                                  addedCategories.length > 0
                                ) {
                                  const addNewIndex = addedCategories.indexOf(
                                    c.name
                                  );
                                  if (addNewIndex > -1) {
                                    addedCategories.splice(addNewIndex, 1);
                                    setAddedCategories([...addedCategories]);
                                  }
                                }
                              }}
                            />
                          </CategoryEditTag>
                        ))}
                    </ColPriorityCategoryTagForMobile>
                  )}

                  <ColAssignedTag isDesktopScreen={isDesktopScreen}>
                    <ParaSecond>
                      Assigned By:
                      {/* <b> */}{" "}
                      {selectedTask.assignedFrom?.firstName &&
                      selectedTask.assignedFrom?.lastName
                        ? `${selectedTask.assignedFrom?.firstName} ${selectedTask.assignedFrom?.lastName}`
                        : selectedTask.assignedFrom?.email}
                      {/* </b> */}
                    </ParaSecond>
                    <ParaThird>
                      {/* <b> */}
                      {selectedTask.timeRemaining}
                      {/* </b> */}
                    </ParaThird>

                    {!Inputshow && (
                      <CrossTag
                        src={Cross}
                        alt="icon"
                        onClick={() => {
                          setInputshow(true);
                          setShowJetbotAnimation(false);
                        }}
                      />
                    )}
                  </ColAssignedTag>
                </Row>

                {/* this is section visible in mobile  */}
                {!isDesktopScreen && (
                  <ColPriorityCategoryTag>
                    <DivForScroll>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((c, index) => (
                          <CategoryEditTag>
                            <CategoryPTag
                              data-tip
                              data-for="registerTip"
                              onMouseOver={() => {
                                handleCategoryHover(index + 1);
                              }}
                            >
                              {c.name}
                            </CategoryPTag>

                            {showCategory && categoryHoverID === index + 1 && (
                              <ReactTooltip
                                id="registerTip"
                                place="top"
                                effect="solid"
                              >
                                {c.name}
                              </ReactTooltip>
                            )}

                            <CategoryCrossIcon
                              src={DeleteCrossSvg}
                              alt="icon"
                              onClick={() => {
                                if (c._id) {
                                  setRemovedCategories([
                                    ...removedCategories,
                                    c._id,
                                  ]);
                                }

                                categories.splice(index, 1);

                                setCategories([...categories]);

                                if (
                                  addedCategories &&
                                  addedCategories.length > 0
                                ) {
                                  const addNewIndex = addedCategories.indexOf(
                                    c.name
                                  );
                                  if (addNewIndex > -1) {
                                    addedCategories.splice(addNewIndex, 1);
                                    setAddedCategories([...addedCategories]);
                                  }
                                }
                              }}
                            />
                          </CategoryEditTag>
                        ))}
                    </DivForScroll>
                  </ColPriorityCategoryTag>
                )}

                {isDesktopScreen && (
                  <RowForPriority>
                    <Col xs={2}>
                      <HeadingParagraph>Priority</HeadingParagraph>
                      <DivForDropDown>
                        <select
                          className="DropDownNumber"
                          defaultValue={
                            selectedTask.priority ? selectedTask.priority : 0
                          }
                          onChange={(e) => {
                            setPriority(parseInt(e.target.value));
                          }}
                        >
                          <option value={0}>none</option>
                          <option value={1}>p:1</option>
                          <option value={2}>p:2</option>
                          <option value={3}>p:3</option>
                          <option value={4}>p:4</option>
                          <option value={5}>p:5</option>
                        </select>
                      </DivForDropDown>
                    </Col>

                    <Col xs={3}>
                      <HeadingParagraph>Add Category</HeadingParagraph>
                      <CategoryDiv>
                        <CategoryInputBox
                          type="text"
                          value={newCategoryName}
                          onChange={(e: any) => {
                            if (
                              !e.target.value ||
                              e.target.value === "" ||
                              regRestrictSpecialCharacters.test(e.target.value)
                            ) {
                              setNewCategoryName(e.target.value);
                            }
                          }}
                          placeholder="NewCategory"
                        />
                        <AddCategoryImage
                          src={AddCategorySvg}
                          alt="Add Category Image"
                          width="21px"
                          height="21px"
                          onClick={() => {
                            if (newCategoryName && newCategoryName.length > 0) {
                              const catIndex = categories
                                .map((cat) => cat.name)
                                .indexOf(newCategoryName.trim());

                              if (catIndex < 0) {
                                setCategories([
                                  ...categories,
                                  { name: newCategoryName },
                                ]);
                                setNewCategoryName("");

                                const addNewIndex =
                                  addedCategories.indexOf(newCategoryName);
                                if (addNewIndex < 0)
                                  setAddedCategories([
                                    ...addedCategories,
                                    newCategoryName,
                                  ]);
                              } else {
                                NotificationManager.warning(
                                  "Category already added!"
                                );
                              }
                            }
                          }}
                        />
                      </CategoryDiv>
                    </Col>

                    <Col xs={3}>
                      <HeadingParagraph>Due Date</HeadingParagraph>
                      <DatePickerParent>
                        <DatePicker
                          name="date"
                          selected={duedate}
                          showTimeSelect
                          minDate={moment().add(1, "day").toDate()}
                          dateFormat="Pp"
                          open={showDatePicker}
                          onClickOutside={() => {
                            setShowDatePicker(false);
                          }}
                          onChange={(date: Date) => {
                            setDueDate(date);
                          }}
                          onInputClick={() => {
                            setShowDatePicker(true);
                          }}
                        />
                        <CalenderImgTag
                          src={CalenderSvg}
                          alt="image"
                          onClick={() => {
                            setShowDatePicker(true);
                          }}
                        />
                      </DatePickerParent>
                    </Col>
                    <Col xs={4}></Col>
                  </RowForPriority>
                )}
              </>
            )}
            {/* this section visible on mobile */}

            {!isDesktopScreen && !Inputshow && (
              <RowForPriority>
                <Col>
                  <HeadingParagraph>Priority</HeadingParagraph>
                  <DivForDropDown>
                    <select
                      className="DropDownNumber"
                      defaultValue={
                        selectedTask.priority ? selectedTask.priority : 0
                      }
                      onChange={(e) => {
                        setPriority(parseInt(e.target.value));
                      }}
                    >
                      <option value={0}>none</option>
                      <option value={1}>p:1</option>
                      <option value={2}>p:2</option>
                      <option value={3}>p:3</option>
                      <option value={4}>p:4</option>
                      <option value={5}>p:5</option>
                    </select>
                  </DivForDropDown>
                </Col>

                <Col xs={7}>
                  <HeadingParagraph>Add Category</HeadingParagraph>
                  <CategoryDiv>
                    <CategoryInputBox
                      type="text"
                      value={newCategoryName}
                      onChange={(e: any) => {
                        setNewCategoryName(e.target.value);
                      }}
                      placeholder="NewCategory"
                    />
                    <AddCategoryImage
                      src={AddCategorySvg}
                      alt="Add Category Image"
                      width="21px"
                      height="21px"
                      onClick={() => {
                        if (newCategoryName && newCategoryName.length > 0) {
                          const catIndex = categories
                            .map((cat) => cat.name)
                            .indexOf(newCategoryName.trim());

                          if (catIndex < 0) {
                            setCategories([
                              ...categories,
                              { name: newCategoryName },
                            ]);
                            setNewCategoryName("");

                            const addNewIndex =
                              addedCategories.indexOf(newCategoryName);
                            if (addNewIndex < 0)
                              setAddedCategories([
                                ...addedCategories,
                                newCategoryName,
                              ]);
                          } else {
                            NotificationManager.warning(
                              "Category already added!"
                            );
                          }
                        }
                      }}
                    />
                  </CategoryDiv>
                </Col>

                <Col xs={7}>
                  <HeadingParagraph>Due Date</HeadingParagraph>
                  <DatePickerParent>
                    <DatePicker
                      name="date"
                      selected={duedate}
                      showTimeSelect
                      minDate={moment().add(1, "day").toDate()}
                      dateFormat="Pp"
                      open={showDatePicker}
                      onClickOutside={() => {
                        setShowDatePicker(false);
                      }}
                      onChange={(date: Date) => {
                        setDueDate(date);
                      }}
                      onInputClick={() => {
                        setShowDatePicker(true);
                      }}
                    />
                    <CalenderImgTag
                      src={CalenderSvg}
                      alt="image"
                      onClick={() => {
                        setShowDatePicker(true);
                      }}
                    />
                  </DatePickerParent>
                </Col>
              </RowForPriority>
            )}

            <DivForTaskAndComment>
              {Inputshow ? (
                <Heading>
                  <SpanForDetail>
                    <UrlToLink text={selectedTask.detail!} />
                  </SpanForDetail>
                  <DescriptionText className={"description" + 0}>
                    <UrlToLink text={selectedTask.isDescription!} />
                  </DescriptionText>

                  {selectedTask.isTemplateTask ? (
                    <>
                      <TaskContainer>
                        <TemplateKeyTag>
                          <ProjectTemplateKeyText>
                            <b>Project Template Key: </b>
                            {`${selectedTask?.templateData?.uniqueKey!}`}
                          </ProjectTemplateKeyText>
                        </TemplateKeyTag>

                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="characters">
                            {(provided) => (
                              <ul
                                style={{ padding: "0" }}
                                className="characters"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {subTasks &&
                                  subTasks.map(
                                    (subtask: SubTask, index: number) => (
                                      <>
                                        <Draggable
                                          key={subtask._id}
                                          draggableId={subtask._id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <LI
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ListContainer key={subtask._id}>
                                                <TaskParagraph>
                                                  <SubTaskIndicatorImgTag
                                                    src={SubTaskIndicatorSvg}
                                                    alt="subtask-indicator"
                                                    width="25px"
                                                    height="22px"
                                                  />
                                                  {/* <b
                                                    style={{ padding: "0 5px" }}
                                                  > */}
                                                  {subtask.name}
                                                  {/* </b> */}
                                                </TaskParagraph>
                                                <CheckBoxContainer>
                                                  {!subtask.isCompleted ? (
                                                    <ParagraphCheckBox
                                                      className="container-checkbox"
                                                      onMouseLeave={() => {
                                                        setTickHover(false);
                                                      }}
                                                      onMouseEnter={() =>
                                                        setTickHover(true)
                                                      }
                                                    >
                                                      <MarkCompleteImgTag
                                                        src={
                                                          tickHover
                                                            ? GreenCircleSvg
                                                            : GrayCircleSvg
                                                        }
                                                        alt="subtask-indicator"
                                                        width="20px"
                                                        height="14px"
                                                        onClick={() => {
                                                          onCompletedChange(
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      Mark as completed
                                                    </ParagraphCheckBox>
                                                  ) : (
                                                    <ParagraphCheckBox className="container-checkbox-complete">
                                                      <MarkCompleteImgTag
                                                        src={GreenCompletedSvg}
                                                        alt="subtask-indicator"
                                                        width="20px"
                                                        height="14px"
                                                        onClick={() => {
                                                          onCompletedChange(
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      <span
                                                        style={{
                                                          color: "#11B753",
                                                        }}
                                                      >
                                                        Completed
                                                      </span>
                                                    </ParagraphCheckBox>
                                                  )}
                                                </CheckBoxContainer>
                                              </ListContainer>
                                              <Row>
                                                <Col>
                                                  <TaskSubTask className="subtaskDescription">
                                                    <UrlToLink
                                                      text={subtask.description}
                                                    />
                                                  </TaskSubTask>
                                                </Col>
                                              </Row>
                                            </LI>
                                          )}
                                        </Draggable>
                                      </>
                                    )
                                  )}

                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </TaskContainer>
                    </>
                  ) : null}
                </Heading>
              ) : (
                <>
                  <InputShowHeading
                    type="text"
                    defaultValue={selectedTask.detail}
                    onChange={(e) => {
                      handleDetail(e);
                    }}
                  />

                  <ReactQuill
                    ref={(el) => {
                      setEditDescriptionQuillObj(el);
                    }}
                    onChange={(e) => {
                      handleDescription(e);
                    }}
                    modules={modulesForDescription}
                    theme="snow"
                    style={{ flex: "auto" }}
                    defaultValue={selectedTask.isDescription}
                    placeholder="Edit description here"
                    formats={quillFormats}
                  />
                  {selectedTask.isTemplateTask ? (
                    <>
                      <TaskContainerEdit>
                        <TemplateKeyTag>
                          <ProjectTemplateKeyText>
                            <b>Project Template Key: </b>
                            {`${selectedTask?.templateData?.uniqueKey!}`}
                          </ProjectTemplateKeyText>
                        </TemplateKeyTag>
                        {/* ********************** editing time sub task list ****************** */}

                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="characters">
                            {(provided) => (
                              <ul
                                style={{ padding: "0" }}
                                className="characters"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {subTasks &&
                                  subTasks.map(
                                    (subtask: SubTask, index: number) => (
                                      <>
                                        <Draggable
                                          key={subtask._id}
                                          draggableId={subtask._id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <LI
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ListContainer key={subtask._id}>
                                                <TaskParagraph>
                                                  <SubTaskIndicatorImgTag
                                                    src={SubTaskIndicatorSvg}
                                                    alt="subtask-indicator"
                                                    width="25px"
                                                    height="22px"
                                                  />
                                                  <b
                                                    style={{ padding: "0 5px" }}
                                                  >
                                                    {subtask.name}
                                                  </b>
                                                </TaskParagraph>
                                                <CheckBoxContainer>
                                                  {!subtask.isCompleted ? (
                                                    <ParagraphCheckBox
                                                      className="container-checkbox"
                                                      onMouseLeave={() => {
                                                        setTickHover(false);
                                                      }}
                                                      onMouseEnter={() =>
                                                        setTickHover(true)
                                                      }
                                                    >
                                                      <MarkCompleteImgTag
                                                        src={
                                                          tickHover
                                                            ? GreenCircleSvg
                                                            : GrayCircleSvg
                                                        }
                                                        alt="subtask-indicator"
                                                        width="20px"
                                                        height="14px"
                                                        onClick={() => {
                                                          onCompletedChange(
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      Mark as completed
                                                    </ParagraphCheckBox>
                                                  ) : (
                                                    <ParagraphCheckBox className="container-checkbox-complete">
                                                      <MarkCompleteImgTag
                                                        src={GreenCompletedSvg}
                                                        alt="subtask-indicator"
                                                        width="20px"
                                                        height="14px"
                                                        onClick={() => {
                                                          onCompletedChange(
                                                            index
                                                          );
                                                        }}
                                                      />
                                                      <span
                                                        style={{
                                                          color: "#11B753",
                                                        }}
                                                      >
                                                        Completed
                                                      </span>
                                                    </ParagraphCheckBox>
                                                  )}
                                                </CheckBoxContainer>
                                              </ListContainer>
                                              <TaskSubTask className="subtaskDescription">
                                                <UrlToLink
                                                  text={subtask.description}
                                                />
                                              </TaskSubTask>
                                            </LI>
                                          )}
                                        </Draggable>
                                      </>
                                    )
                                  )}

                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </TaskContainerEdit>
                    </>
                  ) : null}
                </>
              )}

              {/* full size image view ------- */}

              {openLightBox && (
                <Lightbox
                  mainSrc={taskImages[photoIndex]}
                  nextSrc={taskImages[(photoIndex + 1) % taskImages.length]}
                  prevSrc={
                    taskImages[
                      (photoIndex + taskImages.length - 1) % taskImages.length
                    ]
                  }
                  onCloseRequest={() => setOpenLightBox(false)}
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % taskImages.length)
                  }
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + taskImages.length - 1) % taskImages.length
                    )
                  }
                />
              )}

              {taskComments && taskComments && taskComments.length > 0 ? (
                <CommentsContainer>
                  <SpanTag>COMMENTS:</SpanTag>
                  {taskComments.map((comment, index) => (
                    <DivCommentpara key={comment._id}>
                      <DateSpan>
                        By:
                        {comment.addedBy ? getFullName(comment.addedBy) : ""} (
                        {moment(comment.date).format("Do MMM, YYYY hh:mm a")})
                      </DateSpan>

                      <CommentParagraph className={"test" + index}>
                        <UrlToLink text={comment!.comment!} />
                      </CommentParagraph>

                      {comment.image && comment.image!.url && (
                        <CommentImage src={comment.image.url} alt="image" />
                      )}
                    </DivCommentpara>
                  ))}
                </CommentsContainer>
              ) : null}
            </DivForTaskAndComment>
            <BottomRow>
              <DivforBottom>
                {!selectedTask.cced && (
                  <BottomLeftCol xs={9}>
                    <EnterCommentSpan>Enter Comments</EnterCommentSpan>

                    {showToolTip ? (
                      <DivForToolTip>
                        <ToolText>
                          <DivForCross>
                            <CrossImgTagToolTip
                              src={CrossSvg}
                              className="BlackCross"
                              alt="cross"
                              onClick={() => {
                                setToolTip(false);
                              }}
                            />
                            <CrossImgTagToolTip
                              src={GreyCrossSvg}
                              alt="cross"
                              className="GreyCross"
                              onClick={() => {
                                setToolTip(false);
                              }}
                            />
                          </DivForCross>
                          The progress bar can be used to represent the status
                          of a specific task or project. To show that you are
                          actively working on the task or planning to have the
                          task completed soon, you can use the progress bar to
                          indicate the percentage of a task that has been
                          completed. Starting from the right side, each box on
                          the progress bar represents 20 percent of work
                          completed. Add comments as needed to explain the
                          progress made with this update.
                          <TooltipbtnDiv>
                            <TooltipBtn
                              type="submit"
                              onClick={() => {
                                saveUserSettingsOnServer();
                              }}
                            >
                              Do not show this again
                            </TooltipBtn>
                          </TooltipbtnDiv>
                        </ToolText>
                      </DivForToolTip>
                    ) : null}

                    <DivForEnterComments>
                      {comment ? (
                        <ReactQuill
                          ref={(el) => {
                            setCommentQuillObj(el);
                          }}
                          placeholder="Enter Comments"
                          onChange={handleChange}
                          // onFocus={handleTool}
                          modules={modules}
                          value={comment}
                          theme="snow"
                          style={{ flex: "auto" }}
                          formats={quillFormats}
                          id="resize"
                        />
                      ) : (
                        <ReactQuill
                          ref={(el) => {
                            setCommentQuillObj(el);
                          }}
                          placeholder="Enter Comments"
                          onChange={handleChange}
                          // onFocus={handleTool}
                          modules={modules}
                          // value={comment && comment}
                          theme="snow"
                          style={{ flex: "auto" }}
                          formats={quillFormats}
                          id="resize"
                        />
                      )}
                    </DivForEnterComments>
                  </BottomLeftCol>
                )}

                <BottomRightCol xs={3}>
                  <ProgressActionParent>
                    <div>
                      <Paragraph>Progress:</Paragraph>
                      <ProgressContainer>
                        {progress >= 20 ? (
                          <ColColorBlue
                            onMouseLeave={() => {
                              setHoverCol(false);
                            }}
                            onMouseOver={() =>
                              handleHoverOnCol(0, selectedTask?._id)
                            }
                            onClick={() => {
                              let progressValue = true;
                              if (!progressValue) {
                                alert("Please select a valid progress");
                              }
                              if (!selectedTask.cced) setProgress(0);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                              // when users not updated the progress then it will show error message to user
                            }}
                          />
                        ) : hoverCol ? (
                          taskIdCol === selectedTask._id ? (
                            <ColColorBlue
                              onMouseLeave={() => {
                                setHoverCol(false);
                              }}
                              onClick={() => {
                                let progressValue = true;
                                if (!progressValue) {
                                  alert("Please select a valid progress");
                                }
                                if (!selectedTask.cced) setProgress(0);
                                else
                                  NotificationManager.error(
                                    "Watchers are not allowed to update progress!"
                                  );
                                // when users not updated the progress then it will show error message to user
                              }}
                            />
                          ) : (
                            <ColColor />
                          )
                        ) : (
                          <ColColor
                            onClick={() => {
                              if (!selectedTask.cced) setProgress(20);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                          />
                        )}

                        {progress >= 40 ? (
                          <ColColorBlue
                            onMouseOver={() =>
                              handleHoverOnCol(2, selectedTask?._id)
                            }
                            onClick={() => {
                              if (!selectedTask.cced) setProgress(20);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                          />
                        ) : hoverCol && 2 <= hoverColNum ? (
                          taskIdCol === selectedTask._id ? (
                            <ColColorBlue
                              onClick={() => {
                                if (!selectedTask.cced) setProgress(40);
                                else
                                  NotificationManager.error(
                                    "Watchers are not allowed to update progress!"
                                  );
                              }}
                              onMouseLeave={() => setHoverCol(false)}
                            />
                          ) : (
                            <ColColor />
                          )
                        ) : (
                          <ColColor
                            onMouseOver={() =>
                              handleHoverOnCol(2, selectedTask?._id)
                            }
                            onMouseLeave={() => setHoverCol(false)}
                          />
                        )}
                        {progress >= 60 ? (
                          <ColColorBlue
                            onMouseOver={() =>
                              handleHoverOnCol(3, selectedTask?._id)
                            }
                            onClick={() => {
                              if (!selectedTask.cced) setProgress(40);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                          />
                        ) : hoverCol && 3 <= hoverColNum ? (
                          taskIdCol === selectedTask._id ? (
                            <ColColorBlue
                              onClick={() => {
                                if (!selectedTask.cced) setProgress(60);
                                else
                                  NotificationManager.error(
                                    "Watchers are not allowed to update progress!"
                                  );
                              }}
                              onMouseLeave={() => setHoverCol(false)}
                            />
                          ) : (
                            <ColColor />
                          )
                        ) : (
                          <ColColor
                            onMouseOver={() =>
                              handleHoverOnCol(3, selectedTask?._id)
                            }
                            onMouseLeave={() => setHoverCol(false)}
                          />
                        )}
                        {progress >= 80 ? (
                          <ColColorBlue
                            onMouseOver={() =>
                              handleHoverOnCol(4, selectedTask?._id)
                            }
                            onClick={() => {
                              if (!selectedTask.cced) setProgress(60);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                          />
                        ) : hoverCol && 4 <= hoverColNum ? (
                          taskIdCol === selectedTask._id ? (
                            <ColColorBlue
                              onClick={() => {
                                if (!selectedTask.cced) setProgress(80);
                                else
                                  NotificationManager.error(
                                    "Watchers are not allowed to update progress!"
                                  );
                              }}
                              onMouseLeave={() => setHoverCol(false)}
                            />
                          ) : (
                            <ColColor />
                          )
                        ) : (
                          <ColColor
                            onMouseOver={() =>
                              handleHoverOnCol(4, selectedTask?._id)
                            }
                            onMouseLeave={() => setHoverCol(false)}
                          />
                        )}
                        {progress >= 100 ? (
                          <ColColorBlue
                            onClick={() => {
                              if (!selectedTask.cced) setProgress(80);
                              else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                          />
                        ) : (
                          <ColColor
                            onClick={() => {
                              // Need to check if task is from template and if it is, then process could not be set as 100%
                              //if any of sub task is not marked as completed

                              if (!selectedTask.cced) {
                                if (
                                  selectedTask.isTemplateTask &&
                                  checkIfAnySubTasIskNotCompleted()
                                ) {
                                  NotificationManager.error(
                                    "Task can only be completed once all sub-tasks are marked completed!"
                                  );
                                } else setProgress(100);
                              } else
                                NotificationManager.error(
                                  "Watchers are not allowed to update progress!"
                                );
                            }}
                            onMouseOver={() =>
                              handleHoverOnCol(4, selectedTask?._id)
                            }
                            onMouseLeave={() => setHoverCol(false)}
                          />
                        )}
                      </ProgressContainer>
                    </div>
                    <Button
                      type="submit"
                      onClick={() => {
                        updateTaskClick();
                      }}
                      style={{ margin: "10px 0px" }}
                    >
                      Save Changes
                    </Button>
                  </ProgressActionParent>
                </BottomRightCol>
              </DivforBottom>
            </BottomRow>
          </Container>
        </MainDiv>
      ) : null}
    </>
  );
};

export default TaskDetails;
