import {
  createContext, ReactElement,
  useEffect, useReducer, useState
} from "react";
import { io } from "socket.io-client";
import {
  UserAction, UserContextProps, UserContextValues, UserStateType
} from "../types";

import { NotificationManager } from "react-notifications";
import { LOGGED_IN, LOGGED_OUT, UPDATE_TEAM, UPDATE_USER } from "../consts";

const socket = io(
  process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : ""
);

export const UserContext = createContext({} as UserContextValues);

const initialState: UserStateType = {
  user: null,
  isAuthenticated: false,
};

const userReducer = (state: UserStateType, action: UserAction) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        user: action.user
          ? {
              ...action.user,
              shortName:
                (action.user.firstName
                  ? action.user.firstName.charAt(0).toUpperCase()
                  : "") +
                (action.user.lastName
                  ? action.user.lastName.charAt(0).toUpperCase()
                  : ""),
            }
          : null,
        isAuthenticated: true,
      };

    case LOGGED_OUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case UPDATE_TEAM:
      if (action.teamName && state.user && state.user.companyTeam) {
        return {
          ...state,
          user: {
            ...state.user,
            companyTeam: { ...state.user.companyTeam, name: action.teamName },
          },
        };
      } else return state;

    case UPDATE_USER:
      if (!action.user) return state;

      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.user.firstName,
          lastName: action.user.lastName,
          timeZone: action.user.timeZone,
          mobileNumber: action.user.mobileNumber,
          shortName:
            (action.user.firstName
              ? action.user.firstName.charAt(0).toUpperCase()
              : "") +
            (action.user.lastName
              ? action.user.lastName.charAt(0).toUpperCase()
              : ""),
        },
      };
    default:
      return state;
  }
};

const UserContextProvider = (
  props: UserContextProps
): ReactElement<UserContextValues> => {
  const [userState, userDispatch] = useReducer(userReducer, initialState);
  const [refreshTasks, setRefreshTasks] = useState(false);

  useEffect(() => {
    if (userState && userState.user && userState.user.email) {
      //  console.log("**** setting up task-assigned event ****",socket);

      socket.emit("join", {
        email: userState.user.email,
      });
      socket.on("task-assigned", (data) => {
        // console.log("socket->task-assigned event received ", data);
        if (data && data.msg) {
          NotificationManager.success(data.msg);
        }
        // need to refresh tasks
        setRefreshTasks(true);
      });
    }
  }, [userState]);

  return (
    <UserContext.Provider
      value={{
        userState,
        userDispatch,
        socket,
        refreshTasks,
        setRefreshTasks,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
