import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import backarrow from "../../images/backarrow.svg";
import jetlist from "../../images/JetList.svg";
import myAxios from "../../utils/myaxios";
// import ConfirmationBox from "../utils/confirmation-box";
import OrangeBackArrow from "../../images/orange-backarrow.svg";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";

const Header = styled.div`
  width: 100%;
  padding: 12px;
  background: #fdf0e2;
  /* margin-top: -27.5rem; */
  border-radius: 10px 10px 0 0;
`;

const BackImg = styled.img`
  cursor: pointer;
  display: ${(props) => (props.className === "BlueArrow" ? "block" : "none")};
`;

const ColDiv = styled(Col)`
  &:hover img.BlueArrow {
    display: none;
  }
  &:hover img.OrangeArrow {
    display: block;
  }
`;

const LogoImg = styled.img``;

const MainDivPopUp = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  width: 500px;
  /* height: 70vh; */
  box-shadow: 2px 6px 9px 9px rgb(0 0 0 / 32%);
  height: auto;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  border-radius: 10px;
  padding: 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const DivForNameUnique = styled.div`
  margin: 0 10px;
`;
const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  /* margin: 0px 30px; */
`;

const TempNameInput = styled(Field)`
  width: 100%;
  /* height: 35px; */
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 13px;
  /* margin: 0px 30px; */
  margin-top: 5px;
  display: inline;
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const InputDescription = styled(Field)`
  width: 100%;
  height: 80px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 13px;
  /* margin: 0px 30px; */
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const DisabledLabel = styled.label`
  font-style: normal;
  margin-left: 10px;
  color: #b3b3b3;
  font-weight: 500;
  font-size: 14px;
`;
const NormalLabel = styled.label`
  font-style: normal;
  color: #2f4c95;
  font-weight: 500;
  font-size: 14px;
`;

const StopRecurringTaskBtn = styled.button`
  border: 1px solid #2f4c95;
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  color: white;
  /* margin: 23px 30px; */
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const UpdateRecurringTaskBtn = styled.button`
  border: 1px solid #2f4c95;
  width: 100%;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    &:hover {
      background: #0e2151;
      border: none;
    }
  }
`;

const FieldColumn = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const ValidationERRTagName = styled.span`
  color: red;
  font-size: 12px;
  padding: 3px 0px;
`;

const UpdateRecurringTaskModel = ({
  setRecurringTaskPopUp,
  recurringData,
  taskId,
}: any) => {
  // console.log("recurringData", recurringData);
  const { updateRecurringTask } = useContext(TaskContext);
  const [showFroceStopConfirmation, setShowForceStopConfirmation] =
    useState<boolean>(false);

  let lastRecurringDate = recurringData.lastRecurringDate;
  let parsedLastRecurringDate = moment(lastRecurringDate).format("DD/MM/YYYY");
  let nextRecurringDate = recurringData.nextRecurringDate;
  let parsedNextRecurringDate = moment(nextRecurringDate).format("DD/MM/YYYY");
  // eslint-disable-next-line
  const [nextRecurringDateText, setNextRecurringDateText] = useState(
    parsedNextRecurringDate
  );

  let time = recurringData.updatedAt;
  let forceStopparseTime = moment(time).format("hh:mm A");

  let lastRecurrTime = moment(lastRecurringDate).format("hh:mm A");
  let nextRecurrTime = moment(nextRecurringDate).format("hh:mm A");

  const forceStopRecurringTask = (recurringId: string) => {
    myAxios
      .post(`recurring-task-details/stopRecurringTask`, {
        recurringId: recurringId,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success("Recurring task stopped successfully");
          updateRecurringTask(taskId, response.data.data);
          setShowForceStopConfirmation(false);
          setRecurringTaskPopUp(false);
        } else {
          NotificationManager.error(
            response.data?.message
              ? response.data?.message
              : "Failed to stop recurring task!"
          );
        }
      })
      .catch((error) => {
        setShowForceStopConfirmation(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };
  return (
    <>
      <MainDivPopUp>
        <Header>
          <Row>
            <ColDiv xs="5">
              <BackImg
                className="BlueArrow"
                src={backarrow}
                alt="backarrow"
                onClick={() => {
                  setRecurringTaskPopUp(false);
                }}
              />
              <BackImg
                className="OrangeArrow"
                src={OrangeBackArrow}
                alt="OrangeBackArrow"
                onClick={() => {
                  setRecurringTaskPopUp(false);
                }}
              />
            </ColDiv>
            <Col>
              <LogoImg src={jetlist} alt="jetlist" />
            </Col>
          </Row>
        </Header>

        <Formik
          enableReinitialize={true}
          initialValues={{
            recurringInDay: recurringData.recurringInDay,
            recurringUntil: recurringData.recurringUntil,
            status: recurringData.status,
            lastRecurringDate: parsedLastRecurringDate,
            nextRecurringDate: nextRecurringDateText,
            details: recurringData.detail,
          }}
          validate={(values: any) => {
            const errors: {
              recurringInDay?: string;
              recurringUntil?: string;
              status?: string;
              lastRecurringDate?: string;
              nextRecurringDate?: string;
              details?: string;
            } = {};

            if (!values.recurringInDay) {
              errors.recurringInDay = "Recurring in day is required";
            }
            if (values.recurringInDay <= 0) {
              errors.recurringInDay =
                "Recurring in day should greater than one";
            }
            if (!values.recurringUntil) {
              errors.recurringUntil = "Recurring until is required";
            }
            if (!values.status) {
              errors.status = "Status is required";
            }

            if (!values.details) {
              errors.details = "Details are required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const request = { ...values, id: recurringData._id };

            myAxios
              .post(`recurring-task-details/update`, request)
              .then((response) => {
                if (response.data.success) {
                  NotificationManager.success(
                    "Recurring task updated successfully"
                  );
                  updateRecurringTask(taskId, response.data.data);
                  setRecurringTaskPopUp(false);
                } else {
                  setSubmitting(false);
                  setRecurringTaskPopUp(false);
                  NotificationManager.error(
                    response.data?.message
                      ? response.data?.message
                      : "Failed to update recurring task!"
                  );
                }
              })
              .catch((error) => {
                NotificationManager.error(parseErrorResponse(error));
                setSubmitting(false);
                setRecurringTaskPopUp(false);
              });
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form style={{ position: "relative" }}>
              {recurringData.status === "completed" ? (
                <DivForNameUnique>
                  <br />

                  <Row>
                    <Col>
                      <NormalLabel>Repeats in day(s):</NormalLabel>

                      <DisabledLabel>
                        {recurringData.recurringInDay}
                      </DisabledLabel>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <NormalLabel>Times to repeat:</NormalLabel>

                      <DisabledLabel>
                        {recurringData.recurringUntil}
                      </DisabledLabel>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <NormalLabel>Status:</NormalLabel>

                      <DisabledLabel>{recurringData.status}</DisabledLabel>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <NormalLabel>Last created date:</NormalLabel>

                      <DisabledLabel>
                        {values.lastRecurringDate} {forceStopparseTime}
                      </DisabledLabel>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <NormalLabel>Task details:</NormalLabel>

                      <DisabledLabel>{recurringData.detail}</DisabledLabel>
                    </Col>
                  </Row>
                  <br />
                </DivForNameUnique>
              ) : (
                <>
                  <br />
                  <DivForNameUnique>
                    <Row>
                      <FieldColumn>
                        <Label>Repeats in day(s)</Label>
                        <TempNameInput
                          type="number"
                          placeholder="Recurring in day"
                          name="recurringInDay"
                          onChange={(e: any) => {
                            setFieldValue("recurringInDay", e.target.value);
                            if (e.target.value && e.target.value > 0) {
                              setFieldValue(
                                "nextRecurringDate",
                                moment(recurringData.lastRecurringDate)
                                  .add("days", e.target.value)
                                  .format("DD/MM/YYYY")
                              );
                            }
                          }}
                        />
                        {errors.recurringInDay && (
                          <ValidationERRTagName>
                            {errors.recurringInDay}
                          </ValidationERRTagName>
                        )}
                      </FieldColumn>
                      <FieldColumn>
                        <Label>Times to repeat (-1=infinite)</Label>
                        <TempNameInput
                          type="number"
                          placeholder="Times to repeat"
                          name="recurringUntil"
                        />
                        {errors.recurringUntil && (
                          <ValidationERRTagName>
                            {errors.recurringUntil}
                          </ValidationERRTagName>
                        )}
                      </FieldColumn>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <NormalLabel>Status:</NormalLabel>

                        <DisabledLabel>{recurringData.status}</DisabledLabel>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <NormalLabel>Last created date:</NormalLabel>

                        <DisabledLabel>
                          {values.lastRecurringDate} {lastRecurrTime}
                        </DisabledLabel>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <NormalLabel>Next creation date:</NormalLabel>

                        <DisabledLabel>
                          {values.nextRecurringDate} {nextRecurrTime}
                        </DisabledLabel>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <FieldColumn>
                        <Label>Task details</Label>
                        <InputDescription
                          placeholder="Write a short details (if any)"
                          name="details"
                          component="textarea"
                        />
                        {errors.details && (
                          <ValidationERRTagName>
                            {errors.details}
                          </ValidationERRTagName>
                        )}
                      </FieldColumn>
                    </Row>
                    <br />
                    <Row>
                      {/* <Col className="col-md-1" /> */}
                      <FieldColumn className="col-md-6">
                        <StopRecurringTaskBtn
                          type="button"
                          onClick={() => {
                            setShowForceStopConfirmation(true);
                          }}
                          disabled={showFroceStopConfirmation}
                        >
                          Force Stop
                        </StopRecurringTaskBtn>
                      </FieldColumn>
                      <FieldColumn className="col-md-6">
                        <UpdateRecurringTaskBtn
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Update
                        </UpdateRecurringTaskBtn>
                      </FieldColumn>
                      {/* <Col className="col-md-1" /> */}
                    </Row>
                  </DivForNameUnique>
                  {showFroceStopConfirmation && (
                    <ConfirmationBox
                      setShow={setShowForceStopConfirmation}
                      okButtonClick={() => {
                        forceStopRecurringTask(recurringData._id);
                      }}
                      name="Confirmation"
                      message="Once recurring task is stopped, it would not be possible to start it again"
                      okButtonText="Stop Recurring Task"
                    />
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </MainDivPopUp>
    </>
  );
};

export default UpdateRecurringTaskModel;
