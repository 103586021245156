import { PaginationTaskInput } from "../../types";
export const PageLimit = 5;

export const DefaultPaginationTaskInput: PaginationTaskInput = {
  type: "todo",
  pagination: {
    skip: 0,
    limit: PageLimit,
  },
};
