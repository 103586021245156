import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import BackArrow from "../../images/backarrow.svg";
import JetListLogo from "../../images/logo.svg";
import MobileBasicTaskForm from "./mobile-basic-taskfrom";
import MobileExpandedTaskForm from "./mobile-expanded-taskform";
const Header = styled.div`
  height: 50px;
  background: #ffe6cc;
`;
const SecondHeader = styled.div`
  height: 40px;
  background: #fdf0e2;
`;
const LogoTag = styled.img`
  width: 75px;
  margin-left: 24px;

`;
const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
`;
const DivBasicExpanded = styled.div`
  display: flex;
  justify-content: center;
`;
const VertiLine = styled.div`
  border-left: 2px solid #2f4c95;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const BasicExpandedSeleceted = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  padding: 10px;
`;
const BasicExpadeNormal = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: rgb(252 102 1 / 50%);
  padding: 10px;
`;

const MiddleMobileTaskForm = () => {
  let navigate = useNavigate();
  const { copyTask, setCopyTask, isTaskCopyClick, setTaskCopyClick } =
    useContext(TaskContext);
  const BASIC_FORM = "basic_form";
  const EXPANDED_FORM = "expanded_form";

  const [currentTaskForm, setCurrentTaskForm] = useState<string>(BASIC_FORM);

  const [isDescription, setIsDescription] = useState<any>("");

  const [expandedFormData, setExpandedFormData] = useState<any>();
  const [basicFormData, setBasicFormData] = useState<any>();

  useEffect(() => {
    if (isTaskCopyClick) {
      if (copyTask) {
        setBasicFormData({
          detail: copyTask.detail,
          assignedToEmail: copyTask.assignedTo ? copyTask.assignedTo.email : "",
          groupEmails:
            copyTask.groupUsers && copyTask.groupUsers.length > 0
              ? copyTask.groupUsers
                  .map((c) => {
                    return c.email;
                  })
                  .join(",")
              : "",
          date: "",
          duedate: moment().add(1, "day").toDate(),
          duedateText: copyTask.duedateText,
          cats: copyTask.categories
            ? copyTask.categories.map((ct) => ct.name)
            : [],
          priority: copyTask.priority,
          description: copyTask.isDescription,
          isDescription: copyTask.isDescription,
        });
        setIsDescription(copyTask.isDescription);
      }
      setCurrentTaskForm(EXPANDED_FORM);
      setCopyTask(undefined);
      setTaskCopyClick(false);
    }
    // eslint-disable-next-line
  }, [isTaskCopyClick, setCopyTask, setTaskCopyClick]);

  return (
    <>
      {/* <Container style={{ margin: "0", padding: "0" }}> */}
        <Col >
          <Header>
            <div style={{ padding: "10px" }}>
              <BackArrowTag
                src={BackArrow}
                alt="backArrow"
                onClick={() => {
                  navigate("/");
                }}
              />
              <LogoTag src={JetListLogo} alt="jetlist" />
            </div>
          </Header>

          <SecondHeader>
            <DivBasicExpanded>
              {currentTaskForm === BASIC_FORM ? (
                <>
                  <BasicExpandedSeleceted
                    onClick={() => {
                      setCurrentTaskForm(BASIC_FORM);
                      setBasicFormData(expandedFormData);
                    }}
                  >
                    Basic
                  </BasicExpandedSeleceted>
                  <VertiLine></VertiLine>
                  <BasicExpadeNormal
                    onClick={() => {
                      setCurrentTaskForm(EXPANDED_FORM);
                      setExpandedFormData(basicFormData);
                    }}
                  >
                    Expanded
                  </BasicExpadeNormal>
                </>
              ) : (
                <>
                  <BasicExpadeNormal
                    onClick={() => {
                      setCurrentTaskForm(BASIC_FORM);
                      setBasicFormData(expandedFormData);
                    }}
                  >
                    Basic
                  </BasicExpadeNormal>
                  <VertiLine></VertiLine>
                  <BasicExpandedSeleceted
                    onClick={() => {
                      setCurrentTaskForm(EXPANDED_FORM);
                      setExpandedFormData(basicFormData);
                    }}
                  >
                    Expanded
                  </BasicExpandedSeleceted>
                </>
              )}
            </DivBasicExpanded>
          </SecondHeader>
        </Col>
        <Col>
          {currentTaskForm === BASIC_FORM ? (
            <MobileBasicTaskForm
              isDescription={isDescription}
              setIsDescription={setIsDescription}
              setBasicFormData={setBasicFormData}
              initValues={expandedFormData}
            />
          ) : (
            <MobileExpandedTaskForm
              isDescription={isDescription}
              setIsDescription={setIsDescription}
              setCurrentFormData={setExpandedFormData}
              initValues={basicFormData}
            />
          )}
        </Col>
      {/* </Container> */}
    </>
  );
};

export default MiddleMobileTaskForm;
