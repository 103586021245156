import momentTZ from "moment-timezone";
import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./utils/loader";
import { JETLIST_MAGIC_CRED, LOGGED_IN } from "../consts";
import { UserContext } from "../context/user-context";
import { magic } from "../magic";
import { setAuthHeader } from "../utils/myaxios";

const Callback = () => {
  const { userState, userDispatch } = useContext(UserContext);
  const navigate = useNavigate();

  async function validatedAndSaveToken(didToken: string | null) {
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: didToken,
        timeZone: momentTZ.tz.guess(),
      }),
    });

    if (res.status === 200) {
      const data = await res.json();
      // Set the UserContext to the now logged in user
      let userMetadata = await magic.user.getMetadata();
      userDispatch({
        type: LOGGED_IN,
        user: { ...userMetadata, ...data },
      });

      if (data.jwt) setAuthHeader(data.jwt);
    }
  }

  useEffect(() => {
    if (userState && userState.user && userState.user.issuer) {
      navigate("/");
    }
  }, [userState, navigate]);

  useEffect(() => {
    const queryParams: any = queryString.parse(window.location.search);

    if (queryParams && queryParams.magic_credential) {
      localStorage.setItem(JETLIST_MAGIC_CRED, queryParams.magic_credential);
    }

    magic.auth
      .loginWithCredential()
      .then((response) => {
        validatedAndSaveToken(response);
      })
      .catch((error) => {
        console.error("error occurred", error);
      });
  });

  return (
    <div>
      <Loader text={"Verifying credentials..."} />
    </div>
  );
};

export default Callback;
