import { useContext } from "react";
import { TaskContext } from "../../context/task-context";
import styled from "styled-components";
import CategoryIcon from "../../images/categoryIconForFilter.svg";
import CalenderIcon from "../../images/calenderIconForFilter.svg";
import AssigneeIcon from "../../images/assigneeIcon.svg";
import AssignorIcon from "../../images/assinorIcon.svg";
import GroupIcon from "../../images/group-search.svg";
import Cross from "../../images/crossfilter.svg";
import { USER_VIEW_ARCHIVED_TASK_EVENTS } from "../../customer-io/events";
import {
  USETIFULL_ARCHIVED_TASKS,
  USETIFULL_COMPLETED_TASKS,
  USETIFULL_IN_PROGRESS_TASK,
  USETIFULL_TODO_TASKS,
} from "../../usetifull/class-names";
declare var _cio: any;
interface SidebarWidth {
  isOpen?: boolean;
}
const Container = styled.div`
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "15%" : "0px")};
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-left: 0px;
  }
`;

const FirstMainDiv = styled.div``;
const Todo = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2f4c95;
  /* margin-left: 415px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const Inprogress = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
const Completed = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2f4c95;
  /* margin-right: 116px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
const DivLine = styled.div`
  border-left: 3px solid #2f4c95;
  height: 15px;
  margin: 9px -21px;
  @media (max-width: 1199px) and (min-width: 320px) {
    height: 13px;
    margin: 2px;
    border: 1px solid #2f4c95;
  }
`;

const TodoOrange = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fcca95;
  /* margin-left: 415px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const InprogressOrange = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fcca95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const CompletedOrange = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fcca95;
  /* margin-right: 116px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const DivForArchived = styled.div`
  display: flex;
  align-items: center;
`;

const Archived = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2f4c95;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

const CheckBoxArchived = styled.input`
  width: 15px;
  height: 17px;
  cursor: pointer;
  margin-left: 2px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 13px;
    height: 11px;
  }
`;

const UnorderList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  margin: 2% 22%;
margin-bottom:0;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 75%; */
    width: 100%;
    /* margin-left: 9%; */
    margin: 10px -17px;
  }
`;
const ListItem = styled.li`
  list-style: none;
  /* flex-direction: row;
  justify-content: space-between; */
  cursor: pointer;
`;

const MainDivForFil = styled.div`
  @media (max-width: 1199px) and (min-width: 320px) {
    /* flex-direction: row;
    width: 100%;
    overflow-x: auto; */
    display: flex;
    width: 100%;
    overflow-x: auto;
  }
`;

const UnorderListForFilters = styled.ul`
  display: flex;
  text-align: center;
  justify-content: center;
  list-style: none;
  flex-direction: row;

  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
  }
`;
const ListItems = styled.li`
  /* margin-right: 10px; */
  width: auto;
  display: flex;
  height: 35px;
  background: #ffffff;
  border: 1px solid rgba(47, 76, 149, 0.3);
  box-sizing: border-box;
  border-radius: 50px;
  margin-left: 10px;
  text-align: center;
  padding: 4px 9px;
  color: rgba(47, 76, 149, 0.8);
  @media (max-width: 1199px) and (min-width: 320px) {
    width: auto;
    height: 27px;
    border: 1px solid rgba(47, 76, 149, 0.3);
    font-size: 12px;
    & p {
      width: max-content;
    }
  }
`;

const ImgTag = styled.img`
  padding-right: 10px;
  height: 16px;
  align-self: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* padding-right: 0px; */
    height: 14px;
    display: flex;
    flex-direction: row;
  }
`;

const CrossImg = styled.img`
  width: 24px;
  padding-left: 10px;
  height: 16px;
  align-self: center;
  cursor: pointer;
`;
const TaskTabs = ({ isOpen }: any) => {
  const {
    tabSelected,
    setTabSelected,
    setArchivedChecked,
    criteria,
    searchCriteria,
    removeCatFilter,
    removeAssigneeFilter,
    removeAssignorFilter,
    removeDueDateFilter,
    addTasks,
  } = useContext(TaskContext);

  const handleTabClick = (tab: string) => {
    setTabSelected(tab);
  };

  const handleCheacked = (e: any) => {
    // console.log("e.target.checked", e.target.checked);
    if (e.target.checked) {
      // user view archived task
      _cio.track(USER_VIEW_ARCHIVED_TASK_EVENTS, {
        name: "user view archived task",
      });
    }
    setArchivedChecked(e.target.checked);
  };

  return (
    <>
      <Container isOpen={isOpen}>
        <FirstMainDiv>
          <UnorderList>
            {tabSelected === "trash" ? (
              <ListItem>
                <>
                  <Todo>List of Deleted Tasks</Todo>
                  <div style={{ height: "17px" }}></div>
                </>
              </ListItem>
            ) : (
              <>
                <ListItem>
                  {tabSelected === "todo" ? (
                    <>
                      <Todo>To-Do</Todo>
                      <div style={{ height: "17px" }}></div>
                    </>
                  ) : (
                    <TodoOrange
                      className={USETIFULL_TODO_TASKS}
                      onClick={() => {
                        handleTabClick("todo");
                        addTasks([]);
                        setArchivedChecked(false);
                      }}
                    >
                      To-Do
                    </TodoOrange>
                  )}
                </ListItem>
                <DivLine></DivLine>
                <ListItem>
                  {tabSelected === "inprogress" ? (
                    <>
                      <Inprogress>In-Progress</Inprogress>
                      <div style={{ height: "17px" }}></div>
                    </>
                  ) : (
                    <InprogressOrange
                      className={USETIFULL_IN_PROGRESS_TASK}
                      onClick={() => {
                        handleTabClick("inprogress");
                        setArchivedChecked(false);
                        addTasks([]);
                      }}
                    >
                      In-Progress
                    </InprogressOrange>
                  )}
                </ListItem>
                <DivLine></DivLine>
                <ListItem>
                  {tabSelected === "completed" ? (
                    <>
                      <Completed>Completed</Completed>

                      {/*archived task check box*/}

                      <DivForArchived className={USETIFULL_ARCHIVED_TASKS}>
                        <Archived>Show archived</Archived>
                        <CheckBoxArchived
                          type="checkbox"
                          onChange={(e) => {
                            handleCheacked(e);
                            // addTasks([])
                          }}
                        />
                      </DivForArchived>
                    </>
                  ) : (
                    <CompletedOrange
                      className={USETIFULL_COMPLETED_TASKS}
                      onClick={() => {
                        handleTabClick("completed");
                        addTasks([]);
                      }}
                    >
                      Completed
                    </CompletedOrange>
                  )}
                </ListItem>{" "}
              </>
            )}
          </UnorderList>
        </FirstMainDiv>
        {/* filtered code */}
        <MainDivForFil>
          <UnorderListForFilters>
            {criteria?.categoryList?.map((category) => (
              <ListItems>
                <ImgTag src={CategoryIcon} alt="category" />
                <span>{category.name}</span>
                <CrossImg
                  src={Cross}
                  alt="cross"
                  onClick={() => {
                    removeCatFilter(category._id);
                  }}
                />
              </ListItems>
            ))}

            {criteria?.daysRemainingList?.map((daysRemain) => (
              <ListItems>
                <ImgTag src={CalenderIcon} alt="CalenderIcon" />
                <p>{daysRemain.daysLabel}</p>
                <CrossImg
                  src={Cross}
                  alt="cross"
                  onClick={() => {
                    removeDueDateFilter(daysRemain.daysId);
                  }}
                />
              </ListItems>
            ))}

            {criteria?.assigneeList?.map((asignee) => (
              <ListItems>
                <ImgTag src={AssigneeIcon} alt="assinee" />
                <span>{asignee.email}</span>
                <CrossImg
                  src={Cross}
                  alt="cross"
                  onClick={() => {
                    removeAssigneeFilter(asignee._id);
                  }}
                />
              </ListItems>
            ))}

            {criteria?.assignorList?.map((assigor) => (
              <ListItems>
                <ImgTag src={AssignorIcon} alt="assinor" />
                <span>{assigor.email}</span>
                <CrossImg
                  src={Cross}
                  alt="cross"
                  onClick={() => {
                    removeAssignorFilter(assigor._id);
                  }}
                />
              </ListItems>
            ))}

            {searchCriteria?.categories?.map((category) => (
              <ListItems>
                <ImgTag src={CategoryIcon} alt="category" />
                <span>{category}</span>
                {/* <CrossImg src={Cross} alt="cross" /> */}
              </ListItems>
            ))}

            {searchCriteria?.due && (
              <ListItems>
                <ImgTag src={CalenderIcon} alt="CalenderIcon" />
                {/* <CrossImg src={Cross} alt="cross" /> */}
                <p>Due in {searchCriteria?.due} Hours</p>
              </ListItems>
            )}

            {searchCriteria?.toEmail && (
              <ListItems>
                <ImgTag src={AssigneeIcon} alt="assinee" />
                {/* <CrossImg src={Cross} alt="cross" /> */}
                <span>{searchCriteria?.toEmail}</span>
              </ListItems>
            )}

            {searchCriteria?.fromEmail && (
              <ListItems>
                <ImgTag src={AssignorIcon} alt="assinr" />
                {/* <CrossImg src={Cross} alt="cross" /> */}
                <span>{searchCriteria?.fromEmail}</span>
              </ListItems>
            )}

            {searchCriteria?.groupEmails && (
              <ListItems>
                <ImgTag src={GroupIcon} alt="group search" />
                {/* <CrossImg src={Cross} alt="cross" /> */}
                <span>{searchCriteria?.groupEmails}</span>
              </ListItems>
            )}

            {searchCriteria?.priority && (
              <ListItems>
                <span>P:{searchCriteria?.priority}</span>
                {/* <CrossImg src={Cross} alt="cross" /> */}
              </ListItems>
            )}

            {searchCriteria?.status && (
              <ListItems>
                <span>S:{searchCriteria?.status}</span>
                {/* <CrossImg src={Cross} alt="cross" /> */}
              </ListItems>
            )}
          </UnorderListForFilters>
        </MainDivForFil>
      </Container>
    </>
  );
};

export default TaskTabs;
