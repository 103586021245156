import { AppFeature } from "../../types";

export const removeUnwantedTagsForToolTip = (text: string) => {
  if (text === null || text === "") return text;

  text = text.replace(/<\/?a[^>]*>/g, "");
  text = text.replace(/<img[^>]*>/g, "");
  text = text.replace(/<document[^>]*>/g, "");
  text = text.replace(/<iframe[^>]*>/g, "");
  text = text.replace(/<br[^>]*>/g, "");

  return text;
};

/*  Valid time string  like time:1020, 1203 etc */

export const validateTime = (time: string) => {
  if (time === null || time === "") return false;

  if (time.length !== 4) return false;

  return /^([0-1][0-9]|2[0-3])([0-5][0-9])$/.test(time);
};

export const validateDayName = (day: string) => {
  var days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  if (day === null || day === "") return false;

  return days.includes(day.toLowerCase());
};

export const checkIfFeatureOpen = (
  features: AppFeature[] | undefined,
  currentFeatureName: string
) => {
  if (!features) return false;
  let obj = features.find((o) => o.name === currentFeatureName);

  if (obj) return true;
  return false;
};
export const removeTagsFromTaskDetail = (taskDetail: string) => {
  let detail = taskDetail;

  if (!detail) return detail;

  const toTagIdx = detail.toLowerCase().indexOf("to:");

  if (toTagIdx > -1) {
    let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx);

    if (toTagSpaceIdx !== -1) {
      detail =
        detail.substring(0, toTagIdx) +
        detail.substring(toTagSpaceIdx + 1, detail.length);
    } else {
      // tag is present till the end
      detail = detail.substring(0, toTagIdx);
    }
  }

  const dueTagIdx = detail.toLowerCase().indexOf("due:");

  if (dueTagIdx > -1) {
    let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx);
    if (dueTagSpaceIdx !== -1) {
      detail =
        detail.substring(0, dueTagIdx) +
        detail.substring(dueTagSpaceIdx + 1, detail.length);
    } else {
      detail = detail.substring(0, dueTagIdx);
    }
  }

  let progressIdx = detail.toLowerCase().indexOf("s:");
  let progressInc = 0;

  if (progressIdx > 0) {
    // we would have to ensure that the progress tag starts with space if it is in the middle of the string
    progressIdx = detail.toLocaleLowerCase().indexOf(" s:");
    progressInc = 1;
  }

  if (progressIdx > -1) {
    let progressSpaceIdx = detail
      .toLowerCase()
      .indexOf(" ", progressIdx + progressInc);

    if (progressSpaceIdx > -1) {
      detail =
        detail.substring(0, progressIdx) +
        (progressInc > 0
          ? detail.substring(progressSpaceIdx, detail.length)
          : detail.substring(progressSpaceIdx + 1, detail.length));
    } else {
      detail = detail.substring(0, progressIdx);
    }
  }

  const groupIdx = detail.toLowerCase().indexOf("group:");

  if (groupIdx > -1) {
    let groupSpaceIdx = detail.toLowerCase().indexOf(" ", groupIdx);

    if (groupSpaceIdx > -1) {
      detail =
        detail.substring(0, groupIdx) +
        detail.substring(groupSpaceIdx + 1, detail.length);
    } else {
      detail = detail.substring(0, groupIdx);
    }
  }

  let priorityIdx = detail.toLowerCase().indexOf("p:");
  let priorityInc = 0;

  if (priorityIdx > 0) {
    priorityIdx = detail.toLocaleLowerCase().indexOf(" p:");
    priorityInc = 1;
  }

  if (priorityIdx > -1) {
    let prioritySpaceIdx = detail
      .toLowerCase()
      .indexOf(" ", priorityIdx + priorityInc);

    if (prioritySpaceIdx > -1) {
      detail =
        detail.substring(0, priorityIdx) +
        (priorityInc > 0
          ? detail.substring(prioritySpaceIdx, detail.length)
          : detail.substring(prioritySpaceIdx + 1, detail.length));
    } else {
      detail = detail.substring(0, priorityIdx);
    }
  }

  const ccMailIdx = detail.toLowerCase().indexOf("cc:");
  if (ccMailIdx > -1) {
    let ccMailSpaceIdx = detail.toLowerCase().indexOf(" ", ccMailIdx);
    if (ccMailSpaceIdx !== -1) {
      detail =
        detail.substring(0, ccMailIdx) +
        detail.substring(ccMailSpaceIdx + 1, detail.length);
    } else {
      detail = detail.substring(0, ccMailIdx);
    }
  }

  let hashTagIdx = detail.indexOf("#");
  while (hashTagIdx > -1) {
    let hashTagSpaceIdx = detail.indexOf(" ", hashTagIdx);

    if (hashTagSpaceIdx > -1) {
      detail =
        detail.substring(0, hashTagIdx) + detail.substring(hashTagSpaceIdx + 1);
    } else {
      detail = detail.substring(0, hashTagIdx);
    }

    hashTagIdx = detail.indexOf("#");
  }

  const recurringIdx = detail.toLowerCase().indexOf("r:");

  if (recurringIdx > -1) {
    let recurringSpaceIdx = detail.toLowerCase().indexOf(" ", recurringIdx);

    if (recurringSpaceIdx !== -1) {
      detail =
        detail.substring(0, recurringIdx) +
        detail.substring(recurringSpaceIdx + 1, detail.length);
    } else {
      detail = detail.substring(0, recurringIdx);
    }
  }

  // see if there are any template added against this task

  const templateIdx = detail.toLowerCase().indexOf("tmpl:");
  if (templateIdx > -1) {
    let templateSpaceIdx = detail.toLowerCase().indexOf(" ", templateIdx);

    if (templateSpaceIdx !== -1) {
      detail =
        detail.substring(0, templateIdx) +
        detail.substring(templateSpaceIdx + 1, detail.length);
    } else {
      detail = detail.substring(0, templateIdx);
    }
  }

  return detail;
};

export const parsedURlsFromImgStrings = (text: string) => {
  let detail = text;
  let urls = [];
  const rex = /<img[^>]*src="([^"]+)"[^>]*>/g;
  //const rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;
  let m;
  while ((m = rex.exec(detail))) {
    urls.push(m[1]);
  }

  return urls;
};

export const parseErrorResponse = (error: any) => {
  // Unable to process your request, please try later

  try {
    return error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message[0].messages &&
      error.response.data.message[0].messages[0].message
      ? error.response.data.message[0].messages[0].message
      : "Unable to process your request, please try later";
  } catch (error) {
    return "Unable to process your request, please try later";
  }
};
