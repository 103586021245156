import { Field, Formik } from "formik";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import Logo from "../../images/logo.svg";
import OrLInes from "../../images/oricon.svg";
import Spinner from "../../images/spinner.svg";
import SuccessIcon from "../../images/successicon.svg";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";

const ThirdSpan = styled.span`
  margin: 0 33px;
  padding: 1px 0 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9b9b9b;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 251px;
    height: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #9b9b9b;
    text-align: center;
  }
`;

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;

const ButtonForCred = styled.button`
  width: 64%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #2f4c95;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #2f4c95;
  cursor: pointer;
  z-index: 11111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fc6601;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const LogoImg = styled.img`
  width: 123px;
  margin-top: 16px;

  @media (max-width: 1199px) and (min-width: 390px) {
  }
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fc6601;
  /* margin-bottom:14%; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 43px;
    color: #fc6601;
  }
`;
const LoginParaVeri = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fc6601;
  padding: 24px 0;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 43px;
    color: #fc6601;
  }
`;

const LoginSignupFieldsDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding-left: 0;
    padding: 15px 0;
  }
`;
const Input = styled(Field)`
  width: 64%;
  height: 41px;
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  margin: 8px;
  border-radius: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    margin: 8px 0;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
  }
`;
const DivForLoginButton = styled.div`
  padding: 20px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 55px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-bottom: 0;
  }
`;
const LoginButton = styled.button`
  width: 64%;
  height: 50px;
  color: white;
  background: #2f4c95;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const SpanErrorLoginSignup = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const SpinnerLoginImg = styled.img`
  height: 35px;
  margin-left: 15px;
`;

const SuceessImgTag = styled.img`
  width: 50px;
  margin: 50px;
`;
const TextSpanSuccess = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #797979;
`;

const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const ForgetPassword = ({
  setToShowMagicLinkloginPage,
  setToShowSignupPage,
  showSignupMsgInLoginPage,
}: any) => {
  const [toShowEmailVerificationSuccess, setToShowEmailVerificationSuccess] =
    useState<boolean>(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState<string | undefined>("");
  const [forgotPassLoader, setForgotPassLoader] = useState(false);

  const forgotPassword = (values: any, resetForm: any) => {
    setForgotPassLoader(true);
    myAxios
      .post(`auth/forgot-password`, {
        email: values.email,
      })
      .then((response) => {
        if (response.data.success === true) {
          NotificationManager.success(
            response.data?.message
              ? response.data?.message
              : "Password Successfully Changed"
          );
          setForgotPassLoader(false);
          setToShowEmailVerificationSuccess(true);
          setSaveSuccessMsg(response.data.message);
        } else {
          NotificationManager.error(
            response.data?.message
              ? response.data?.message
              : "Some thing went wrong, Please try again!"
          );
          setToShowEmailVerificationSuccess(false);
          setForgotPassLoader(false);
        }
      })
      .catch((error: any) => {
        NotificationManager.error(parseErrorResponse(error));
        setForgotPassLoader(false);
        setToShowEmailVerificationSuccess(false);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <DIV>
            <LogoImg src={Logo} alt="logo" />

            <DivForPara>
              {!toShowEmailVerificationSuccess ? (
                <LoginPara>Forgot Password</LoginPara>
              ) : (
                <LoginParaVeri>Email Verification</LoginParaVeri>
              )}

              {!toShowEmailVerificationSuccess && (
                <ThirdSpan>
                  Enter the email address you’d like your password reset
                  information sent to.
                </ThirdSpan>
              )}
            </DivForPara>
          </DIV>

          {!toShowEmailVerificationSuccess ? (
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values: any) => {
                const errors: {
                  email?: string;
                } = {};

                if (!values.email) {
                  errors.email = " email required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                forgotPassword(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <LoginSignupFieldsDiv>
                    <Input type="email" placeholder="email" name="email" />

                    {errors.email && (
                      <SpanErrorLoginSignup>
                        {errors.email && touched.email && errors.email}
                      </SpanErrorLoginSignup>
                    )}

                    <DivForLoginButton>
                      <LoginButton type="submit">
                        CONTINUE
                        {forgotPassLoader && (
                          <SpinnerLoginImg src={Spinner} alt="Loading" />
                        )}
                      </LoginButton>
                    </DivForLoginButton>
                  </LoginSignupFieldsDiv>
                </form>
              )}
            </Formik>
          ) : (
            <div
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SuceessImgTag src={SuccessIcon} />
              <TextSpanSuccess>{saveSuccessMsg}</TextSpanSuccess>
              {/* <TextSpanSuccess>
                An email has been sent to “useremail@email.com” with further
                instructions
              </TextSpanSuccess> */}
            </div>
          )}

          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>

          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                try {
                  setToShowMagicLinkloginPage(true);
                } catch {}
              }}
            >
              Login with Magic Link
            </ButtonForCred>
          </DivForLoginWithCredintial>

          <BottomDiv>
            <SpanAccount>Don't have an account |</SpanAccount>
            <SpanSignup
              onClick={() => {
                try {
                  setToShowSignupPage(true);
                } catch {}
              }}
            >
              SIGNUP
            </SpanSignup>
          </BottomDiv>
        </Row>
      </Container>
    </>
  );
};

export default ForgetPassword;
