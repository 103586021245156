import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import GlobalStyle from "../../style/style";

const CenterDiv = styled.div`
  display: flex;
  /* justify-content: center; */
`;

const CreateButton = styled.button`
  width: 148px;
  height: 40px;
  background: #2f4c95;
  box-shadow: 0px 4px 4px rgba(11, 34, 91, 0.2);
  border-radius: 5px;
  border: none;
  color: #fff;
  display: none;
  z-index: 1111;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: block;
  }
`;

const MainMobileTaskForm = ({ isMobileScreen }: any) => {
  let navigate = useNavigate();

  const [isMobileTaskForm, setIsMobileTaskForm] = useState(false);

  const CreateToggleBtn = () => {
    setIsMobileTaskForm(!isMobileTaskForm);

    // if (isMobileTaskForm) {
    navigate("middle-mobile-taskform");
    // }
  };

  return (
    <>
      <GlobalStyle />
      <CenterDiv>
        <CreateButton onClick={CreateToggleBtn}>Create Task</CreateButton>

        {/* {isMobileTaskForm && <MiddleMobileTaskForm />} */}
      </CenterDiv>
    </>
  );
};

export default MainMobileTaskForm;
