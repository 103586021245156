import {createGlobalStyle} from 'styled-components'


const GlobalStyle = createGlobalStyle`
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

* {

  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
`;
export default GlobalStyle;