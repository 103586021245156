import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { ProjectTemplatesContext } from "../../context/project-template-context";
import { TeamContext } from "../../context/team-context";
import { USER_CREATE_TEMPLATE_EVENTS } from "../../customer-io/events";
import AddNewSubTaskSvg from "../../images/addSubTask.svg";
import BackArrow from "../../images/backarrow.svg";
import CrossFroTempSvg from "../../images/cross.svg";
import JetListLogo from "../../images/logo.svg";
import RemoveSubTasksInputFieldSvg from "../../images/removeinput.svg";
import { SubTemplate, TaskTemplate } from "../../types";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";
declare var _cio: any;

const WatchParent = styled.div`
  left: 50%;
  top: 45%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const CrossTemplatePop = styled.img`
  width: 23px;
  position: absolute;
  left: -12px;
  top: 3%;
  cursor: pointer;
  background: white;
  border-radius: 51%;
`;

const MainDivPopUp = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 840px;
  height: 100vh;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 20px;
  z-index: 11;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    height: auto;
    right: 0;
    /* left: 11px; */
    border-radius: 5px;
    padding: 0px;
    /* top: 7px; */
    box-shadow: 0px 15px 10px rgb(0 0 0 / 80%);
  }
`;

const DivForNameUnique = styled.div`
  display: flex;
  margin: 0 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: block;
    margin-top: 10px;
  }
`;

const TempNameInput = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    display: block;
    padding: 5px;

    ::-webkit-input-placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: rgba(60, 59, 91, 0.8);
      padding: 5px;
    }
  }
`;

const ValidationERRTagName = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;

  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const ValidationErrSubTaskTag = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;

  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;

const InputDescription = styled(Field)`
  width: 775px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  /* margin: 0px 30px; */
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    right: 0;
    left: 11px;
    border-radius: 5px;
    padding: 5px 10px;
    top: 7px;
  }
`;

const InputTempKey = styled(Field)`
  width: 380px;
  height: 35px;
  background: #f0f0f0;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  color: black;
  position: relative;
  padding: 5px;
  text-transform: uppercase;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const DivSubTask = styled.div`
  padding: 31px 21px;
  display: flex;
  justify-content: space-between;
  width: 98%;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 3px 14px;
  }
`;

const Span = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(60, 59, 91, 0.8);
`;

const InputAddSubTask = styled(Field)`
  width: 388px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin: 4px 0px;
  position: relative;
  padding: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 86%;
    padding: 5px;
  }
`;

const RemoveSubTaskImg = styled.img`
  top: 6.4%;
  cursor: pointer;
  position: absolute;
  right: 9px;
`;

const AddNewSubTasksImg = styled.img`
  cursor: pointer;
  position: absolute;
  right: 37px;
  top: 34.5%;
  @media (max-width: 1199px) and (min-width: 320px) {
    right: 9px;
    top: 6.5%;
  }
`;

const SubTasksInputDiv = styled.div`
  max-height: 183vh;
  height: 44vh;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-bottom: 4rem;
  }
`;

const CreateTemplateBtn = styled.button`
  border: none;
  width: 200px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  /* margin: 23px 30px; */
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;

const DivBorder = styled.div`
  /* width: 767px; */
  width: 100%;
  height: auto;
  /* background: #ffffff; */
  background: #fdf0e2;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  margin: 10px 0;
`;

const RowForBtn = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 3px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;
const ColumnForBtns = styled(Col)`
  display: flex;
  justify-content: center;
  margin: 3px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    position: fixed;
    background: white;
    bottom: 0;
  }
`;
const Header = styled.div`
  height: 50px;
  background: #ffe6cc;
`;

const LogoTag = styled.img`
  width: 75px;
  margin-left: 24px;
`;
const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
`;
const CreateTemplateModel = ({ isMobileView }: any) => {
  const { selectedTeam } = useContext(TeamContext);
  const {
    addNewTemplate,
    updateTemplate,
    openSidebarPopup,
    setSidebarPopup,
    selectedTaskTemplates,
  } = useContext(ProjectTemplatesContext);
  const [loading, setLoading] = useState(false);
  const [subTaskRemovedIds, setSubTaskRemovedIds] = useState<any>([]);
  const [subTemplatesErrors, setSubTemplateErrors] = useState<any>([]);

  let focus = false;

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          ["blockquote"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],

          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: [] }],
          ["clean"],
        ],

        table: true,
      },
    }),
    []
  );

  return (
    <>
      {/* create template right side popup */}
      {openSidebarPopup && (
        <MainDivPopUp>
          {loading && (
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          )}
          {/* showing this header  in mobile view*/}
          {!isMobileView ? (
            <>
              {/* <HeaderDiv>
                <Row>
                  <Col xs="5">
                    <BackImg
                      src={backarrow}
                      alt="backarrow"
                      onClick={() => {
                        setSidebarPopup(!openSidebarPopup);
                        setSubTaskRemovedIds([]);
                        setSubTemplateErrors([]);
                      }}
                    />
                  </Col>
                  <Col>
                    <LogoImg src={jetlist} alt="jetlist" />
                  </Col>
                </Row>
              </HeaderDiv> */}

              <Header>
                <div style={{ padding: "10px" }}>
                  <BackArrowTag
                    src={BackArrow}
                    alt="backArrow"
                    onClick={() => {
                      setSidebarPopup(!openSidebarPopup);
                      setSubTaskRemovedIds([]);
                      setSubTemplateErrors([]);
                    }}
                  />
                  <LogoTag src={JetListLogo} alt="jetlist" />
                </div>
              </Header>
            </>
          ) : (
            //  showing this header  in desktop view
            <CrossTemplatePop
              src={CrossFroTempSvg}
              alt="cross"
              onClick={() => {
                setSidebarPopup(!openSidebarPopup);
                setSubTaskRemovedIds([]);
                setSubTemplateErrors([]);
              }}
            />
          )}

          <Formik
            enableReinitialize={true}
            initialValues={
              {
                name: selectedTaskTemplates! ? selectedTaskTemplates.name : "",
                description: selectedTaskTemplates!
                  ? selectedTaskTemplates.description
                  : "",
                uniqueKey: selectedTaskTemplates!
                  ? selectedTaskTemplates.uniqueKey
                  : "",
                subTemplates:
                  selectedTaskTemplates && selectedTaskTemplates.subTemplates
                    ? selectedTaskTemplates.subTemplates
                    : [{ name: "", description: "" } as SubTemplate],
              } as TaskTemplate
            }
            validate={(values: any) => {
              const errors: {
                name?: string;
                uniqueKey?: string;
                subTemplates?: [any];
              } = {};

              if (!values.name) {
                errors.name = "Template name is required field";
              }

              let subtemplErrors: Array<any> = [];

              let isError = false;

              values.subTemplates.forEach((subTempl: any) => {
                if (subTempl.name && subTempl.name.length > 0)
                  subtemplErrors.push({ name: "" });
                else if (!(subTempl.name && subTempl.name.length > 0)) {
                  isError = true;
                  subtemplErrors.push({
                    name: "Sub template name is required field",
                  });
                } else if (subTempl.name && subTempl.name.length < 1) {
                  isError = true;
                  subtemplErrors.push({
                    name: "Empty sub task",
                  });
                } else {
                  isError = true;
                  subtemplErrors.push({
                    name: "",
                  });
                }
              });

              setSubTemplateErrors(subtemplErrors);
              if (isError) {
                errors.subTemplates = [{ name: "name required" }];
                // errors.subTemplates = [subtemplErrors]
              }
              // console.log("subtemplErrors", subtemplErrors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log("values", values);

              if (!selectedTaskTemplates) {
                setLoading(true);

                _cio.track(USER_CREATE_TEMPLATE_EVENTS, {
                  name: "user create new template",
                });

                const templateCreateRequest = {
                  ...values,
                  team: selectedTeam?.id,
                  subTemplates: values.subTemplates?.map((subTempl, index) => ({
                    ...subTempl,
                    order: index + 1,
                  })),
                };
                myAxios
                  .post(`project-templates`, templateCreateRequest)
                  .then((response) => {
                    if (response.data.success) {
                      addNewTemplate(response.data.data);
                      NotificationManager.success(
                        "Task template created successfully"
                      );
                      setSidebarPopup(false);
                      resetForm();
                    } else {
                      NotificationManager.error(
                        response.data?.message
                          ? response.data?.message
                          : "Failed to create template task!"
                      );
                      resetForm();
                    }
                    setLoading(false);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    setLoading(false);
                    NotificationManager.error(parseErrorResponse(error));
                    resetForm();
                  });
              } else {
                // update call
                const id = selectedTaskTemplates._id;
                const templateUpdateRequest = {
                  ...values,
                  subTemplates: values.subTemplates?.map((subTempl, index) => ({
                    ...subTempl,
                    order: index + 1,
                  })),
                };
                setLoading(true);
                myAxios
                  .post(`project-templates/update`, {
                    id: id,
                    ...templateUpdateRequest,
                    deletedSubTemplates: subTaskRemovedIds,
                  })
                  .then((response) => {
                    if (response.data.success) {
                      NotificationManager.success(
                        "Task template update successfully"
                      );
                      updateTemplate(response.data.data);
                      setSidebarPopup(false);
                      // setTaskTemplateDescription("");
                      subTemplatesErrors([]);

                      resetForm();
                    } else {
                      NotificationManager.error(
                        response.data?.message
                          ? response.data?.message
                          : "Failed to update template task!"
                      );

                      resetForm();
                    }
                    setLoading(false);
                  })
                  .catch((error) => {
                    NotificationManager.error(parseErrorResponse(error));
                    setSubmitting(false);
                    resetForm();
                    setLoading(false);
                  });
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue, errors }) => (
              <Form style={{ position: "relative" }}>
                <DivForNameUnique>
                  <div>
                    <Label>Template Name</Label>

                    {selectedTaskTemplates! ? (
                      <TempNameInput
                        type="text"
                        placeholder="update tasks for Jetlist"
                        name="name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let nameValue = e.currentTarget.value;
                          setFieldValue("name", nameValue);
                          nameValue = nameValue.replaceAll(" ", "-");
                          setFieldValue("uniqueKey", nameValue.toUpperCase());
                        }}
                        onBlur={() => {
                          if (values.uniqueKey && values.uniqueKey.length > 0) {
                            if (
                              values.uniqueKey !==
                              selectedTaskTemplates.uniqueKey
                            ) {
                              myAxios
                                .post(
                                  `project-templates/check-key-availability`,
                                  {
                                    uniqueKey: values.uniqueKey,
                                    teamId: selectedTeam?.id,
                                  }
                                )
                                .then((response) => {
                                  if (response.data.success) {
                                    setFieldValue(
                                      "uniqueKey",
                                      response.data.data.uniqueKey
                                    );
                                  } else {
                                    NotificationManager.error(
                                      response.data?.message
                                        ? response.data?.message
                                        : "Failed to check template key availability!"
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.error(error);
                                  NotificationManager.error(
                                    parseErrorResponse(error)
                                  );
                                });
                            }
                          }
                        }}
                      />
                    ) : (
                      <TempNameInput
                        type="text"
                        placeholder="New Tasks for Jetlist"
                        name="name"
                        autoFocus={true}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let nameValue = e.currentTarget.value;
                          setFieldValue("name", nameValue);
                          nameValue = nameValue.replaceAll(" ", "-");
                          setFieldValue("uniqueKey", nameValue.toUpperCase());
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (values.uniqueKey && values.uniqueKey.length > 0) {
                            setLoading(true);
                            myAxios
                              .post(
                                `project-templates/check-key-availability`,
                                {
                                  uniqueKey: values.uniqueKey,
                                  teamId: selectedTeam?.id,
                                }
                              )
                              .then((response) => {
                                if (response.data.success) {
                                  setFieldValue(
                                    "uniqueKey",
                                    response.data.data.uniqueKey
                                  );
                                } else {
                                  NotificationManager.error(
                                    response.data?.message
                                      ? response.data?.message
                                      : "Failed to check template key availability!"
                                  );
                                }
                                setLoading(false);
                              })
                              .catch((error) => {
                                setLoading(false);
                                NotificationManager.error(
                                  parseErrorResponse(error)
                                );
                                console.error(error);
                              });
                          }
                        }}
                      />
                    )}
                    {errors.name && (
                      <ValidationERRTagName>{errors.name}</ValidationERRTagName>
                    )}
                  </div>
                  <div>
                    <Label>Template Key</Label>
                    {/* <EditIcon src={TaskTempEditIcon} alt="edit" /> */}
                    {selectedTaskTemplates! ? (
                      <InputTempKey type="text" name="uniqueKey" disabled />
                    ) : (
                      <InputTempKey
                        type="text"
                        name="uniqueKey"
                        disabled
                        value={values.uniqueKey ? values.uniqueKey : ""}
                      />
                    )}
                  </div>
                </DivForNameUnique>

                <div style={{ margin: "10px 10px" }}>
                  <Label>Template Description</Label>
                  <InputDescription
                    placeholder="Description (if any) "
                    name="description"
                    component="textarea"
                  />
                </div>
                <FieldArray name="subTemplates">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { subTemplates } = values;

                    return (
                      <div>
                        <DivSubTask>
                          <Span>Add Sub-Tasks</Span>
                          <AddNewSubTasksImg
                            src={AddNewSubTaskSvg}
                            alt="add"
                            onClick={(value: any) => {
                              push("");
                              focus = true;
                            }}
                          />
                        </DivSubTask>

                        <SubTasksInputDiv>
                          {subTemplates.map(
                            (subtask: SubTemplate, index: number) => (
                              <>
                                <div key={index} style={{ margin: "0px 10px" }}>
                                  <DivBorder>
                                    <Label>Sub-Task-{index + 1}</Label> <br />
                                    {selectedTaskTemplates ? (
                                      <>
                                        <InputAddSubTask
                                          type="text"
                                          name={`subTemplates[${index}].name`}
                                          placeholder="sub-task title"
                                          autoFocus={focus ? true : false}
                                          onKeyPress={(e: any) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        />
                                        {subTemplatesErrors &&
                                          subTemplatesErrors[index] &&
                                          subTemplatesErrors[index]?.name && (
                                            <ValidationErrSubTaskTag>
                                              {subTemplatesErrors[index].name}
                                            </ValidationErrSubTaskTag>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        <InputAddSubTask
                                          type="text"
                                          name={`subTemplates[${index}].name`}
                                          placeholder="sub-task title"
                                          autoFocus={focus ? true : false}
                                          onKeyPress={(e: any) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        />
                                        {subTemplatesErrors &&
                                          subTemplatesErrors[index] &&
                                          subTemplatesErrors[index]?.name && (
                                            <ValidationErrSubTaskTag>
                                              {subTemplatesErrors[index].name}
                                            </ValidationErrSubTaskTag>
                                          )}
                                      </>
                                    )}
                                    <RemoveSubTaskImg
                                      src={RemoveSubTasksInputFieldSvg}
                                      alt="remove"
                                      onClick={() => {
                                        remove(index);

                                        setSubTaskRemovedIds(
                                          subTaskRemovedIds
                                            ? [
                                                ...subTaskRemovedIds,
                                                subTemplates[index]._id,
                                              ]
                                            : [subTemplates[index]._id]
                                        );
                                      }}
                                    />
                                    {selectedTaskTemplates &&
                                    selectedTaskTemplates.subTemplates ? (
                                      <>
                                        <div style={{ padding: "5px" }}>
                                          <ReactQuill
                                            placeholder="Sub-task description"
                                            modules={modules}
                                            theme="snow"
                                            style={{ flex: "auto" }}
                                            // name={`subTemplates[${index}].description`}
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `subTemplates[${index}].description`,
                                                e
                                              );
                                            }}
                                            defaultValue={
                                              subTemplates[index].description
                                            }
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <ReactQuill
                                          placeholder="Sub-task description"
                                          modules={modules}
                                          theme="snow"
                                          style={{ flex: "auto" }}
                                          // name={`subTemplates[${index}].description`}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `subTemplates[${index}].description`,
                                              e
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                  </DivBorder>
                                </div>
                              </>
                            )
                          )}
                        </SubTasksInputDiv>
                      </div>
                    );
                  }}
                </FieldArray>
                {/* <BtnDiv>
                  {selectedTaskTemplates! ? (
                    <CreateTemplateBtn type="submit" disabled={isSubmitting}>
                      Update Template
                    </CreateTemplateBtn>
                  ) : (
                    <CreateTemplateBtn type="submit" disabled={isSubmitting}>
                      Create Template
                    </CreateTemplateBtn>
                  )}
                </BtnDiv> */}

                <RowForBtn>
                  <ColumnForBtns>
                    {selectedTaskTemplates! ? (
                      <CreateTemplateBtn type="submit" disabled={isSubmitting}>
                        Update Template
                      </CreateTemplateBtn>
                    ) : (
                      <CreateTemplateBtn type="submit" disabled={isSubmitting}>
                        Create Template
                      </CreateTemplateBtn>
                    )}
                  </ColumnForBtns>
                </RowForBtn>
              </Form>
            )}
          </Formik>
        </MainDivPopUp>
      )}
    </>
  );
};

export default CreateTemplateModel;
