import { Field, Form, Formik } from "formik";
import { useContext } from "react";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import { SearchProps } from "../../types";
import { isValidEmail, parseTags } from "../../utils/utils";

const SearchWrapper = styled.div`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
  }
`;
const SearchboxContainer = styled.div`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: space-evenly;
  }
`;

const Inputbox = styled(Field)`
  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 74%; */
    width: 58%;
    height: 36px;
    padding: 13px;
    position: absolute;
    margin-top: 10.1px;
    /* margin-left: 10%; */
    border-radius: 20px 0 0 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgB5ZbfcdpAEMa/1R9m/BQ6CCXQQUgFdiqwPGMTv5lUAHTgvHkCM8EVxKkgooLQgUkHfslkxifdZk+OTgdIQsLxk7+n41jtT7u6213gtYmaGqpzDMT6hAhvwehmmx7WWmOZKsRHC6zRQrVgjtDVAa6YaCSWXdQac6wUzpq+QCX4MULf79A3BnpoIdI88eeY7rUr20yGOAXT9UaUjAf26Ja0XrH/FBUn6BPRQNJ/vOGAEfuKP9ACD2gKlvT20pB+WqgANdO0M9fXVU74Up7R3kRWp8UmLYKZPmsMTod076R3rTx+f3TT7LupoTch8Dj/rTV/6sxR+sLeJhTjQ6FG4Rc9YabP1jnR2BzQvWBJQJSvNPO0DTRXoLRE/e85+VwqQFQLVpcYuNF2ZljgAJkDlWp2oz6uBSPFSb5k5u94hsLEeWmSgErS7aa6Z201YjxD2TVi/Mp/q3C3FlgweXiTr5mq719jeXxvfXN9xKgz/N8qwE5qNLUrk6XS1M+XSbB7OyxYuszKblacxKZ6vEDfKbfrsmtpwdsn8U90eNQeY1T4orjUxv5vTiIVpzkM6SsOkKn1kjJbsznVt7XgzIi4aGcSdXqOMVpCdYd+FD5oEc7Lr+YGOLxB7NZaaYOTpnAzoRiorX7S1RTpyr5c3o8/yluzjDqF1qZ2hwp32z3WAMVJ5KbXOq8ZCkrBpsQlobQ44qvdB3jFEo00/65kp7czEnF2Vrr74LUzV3KBiExrazj+iN0y8TgKtBe5fbkMvnfKzKaLVAo9kTjDuxJaNhKJ9ztzRvLt7aFgG954vM0YI+mvv9Ej/ymVSUVxqIK7E0kr8CFy4XI2luGMB2Yd4IVlxiGBG2o/8XmEV6u/QRAjJVbuMXMAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: 805px;
    border: none;
    &::-webkit-input-placeholder {
      color: rgba(60, 59, 91, 0.29);
      font-size: 8px;
      line-height: 12px;
      font-weight: 400;
      padding-left: 20px;
    }
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 60%;
    height: 36px;
    padding: 13px;
    position: absolute;
    margin-top: 14.1px;
    margin-left: -14%; */
    width: 60%;
    height: 29px;
    position: absolute;
   /* right: 28%; */
   right: 39%;
    top: -3px;
  }
`;

const Error = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  padding: 3px;
`;

const SearchBox = ({ isOpenSearchBox }: any) => {
  const { applySearchFilters, searchText, setSearchText } =
    useContext(TaskContext);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={{
        searchText,
      }}
      validate={(values: SearchProps) => {
        const searchCriteria = parseTags(
          values.searchText ? values.searchText : ""
        );

        setSearchText(values.searchText ? values.searchText : "");

        const errors: SearchProps = {};
        if (
          searchCriteria.fromEmail &&
          !isValidEmail(searchCriteria.fromEmail)
        ) {
          errors.searchText = "Invalid email against 'from:' tag";
        }

        if (searchCriteria.toEmail && !isValidEmail(searchCriteria.toEmail)) {
          errors.searchText = "Invalid email against 'to:' tag";
        }

        if (
          !errors.searchText &&
          searchCriteria.status &&
          (searchCriteria.status < 0 || searchCriteria.status > 5)
        )
          errors.searchText = "'s:' tag values should be in range 0-5";

        return errors;
      }}
      onSubmit={(values: SearchProps, { setSubmitting }) => {
        applySearchFilters(
          parseTags(values.searchText ? values.searchText : ""),
          null
        );
        setSubmitting(false);
      }}
    >
      {({ errors, values, submitForm }) => {
        return (
          <Form onClick={() => {}}>
            {isOpenSearchBox ? (
              <>
                <SearchWrapper>
                  <SearchboxContainer>
                    <Inputbox
                      name="searchText"
                      type="text"
                      placeholder="Search Task"
                      value={values.searchText}
                    />
                  </SearchboxContainer>
                  {errors && errors.searchText ? (
                    <Error>{errors.searchText}</Error>
                  ) : null}
                </SearchWrapper>
              </>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchBox;
