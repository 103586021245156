import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AccordionContext, Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { ProjectTemplatesContext } from "../../context/project-template-context";
import { TeamContext } from "../../context/team-context";
import { USER_SHARE_TEMPLATE_EVENTS } from "../../customer-io/events";
import AccordionDownSvg from "../../images/accordionDownArrow.svg";
import AccordionUpSvg from "../../images/accordionUpArrow.svg";
import shareTemplateIcon from "../../images/bluesharetemplateicon.svg";
import templateCopyIcon from "../../images/bluetemplatecopyicon.svg";
import templateDeleteIcon from "../../images/bluetemplatedeleteicon.svg";
import templateEditIcon from "../../images/bluetemplateediticon.svg";
import CrosshandOffBox from "../../images/cross.svg";
import DragAndDropSvg from "../../images/draganddrop.svg";
import SendEMail from "../../images/send.svg";
import ShareByIcon from "../../images/share-by.svg";
import ShareWithIcon from "../../images/share-with.svg";
import SubTemplateIndicatorSvg from "../../images/subtask-indicator.svg";
import CopyTemplateHoverIcon from "../../images/template-copy-hover.svg";
import CopyTemplateKeyIcon from "../../images/template-copy-key.svg";
import DeleteTemplateHoverIcon from "../../images/template-delete-hover.svg";
import EditTemplateHoverIcon from "../../images/template-edit-hover.svg";
import CopyTemplateKeyHoverIcon from "../../images/template-key-copy-hover.svg";
import ShareTemplateHoverIcon from "../../images/template-share-hover.svg";
import myAxios from "../../utils/myaxios";
import { isValidEmail } from "../../utils/utils";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";
declare var _cio: any;
const WatchParent = styled.div`
  left: 50%;
  top: 32%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const SpanTempKey = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 0px;
  /* color: rgba(60, 59, 91, 0.4); */
  color: #3c3b5bcc;
`;

const SpanKeyName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3c3b5bcc;
  padding: 0 3px;
  text-transform: uppercase;
  margin-top: 4px;
`;

const TempListDivFirstRow = styled.div`
  /* display: flex;
  justify-content: right; */
`;

const MainDivForListAllTemps = styled.div`
  /* border-bottom: 1px solid #e6e6e6; */
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  /* background: #f6f9ff; */
`;
const CopyKeyImgTag = styled.img`
  padding: 0 4px;
  cursor: pointer;
  position: relative;
`;
const CopyTemplateImageTag = styled.img`
  margin: 0 20px;
  cursor: pointer;
  right: left;
  left: 48.7%;
`;

const ShareTemplateImageTag = styled.img`
  cursor: pointer;
  right: left;
  left: 48.7%;
`;

const ShareWithImageTag = styled.img`
  right: left;
  margin-right: 5px;
  left: 48.7%;
  width: 18px;
`;

const EditTemplateImageTag = styled.img`
  margin-left: 20px;
  cursor: pointer;
  right: left;
  left: 48.7%;
`;
const DeleteTemplateImageTag = styled.img`
  cursor: pointer;
  right: left;
  left: 48.7%;
`;

const SpanTskTempName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(60, 59, 91, 0.8);
  /* padding-left: 16px; */
`;
const SpanTskTempDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(60, 59, 91, 0.8);
  /* padding-left: 50px; */
  /* width: 100%; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const SpanShareWith = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #3c3b5bcc;
  padding-right: 3px;
  cursor: pointer;
`;

const SpanShareBy = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #3c3b5bcc;
`;

const CopyUniqueKeyDiv = styled.div`
  width: 158px;
  height: 39px;
  background: #ffe6cc;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
  position: absolute;
  border: 2px solid #ced7ef;
  padding: 6px;
  left: -68px;
  top: 30px;
  z-index: 11111;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    left: -49px;
    width: 110px;
    height: 29px;
    padding: 0px;
    border-radius: 5px;
  }
`;

const SpanText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2f4c95;
  display: inline-block;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
  }
`;

const ShareWithUsersModel = styled.div`
  width: auto;
  height: auto;
  background: #ffe6cc;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  border: 2px solid #ced7ef;
  left: 1.5%;
  padding: 11px;
  top: 124%;
  z-index: 1111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: auto;
    height: auto;
    background: #ffe6cc;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    border: 2px solid #ced7ef;
    left: 24%;
    z-index: 1111;
  }
`;
const SpanForName = styled.span`
  /* color: rgba(60, 59, 91, 0.29); */
  /* font-family: "Poppins"; */
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  /* padding-left: 20px; */
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;
const ShareTemplatePopUpDiv = styled.div`
  position: absolute;
  z-index: 9;
  width: 400px;
  /* left: 0; */
  padding: 10px;
  /* top: 148%; */
  top: 36px;
  /* left: -163%; */
  right: 0%;
  background: #ffe6cc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 300px;
    height: auto;
    /* left: 14%; */
    top: 39px;
  }
`;

const ShareTemplatePopupText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #3c3b5b;
  & select {
    width: 200px;
  }
  & span {
    font-size: 16px;
    margin: 10px 0px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 19px;

    & span {
      font-size: 10px;
      margin: 10px 0px;
    }
  }
`;
const ShareTemplateCrossButton = styled.img`
  display: block;
  width: 25px;
  height: 23px;
  position: absolute;
  cursor: pointer;
  top: -8%;
  left: 96%;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 94%; */
    width: 20px;
    height: 23px;
  }
`;
const EmailInputBox = styled.input`
  width: 325px;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  /* margin-top: 5px; */
  padding: 10px;
  padding-left: 5px;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 20px;
    @media (max-width: 1199px) and (min-width: 320px) {
      font-size: 12px;
    }
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 233px;
    height: 33px;
  }
`;

const SendEmailButton = styled.img`
  width: 44px;
  height: 44px;
  background: #2f4c95;
  border-radius: 50px;
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 32px;
    height: 32px;
  }
`;
const ShareTemplateErrorSpan = styled.span`
  color: red;
  font-size: 13px;
  font-weight: 300;
  padding-left: 10px;
`;
const DivForFirstRow = styled.div`
  /* padding: 0 20px; */
  /* display: flex;
  justify-content: space-between; */
`;
const ToggleAccordionImgTag = styled.img`
  width: 30px;
  margin-top: 6px;
  cursor: pointer;
`;
const SubTemplateName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(60, 59, 91, 0.8);
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: block; */
`;

const SubTemplatesDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(60, 59, 91, 0.8);
  /* width: 100%; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; */

  &:p {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: rgba(60, 59, 91, 0.8) !important;
    width: 100% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
  }
`;

const MainRowForSubTemplate = styled(Row)`
  background: #ffffff;
  border: 1px solid #c5cfe3;
  border-radius: 5px;
  padding: 6px 20px;
  margin: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 10px 0;
    display: flex;
    padding: 0;
  }
`;
const ColumnForSubTemplateFirst = styled(Col)`
  padding: 0;
  margin-bottom: -11px;

  @media (max-width: 1199px) and (min-width: 320px) {
    flex: 7;
  }
`;
const ColumnForSubTemplateSecond = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    flex: 1;
    display: flex;
    justify-content: right;
  }
`;

const LiForSubTaskTemplates = styled.li`
  list-style: none;
`;
const DragAndDropImgTag = styled.img`
  margin: 15px 36px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 15px;
    margin: 0px;
  }
`;
const DragDropSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: rgba(60, 59, 91, 0.8);
`;
const TempNameAndImgRow = styled(Row)`
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
  }
`;
const TempNameAndImgColFirst = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    flex: 1;
  }
`;
const TempNameAndImgColSecond = styled(Col)`
  padding: 0;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* flex:1; */
    flex: 8;
    width: 50%;
  }
`;
const DivForTemplateKey = styled.div`
  display: flex;
  justify-content: right;
  margin: 0 -31px;

  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: left;
    margin: 0px;
  }
`;
const SubTemplateImgTag = styled.img`
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 13px;
  }
`;

const TemplateList = ({ isMobileView }: any) => {
  const { selectedTeam } = useContext(TeamContext);
  const {
    allTaskTemplates,
    setAllTaskTemplates,
    openSidebarPopup,
    setSidebarPopup,
    deleteTemplate,
    setSelectedTaskTemplates,
    addNewTemplate,
    updateTemplate,
    updateSubTemplates,
  } = useContext(ProjectTemplatesContext);
  const [loading, setLoading] = useState(false);
  const [copyUniqueKeyShowPopUp, setCopyUniqueKeyShowPopUp] =
    useState<boolean>(false);
  // for showing sperate text when clicked to copy unique key icon
  const [copyUniqueKeyIDs, setCopyUniqueKeyIDs] = useState();
  const [showShareWithList, setShowShareWithList] = useState<boolean>(false);
  const [shareWithTemplateId, setShareWithTemplateId] =
    useState<any>(undefined);

  const [showShareModel, setShowShareModel] = useState<boolean>(false);
  const [shareTemplateId, setShareTemplateId] = useState<any>(undefined);
  const [shareTemplateError, setShareTemplateEmailError] = useState<
    string | undefined
  >(undefined);

  const [shareTemplateEmailText, setShareTemplateEmailText] = useState<
    string | undefined
  >(undefined);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [showCopyModel, setShowCopyModel] = useState(false);
  const [templateIdToDelete, setTemplateIdToDelete] = useState();
  const [templateIdToCopy, setTemplateToCopy] = useState();

  const [templateCopyImg, setTemplateCopyImg] = useState<any>([]);
  const [templateKeyCopyImg, setTemplateKeyCopyImg] = useState<any>([]);
  const [templateEditImg, setTemplateEditImg] = useState<any>([]);
  const [templateDeleteImg, setTemplateDeleteImg] = useState<any>([]);
  const [templateShareImg, setTemplateShareImg] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>(null);

  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  useEffect(() => {
    if (selectedTeam) {
      setLoading(true);
      myAxios
        .get(`project-templates/${selectedTeam.id}`)
        .then((response) => {
          setAllTaskTemplates(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error, "error in getting templates tasks");
        });
    }
  }, [selectedTeam, setAllTaskTemplates]);

  useEffect(() => {
    if (allTaskTemplates && allTaskTemplates.length > 0) {
      let tmck = [];
      let tmc = [];
      let tme = [];
      let tmd = [];
      let tms = [];
      for (let i = 0; i < allTaskTemplates.length; i++) {
        tmck.push(templateCopyIcon);
        tmc.push(CopyTemplateKeyIcon);
        tme.push(templateEditIcon);
        tmd.push(templateDeleteIcon);
        tms.push(shareTemplateIcon);
      }
      setTemplateCopyImg(tmck);
      setTemplateKeyCopyImg(tmc);
      setTemplateEditImg(tme);
      setTemplateDeleteImg(tmd);
      setTemplateShareImg(tms);
    }
  }, [allTaskTemplates]);

  const deletePopUpFun = () => {
    setShowDeleteModel(!showDeleteModel);
  };

  const handleUniqueKeyCopyTextPopUpFun = () => {
    setCopyUniqueKeyShowPopUp(!copyUniqueKeyShowPopUp);
    setTimeout(() => {
      setCopyUniqueKeyShowPopUp(false);
    }, 2000);
  };

  const deleteTaskTemplate = (templateId: any) => {
    setLoading(true);
    myAxios
      .delete(`project-templates/${templateId}`)
      .then((response) => {
        setShowDeleteModel(false);
        if (response.data.success) {
          deleteTemplate(templateId);
          NotificationManager.success("Template deleted successfully");
        } else {
          NotificationManager.error(
            response.data?.message
              ? response.data?.message
              : "Failed to delete template!"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const copyTemplate = (templateId: any) => {
    setLoading(true);
    myAxios
      .post(`project-templates/copy-template`, { templateId })
      .then((response) => {
        setShowCopyModel(false);
        if (response.data.success) {
          addNewTemplate(response.data.data);
          NotificationManager.success("Copy template successfully");
        } else {
          NotificationManager.error(
            response.data?.message
              ? response.data?.message
              : "Failed to copy template!"
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const validateShareEmails = (emailText: string) => {
    if (!emailText || emailText.length === 0) {
      setCanSubmit(false);
      setShareTemplateEmailError(undefined);
    } else {
      if (emailText.includes(",")) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          setCanSubmit(false);
          setShareTemplateEmailError("Valid email are required after ','");
        } else {
          const invalidEmails = emailText.split(",").filter((e) => {
            if (!isValidEmail(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            setCanSubmit(false);
            setShareTemplateEmailError(
              "Following emails [" +
                invalidEmails.join(", ") +
                "] are not valid."
            );
          } else {
            setCanSubmit(true);
            setShareTemplateEmailError(undefined);
          }
        }
      } else {
        //Single emails
        if (!isValidEmail(emailText)) {
          setCanSubmit(false);
          setShareTemplateEmailError("Please enter valid email");
        } else {
          // email is valid
          setCanSubmit(true);
          setShareTemplateEmailError(undefined);
        }
      }
    }
  };

  const shareTemplateClick = () => {
    if (canSubmit && shareTemplateEmailText) {
      _cio.track(USER_SHARE_TEMPLATE_EVENTS, {
        name: "user share template",
      });
      setLoading(true);
      myAxios
        .post(`project-templates/share-template`, {
          templateId: shareTemplateId,
          userEmails: shareTemplateEmailText.split(","),
          teamId: selectedTeam?.id,
        })
        .then((response) => {
          setLoading(false);
          setShowShareModel(false);
          setCanSubmit(false);
          setShareTemplateEmailError(undefined);
          setShareTemplateEmailText(undefined);
          if (response.data.success) {
            updateTemplate(response.data.data);
            NotificationManager.success("Share template successfully");
          } else {
            NotificationManager.error(
              response.data?.message
                ? response.data?.message
                : "Failed to share template!"
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    }
  };

  const CustomToggle = ({ children, eventKey, callback, handleClick }: any) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey);

      handleClick();
    });

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <>
        <ToggleAccordionImgTag
          src={isCurrentEventKey ? AccordionUpSvg : AccordionDownSvg}
          alt="down"
          onClick={decoratedOnClick}
        />
      </>
    );
  };

  const [updateSubTemplatesOrderOnServer, setUpdateSubTemplatesOrderOnServer] =
    useState<boolean>(false);

  useEffect(() => {
    if (updateSubTemplatesOrderOnServer) {
      // Need to Update  sub tasks order on server

      myAxios
        .post(
          "/sub-templates/update-order",
          allTaskTemplates[activeKey]?.subTemplates?.map((st) => ({
            id: st._id,
            order: st.order,
          }))
        )
        .then((res: any) => {
          setUpdateSubTemplatesOrderOnServer(false);
        })
        .catch((error) => {
          setUpdateSubTemplatesOrderOnServer(false);
          console.error(
            "Getting Error at the time of Updating order of sub tasks ---- \n ",
            error
          );
        });
    }
  }, [updateSubTemplatesOrderOnServer, allTaskTemplates, activeKey]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    // let subtemp = allTaskTemplates

    if (
      allTaskTemplates &&
      allTaskTemplates[activeKey] &&
      allTaskTemplates[activeKey]?.subTemplates
    ) {
      const subTemplates = allTaskTemplates[activeKey]?.subTemplates;
      const tmplId = allTaskTemplates[activeKey]._id;
      if (subTemplates && tmplId) {
        let items: any = Array.from(subTemplates);

        // console.log("subTemplates", allTaskTemplates[0].subTemplates);
        const [removedItem]: any = items?.splice(result.source.index, 1);

        items?.splice(result.destination.index, 0, removedItem);
        items = items.map((it: any, index: number) => ({
          ...it,
          order: index + 1,
        }));

        updateSubTemplates(tmplId, items);
        setUpdateSubTemplatesOrderOnServer(true);
      }
    }
  };

  return (
    <>
      {allTaskTemplates.map((template: any, index: any) => {
        return (
          <>
            {/* {console.log(template)} */}
            <MainDivForListAllTemps key={index}>
              {loading && (
                <WatchParent>
                  <Watch color="orange" height={50} width={50} />
                </WatchParent>
              )}

              {/*   for showing in mobile.......  */}
              {/* {openOptionBarForDltEdit && OptionBarID === template._id && (
                <MainDivOptionBar>
                  <span>
                    <i className="arrowUpOption UpSignForOption"></i>
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <EditSpanTag
                      title="Edit Template"
                      onClick={() => {
                        setSidebarPopup(!openSidebarPopup);
                        setSelectedTaskTemplates(template);
                      }}
                    >
                      Edit
                    </EditSpanTag>

                    <CopyTempSpanTag
                      title="Copy Template"
                      onClick={() => {
                        setTemplateToCopy(template._id);
                        setShowCopyModel(true);
                      }}
                    >
                      Copy Template
                    </CopyTempSpanTag>

                    <DltSpanTag
                      title="Delete Template"
                      onClick={() => {
                        deletePopUpFun();
                        setTemplateIdToDelete(template._id);
                      }}
                    >
                      Delete
                    </DltSpanTag>
                  </div>
                </MainDivOptionBar>
              )} */}

              <Accordion defaultActiveKey={"0"} activeKey={activeKey}>
                <Card>
                  <Card.Header>
                    <DivForFirstRow>
                      {/* first column div******** */}
                      <Row>
                        <Col sm={9}>
                          <TempNameAndImgRow>
                            <TempNameAndImgColFirst sm={1}>
                              <CustomToggle
                                eventKey={`${index}`}
                                handleClick={() => {
                                  if (activeKey === `${index}`) {
                                    setActiveKey(null);
                                  } else {
                                    setActiveKey(`${index}`);
                                  }
                                }}
                              ></CustomToggle>
                            </TempNameAndImgColFirst>
                            <TempNameAndImgColSecond sm={11}>
                              <SpanTskTempName>
                                {" "}
                                {template.name}
                              </SpanTskTempName>
                              {/* <div> */}
                              <SpanTskTempDescription>
                                {template.description}
                              </SpanTskTempDescription>
                              {/* </div> */}
                            </TempNameAndImgColSecond>
                          </TempNameAndImgRow>
                        </Col>
                        {/*  -------------second column ICONS--------------- */}
                        <Col
                          sm={3}
                          style={{ display: "flex", justifyContent: "right" }}
                        >
                          {isMobileView && (
                            <TempListDivFirstRow>
                              {/* {isMobileView ? ( */}
                              {/* // for showing in desktop....... */}
                              <div style={{ position: "relative" }}>
                                <ShareTemplateImageTag
                                  src={templateShareImg[index]}
                                  alt="share"
                                  title="Share Template"
                                  onClick={() => {
                                    setCanSubmit(false);
                                    setShareTemplateEmailError(undefined);
                                    setShareTemplateEmailText(undefined);
                                    setShareTemplateId(template._id);
                                    setShowShareModel(true);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateShareImg];
                                    st[index] = ShareTemplateHoverIcon;
                                    setTemplateShareImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateShareImg];
                                    st[index] = shareTemplateIcon;
                                    setTemplateShareImg(st);
                                  }}
                                />
                                {/* Showing share template model when user click on template share icon to share template with other users */}
                                {showShareModel &&
                                shareTemplateId === template._id ? (
                                  <ShareTemplatePopUpDiv
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <span>
                                      <i className="modelOver UpOver"></i>
                                    </span>
                                    <ShareTemplateCrossButton
                                      src={CrosshandOffBox}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowShareModel(false);
                                      }}
                                    />

                                    <ShareTemplatePopupText>
                                      <span>
                                        Enter comma separated email IDs.
                                      </span>
                                    </ShareTemplatePopupText>
                                    <EmailInputBox
                                      type="text"
                                      placeholder="Enter email address"
                                      onChange={(e) => {
                                        setShareTemplateEmailText(
                                          e.target.value
                                        );
                                        validateShareEmails(e.target.value);
                                      }}
                                    />
                                    <SendEmailButton
                                      src={SendEMail}
                                      onClick={() => {
                                        shareTemplateClick();
                                      }}
                                    />
                                    {shareTemplateError && (
                                      <ShareTemplateErrorSpan>
                                        {shareTemplateError}
                                      </ShareTemplateErrorSpan>
                                    )}
                                  </ShareTemplatePopUpDiv>
                                ) : null}

                                <EditTemplateImageTag
                                  src={templateEditImg[index]}
                                  alt="edit"
                                  title="Edit Template"
                                  onClick={() => {
                                    setSidebarPopup(!openSidebarPopup);
                                    setSelectedTaskTemplates(template);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateEditImg];
                                    st[index] = EditTemplateHoverIcon;
                                    setTemplateEditImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateEditImg];
                                    st[index] = templateEditIcon;
                                    setTemplateEditImg(st);
                                  }}
                                />
                                <CopyTemplateImageTag
                                  src={templateCopyImg[index]}
                                  alt="copytemplate"
                                  title="Copy Template"
                                  onClick={() => {
                                    setTemplateToCopy(template._id);
                                    setShowCopyModel(true);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateCopyImg];
                                    st[index] = CopyTemplateHoverIcon;
                                    setTemplateCopyImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateCopyImg];
                                    st[index] = templateCopyIcon;
                                    setTemplateCopyImg(st);
                                  }}
                                />
                                <DeleteTemplateImageTag
                                  src={templateDeleteImg[index]}
                                  alt="delete"
                                  title="Delete Template"
                                  onClick={() => {
                                    deletePopUpFun();
                                    setTemplateIdToDelete(template._id);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateDeleteImg];
                                    st[index] = DeleteTemplateHoverIcon;
                                    setTemplateDeleteImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateDeleteImg];
                                    st[index] = templateDeleteIcon;
                                    setTemplateDeleteImg(st);
                                  }}
                                />
                              </div>
                            </TempListDivFirstRow>
                          )}
                        </Col>
                        {/* ********THIRD ROW FOR TEMPLATE KEY AND SHARE TEMPLATE **********  */}
                        <Row>
                          <Col sm={1}></Col>
                          <Col
                            sm={8}
                            style={{ padding: "0", marginTop: "10px" }}
                          >
                            <Row>
                              <Col
                                sm={12}
                                style={{ padding: "0", margin: "0 -13px" }}
                              >
                                <div style={{ position: "relative" }}>
                                  {isDesktopScreen &&
                                    template.sharedWith &&
                                    template.sharedWith.length > 0 && (
                                      <>
                                        <ShareWithImageTag
                                          src={ShareWithIcon}
                                          alt="sharewith"
                                        />
                                        <SpanShareWith
                                          onMouseEnter={() => {
                                            setShareWithTemplateId(
                                              template._id
                                            );
                                            setShowShareWithList(true);
                                          }}
                                          onMouseLeave={() => {
                                            setShareWithTemplateId(undefined);
                                            setShowShareWithList(false);
                                          }}
                                        >
                                          Shared with{" "}
                                          {template.sharedWith.length}{" "}
                                          {template.sharedWith.length === 1
                                            ? "user"
                                            : "users"}
                                        </SpanShareWith>

                                        {showShareWithList &&
                                          shareWithTemplateId ===
                                            template._id && (
                                            <ShareWithUsersModel>
                                              <span>
                                                <i className="groupOver UpOver"></i>
                                              </span>
                                              {template.sharedWith.map(
                                                (user: any) => {
                                                  return (
                                                    <>
                                                      {user.firstName &&
                                                      user.firstName ? (
                                                        <SpanForName>
                                                          {user.firstName}{" "}
                                                          {user.lastName}
                                                        </SpanForName>
                                                      ) : (
                                                        <SpanForName>
                                                          {user.email}
                                                        </SpanForName>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ShareWithUsersModel>
                                          )}
                                      </>
                                    )}
                                </div>

                                {isDesktopScreen && template.sharedBy && (
                                  <>
                                    <ShareWithImageTag
                                      src={ShareByIcon}
                                      alt="shareby"
                                    />
                                    <SpanShareWith>Shared By:</SpanShareWith>
                                    <SpanShareBy>
                                      {template.sharedBy?.firstName
                                        ? `${template.sharedBy?.firstName} ${template.sharedBy?.lastName}`
                                        : template.sharedBy?.email}
                                    </SpanShareBy>
                                  </>
                                )}
                              </Col>
                            </Row>

                            {/*0000 */}
                          </Col>

                          <Col sm={3} style={{ marginTop: "10px" }}>
                            <DivForTemplateKey>
                              <SpanTempKey> Template Key:</SpanTempKey>
                              <SpanKeyName>{template.uniqueKey}</SpanKeyName>
                              <div style={{ position: "relative" }}>
                                <CopyKeyImgTag
                                  src={templateKeyCopyImg[index]}
                                  alt="copykey"
                                  title="Copy Template Key"
                                  onMouseEnter={() => {
                                    const st = [...templateKeyCopyImg];
                                    st[index] = CopyTemplateKeyHoverIcon;
                                    setTemplateKeyCopyImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateKeyCopyImg];
                                    st[index] = CopyTemplateKeyIcon;
                                    setTemplateKeyCopyImg(st);
                                  }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${template.uniqueKey}`
                                    );
                                    handleUniqueKeyCopyTextPopUpFun();
                                    setCopyUniqueKeyIDs(template._id);
                                  }}
                                />

                                {/* copy Unique key Small Popup */}
                                {copyUniqueKeyShowPopUp &&
                                  copyUniqueKeyIDs === template._id && (
                                    <CopyUniqueKeyDiv>
                                      <span>
                                        <i className="arrow up"></i>
                                      </span>
                                      <SpanText>Key copied!</SpanText>
                                    </CopyUniqueKeyDiv>
                                  )}
                              </div>
                            </DivForTemplateKey>
                          </Col>
                        </Row>
                      </Row>
                    </DivForFirstRow>
                  </Card.Header>
                  {/* ******************* map for sub templates*********** */}
                  <Accordion.Collapse eventKey={`${index}`}>
                    <Card.Body style={{ background: "#F6F9FF" }}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                          {(provided) => (
                            <ul
                              style={{ padding: "0" }}
                              className="characters"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {allTaskTemplates &&
                                allTaskTemplates[index].subTemplates &&
                                allTaskTemplates[index]?.subTemplates?.map(
                                  (subTemp: any, index: any) => {
                                    return (
                                      <>
                                        <Draggable
                                          key={subTemp._id}
                                          draggableId={subTemp._id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <LiForSubTaskTemplates
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <MainRowForSubTemplate>
                                                <ColumnForSubTemplateFirst
                                                  sm={11}
                                                >
                                                  <div className="paraCorrect">
                                                    <SubTemplateImgTag
                                                      src={
                                                        SubTemplateIndicatorSvg
                                                      }
                                                      alt="subtemplate"
                                                    />
                                                    <SubTemplateName>
                                                      {subTemp.name}
                                                    </SubTemplateName>

                                                    <SubTemplatesDescription
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          subTemp.description,
                                                      }}
                                                    ></SubTemplatesDescription>
                                                  </div>
                                                </ColumnForSubTemplateFirst>
                                                <ColumnForSubTemplateSecond
                                                  sm={1}
                                                >
                                                  <DragAndDropImgTag
                                                    src={DragAndDropSvg}
                                                    alt="draganddrop"
                                                  />
                                                </ColumnForSubTemplateSecond>
                                              </MainRowForSubTemplate>
                                              {/* </MainDivForSubTemplate> */}
                                            </LiForSubTaskTemplates>
                                          )}
                                        </Draggable>
                                      </>
                                    );
                                  }
                                )}

                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {/* *****************this seaction visible on mobile----- ****************/}

                      <div style={{ position: "relative" }}>
                        {!isDesktopScreen &&
                          template.sharedWith &&
                          template.sharedWith.length > 0 && (
                            <>
                              <ShareWithImageTag
                                src={ShareWithIcon}
                                alt="sharewith"
                              />
                              <SpanShareWith
                                onMouseEnter={() => {
                                  setShareWithTemplateId(template._id);
                                  setShowShareWithList(true);
                                }}
                                onMouseLeave={() => {
                                  setShareWithTemplateId(undefined);
                                  setShowShareWithList(false);
                                }}
                              >
                                Shared with {template.sharedWith.length}{" "}
                                {template.sharedWith.length === 1
                                  ? "user"
                                  : "users"}
                              </SpanShareWith>

                              {showShareWithList &&
                                shareWithTemplateId === template._id && (
                                  <ShareWithUsersModel>
                                    <span>
                                      <i className="groupOver UpOver"></i>
                                    </span>
                                    {template.sharedWith.map((user: any) => {
                                      return (
                                        <>
                                          {user.firstName && user.firstName ? (
                                            <SpanForName>
                                              {user.firstName} {user.lastName}
                                            </SpanForName>
                                          ) : (
                                            <SpanForName>
                                              {user.email}
                                            </SpanForName>
                                          )}
                                        </>
                                      );
                                    })}
                                  </ShareWithUsersModel>
                                )}
                            </>
                          )}
                      </div>

                      {!isDesktopScreen && template.sharedBy && (
                        <>
                          <ShareWithImageTag src={ShareByIcon} alt="shareby" />
                          <SpanShareWith>Shared By:</SpanShareWith>
                          <SpanShareBy>
                            {template.sharedBy?.firstName
                              ? `${template.sharedBy?.firstName} ${template.sharedBy?.lastName}`
                              : template.sharedBy?.email}
                          </SpanShareBy>
                        </>
                      )}
                      <Row style={{ display: "flex" }}>
                        <Col sm={6} style={{ flex: "1" }}>
                          {!isDesktopScreen && (
                            <DragDropSpan>
                              You can drag and drop sub-task to reorder
                            </DragDropSpan>
                          )}
                        </Col>
                        <Col sm={6} style={{ flex: "1" }}>
                          {!isMobileView && (
                            <TempListDivFirstRow>
                              <div style={{ position: "relative" }}>
                                <ShareTemplateImageTag
                                  src={templateShareImg[index]}
                                  alt="share"
                                  title="Share Template"
                                  onClick={() => {
                                    setCanSubmit(false);
                                    setShareTemplateEmailError(undefined);
                                    setShareTemplateEmailText(undefined);
                                    setShareTemplateId(template._id);
                                    setShowShareModel(true);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateShareImg];
                                    st[index] = ShareTemplateHoverIcon;
                                    setTemplateShareImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateShareImg];
                                    st[index] = shareTemplateIcon;
                                    setTemplateShareImg(st);
                                  }}
                                />
                                {/* Showing share template model when user click on template share icon to share template with other users */}
                                {showShareModel &&
                                shareTemplateId === template._id ? (
                                  <ShareTemplatePopUpDiv
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <span>
                                      <i className="modelOver UpOver"></i>
                                    </span>
                                    <ShareTemplateCrossButton
                                      src={CrosshandOffBox}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowShareModel(false);
                                      }}
                                    />

                                    <ShareTemplatePopupText>
                                      <span>
                                        Enter comma separated email IDs.
                                      </span>
                                    </ShareTemplatePopupText>
                                    <EmailInputBox
                                      type="text"
                                      placeholder="Enter email address"
                                      onChange={(e) => {
                                        setShareTemplateEmailText(
                                          e.target.value
                                        );
                                        validateShareEmails(e.target.value);
                                      }}
                                    />
                                    <SendEmailButton
                                      src={SendEMail}
                                      onClick={() => {
                                        shareTemplateClick();
                                      }}
                                    />
                                    {shareTemplateError && (
                                      <ShareTemplateErrorSpan>
                                        {shareTemplateError}
                                      </ShareTemplateErrorSpan>
                                    )}
                                  </ShareTemplatePopUpDiv>
                                ) : null}

                                <EditTemplateImageTag
                                  src={templateEditImg[index]}
                                  alt="edit"
                                  title="Edit Template"
                                  onClick={() => {
                                    setSidebarPopup(!openSidebarPopup);
                                    setSelectedTaskTemplates(template);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateEditImg];
                                    st[index] = EditTemplateHoverIcon;
                                    setTemplateEditImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateEditImg];
                                    st[index] = templateEditIcon;
                                    setTemplateEditImg(st);
                                  }}
                                />
                                <CopyTemplateImageTag
                                  src={templateCopyImg[index]}
                                  alt="copytemplate"
                                  title="Copy Template"
                                  onClick={() => {
                                    setTemplateToCopy(template._id);
                                    setShowCopyModel(true);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateCopyImg];
                                    st[index] = CopyTemplateHoverIcon;
                                    setTemplateCopyImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateCopyImg];
                                    st[index] = templateCopyIcon;
                                    setTemplateCopyImg(st);
                                  }}
                                />
                                <DeleteTemplateImageTag
                                  src={templateDeleteImg[index]}
                                  alt="delete"
                                  title="Delete Template"
                                  onClick={() => {
                                    deletePopUpFun();
                                    setTemplateIdToDelete(template._id);
                                  }}
                                  onMouseEnter={() => {
                                    const st = [...templateDeleteImg];
                                    st[index] = DeleteTemplateHoverIcon;
                                    setTemplateDeleteImg(st);
                                  }}
                                  onMouseLeave={() => {
                                    const st = [...templateDeleteImg];
                                    st[index] = templateDeleteIcon;
                                    setTemplateDeleteImg(st);
                                  }}
                                />
                              </div>
                            </TempListDivFirstRow>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {showDeleteModel && templateIdToDelete === template._id && (
                <ConfirmationBox
                  setShow={setShowDeleteModel}
                  okButtonClick={() => {
                    deleteTaskTemplate(templateIdToDelete);
                  }}
                  name="Confirmation"
                  message="Are you sure, you want to delete this template?"
                  okButtonText="Delete Template"
                />
              )}
              {showCopyModel && templateIdToCopy === template._id && (
                <ConfirmationBox
                  setShow={setShowCopyModel}
                  okButtonClick={() => {
                    copyTemplate(templateIdToCopy);
                  }}
                  name="Confirmation"
                  message="Are you sure, you want to copy this template?"
                  okButtonText="Copy Template"
                />
              )}
            </MainDivForListAllTemps>
          </>
        );
      })}
    </>
  );
};
export default TemplateList;
