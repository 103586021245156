import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { NotificationManager } from "react-notifications";
import ReactQuill from "react-quill";
import ReactTooltip from "react-tooltip";
import styled, { keyframes } from "styled-components";
import { CommonContext } from "../../context/common-context";
import { ProjectTemplatesContext } from "../../context/project-template-context";
import { TaskContext } from "../../context/task-context";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import { UserProfileContext } from "../../context/user-profile-context";
import {
  USER_ASSIGN_GROUP_TASK_EVENTS,
  USER_ASSIGN_SELF_A_TASK_EVENTS,
  USER_ASSIGN_SOMEONE_ELSE_A_TASK_EVENTS,
  USER_ASSIGN_TEAM_MULTIPLE_ASSIGNEE_TASK_EVENTS,
  USER_USE_TEMPLATE_IN_A_TASK_EVENTS,
  USE_JETBOT_IN_EXPANDED_TASK_EVENTS,
} from "../../customer-io/events";
import AddCategorySvg from "../../images/addCategoriesBlue.svg";
import CalenderSvg from "../../images/calendarfordue.svg";
import CrossJetBotSvg from "../../images/cross.svg";
import DeleteCrossSvg from "../../images/delete-cross.svg";
import JetBotIcon from "../../images/jet-bot.svg";
import JetBotAnimation from "../../images/jetbot.gif";
import ToggleOff from "../../images/toggle-off.svg";
import ToggleOn from "../../images/toggle-on.svg";
import { Group } from "../../types";
import { USETIFULL_JETBOT } from "../../usetifull/class-names";
import myAxios from "../../utils/myaxios";
import {
  isValidInHandoverOrNot,
  removeLineBreakAtStart,
} from "../../utils/utils";
import { GPT_3_OPENAI } from ".././utils/app-feature-list";
import {
  acceptedDocumentTypes,
  quillFormats,
  regRestrictSpecialCharacters,
} from ".././utils/constants";
import {
  checkIfFeatureOpen,
  parseErrorResponse,
  removeTagsFromTaskDetail,
} from ".././utils/utills";
import Autocomplete from "../autocomplete";
import JetBotSuggestionPopup from "../jet-bot/jet-bot-suggestion-popup";
import Loader from "../utils/loader";
declare var _cio: any;

const MainContainer = styled.div`
  padding: 14px;
`;
const ExpandedInputField = styled(Field)`
  /* width: 920px; */
  width: 100%;
  height: 40px;
  border: none;
  background: #ffffff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 48px;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    /* font-size: 22px; */
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 10px;
  }
`;

const ExpandedLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #797979;
`;

const SingleGroupSpanTag = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #2f4c95;
  margin-left: 8px;
  cursor: pointer;
`;
const SingleGroupSpanText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #797979;
  display: inherit;
`;
const GroupSpanText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #797979;
  display: inherit;
  /* width: 104px; */
`;

const EmailFeild = styled(Field)`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  padding-left: 10px;
  border: none;

  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    /* font-size: 22px; */
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 10px;
  }
`;

const RowForRadioOrEmails = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
`;
const CatgoryInputTag = styled(Field)`
  width: 100%;
  height: 40px;
  left: 390px;
  top: 461px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    /* font-size: 22px; */
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 5px;
  }
`;

const AssignExpandedBtn = styled.button`
  width: 209px;
  height: 60px;
  background: #2f4c95;
  border-radius: 10px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 10px 0px;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const DescriptionRow = styled(Row)`
  margin: 10px 0;
  padding: 0;
`;
const CateAndPriorityRow = styled(Row)`
  padding-top: 5px;
`;
const CategoryDiv = styled.div`
  /* display: flex; */
  position: relative;
`;

const AddCategoryImage = styled.img`
  position: absolute;
  /* right: 65px; */
  right: 20px;
  top: 10px;
  cursor: pointer;
  /* @media (max-width: 1199px) and (min-width: 320px) {
    left: 232%;
  } */
`;
const ColPriorityCategoryTagForMobile = styled(Col)`
  display: flex;
  flex-wrap: inherit;
  min-width: 1px;
  max-width: 100%;
  overflow: auto;
`;

const CategoryEditTag = styled(Col)`
  padding: 2px 15px;
  margin: 10px;
  max-width: 100px;
  background: #2f4c951a;
  color: #797979;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff5ea;
`;

const CategoryPTag = styled.p`
  margin: 0px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;
const CategoryCrossIcon = styled.img`
  font-weight: 400px;
  size: 12px;
  margin-left: 10px;
  line-height: 20px;
  width: 10px;
  color: #2f4c95;
  cursor: pointer;
`;

const TaskError = styled.div`
  color: red;
  margin: 0px 9px;
  font-size: 12px;
  padding: 3px;
`;
const EmailError = styled.div`
  color: red;
  /* margin: 0px 9px;  */
  font-size: 12px;
  padding: 3px;
`;
const AssigneeToMeSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #fc6601;
  display: flex;
  padding: 25px 0;
  cursor: pointer;
`;

const CalenderImgTagExpandedMode = styled.img`
  position: absolute;
  z-index: 111111;
  width: 25px;
  height: 29px;
  right: 20px;
  top: 47%;
  cursor: pointer;
  z-index: 11;
`;
const RadioLabel = styled.label`
  /* margin-left: 7%; */
`;

const JetBotImgTagExpandedMode = styled.img`
  width: 29px;
  position: absolute;
  top: 44px;
  right: 16px;
  cursor: pointer;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const JetBotLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #fc6601;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 50px;
  right: 24px;
`;

const JetBotPopUpInnerDiv = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: -6px 9px 18px 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  z-index: 1111;
  right: 12px;
  margin-top: 36px;
  padding: 15px 15px 2px 15px;
  border: solid #fc6601;
  border-width: 1px;
  top: -58px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;
const JetBotPopHeader = styled.p`
  color: #797979;
  font-weight: 600;
  font-size: 14px;
  line-height: 2px;
`;
const JetBotPopText = styled.p`
  color: #797979;
  font-weight: 400;
  font-size: 12px;
  line-height: 1px;
`;

const ExpandedrossImgTag = styled.img`
  position: absolute;
  background: white;
  border-radius: 50%;
  right: -10px;
  top: -10px;
  cursor: pointer;
  width: 20px;
`;
const RecurringMainDiv = styled.div`
  border: 1px solid #fc6601;
  border-radius: 10px;
  margin-top: 5px;
`;
const InnerDiv = styled.div`
  padding: 20px;
`;
const SpanRecurring = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #2f4c95;
  padding: 20px;
`;
const RecurringToggle = styled.img`
  cursor: pointer;
`;

const RecurringPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #797979;
  /* background: ${(isRecurringTask) =>
    isRecurringTask ? "black" : "yellow"}; */
`;
const RecurringParaDisabled = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #797979;
  /* background: ${(isRecurringTask) =>
    isRecurringTask ? "black" : "yellow"}; */
`;
const RecurringLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #797979;
`;
const RecurringLabelDisabled = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;

  color: rgba(121, 121, 121, 0.5);
`;
const InputField = styled.input`
  width: 100%;
  border: none;
  height: 40px;
  left: 201px;
  top: 594px;
  /* background: rgba(255, 255, 255, 0.5); */
  border-radius: 5px;
  padding-left: 10px;
`;
const InputFieldRecurringUntil = styled.input`
  width: 100%;
  border: none;
  height: 40px;
  left: 201px;
  top: 594px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding-left: 10px;
`;
const InputFieldDisabled = styled.input`
  width: 100%;
  border: none;
  height: 40px;
  left: 201px;
  top: 594px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding-left: 10px;
`;
const PrioDescriptionColumn = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 5px;
`;
const FieldForProgressLabel = styled(Field)`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  padding-left: 49px;
`;
const DivForLabelSpan = styled.div`
  position: absolute;
  margin-left: 7px;
  height: 23px;
  margin-top: 6px;
  width: 34px;
  height: 30px;
  background: #ffe6cc;
  border-radius: 3px;
`;
const SPan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 6px;
  color: #797979;
  justify-content: center;
  display: flex;
`;

const EmailAutoComplete = ({
  allTeamMembersList,
  setValue,
  value,
  getUserEmail,
  setFieldValue,
}: any) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    if (value && value.length > 0) {
      setFieldValue("groupEmails", value);
      setFieldValue("assignedToEmail", value);
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <Autocomplete
      style={{
        width: "100%",
        height: "40px",
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        border: "none",
      }}
      messages={{
        loading: "Loading...",
        notFound: "No results found",
      }}
      placeholder="Enter Emails"
      items={
        currentValue && currentValue.length > 0
          ? [
              currentValue,
              ...allTeamMembersList.map((email: any) => email.email),
            ]
          : allTeamMembersList.map((email: any) => email.email)
      }
      defaultValue={getUserEmail}
      onChange={setValue}
      value={value}
      onItemsChange={setValue}
    >
      {(item) => <Autocomplete.Item key={item}>{item}</Autocomplete.Item>}
    </Autocomplete>
  );
};

const ExpandedTaskForm = ({
  isOpen,
  isDescription,
  setIsDescription,
  setCurrentFormData,
  initValues,
}: any) => {
  const {
    addTasks,
    addTask,
    setTaskCount,
    addCategories,
    addAssignee,
    addAssignor,
    addDayFilters,
    currentPaginationTaskInput,
    forceStopJetBot,
    setForceStopJetBot,
  } = useContext(TaskContext);

  const { allTaskTemplates } = useContext(ProjectTemplatesContext);
  const { emails, groups } = useContext(UserProfileContext);
  const [value, setValue] = useState("");
  const { hideLoader, showLoader } = useContext(CommonContext);
  const { userState } = useContext(UserContext);
  const { selectedTeam, teamMembers } = useContext(TeamContext);

  const [showJetBotLoader, setShowJetBotLoader] = useState<boolean>(false);
  const [showJetBotPopup, setShowJetBotPopup] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const [expandedTaskForm, setExpandedTaskForm] = useState<any>();

  const [gptSuggestionsText, setGptSuggestionsText] = useState<
    string | undefined
  >(undefined);

  const [singleGroupRadioBtn, setSingleGroupRadioBtn] = useState(false);
  const [radioBtntype, setRadioBtnType] = useState<string | undefined>(
    "single_task"
  );
  const [showUserGroup, setShowUserGroup] = useState<boolean>(false);
  // const [groupIndex, setGroupIndex] = useState<number>();
  // const [selectedGroup, setSelectedGroup] = useState<any>();

  const [expandedPriority, setExpandedPriority] = useState(0);
  const [expandedTemplate, setExpandedTemplate] = useState("0");
  const [expandedGroup, setExpandedGroup] = useState("0");

  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [categories, setCategories] = useState<any[]>([]);

  const [categoryHoverID, setCatHoverID] = useState();
  const [showCategory, setShowCategory] = useState(false);

  const [quillObj, setQuillObj] = useState<any>();
  const [ExpandedDescription, setExpandedDescription] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [uploadImageURL, setUploadImageURL] = useState<string | undefined>(
    undefined
  );
  // eslint-disable-next-line
  const [uploadedPdfURL, setUploadedPdfURL] = useState<string | undefined>(
    undefined
  );

  const [uplaodDescriptionVideoUrl, setUplaodDescriptionVideoUrl] =
    useState<any>();

  const [getUserEmail, setEmailUser] = useState<any>();
  const [showAssignToMeField, setShowAssignToMeField] =
    useState<boolean>(false);

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const [validationExpandedTaskForm, setValidationExpandedTaskForm] = useState<
    String | undefined
  >("");
  const [ccEmailError, setCcEmailError] = useState<String | undefined>(
    undefined
  );
  const [ExpandedformCanSubmit, setExpandedTaskformCanSubmit] = useState(false);

  const [ccEmailCanSubmit, setCCEmailCanSubmit] = useState(true);
  const [allGroupEmails, setAllGroupEmails] = useState<any>();

  const [isRecurringTask, setIsRecurringTask] = useState<boolean>(false);
  const [recurringUntil, setRecurringUntil] = useState<any>();
  const [recurringInDay, setRecurringInDay] = useState<any>();
  const [recurringCustomDay, setRecurringCustomDay] = useState<any>();
  const [recurringCustomTime, setRecurringCustomTime] = useState<any>();

  const [recurringError, setRecurringError] = useState<string | undefined>(
    undefined
  );
  const [isRecurringError, setIsRecurringError] = useState(false);

  const [isRecurringOtherFieldsDiabled, setIsRecurringOtherFieldsDisabled] =
    useState(false);

  const [localDueDate, setLocalDueDate] = useState(
    moment().add(1, "day").toDate()
  );

  let allGroupEmailsArr: any = [];

  allGroupEmailsArr.push(allGroupEmails);

  let arr: any = [];

  let groupEmailsArray: any = [];
  groupEmailsArray.push(getUserEmail);

  function onChangeValue(event: any) {
    setRadioBtnType(event.target.value);
  }
  const handleCategoryHover = (index: any) => {
    setCatHoverID(index);
    setShowCategory(true);
  };

  // uplaoding image
  useEffect(() => {
    if (uploadImageURL && quillObj) {
      quillObj.getEditor().insertEmbed(quillObj.index, "image", uploadImageURL);
      quillObj
        .getEditor()
        .clipboard.dangerouslyPasteHTML(
          quillObj.getEditor().getSelection().index + 10,
          `</br></br></br></br>`
        );
      quillObj
        .getEditor()
        .setSelection(quillObj.getEditor().getSelection().index + 15);
    }
    setUploadImageURL(undefined);
  }, [uploadImageURL, quillObj]);

  useEffect(() => {
    if (uplaodDescriptionVideoUrl && quillObj) {
      quillObj
        .getEditor()
        .insertEmbed(quillObj.index, "video", uplaodDescriptionVideoUrl);
    }
    setUplaodDescriptionVideoUrl(undefined);
  }, [uplaodDescriptionVideoUrl, quillObj]);

  const uploadImage = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        setLoading(true);
      }
      myAxios.post("/upload", formData).then((res) => {
        setLoading(false);

        if (res.data && res.data.length > 0) {
          setUploadImageURL(res.data[0].url);
        }
      });
    };
  }, []);

  const base64DescriptionImageConvertion = async (base64: any) => {
    if (base64.includes('<img src="data:image/png;base64')) {
      let converttedBase64 = base64.substring(
        base64.indexOf('<img src="data:image/png;base64')
      );
      converttedBase64 = converttedBase64.substring(
        0,
        converttedBase64.indexOf(">") + 1
      );

      converttedBase64 = converttedBase64
        .replace("<p>", "")
        .replace("</p>", "")
        .replace("<img", "")
        .replace("src=", "")
        .replace(">", "")
        .replace(/"/g, "");

      const base64Response = await fetch(converttedBase64);
      const file = await base64Response.blob();
      const formData = new FormData();
      formData.append("files", file);
      convertedDescriptionImageSendToServer(formData);
    } else {
      // setExpandedDescription(base64);
      setIsDescription(base64);
    }
  };

  const convertedDescriptionImageSendToServer = (formData: any) => {
    showLoader("Uploading description image...");
    myAxios
      .post("/upload", formData)
      .then((res) => {
        hideLoader();
        setUploadImageURL(res.data[0].url);
        // setIsDescription(commentText)
      })
      .catch((error) => {});
  };

  const uplaodVideo = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      showLoader("Uploading video ...");

      return myAxios.post("/upload", formData).then((res) => {
        if (res.data && res.data.length > 0) {
          setUplaodDescriptionVideoUrl(res.data[0].url);
          hideLoader();
        }
      });
    };
  };

  const uploadFile = useCallback(() => {
    /*  setUploadedPdfURL(
      "https://jetlist-pub.s3.amazonaws.com/amezonGift.pdf-uf8Jpr.pdf"
    ); */
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", acceptedDocumentTypes);
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading description document...");
      }
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();

        if (res.data && res.data.length > 0) {
          setUploadedPdfURL(res.data[0].url);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["video"],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: [] }],
          ["clean"],
        ],
        handlers: {
          image: uploadImage,
          video: () => {
            uplaodVideo();
          },
          link: uploadFile,
        },
        table: true,
      },
      // eslint-disable-next-line
    }),
    // eslint-disable-next-line
    []
  );

  const handleChanges = (event: any) => {
    base64DescriptionImageConvertion(event);
    setExpandedTaskForm({
      ...expandedTaskForm,
      isDescription: event,
    });
  };

  const expandedTaskFormValidation = (emailText: any) => {
    if (!emailText || emailText.length === 0) {
      setExpandedTaskformCanSubmit(false);
      setValidationExpandedTaskForm(undefined);
    } else {
      if (emailText.includes(",")) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e: any) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          setExpandedTaskformCanSubmit(false);
          setValidationExpandedTaskForm("Valid email are required after ','");
        } else {
          const invalidEmails = emailText.split(",").filter((e: any) => {
            if (!isValidInHandoverOrNot(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            setExpandedTaskformCanSubmit(false);
            setValidationExpandedTaskForm(
              "Following emails [" +
                invalidEmails.join(", ") +
                "] are not valid."
            );
          } else {
            setExpandedTaskformCanSubmit(true);
            setValidationExpandedTaskForm(undefined);
          }
        }
      } else {
        //Single emails
        if (!isValidInHandoverOrNot(emailText)) {
          setExpandedTaskformCanSubmit(false);
          setValidationExpandedTaskForm("Please enter valid email");
        } else {
          // email is valid
          setExpandedTaskformCanSubmit(true);
          setValidationExpandedTaskForm(undefined);
        }
      }
    }
  };

  const ccEmailValidation = (emailText: any) => {
    if (emailText && emailText.length > 0) {
      if (emailText.includes(",")) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e: any) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          setCCEmailCanSubmit(false);
          setCcEmailError("Valid email are required after ','");
        } else {
          const invalidEmails = emailText.split(",").filter((e: any) => {
            if (!isValidInHandoverOrNot(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            setCCEmailCanSubmit(false);
            setCcEmailError(
              "Following emails [" +
                invalidEmails.join(", ") +
                "] are not valid."
            );
          } else {
            setCCEmailCanSubmit(true);
            setCcEmailError(undefined);
          }
        }
      } else {
        //Single emails
        if (!isValidInHandoverOrNot(emailText)) {
          setCCEmailCanSubmit(false);
          setCcEmailError("Please enter valid email");
        } else {
          // email is valid

          setCCEmailCanSubmit(true);
          setCcEmailError(undefined);
        }
      }
    } else {
      setCCEmailCanSubmit(true);
      setCcEmailError("");
    }
  };

  const searchSuggestion = (searchText: string) => {
    if (searchText === "" || searchText.trim() === "") {
      NotificationManager.error(
        "Please enter task details to search suggestion...",
        "",
        3000
      );
      return;
    }

    // Send track event to customer.io
    _cio.track(USE_JETBOT_IN_EXPANDED_TASK_EVENTS, {
      name: "Jetbot in expanded task",
    });

    setSearchText(searchText);
    setShowJetBotLoader(true);
    setGptSuggestionsText(undefined);
    myAxios
      .post(`gpt-3/search-suggestion`, {
        searchText: searchText,
      })
      .then((response) => {
        if (response.data.success) {
          let suggestionText = "";
          if (response.data.data) {
            response.data.data.forEach((dt: any) => {
              suggestionText = suggestionText + "\n" + dt.text;
            });
          }
          suggestionText = removeLineBreakAtStart(suggestionText);

          suggestionText = suggestionText.replaceAll("\n", "<br>");
          setGptSuggestionsText(suggestionText);
          setShowJetBotPopup(true);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to search...",
            "",
            5000
          );
        }
        setShowJetBotLoader(false);
      })
      .catch((err) => {
        NotificationManager.error(parseErrorResponse(err));
        setShowJetBotLoader(false);
      });
  };

  const keepInDescription = () => {
    setShowJetBotPopup(false);
    // setShowOptions(true);
    setIsDescription(gptSuggestionsText);
  };

  useEffect(() => {
    if (expandedTaskForm) {
      setCurrentFormData({
        ...expandedTaskForm,
        detailsWithOutTags: expandedTaskForm.detail,
      });
    }
    // eslint-disable-next-line
  }, [expandedTaskForm]);
  useEffect(() => {
    if (initValues) {
      setExpandedPriority(initValues.priority);
      setExpandedTemplate(
        initValues.templateName ? initValues.templateName : "0"
      );
      setCategories(initValues.cats);
      setNewCategoryName("");
      setSingleGroupRadioBtn(false);
      setShowAssignToMeField(false);

      if (initValues.groupEmails && initValues.groupEmails.length > 0) {
        setEmailUser(initValues.groupEmails);
        setAllGroupEmails(initValues.groupEmails);
        setRadioBtnType("groupTask");
        setSingleGroupRadioBtn(true);
        expandedTaskFormValidation(initValues.groupEmails);
        setValue(initValues.groupEmails);
      } else {
        setRadioBtnType("single_task");
      }

      if (initValues.assignedToEmail) {
        expandedTaskFormValidation(initValues.assignedToEmail);
        setValue(initValues.assignedToEmail);
      }

      if (
        initValues.groupName &&
        initValues.groupName.length > 0 &&
        initValues.groupName !== "0"
      ) {
        // setSingleGroupRadioBtn(false);
        setShowUserGroup(true);
        setRadioBtnType("user_group");
        setExpandedGroup(initValues.groupName);
      }

      if (initValues.isRecurring) {
        // Need to set recurring task related details
        setIsRecurringTask(initValues.isRecurring);
        setRecurringInDay(
          initValues.recurringInDay ? initValues.recurringInDay + "" : ""
        );
        setRecurringUntil(
          initValues.recurringUntil && initValues.recurringUntil !== -1
            ? initValues.recurringUntil + ""
            : ""
        );

        setRecurringCustomDay(
          initValues.recurringCustomDay
            ? initValues.recurringCustomDay.toLowerCase()
            : "0"
        );
        setRecurringCustomTime(
          initValues.recurringCustomTime ? initValues.recurringCustomTime : "0"
        );
      }

      setExpandedTaskForm({ ...initValues });
    }
  }, [initValues]);

  useEffect(() => {
    if (isRecurringTask) {
      if (recurringInDay && recurringInDay.length > 0) {
        setIsRecurringError(false);
        setRecurringError(undefined);

        if (recurringCustomDay && recurringCustomDay !== "0") {
          if (!(recurringCustomTime && recurringCustomTime !== "0")) {
            setIsRecurringError(true);
            setRecurringError("Custom time required! ");
          }
        } else if (recurringCustomTime && recurringCustomTime !== "0") {
          setIsRecurringError(true);
          setRecurringError("Custom day required! ");
        } else {
        }
      } else {
        setIsRecurringError(true);
        setRecurringError(
          "recurring in days required to create recurring task"
        );
      }
    } else {
      setIsRecurringError(false);
      setRecurringError(undefined);
    }
  }, [
    recurringInDay,
    isRecurringTask,
    recurringCustomDay,
    recurringCustomTime,
  ]);

  useEffect(() => {
    if (value) {
      setAllGroupEmails(value);
      expandedTaskFormValidation(value);

      singleGroupRadioBtn
        ? setExpandedTaskForm({
            ...expandedTaskForm,
            groupEmails: value,
          })
        : setExpandedTaskForm({
            ...expandedTaskForm,
            assignedToEmail: value,
          });
    }
    // eslint-disable-next-line
  }, [value]);

  const validateExpandedTaskForm = (values: any) => {
    const errors: {
      detail?: string;
      assignedToEmail?: string;
      groupEmails?: string;
      ccUserMail?: string;
    } = {};
    if (!values.detail) {
      errors.detail = "No task summary given ";
    }

    if (showUserGroup) {
      if (
        !expandedGroup ||
        expandedGroup.trim().length === 0 ||
        expandedGroup === "0"
      ) {
        errors.groupEmails = "please select group ";
      } else {
        const indx = groups.findIndex((grp) => grp.name === expandedGroup);

        if (indx > -1) {
          const emails = groups[indx] && groups[indx].groupEmails;

          if (emails && emails?.length > 0) setExpandedTaskformCanSubmit(true);
          else errors.groupEmails = "No email found in the selected group ";
        }
      }
    } else {
      if (
        !values.assignedToEmail &&
        !showAssignToMeField &&
        !singleGroupRadioBtn
      ) {
        errors.assignedToEmail = "please add email ";
      }

      if (
        values.groupEmails &&
        values.groupEmails.length <= 0 &&
        !showAssignToMeField &&
        singleGroupRadioBtn
      ) {
        errors.groupEmails = "please add group emails ";
      }
    }

    if (ccEmailError && ccEmailError.length > 0) {
      errors.ccUserMail = "please add cc emails ";
    }

    return errors;
  };

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div>
        <Formik
          initialValues={
            initValues
              ? {
                  detail: removeTagsFromTaskDetail(initValues.detail),
                  assignedToEmail:
                    initValues.assignedToEmail &&
                    initValues.assignedToEmail.length > 0
                      ? initValues.assignedToEmail
                      : initValues.groupEmails,
                  groupEmails:
                    initValues.groupEmails && initValues.groupEmails.length > 0
                      ? initValues.groupEmails
                      : initValues.assignedToEmail,
                  ccUserMail: initValues.ccUserMail
                    ? initValues.ccUserMail
                    : "",
                  date: "",
                  duedate: initValues.duedate
                    ? initValues.duedate
                    : moment().add(1, "day").toDate(),
                  duedateText: initValues.duedateText,
                  cats: initValues.cats ? initValues.cats : [],
                  priority: initValues.priority,
                  isDescription: initValues.isDescription,
                  templateName: initValues.templateName
                    ? initValues.templateName
                    : "",
                  isTemplateTask: initValues.templateName ? true : false,
                  isRecurring: initValues.isRecurring
                    ? initValues.isRecurring
                    : false,
                  recurringInDay: initValues.recurringInDay
                    ? initValues.recurringInDay
                    : 1,
                  recurringUntil: initValues.recurringUntil
                    ? initValues.recurringUntil
                    : 2,
                  recurringCustomDay: initValues.recurringCustomDay
                    ? initValues.recurringCustomDay
                    : "0",
                  recurringCustomTime: initValues.recurringCustomTime
                    ? initValues.recurringCustomTime
                    : "0",
                  progress20: "20% Complete",
                  progress40: "40% Complete",
                  progress60: "60% Complete",
                  progress80: "80% Complete",
                  progress100: "100% Complete",
                  teamId: selectedTeam?.id,
                }
              : {
                  detail: "",
                  assignedToEmail: "",
                  groupEmails: "",
                  ccUserMail: "",
                  date: "",
                  duedate: "",
                  duedateText: "",
                  cats: [""],
                  priority: 0,
                  isDescription: "",
                  templateName: "",
                  isTemplateTask: false,
                  isRecurring: false,
                  recurringInDay: 1,
                  recurringUntil: 2,
                  recurringCustomDay: "0",
                  recurringCustomTime: "0",
                  isRecurringCustomStart: false,
                  progress20: "20% Complete",
                  progress40: "40% Complete",
                  progress60: "60% Complete",
                  progress80: "80% Complete",
                  progress100: "100% Complete",
                  teamId: selectedTeam?.id,
                }
          }
          validate={(values) => {
            return validateExpandedTaskForm(values);
          }}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            // if (!values.teamId) {
            values.teamId = selectedTeam?.id;
            // }

            if (!values.duedate) {
              // task would be due in 1 day if not provided
              // copy assignedfrom id and assigned to id to assignedto and from as backend would expect id in that
              values.duedate = moment().add(1, "day").toDate();
            }

            console.log("values", values);

            /**
             * We will try to set due date to next nearest round figure of 30 minutes interval
             */

            var taskDueMoment = moment(values.duedate);
            const isToday = taskDueMoment.isSame(moment(), "day");
            if (taskDueMoment.isValid() && !isToday) {
              if (
                taskDueMoment.minutes() >= 0 &&
                taskDueMoment.minutes() <= 30
              ) {
                values.duedate = taskDueMoment
                  .startOf("hours")
                  .add(30, "minutes")
                  .toDate();
              } else {
                values.duedate = taskDueMoment
                  .startOf("hours")
                  .add(1, "hours")
                  .toDate();
              }
            }

            values.duedateText = moment(values.duedate).format(
              "YYYY-MM-DD HH:mm"
            );

            values.isDescription = isDescription;
            values.priority = expandedPriority;
            values.cats = categories;

            singleGroupRadioBtn
              ? (values.groupEmails = allGroupEmailsArr)
              : (values.groupEmails = arr);

            singleGroupRadioBtn && (values.assignedToEmail = "");

            if (showUserGroup) {
              const indx = groups.findIndex(
                (grp) => grp.name === expandedGroup
              );

              if (indx > -1) {
                values.groupEmails = [
                  groups[indx].groupEmails
                    ?.map((email) => email.email)
                    .join(","),
                ];
                //  setExpandedTaskformCanSubmit(true);
              }
            }

            if (values.templateName) values.isTemplateTask = true;

            if (isRecurringTask) {
              let isRecurringCustomStart = false;
              values.isRecurring = isRecurringTask;
              values.recurringInDay = recurringInDay;
              values.recurringUntil = recurringUntil ? recurringUntil : "-1";
              values.recurringCustomDay =
                recurringCustomDay && recurringCustomDay !== "0"
                  ? recurringCustomDay
                  : "";
              values.recurringCustomTime =
                recurringCustomTime && recurringCustomTime !== "0"
                  ? recurringCustomTime
                  : "";
              if (recurringCustomDay && recurringCustomDay !== "0")
                isRecurringCustomStart = true;

              values.isRecurringCustomStart = isRecurringCustomStart;
            }

            const values1 = { ...values };

            if (
              ExpandedformCanSubmit &&
              ccEmailCanSubmit &&
              !isRecurringError
            ) {
              if (
                values.isTemplateTask &&
                values.templateName &&
                values.templateName.length > 0
              ) {
                _cio.track(USER_USE_TEMPLATE_IN_A_TASK_EVENTS, {
                  name: "user created task using template on expanded task form",
                });
              }

              //  // check if user assign task to self/group/other

              if (values1.groupEmails.length > 0) {
                // task is group type

                _cio.track(USER_ASSIGN_GROUP_TASK_EVENTS, {
                  name: "user assign a task to group users at expanded task form",
                });
              } else if (values1.assignedToEmail) {
                if (values1.assignedToEmail.trim() === userState.user?.email) {
                  // task is self type
                  _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
                    name: "user assign a task to self at expanded task form",
                  });
                } else {
                  if (values1.assignedToEmail.includes(",")) {
                    _cio.track(USER_ASSIGN_TEAM_MULTIPLE_ASSIGNEE_TASK_EVENTS, {
                      name: "user assign a task to multiple users at expanded task form",
                    });
                  } else {
                    // task is other type or assign some one else
                    _cio.track(USER_ASSIGN_SOMEONE_ELSE_A_TASK_EVENTS, {
                      name: "user assign a task to someone else at expanded task form",
                    });
                  }
                  if (
                    userState.user?.email &&
                    values1.assignedToEmail.includes(userState.user?.email)
                  ) {
                    // task is self type
                    _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
                      name: "user assign a task to self at expanded task form",
                    });
                  }
                }
              } else {
                // task is self type
                _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
                  name: "user assign a task to self at expanded task form",
                });
              }

              myAxios
                .post(`tasks`, values)
                .then((response) => {
                  setCurrentFormData(undefined);
                  setExpandedTaskForm(undefined);
                  initValues = undefined;
                  resetForm();
                  values.detail = "";
                  values.assignedToEmail = "";
                  values.groupEmails = "";
                  values.ccUserMail = "";
                  setIsRecurringTask(false);
                  setRecurringUntil("");
                  setRecurringInDay("");
                  setRecurringCustomDay("0");
                  setRecurringCustomTime("0");
                  setExpandedDescription("");
                  setExpandedPriority(0);
                  setExpandedTemplate("0");
                  setRadioBtnType("single_task");
                  setCategories([]);
                  setNewCategoryName("");
                  setValue("");
                  setSingleGroupRadioBtn(false);
                  setShowAssignToMeField(false);
                  setSubmitting(false);
                  setIsDescription("");
                  setFieldValue("assignedToEmail", "");
                  allGroupEmailsArr = [];
                  setAllGroupEmails("");
                  setShowUserGroup(false);
                  setLocalDueDate(moment().add(1, "day").toDate());
                  resetForm();
                  if (response.data.success) {
                    if (response.data.data) addTask(response.data.data);
                    NotificationManager.success(
                      response.data?.message
                        ? response.data?.message
                        : "Task successfully added!"
                    );

                    // Need to refresh Menu Filter after addding new task

                    myAxios
                      .get(`menu-filters/${selectedTeam?.id}`)
                      .then((response: any) => {
                        addCategories(response.data.data.categories);
                        addAssignee(response.data.data.assigneeList);
                        addAssignor(response.data.data.assignorList);
                        addDayFilters(response.data.data.daysMenuArray);
                      })
                      .catch((error) => {});

                    //  Also fetch latest task as selected pagination

                    myAxios
                      .post(`tasks/paginated-tasks`, currentPaginationTaskInput)
                      .then((response: any) => {
                        if (response.data.data.tasks)
                          addTasks(response.data.data.tasks);
                        else addTasks([]);
                        if (response.data.data.count)
                          setTaskCount(response.data.data.count);
                        else setTaskCount(0);
                      })
                      .catch((error) => {
                        console.error("error ", error);
                      });
                  } else {
                    NotificationManager.error(
                      response.data?.message
                        ? response.data?.message
                        : "Failed to add task!"
                    );
                  }
                  setCurrentFormData(undefined);
                  setExpandedTaskForm(undefined);
                  initValues = undefined;
                  setIsRecurringTask(false);
                  setRecurringUntil("");
                  setRecurringInDay("");
                  setRecurringCustomDay("0");
                  setRecurringCustomTime("0");
                  resetForm();
                  setExpandedDescription(null);
                  setExpandedPriority(0);
                  setExpandedTemplate("0");
                  setRadioBtnType("single_task");
                  setCategories([]);
                  setNewCategoryName("");
                  setValue("");
                  setSingleGroupRadioBtn(false);
                  setShowAssignToMeField(false);
                  setSubmitting(false);
                  allGroupEmailsArr = [];
                  setIsDescription("");
                  setFieldValue("assignedToEmail", "");
                  setShowUserGroup(false);
                  setLocalDueDate(moment().add(1, "day").toDate());
                  setAllGroupEmails("");
                  resetForm();
                })
                .catch((error) => {
                  setCurrentFormData(undefined);
                  setExpandedTaskForm(undefined);
                  initValues = undefined;
                  resetForm();
                  values.detail = "";
                  values.assignedToEmail = "";
                  values.groupEmails = "";
                  setIsRecurringTask(false);
                  setRecurringUntil("");
                  setRecurringInDay("");
                  setRecurringCustomDay("0");
                  setRecurringCustomTime("0");
                  setExpandedDescription("");
                  setExpandedDescription("");
                  setExpandedPriority(0);
                  setExpandedTemplate("0");
                  setRadioBtnType("single_task");
                  setCategories([]);
                  setValue("");
                  setNewCategoryName("");
                  setSingleGroupRadioBtn(false);
                  setShowAssignToMeField(false);
                  NotificationManager.error(parseErrorResponse(error));
                  setSubmitting(false);
                  setIsDescription("");
                  setFieldValue("assignedToEmail", "");
                  allGroupEmailsArr = [];
                  setAllGroupEmails("");
                  setLocalDueDate(moment().add(1, "day").toDate());
                  setShowUserGroup(false);
                  resetForm();
                });
            }
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
          }) => (
            <Form>
              <MainContainer>
                <Row>
                  <Col>
                    <ExpandedLabel>Task Summary</ExpandedLabel>
                    <ExpandedInputField
                      type="text"
                      name="detail"
                      placeholder="Task Summary"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setExpandedTaskForm({
                          ...expandedTaskForm,
                          detail: e.target.value,
                        });
                        handleChange(e);
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleBlur(e);
                      }}
                    />
                  </Col>
                  {errors.detail && touched.detail && (
                    <TaskError>{errors.detail}</TaskError>
                  )}
                </Row>

                <RowForRadioOrEmails>
                  <Col>
                    <Row>
                      <Col sm={3}>
                        <div onChange={onChangeValue}>
                          <label>
                            <input
                              type="radio"
                              className="groupsinglecheck"
                              checked={radioBtntype === "single_task"}
                              value="single_task"
                              onClick={() => {
                                setShowUserGroup(false);
                                setSingleGroupRadioBtn(false);
                                setShowAssignToMeField(false);
                                // setEmailUser(null);
                                if (expandedTaskForm)
                                  setExpandedTaskForm({
                                    ...expandedTaskForm,
                                    assignedToEmail:
                                      expandedTaskForm.groupEmails
                                        ? expandedTaskForm.groupEmails
                                        : "",
                                    groupEmails: "",
                                    groupName: "",
                                  });
                              }}
                            />
                            <SingleGroupSpanTag>Single Task</SingleGroupSpanTag>
                          </label>
                        </div>
                        <SingleGroupSpanText>
                          assign same task to single/multiple users
                        </SingleGroupSpanText>
                      </Col>
                      <Col sm={4}>
                        <div onChange={onChangeValue}>
                          <RadioLabel>
                            <input
                              type="radio"
                              className="groupcheck"
                              value="groupTask"
                              checked={radioBtntype === "groupTask"}
                              onClick={() => {
                                setShowUserGroup(false);
                                setSingleGroupRadioBtn(true);
                                setShowAssignToMeField(false);
                                if (expandedTaskForm)
                                  setExpandedTaskForm({
                                    ...expandedTaskForm,
                                    groupEmails:
                                      expandedTaskForm.assignedToEmail
                                        ? expandedTaskForm.assignedToEmail
                                        : "",
                                    assignedToEmail: "",
                                    groupName: "",
                                  });
                              }}
                            />

                            <SingleGroupSpanTag>
                              Group Task (emails)
                            </SingleGroupSpanTag>
                            <GroupSpanText>
                              assign group task by comma separated emails
                            </GroupSpanText>
                          </RadioLabel>
                        </div>
                      </Col>
                      <Col sm={5}>
                        <div onChange={onChangeValue}>
                          <RadioLabel>
                            <input
                              type="radio"
                              className="groupcheck"
                              value="user_group"
                              checked={radioBtntype === "user_group"}
                              onClick={() => {
                                setShowUserGroup(true);
                                setFieldValue("assignedToEmail", "");
                                setExpandedTaskForm({
                                  ...expandedTaskForm,
                                  groupEmails: "",
                                  groupName: "",
                                });
                              }}
                            />

                            <SingleGroupSpanTag>
                              Group Task (email groups)
                            </SingleGroupSpanTag>
                            <GroupSpanText>
                              assign group task by selecting email group
                            </GroupSpanText>
                          </RadioLabel>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={5}>
                    <ExpandedLabel>
                      {singleGroupRadioBtn &&
                        !showAssignToMeField &&
                        !showUserGroup &&
                        "Group Task"}
                      {!singleGroupRadioBtn &&
                        !showAssignToMeField &&
                        !showUserGroup &&
                        "Assign to"}
                    </ExpandedLabel>
                    {showAssignToMeField && !showUserGroup && (
                      <ExpandedLabel>Assignee to me</ExpandedLabel>
                    )}
                    {showUserGroup && (
                      <ExpandedLabel>User group list</ExpandedLabel>
                    )}

                    {!showUserGroup ? (
                      <EmailFeild
                        component={EmailAutoComplete}
                        allTeamMembersList={teamMembers}
                        setValue={setValue}
                        value={value}
                        setFieldValue={setFieldValue}
                        getUserEmail={getUserEmail}
                        name={
                          singleGroupRadioBtn
                            ? "groupEmails"
                            : "assignedToEmail"
                        }
                      />
                    ) : (
                      <select
                        className="ExpandedTemplateDropDown"
                        value={expandedGroup}
                        onChange={(e) => {
                          setExpandedGroup(e.target.value);
                          setExpandedTaskForm({
                            ...expandedTaskForm,

                            assignedToEmail: "",
                            groupName: e.target.value,
                          });
                        }}
                      >
                        <option value={"0"}>none</option>
                        {groups &&
                          groups.length > 0 &&
                          groups.map((group: Group, index: number) => (
                            <option value={group.name}>{group.name}</option>
                          ))}
                      </select>
                    )}

                    {checkIfFeatureOpen(
                      userState?.user?.appFeatures,
                      GPT_3_OPENAI
                    ) && (
                      <>
                        {showJetBotLoader ? (
                          <JetBotLoader />
                        ) : (
                          <>
                            {values.detail &&
                              values.detail.length > 2 &&
                              !showJetBotPopup &&
                              forceStopJetBot && (
                                <JetBotPopUpInnerDiv>
                                  <ExpandedrossImgTag
                                    src={CrossJetBotSvg}
                                    alt="cross"
                                    onClick={() => {
                                      setForceStopJetBot(false);
                                    }}
                                  />
                                  <JetBotPopHeader>
                                    Hello, I am JetBot
                                  </JetBotPopHeader>
                                  <JetBotPopText>
                                    I can suggest a description based on your
                                    task summary. Interested, click me!
                                  </JetBotPopText>
                                  <span>
                                    <i className="tooltipDown"></i>
                                  </span>
                                </JetBotPopUpInnerDiv>
                              )}
                            <JetBotImgTagExpandedMode
                              className={USETIFULL_JETBOT}
                              src={
                                values.detail &&
                                values.detail.length > 2 &&
                                !showJetBotPopup &&
                                forceStopJetBot
                                  ? JetBotAnimation
                                  : JetBotIcon
                              }
                              alt="robo"
                              title="Ask Suggestion to Jet-Bot"
                              onClick={() => {
                                searchSuggestion(values.detail);
                              }}
                            />
                          </>
                        )}
                      </>
                    )}

                    <EmailError>{validationExpandedTaskForm}</EmailError>
                    {errors.assignedToEmail && touched.assignedToEmail && (
                      <EmailError>{errors.assignedToEmail}</EmailError>
                    )}
                    {errors.groupEmails && touched.groupEmails && (
                      <EmailError>{errors.groupEmails}</EmailError>
                    )}
                  </Col>
                </RowForRadioOrEmails>
                <CateAndPriorityRow>
                  <Col md={7}>
                    <Col>
                      <ExpandedLabel>CC Emails</ExpandedLabel>
                    </Col>

                    <Col>
                      <EmailFeild
                        name={"ccUserMail"}
                        placeholder="Enter Emails"
                        defaultValue={getUserEmail}
                        onChange={(e: any) => {
                          setFieldValue("ccUserMail", e.target.value);

                          ccEmailValidation(e.target.value);
                          setExpandedTaskForm({
                            ...expandedTaskForm,
                            ccUserMail: e.target.value,
                          });
                        }}
                      />
                      <EmailError>{ccEmailError}</EmailError>
                    </Col>
                  </Col>
                  <Col md={5}>
                    <Col>
                      <ExpandedLabel>Templates</ExpandedLabel>
                    </Col>
                    <Col>
                      <select
                        className="ExpandedTemplateDropDown"
                        value={expandedTemplate}
                        onChange={(e) => {
                          //setExpandedPriority(parseInt(e.target.value));
                          setExpandedTemplate(e.target.value);
                          setFieldValue("templateName", e.target.value);
                          setExpandedTaskForm({
                            ...expandedTaskForm,
                            templateName: e.target.value,
                          });
                        }}
                      >
                        <option value={"0"}>none</option>

                        {allTaskTemplates &&
                          allTaskTemplates.length > 0 &&
                          allTaskTemplates.map((temp) => (
                            <option value={temp.uniqueKey}>
                              ${temp.uniqueKey}
                            </option>
                          ))}
                      </select>
                    </Col>
                  </Col>
                </CateAndPriorityRow>

                <RecurringMainDiv>
                  <InnerDiv>
                    {isRecurringTask ? (
                      <RecurringToggle
                        src={ToggleOn}
                        className="img-responsive center-block"
                        onClick={() => {
                          setFieldValue("isRecurringTask", false);
                          setIsRecurringTask(!isRecurringTask);
                          setExpandedTaskForm({
                            ...expandedTaskForm,
                            isRecurring: false,
                          });
                        }}
                      />
                    ) : (
                      <RecurringToggle
                        src={ToggleOff}
                        className="img-responsive center-block"
                        onClick={() => {
                          setFieldValue("isRecurringTask", true);
                          setIsRecurringTask(!isRecurringTask);
                          setExpandedTaskForm({
                            ...expandedTaskForm,
                            isRecurring: true,
                          });

                          if (recurringInDay && recurringInDay.length > 0)
                            setIsRecurringOtherFieldsDisabled(false);
                          else setIsRecurringOtherFieldsDisabled(true);
                        }}
                      />
                    )}

                    <SpanRecurring>Recurring Task</SpanRecurring>

                    {!isRecurringTask ? (
                      <>
                        {/* this condition excute when toggle button off all fields are disabled mode */}
                        <RecurringParaDisabled>
                          This task has a recurring nature means it repeats
                          every # of days specified.
                        </RecurringParaDisabled>

                        <Row>
                          <Col>
                            <RecurringLabelDisabled>
                              Recurring In Days
                            </RecurringLabelDisabled>
                            <InputFieldDisabled type="text" disabled />
                          </Col>
                          <Col>
                            <RecurringLabelDisabled>
                              Recurring Until
                            </RecurringLabelDisabled>
                            <InputFieldDisabled type="text" disabled />
                          </Col>
                          <Col>
                            <RecurringLabelDisabled>Day</RecurringLabelDisabled>
                            <InputFieldDisabled
                              type="text"
                              disabled
                              placeholder="none"
                            />
                          </Col>
                          <Col>
                            <RecurringLabelDisabled>
                              Time
                            </RecurringLabelDisabled>
                            <InputFieldDisabled
                              type="text"
                              disabled
                              placeholder="none"
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <RecurringPara>
                          This task has a recurring nature means it repeats
                          every # of days specified.
                        </RecurringPara>

                        <Row>
                          <Col>
                            <RecurringLabel>Recurring In Days</RecurringLabel>
                            <InputField
                              name="recurringInDay"
                              type="number"
                              maxLength={2}
                              value={recurringInDay}
                              onChange={(e: any) => {
                                if (e.target.value && e.target.value.length > 0)
                                  setIsRecurringOtherFieldsDisabled(false);
                                else setIsRecurringOtherFieldsDisabled(true);
                                setFieldValue("recurringInDay", e.target.value);
                                setRecurringInDay(e.target.value);
                                setExpandedTaskForm({
                                  ...expandedTaskForm,
                                  recurringInDay: e.target.value,
                                });
                              }}
                            />
                          </Col>
                          <Col>
                            <RecurringLabel>Recurring Until</RecurringLabel>
                            {!isRecurringOtherFieldsDiabled ? (
                              <InputField
                                type="number"
                                name="recurringUntil"
                                // disabled={isRecurringOtherFieldsDiabled}
                                maxLength={3}
                                value={recurringUntil}
                                onChange={(e) => {
                                  setRecurringUntil(e.target.value);
                                  setFieldValue(
                                    "recurringUntil",
                                    e.target.value
                                  );
                                  setExpandedTaskForm({
                                    ...expandedTaskForm,
                                    recurringUntil: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <InputFieldRecurringUntil
                                type="number"
                                name="recurringUntil"
                                disabled
                              />
                            )}
                          </Col>
                          <Col>
                            <RecurringLabel>Day</RecurringLabel>

                            <select
                              name="recurringCustomDay"
                              className="ExpandedRecurringDayDropDown"
                              disabled={isRecurringOtherFieldsDiabled}
                              value={recurringCustomDay}
                              onChange={(e) => {
                                //setExpandedPriority(parseInt(e.target.value));
                                // setExpandedTemplate(e.target.value);
                                setRecurringCustomDay(e.target.value);
                                setFieldValue(
                                  "recurringCustomDay",
                                  e.target.value
                                );
                                setExpandedTaskForm({
                                  ...expandedTaskForm,
                                  recurringCustomDay: e.target.value,
                                });
                              }}
                            >
                              <option value={"0"}>none</option>
                              <option value={"sun"}>Sunday</option>
                              <option value={"mon"}>Monday</option>
                              <option value={"tue"}>Tuesday</option>
                              <option value={"wed"}>Wednesday</option>
                              <option value={"thu"}>Thursday</option>
                              <option value={"fri"}>Friday</option>
                              <option value={"sat"}>Saturday</option>
                            </select>
                          </Col>
                          <Col>
                            <RecurringLabel>Time</RecurringLabel>

                            <select
                              name="recurringCustomTime"
                              className="ExpandedRecurringDayDropDown"
                              disabled={isRecurringOtherFieldsDiabled}
                              value={recurringCustomTime}
                              onChange={(e) => {
                                //setExpandedPriority(parseInt(e.target.value));
                                // setExpandedTemplate(e.target.value);
                                setRecurringCustomTime(e.target.value);
                                setFieldValue(
                                  "recurringCustomTime",
                                  e.target.value
                                );
                                setExpandedTaskForm({
                                  ...expandedTaskForm,
                                  recurringCustomTime: e.target.value,
                                });
                              }}
                            >
                              <option value={"0"}>none</option>
                              <option value={"0000"}>00:00</option>
                              <option value={"0030"}>00:30</option>
                              <option value={"0100"}>01:00</option>
                              <option value={"0130"}>01:30</option>
                              <option value={"0200"}>02:00</option>
                              <option value={"0230"}>02:30</option>
                              <option value={"0300"}>03:00</option>
                              <option value={"0330"}>03:30</option>
                              <option value={"0400"}>04:00</option>
                              <option value={"0430"}>04:30</option>
                              <option value={"0500"}>05:00</option>
                              <option value={"0530"}>05:30</option>
                              <option value={"0600"}>06:00</option>
                              <option value={"0630"}>06:30</option>
                              <option value={"0700"}>07:00</option>
                              <option value={"0730"}>07:30</option>
                              <option value={"0800"}>08:00</option>
                              <option value={"0830"}>08:30</option>
                              <option value={"0900"}>09:00</option>
                              <option value={"0930"}>09:30</option>
                              <option value={"1000"}>10:00</option>
                              <option value={"1030"}>10:30</option>
                              <option value={"1100"}>11:00</option>
                              <option value={"1130"}>11:30</option>
                              <option value={"1200"}>12:00</option>
                              <option value={"1230"}>12:30</option>
                              <option value={"1300"}>13:00</option>
                              <option value={"1330"}>13:30</option>
                              <option value={"1400"}>14:00</option>
                              <option value={"1430"}>14:30</option>
                              <option value={"1500"}>15:00</option>
                              <option value={"1530"}>15:30</option>
                              <option value={"1600"}>16:00</option>
                              <option value={"1630"}>16:30</option>
                              <option value={"1700"}>17:00</option>
                              <option value={"1730"}>17:30</option>
                              <option value={"1800"}>18:00</option>
                              <option value={"1830"}>18:30</option>
                              <option value={"1900"}>19:00</option>
                              <option value={"1930"}>19:30</option>
                              <option value={"2000"}>20:00</option>
                              <option value={"2030"}>20:30</option>
                              <option value={"2100"}>21:00</option>
                              <option value={"2130"}>21:30</option>
                              <option value={"2200"}>22:00</option>
                              <option value={"2230"}>22:30</option>
                              <option value={"2300"}>23:00</option>
                              <option value={"2130"}>23:30</option>
                            </select>
                          </Col>
                        </Row>
                        <EmailError>{recurringError}</EmailError>
                      </>
                    )}
                  </InnerDiv>
                </RecurringMainDiv>

                <Row>
                  <Col sm={7}>
                    <CateAndPriorityRow>
                      {/* <Col md={7}> */}
                      <ExpandedLabel>Categories</ExpandedLabel>

                      <CategoryDiv>
                        <CatgoryInputTag
                          type="text"
                          name="cats"
                          value={newCategoryName}
                          // onChange={(e: any) => {
                          //   setNewCategoryName(e.target.value);
                          // }}
                          onChange={(e: any) => {
                            if (
                              !e.target.value ||
                              e.target.value === "" ||
                              regRestrictSpecialCharacters.test(e.target.value)
                            ) {
                              setNewCategoryName(e.target.value);
                              setCurrentFormData(e.target.value);
                            }
                          }}
                          placeholder="Add Category"
                        />
                        <AddCategoryImage
                          src={AddCategorySvg}
                          alt="Add Category Image"
                          onClick={() => {
                            if (newCategoryName && newCategoryName.length > 0) {
                              const catIndex = categories
                                .map((cat) => cat)
                                .indexOf(newCategoryName.trim());

                              if (catIndex < 0) {
                                setCategories([...categories, newCategoryName]);
                                setExpandedTaskForm({
                                  ...expandedTaskForm,
                                  cats: [...categories, newCategoryName],
                                });
                                setNewCategoryName("");
                              } else {
                                NotificationManager.warning(
                                  "Category already added!"
                                );
                              }
                            }
                          }}
                        />
                      </CategoryDiv>
                      {/* </Col> */}

                      <Col>
                        <ColPriorityCategoryTagForMobile>
                          {categories &&
                            categories.length > 0 &&
                            categories.map((c, index) => (
                              <CategoryEditTag>
                                <CategoryPTag
                                  data-tip
                                  data-for="registerTip"
                                  onMouseOver={() => {
                                    handleCategoryHover(index + 1);
                                  }}
                                >
                                  {c}
                                </CategoryPTag>

                                {showCategory &&
                                  categoryHoverID === index + 1 && (
                                    <ReactTooltip
                                      id="registerTip"
                                      place="top"
                                      effect="solid"
                                    >
                                      {c}
                                    </ReactTooltip>
                                  )}

                                <CategoryCrossIcon
                                  src={DeleteCrossSvg}
                                  alt="icon"
                                  onClick={() => {
                                    categories.splice(index, 1);

                                    setCategories([...categories]);
                                    setExpandedTaskForm({
                                      ...expandedTaskForm,
                                      cats: [...categories],
                                    });
                                  }}
                                />
                              </CategoryEditTag>
                            ))}
                        </ColPriorityCategoryTagForMobile>
                      </Col>
                      <Row style={{ display: "flex", paddingTop: "10px" }}>
                        <Col>
                          <Col>
                            <ExpandedLabel>Priority</ExpandedLabel>
                          </Col>
                          <select
                            className="ExpandedpriorityDropDown"
                            value={expandedPriority}
                            onChange={(e) => {
                              setExpandedPriority(parseInt(e.target.value));
                              setExpandedTaskForm({
                                ...expandedTaskForm,
                                priority: e.target.value,
                              });
                            }}
                          >
                            <option value={0}>none</option>
                            <option value={1}>p:1</option>
                            <option value={2}>p:2</option>
                            <option value={3}>p:3</option>
                            <option value={4}>p:4</option>
                            <option value={5}>p:5</option>
                          </select>
                        </Col>

                        <Col style={{ position: "relative", padding: "0" }}>
                          <Col>
                            <ExpandedLabel>Due Date</ExpandedLabel>
                          </Col>

                          <DatePicker
                            className="Expandeddatepicker"
                            name="date"
                            showTimeSelect
                            selected={localDueDate}
                            dateFormat="Pp"
                            onChange={(date: any) => {
                              setLocalDueDate(date);
                              setFieldValue("duedate", date);
                              setExpandedTaskForm({
                                ...expandedTaskForm,
                                duedate: date,
                              });
                            }}
                            open={showDatePicker}
                            onInputClick={() => {
                              setShowDatePicker(true);
                            }}
                            onClickOutside={() => {
                              setShowDatePicker(false);
                            }}
                            filterTime={filterPassedTime}
                            minDate={moment().add(0, "day").toDate()}
                            // minTime={moment().add(0, "day").toDate()}
                          />
                          <CalenderImgTagExpandedMode
                            src={CalenderSvg}
                            onClick={() => {
                              setShowDatePicker(true);
                            }}
                          />
                        </Col>
                      </Row>
                    </CateAndPriorityRow>

                    <DescriptionRow>
                      {loading && (
                        <Loader text={"Uploading description image..."} />
                      )}
                      <ExpandedLabel>Description</ExpandedLabel>
                      {isDescription ? (
                        <ReactQuill
                          ref={(el) => {
                            setQuillObj(el);
                          }}
                          className="QuillDescriptionExpandedMode"
                          placeholder="Description (Optional)"
                          onChange={handleChanges}
                          modules={modules}
                          formats={quillFormats}
                          value={isDescription}
                          theme="snow"
                          style={{ flex: "auto", padding: "0" }}
                        />
                      ) : (
                        <ReactQuill
                          ref={(el) => {
                            setQuillObj(el);
                          }}
                          className="QuillDescriptionExpandedMode"
                          placeholder="Description (Optional)"
                          onChange={handleChanges}
                          modules={modules}
                          formats={quillFormats}
                          value={ExpandedDescription}
                          theme="snow"
                          style={{ flex: "auto", padding: "0" }}
                        />
                      )}
                    </DescriptionRow>
                  </Col>
                  <PrioDescriptionColumn sm={5}>
                    <ExpandedLabel>Progress Label</ExpandedLabel>

                    <Col style={{ display: "flex" }}>
                      <DivForLabelSpan>
                        <SPan>20%</SPan>
                      </DivForLabelSpan>
                      <FieldForProgressLabel type="text" name="progress20" />
                    </Col>

                    <Col>
                      <DivForLabelSpan>
                        <SPan>40%</SPan>
                      </DivForLabelSpan>
                      <FieldForProgressLabel type="text" name="progress40" />
                    </Col>
                    <Col>
                      <DivForLabelSpan>
                        <SPan>60%</SPan>
                      </DivForLabelSpan>
                      <FieldForProgressLabel type="text" name="progress60" />
                    </Col>
                    <Col>
                      <DivForLabelSpan>
                        <SPan>80%</SPan>
                      </DivForLabelSpan>

                      <FieldForProgressLabel type="text" name="progress80" />
                    </Col>
                    <Col>
                      <DivForLabelSpan>
                        <SPan>100%</SPan>
                      </DivForLabelSpan>

                      <FieldForProgressLabel type="text" name="progress100" />
                    </Col>

                    {/* </Row> */}
                  </PrioDescriptionColumn>
                </Row>

                <Row>
                  <Col>
                    <AssignExpandedBtn type="submit">Assign</AssignExpandedBtn>
                  </Col>
                  <Col>
                    <AssigneeToMeSpan
                      onClick={() => {
                        setEmailUser(userState.user?.email);
                        setShowAssignToMeField(true);
                        setRadioBtnType("single_task");
                        setFieldValue("groupEmails", [userState.user?.email]);
                        setFieldValue("assignedToEmail", userState.user?.email);
                        setShowUserGroup(false);
                        userState.user?.email &&
                          setValue(userState.user?.email);
                        setAllGroupEmails(userState.user?.email);
                        setValidationExpandedTaskForm(undefined);
                        setSingleGroupRadioBtn(false);
                        expandedTaskFormValidation(userState.user?.email);
                      }}
                    >
                      {" "}
                      Assign to me{" "}
                    </AssigneeToMeSpan>
                  </Col>
                  <Col md={5}></Col>
                </Row>
              </MainContainer>
            </Form>
          )}
        </Formik>
        {showJetBotPopup && (
          <JetBotSuggestionPopup
            gptSuggestionsText={gptSuggestionsText}
            setShowJetBotPopup={setShowJetBotPopup}
            keepInDescription={keepInDescription}
            searchText={searchText}
            setGptSuggestionsText={setGptSuggestionsText}
          />
        )}
      </div>
    </>
  );
};

export default ExpandedTaskForm;
