import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import { UserProfileContext } from "../../context/user-profile-context";
import AddIcon from "../../images/add_icon.svg";
import AddHoverIcon from "../../images/add_icon_hover.svg";
import DeleteEmailHover from "../../images/delete-email-hover.svg";
import DeleteEmailNormal from "../../images/delete-email-normal.svg";
import SearchIconSvg from "../../images/searchicon.svg";
import myAxios from "../../utils/myaxios";
import { isValidEmail } from "../../utils/utils";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const EmailListContainer = styled(Col)`
  position: relative;
  min-width: 50%;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  /* height: 30%; */
  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 100%; */
    margin: 12px;
    /* height: 333px; */
    width: 94%;
  }
`;
const EmailUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
  /* height: 251px;
  overflow-y: scroll; */
`;
const Label = styled.li`
  color: #797979;
  font-size: 14px;
  font-weight: 400;
`;

const EmailContainer = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e6e6e6;

  @media (max-width: 1199px) and (min-width: 320px) {
    /* justify-content: center;
    flex-direction: column; */
  }
  padding-bottom: 10px;
  padding-top: 10px;
`;

const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #2f4c95;
`;
const DeleteImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
const AddAppFeatureContainer = styled.div``;
const Div = styled.div`
  margin-top: 10px;
`;
const InputBox = styled.input`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const ErrorSpan = styled.span`
  color: red;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ButtonLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #08ca1b;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: absolute;
  right: 3%;
  top: 40%;
`;
const LineHori = styled.div`
  width: 100%;
  margin-top: 10px;
  border-bottom: 0.5px solid #797979;
`;
const SearchBoxInput = styled.input`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;
const SearchIconTag = styled.img`
  position: absolute;
  right: 2%;
  top: 42%;
`;
const AddIconTag = styled.img`
  position: absolute;
  width: 30px;
  right: 1%;
  top: 37%;
`;
const EmailListView = () => {
  const { userState } = useContext(UserContext);
  const { emails, setEmails } = useContext(UserProfileContext);

  const { selectedTeam, teamMembers, setTeamMembers } = useContext(TeamContext);
  // console.log("allTeamMembersList:-", allTeamMembersList);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAddLoading, setShowAddLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>("");
  const [deletedEmail, setDeletedEmail] = useState<string>("");
  const [filteredEmails, setFilteredEmails] = useState<any[]>([]);
  const [addImageIcon, setAddImageIcon] = useState<any>(AddIcon);

  // console.log("filteredEmails", filteredEmails);

  useEffect(() => {
    myAxios
      .get(`teams`)
      .then((response) => {
        response.data.data.forEach((associate: any) => {
          // getting all data
          if (associate.team.owner._id === response.data.data[0].user._id) {
            myAxios
              .get(`teams/get-team-members/${associate.team._id}`)
              .then((response) => {
                if (
                  response.data.success &&
                  response.data.data &&
                  response.data.data.length > 0
                ) {
                  setTeamMembers(response.data.data);
                }
              })
              .catch((error) => {
                console.error(error, "error in getting team members");
              });
          }
        });
      })
      .catch((error) => {
        console.error(error, "error in getting teams");
      });
  }, []);

  const loadEmailList = () => {
    setLoading(true);
    setTimeout(() => {
      // myAxios
      //   .get(`emails`)
      //   .then((response) => {

      //     if (response.data && response.data.success) {
      //       setEmails(response.data.data);
      //     }
      //     setLoading(false);
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     console.error(error, "error in getting custom email");
      //   });

      myAxios
        .get(`teams`)
        .then((response) => {
          response.data.data.forEach((associate: any) => {
            // getting all data
            if (associate.team.owner._id === response.data.data[0].user._id) {
              myAxios
                .get(`teams/get-team-members/${associate.team._id}`)
                .then((response) => {
                  if (
                    response.data.success &&
                    response.data.data &&
                    response.data.data.length > 0
                  ) {
                    setFilteredEmails(response.data.data);
                    setTeamMembers(response.data.data);
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  console.error(error, "error in getting team members");
                });
            }
          });
        })
        .catch((error) => {
          console.error(error, "error in getting teams");
        });
    }, 100);
  };
  useEffect(() => {
    if (userState && userState.user) {
      loadEmailList();
    }
    // eslint-disable-next-line
  }, [userState]);

  // const deleteEmail = (email: string) => {
  //   console.log("deleted emails is ", email);
  //   setLoading(true);
  //   setTimeout(() => {
  //     myAxios
  //       .delete(`emails/${email}`)
  //       .then((response) => {
  //         if (response.data && response.data.success) {
  //           loadEmailList();
  //         }
  //         setLoading(false);
  //         NotificationManager.success("Email deleted successfully");
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         NotificationManager.error(parseErrorResponse(error));
  //         console.error(error, "error in getting deleting email");
  //       });
  //   }, 100);
  // };

  const checkIfEmailAlreadyExist = () => {
    if (email) {
      const found = emails.some((el) => el.email === email);
      if (found) {
        setEmailError("email already added");
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  const addEmailClick = () => {
    if (email === "" || email.trim() === "") {
      setEmailError("Please enter email");
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter valid email");
      return;
    }

    if (checkIfEmailAlreadyExist()) return;

    setShowAddLoading(true);

    myAxios
      .post(`teams/invite-user`, {
        email: email,
        teamId: selectedTeam?.id,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "New user added successfully!...",
            "",
            1500
          );
          setFilteredEmails(response.data.data);
          setTeamMembers(response.data.data);
          setEmail("");
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to add...",
            "",
            5000
          );
        }
        setShowAddLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  useEffect(() => {
    if (email && email.length > 0) {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    setFilteredEmails(
      teamMembers
        ? [
            ...teamMembers.filter((email: any) => {
              return email.email
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }),
          ]
        : []
    );
  }, [searchText]);

  // const DeleteEmailComponent = ({ email }: any) => {
  //   const [selectedImage, setSelectedImage] = useState<any>(DeleteEmailNormal);
  //   return (
  //     <>
  //       <DeleteImage
  //         src={selectedImage}
  //         onClick={() => {
  //           setDeletedEmail(email.email);
  //           setShowDeleteConfirmation(true);
  //         }}
  //         onMouseEnter={() => {
  //           setSelectedImage(DeleteEmailHover);
  //         }}
  //         onMouseLeave={() => {
  //           setSelectedImage(DeleteEmailNormal);
  //         }}
  //       />
  //     </>
  //   );
  // };

  return (
    <>
      <EmailListContainer sm={4}>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}

        <HeadingText>List of emails</HeadingText>

        <AddAppFeatureContainer>
          <Div>
            <div style={{ position: "relative" }}>
              <InputBox
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Add new email"
              />
              {showAddLoading ? (
                <ButtonLoader />
              ) : (
                <AddIconTag
                  onMouseEnter={() => {
                    setAddImageIcon(AddHoverIcon);
                  }}
                  onMouseLeave={() => {
                    setAddImageIcon(AddIcon);
                  }}
                  src={addImageIcon}
                  onClick={addEmailClick}
                />
              )}
            </div>
          </Div>
          <ErrorSpan> {emailError && emailError}</ErrorSpan>
        </AddAppFeatureContainer>
        <AddAppFeatureContainer>
          <Div>
            <LineHori></LineHori>
            <div style={{ position: "relative" }}>
              <SearchBoxInput
                type="text"
                placeholder="Search email"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <SearchIconTag src={SearchIconSvg} />
            </div>
          </Div>
        </AddAppFeatureContainer>
        <EmailUl>
          {filteredEmails &&
            filteredEmails.length > 0 &&
            filteredEmails.map((teamMember: any) => {
              return (
                <EmailContainer>
                  <div>
                    {teamMember.firstName &&
                    teamMember.firstName &&
                    teamMember.email ? (
                      <Label>
                        {teamMember.firstName} {teamMember.lastName}
                      </Label>
                    ) : (
                      <Label>{teamMember.email}</Label>
                    )}
                  </div>
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteEmailComponent email={email} />
                  </div> */}
                </EmailContainer>
              );
            })}
        </EmailUl>
        {/* {showDeleteConfirmation && (
          <ConfirmationBox
            setShow={setShowDeleteConfirmation}
            name="Confirmation"
            message="Are you sure, you want to delete this email?"
            okButtonText="Delete"
            okButtonClick={() => {
              setShowDeleteConfirmation(false);
              deleteEmail(deletedEmail);
            }}
          />
        )} */}
      </EmailListContainer>
    </>
  );
};

export default EmailListView;
