import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { UserContext } from "../../context/user-context";
import CommentGreyIcon from "../../images/commentGrey.svg";
import CommentRedIcon from "../../images/commentRedIcon.svg";
import Eye from "../../images/eye.svg";
import HandOffImg from "../../images/handoff.svg";
import MoreOptionOrange from "../../images/more-vertical-orange.svg";
import ThreeDot from "../../images/more-vertical.svg";
import NudgeIconForView from "../../images/nudgeIcon.svg";
import NudgeRedIcon from "../../images/nudgeIconRed.svg";
import RecurringIcon from "../../images/recurring.svg";
import ShareIcon from "../../images/shareicon.svg";
import SubTaskIndicatorSvg from "../../images/subtask-indicator.svg";
import ToggleOffImg from "../../images/toggleoff.svg";
import ToggleOnImg from "../../images/toggleon.svg";

interface SidebarWidth {
  isOpen?: boolean;
  showModel?: boolean;
}
const TaskContainer = styled(Container)`
  width: 953px;
  background: #f6f9ff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  align-items: center;
  /* margin-left: auto;
margin-right: auto; */
  margin-bottom: 5px;

  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "23%" : "auto")};

  position: ${(props: SidebarWidth) => (props.showModel ? "fixed" : "intial")};

  &:hover {
    border: 0px solid #c1c9df;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.24);
    /* background-size: 100% 2px; */
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 97%;

    margin-left: auto;
    /* height: 100px; */
    min-height: 100px;
  }
`;

const ParentRow = styled(Row)`
  padding: 8px;
  position: relative;

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0px;
  }
`;

const FirstRow = styled(Row)`
  padding-bottom: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
`;

const TaskDetail = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
    margin-top: 7px;
    min-height: 18px;
  }
`;

const Detail = styled.p`
  color: #2f4c95;
  font-size: 16px;
  line-height: 33px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding-top: 6px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0;
  }
`;
const SpanText = styled.span``;
const Watcher = styled(Col).attrs({
  md: 1,
})``;

const IMGTag = styled.img`
  display: ${(props) => (props.className === "Blue" ? "block" : "none")};
  width: 25px;
  height: 24px;
`;

const Dots = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    align-self: right;
    display: flex;
    justify-content: flex-end;

    & img {
      /* width: 19px; */
    }
  }
`;

const ColCatgory = styled(Col)`
  color: #fc6601;
  font-size: 14px;
  line-height: 16.5px;
  font-weight: 600;
  margin-top: 3px;
  /* flex-basis: 43%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid; */

  @media (max-width: 1199px) and (min-width: 320px) {
    color: #fc6601;
    font-size: 11px;
    line-height: 16.5px;
    font-weight: 500;
    /* flex-basis: 10%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-basis: 36%;
    flex: 1;
  }
`;

const SecondRow = styled(Row)`
  align-items: center;
  width: 100%;
`;
const ThirdRow = styled(Row)`
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1199px) and (min-width: 320px) {
    justify-content: space-around;
  }
`;

const TaskText = styled(Col)`
  color: #2f4c95;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  flex-shrink: 0;
  /* flex-grow: 0; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 8px;
    line-height: 14px;
    margin-top: 3px;
  }
`;

const UserEmail = styled(TaskText)`
  flex-basis: 30%;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 9px;
    line-height: 14px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const GroupDiv = styled.div`
  flex-basis: 30%;

  color: #2f4c95;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 9px;
    line-height: 14px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const TimeLeft = styled(TaskText)`
  flex-basis: 14%;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-basis: 25%;

    font-size: 9px;
    line-height: 14px;
    /* margin-top: 3px; */
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const ProgressContainer = styled(Col)`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;

  padding: 8px 0;
  @media (max-width: 1199px) and (min-width: 320px) {
    justify-content: flex-end;
  }
`;

const ColColorBlue = styled.div`
  background: #2f4c95;
  width: 20px;
  height: 20px;
  border: 2px solid #2f4c95;
  border-radius: 3px;
  margin: 5px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 12px;
    height: 12px;
  }
`;

const ColColor = styled.div`
  background: #ffe6cc;
  width: 20px;
  height: 20px;
  border: 2px solid #ffe6cc;
  border-radius: 3px;
  margin: 5px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 12px;
    height: 12px;
  }
`;
const DivPriority = styled.div`
  height: 23px;
  width: 50px;
  background: ${(props) => (props.color ? props.color : "none")};
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 35px;
    height: 18px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 6px;
  }
`;

const EyeDiv = styled(Col)`
  width: auto;
  /* padding-right: 10px; */

  & img {
    width: 24px;
  }
`;
const DivForComment = styled.div`
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    position: relative;
  }
`;

const ToggleBtnOn = styled.img`
  height: 25px;
  transition-property: width;
  transition-duration: 2s;
  transition-delay: 2s;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 39px;
    justify-content: flex-end;
  }
`;

const HandOffBtn = styled.img`
  width: 55px;
  height: 25px;
  background: #2f4c95;
  border-radius: 50px;
  background: #2f4c95;
  border-radius: 50px;
  /* margin-left: 8px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 39px;
    height: auto;
    margin-left: 4px;
  }
`;

const CommentRedTag = styled.img`
  width: auto;
  height: 20px;
  padding-right: 8px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 42px;
  }
`;

const RecurringImgTag = styled.img`
  width: 50px;
  height: 22px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 22px;
  }
`;

const CommentSpanCount = styled.span`
  color: #f6f9ff;
  position: absolute;
  left: 41%;
  bottom: 50%;
  border-radius: 62%;
  border: 2px solid #f6f9ff;
  width: 18px;
  height: 18px;
  background: #fc6601;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    top: -6px;
    height: 18px;
    width: 18px;
    font-size: 10px;
    left: 12px;
  }
`;

const CommentGreyTag = styled.img`
  width: auto;
  height: 20px;
  padding-right: 8px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 42px;
  }
`;

const NudgeNotificationImg = styled.img`
  height: 22px;
  width: 46px;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 49px;
  }
`;

const DivForNudegeNotificationIcon = styled(Col)`
  position: relative;
  width: 46px;
`;
const DivCountNudgeReceived = styled.span`
  position: absolute;
  padding: 2px 1px;
  font-size: 9px;
  text-align: center;
  height: 18px;
  width: 18px;
  font-weight: 600;
  border: 2px solid #f6f9ff;
  background: #fc6601;
  border-radius: 50%;
  display: inline-block;
  color: white;
  right: 30.7%;
  z-index: 111;
  bottom: 83.2%;
  top: -7px;
  left: 25px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    width: 17px;
    height: 17px;
    /* top: -5px; */
    left: 27px;
    padding: 0;
  }
`;

const ShareIconimgTag = styled.img`
  width: 44px;
  height: 22px;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 18px;
  }
`;

const ShareIconMainDiv = styled(Col)`
  position: relative;
  width: 54px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 26px;
  }
`;
const SubTaskIndicatorImgTag = styled.img`
  padding: 0 10px;
`;

const DivForIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
  position: absolute;
  right: 12px;
  align-items: center;
  padding: 0;
  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;

const MiddleRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const ColHandOff = styled(Col)`
  width: auto;
`;
const ColToggleBtn = styled(Col)`
  width: auto;
`;

const ColForMobileViewPriority = styled(Col)`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex: 0;
`;

const RowMobileProgressBar = styled(Row)`
  background: #c1c7d73b;
  box-shadow: 0px 10px 10px rgba(218, 225, 241, 0.5);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  display: flex;
  margin: 4px 0px 0px;
`;
const SpanUpdatePro = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  color: #2f4c95;
  padding: 11px 0px;
  margin: 0px -13px;
`;

const ProgressCountSpanMobile = styled.span`
  width: 21px;
  height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #2f4c95;
`;

const DisabledTaskListItem = ({ isOpen, task }: any) => {
  const { userState } = useContext(UserContext);
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const opacity = 0.5;
  return (
    <TaskContainer style={{ opacity: opacity }} key={task._id} isOpen={isOpen}>
      <ParentRow>
        <Col>
          <FirstRow>
            {isDesktopScreen ? (
              <Col sm={1}>
                {task.priority === 5 ? (
                  <DivPriority color="#11b753">
                    <span>{`P${task.priority}`}</span>
                  </DivPriority>
                ) : null}
                {task.priority === 4 ? (
                  <DivPriority color="#eccc22">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 3 ? (
                  <DivPriority color="#dd8426">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 2 ? (
                  <DivPriority color="#ff4004">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 1 ? (
                  <DivPriority color="#a80000">
                    {`P${task.priority}`}
                  </DivPriority>
                ) : null}
              </Col>
            ) : null}

            <ColToggleBtn sm={1}>
              <ToggleBtnOn
                src={task.notificationOn ? ToggleOnImg : ToggleOffImg}
                alt="on"
                title="email notification"
              />
            </ColToggleBtn>
            {task.assignedFrom?._id === userState.user?.id ? (
              <ColHandOff sm={1}>
                {task.assignedFrom?._id === userState.user?.id && (
                  <HandOffBtn src={HandOffImg} title="handoff new users" />
                )}
              </ColHandOff>
            ) : (
              <div style={{ width: "70px" }}> </div>
            )}

            {isDesktopScreen ? (
              <ColCatgory sm={5}>
                {task.categories && task.categories.length
                  ? task.categories
                      .map((c: any) => {
                        return c.name;
                      })
                      .join(" | ")
                  : ""}
              </ColCatgory>
            ) : null}

            <DivForIcons>
              {task.isTemplateTask && (
                <SubTaskIndicatorImgTag
                  src={SubTaskIndicatorSvg}
                  alt="subtask-indicator"
                />
              )}

              {task.cced ? (
                <EyeDiv title="You are a watching this task">
                  <img src={Eye} alt="eye" />
                </EyeDiv>
              ) : (
                <Watcher></Watcher>
              )}

              {task && task!.recurringTaskData && (
                <RecurringImgTag src={RecurringIcon} alt="RecurringIcon" />
              )}

              {task && task.taskNudges && task.taskNudges.length > 0 ? (
                <DivForNudegeNotificationIcon sm={1}>
                  <DivCountNudgeReceived>
                    {task.taskNudges.length}
                  </DivCountNudgeReceived>
                  <NudgeNotificationImg src={NudgeRedIcon} alt="img" />
                </DivForNudegeNotificationIcon>
              ) : (
                // <DivForNudegeNotificationIcon sm={1}>
                <NudgeNotificationImg src={NudgeIconForView} alt="img" />
                // </DivForNudegeNotificationIcon>
              )}

              <ShareIconMainDiv sm={1}>
                <ShareIconimgTag src={ShareIcon} alt="shareImage" />
              </ShareIconMainDiv>

              {task && task.comments && task.comments.length > 0 ? (
                <DivForComment>
                  <CommentSpanCount>{task.comments.length}</CommentSpanCount>
                  <CommentRedTag src={CommentRedIcon} alt="img" />
                </DivForComment>
              ) : (
                <CommentGreyTag src={CommentGreyIcon} alt="img" />
              )}

              <Dots sm={1}>
                <IMGTag src={ThreeDot} alt="threedot" className="Blue" />
                <IMGTag
                  src={MoreOptionOrange}
                  alt="threedot"
                  className="Orange"
                />
              </Dots>
            </DivForIcons>
          </FirstRow>

          {/* this row visible only in mobile--*/}
          <MiddleRow>
            {task.priority && !isDesktopScreen ? (
              <ColForMobileViewPriority sm={1}>
                {task.priority === 5 ? (
                  <DivPriority color="#11b753">
                    <span>{`P${task.priority}`}</span>
                  </DivPriority>
                ) : null}
                {task.priority === 4 ? (
                  <DivPriority color="#eccc22">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 3 ? (
                  <DivPriority color="#dd8426">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 2 ? (
                  <DivPriority color="#ff4004">{`P${task.priority}`}</DivPriority>
                ) : null}
                {task.priority === 1 ? (
                  <DivPriority color="#a80000">
                    {`P${task.priority}`}
                  </DivPriority>
                ) : null}
              </ColForMobileViewPriority>
            ) : null}

            {!isDesktopScreen && task.categories && task.categories.length ? (
              <ColCatgory sm={5}>
                {task.categories && task.categories.length
                  ? task.categories
                      .map((c: any) => {
                        return c.name;
                      })
                      .join(" | ")
                  : ""}
              </ColCatgory>
            ) : null}
          </MiddleRow>
          {/* ******** */}

          <SecondRow>
            <TaskDetail>
              <Detail>
                <SpanText>{task.detail}</SpanText>
              </Detail>
            </TaskDetail>
          </SecondRow>

          <ThirdRow>
            {task.assignedFrom?.fullName ? (
              <UserEmail>{task.assignedFrom?.fullName}</UserEmail>
            ) : (
              <UserEmail>{task.assignedFrom?.email}</UserEmail>
            )}

            {task.assignmentType === "to_and_group" ? (
              <UserEmail>
                {task.assignedTo?.fullName
                  ? task.assignedTo?.fullName
                  : task.assignedTo?.email}
                , {`<<Group>>`}
              </UserEmail>
            ) : task.assignmentType === "group" ? (
              <GroupDiv>
                <span>{`<<Group>>`}</span>
              </GroupDiv>
            ) : task.assignedTo?.fullName ? (
              <UserEmail>{task.assignedTo?.fullName}</UserEmail>
            ) : (
              <UserEmail>{task.assignedTo?.email}</UserEmail>
            )}

            <TimeLeft>{task.timeRemaining}</TimeLeft>

            {isDesktopScreen ? (
              <ProgressContainer>
                {task.progress >= 20 ? <ColColorBlue /> : <ColColor />}

                {task.progress >= 40 ? <ColColorBlue /> : <ColColor />}
                {task.progress >= 60 ? <ColColorBlue /> : <ColColor />}
                {task.progress >= 80 ? <ColColorBlue /> : <ColColor />}
                {task.progress >= 100 ? <ColColorBlue /> : <ColColor />}
              </ProgressContainer>
            ) : (
              //-------------this row visible in mobile only  ----------------
              <RowMobileProgressBar>
                <Col>
                  <SpanUpdatePro>update your progress</SpanUpdatePro>
                </Col>

                <Col>
                  <ProgressContainer>
                    {task.progress >= 20 ? <ColColorBlue /> : <ColColor />}

                    {task.progress >= 40 ? <ColColorBlue /> : <ColColor />}
                    {task.progress >= 60 ? <ColColorBlue /> : <ColColor />}
                    {task.progress >= 80 ? <ColColorBlue /> : <ColColor />}
                    {task.progress >= 100 ? <ColColorBlue /> : <ColColor />}
                    <ProgressCountSpanMobile>
                      {task.progress}%
                    </ProgressCountSpanMobile>
                  </ProgressContainer>
                </Col>
              </RowMobileProgressBar>
            )}
          </ThirdRow>
        </Col>
      </ParentRow>
    </TaskContainer>
  );
};
export default DisabledTaskListItem;
