import { Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TeamContext } from "../../context/team-context";
import BackArrow from "../../images/backarrow.svg";
import DeleteEmailHover from "../../images/delete-email-hover.svg";
import DeleteEmailNormal from "../../images/delete-email-normal.svg";
import JetListLogo from "../../images/logo.svg";
import { TeamMember } from "../../types";
import myAxios from "../../utils/myaxios";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";

interface SidebarWidth {
  isOpen?: boolean;
  isHeight?: boolean;
}

const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;

const Container = styled.div`
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "253px" : "60px")};
  position: absolute;
  top: 12%;
  left: 5%;
  @media (max-width: 1199px) and (min-width: 320px) {
    top: 0;
    left: 0;
    right: 0;
    margin-left: 0;
  }
`;

const Wrapper = styled.div`
  width: 600px;
  height: 75vh;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    border: none;
    border-radius: 0;
    width: 100%;
  }
`;
const ROW = styled(Row)`
  border: 1px solid rgb(224, 224, 224);
  width: 100%;
  margin: 0;
  height: 12%;
  border-radius: 10px 10px 0 0;
  @media (max-width: 1199px) and (min-width: 320px) {
    border-radius: 0;
    border: 1px solid rgb(224, 224, 224);
  }
`;
const COL = styled(Col)`
  display: flex;
`;
const Span = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #2f4c95;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const RowSecond = styled(Row)`
  margin: 0;
  margin-top: 20px;
`;
const DivForName = styled.div`
  /* height: 346px; */
  height: ${(props: SidebarWidth) =>
    props.isHeight === false ? "423px" : "346px"};
  overflow-y: scroll;
`;

const ColorRound = styled.div`
  /* position:absolute; */
  width: 34px;
  height: 34px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 27px;
    height: 27px;
    font-size: 12px;
    margin-top: 2px;
  }
`;
const SpanName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #797979;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    padding-left: 4px;
    line-height: 19px;
  }
`;
const SpanType = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #797979;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;
const DivBottom = styled(Row)`
  margin: 0;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  @media (max-width: 1199px) and (min-width: 320px) {
    align-items: center;
  }
`;
const InputTag = styled(Field)`
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 10px;
`;
const InviteBtn = styled.button`
  width: 130px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const EmailError = styled.div`
  color: red;
  font-size: 12px;
  padding: 3px 0px;
  /* @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  } */
`;
const Header = styled.div`
  height: 50px;
  background: #ffe6cc;
`;

const LogoTag = styled.img`
  width: 75px;
  margin-left: 24px;
`;
const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
`;

const ColBtnDiv = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
`;

const DeleteImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
const ColumnFirst = styled(Col)`
  display: flex;
  padding: 8px;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 8px;
    display: inline;
  }
`;
const ColumnThird = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 8px;
    display: flex;
    justify-content: start;
  }
`;
const ColumnFourth = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    /* padding: 8px; */
    display: flex;
    justify-content: start;
    padding-left: 21px;
  }
`;

const TeamMembersList = ({ isOpen, setIsOpen }: any) => {
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  let navigate = useNavigate();
  const { teamMembers, selectedTeam, setTeamMembers } = useContext(TeamContext);
  console.log("teamMembers", teamMembers);
  console.log("selectedTeam", selectedTeam);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const getRandomColorByIndex = (index: any) => {
    var colors = [
      "#F44336",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#CDDC39",
      "#FFEB3B",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B",
    ];

    /* // Make sure the index is within the bounds of the colors array */
    if (index < 0 || index >= colors.length) {
      index = Math.floor(Math.random() * colors.length);
    }

    return colors[index];
  };

  const [deletedTeamId, setDeletedTeamId] = useState<string>("");
  const [deletedUserId, setDeletedUserId] = useState<string>("");

  const DeleteEmailComponent = ({ userId, teamId }: any) => {
    const [selectedImage, setSelectedImage] = useState<any>(DeleteEmailNormal);
    return (
      <>
        <DeleteImage
          src={selectedImage}
          onClick={() => {
            // setDeletedUserEmail(email);

            console.log("userId --- ", userId);
            console.log("teamId --- ", teamId);
            setDeletedTeamId(teamId);
            setDeletedUserId(userId);

            setShowDeleteConfirmation(true);
          }}
          onMouseEnter={() => {
            setSelectedImage(DeleteEmailHover);
          }}
          onMouseLeave={() => {
            setSelectedImage(DeleteEmailNormal);
          }}
        />
      </>
    );
  };

  const deleteTeamMember = () => {
    setLoading(true);
    setTimeout(() => {
      myAxios
        .delete(`teams/${deletedUserId}/${deletedTeamId}`)
        .then((response) => {
          if (response.data && response.data.success) {
            NotificationManager.success("Team member removed successfully!");

            // Refreash team member list

            myAxios
              .get(`teams/get-team-members/${selectedTeam?.id}`)
              .then((response) => {
                if (
                  response.data.success &&
                  response.data.data &&
                  response.data.data.length > 0
                ) {
                  setTeamMembers(response.data.data);
                }
              })
              .catch((error) => {
                console.error(error, "error in getting team members");
              });
          } else {
            NotificationManager.error(
              "Not able to remove team member, please try again!"
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          NotificationManager.error(parseErrorResponse(error));
        });
    }, 100);
  };

  return (
    <>
      <Container isOpen={isOpen}>
        {!isDesktopScreen && (
          <>
            <Header>
              <div style={{ padding: "10px" }}>
                <BackArrowTag
                  src={BackArrow}
                  alt="backArrow"
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <LogoTag src={JetListLogo} alt="jetlist" />
              </div>
            </Header>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <ListSpan>List of Teams</ListSpan> */}
            </div>
          </>
        )}

        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}

        <Wrapper>
          <ROW>
            <COL sm={12}>
              <Span>List of {" " + selectedTeam?.name + " "} Team Members</Span>
            </COL>
          </ROW>

          <DivForName isHeight={selectedTeam?.isOwner === true ? true : false}>
            {teamMembers &&
              teamMembers.map((teamMember: TeamMember, index: any) => {
                return (
                  <>
                    <RowSecond>
                      <ColumnFirst xs={1}>
                        <ColorRound
                          style={{
                            backgroundColor: getRandomColorByIndex(index),
                          }}
                        >
                          {teamMember.firstName && teamMember.email
                            ? teamMember.firstName.charAt(0)
                            : teamMember.email.charAt(0)}
                        </ColorRound>
                      </ColumnFirst>
                      <Col
                        xs={8}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {teamMember.firstName &&
                        teamMember.firstName &&
                        teamMember.email ? (
                          <SpanName>
                            {teamMember.firstName} {teamMember.lastName}
                          </SpanName>
                        ) : (
                          <SpanName>{teamMember.email}</SpanName>
                        )}

                        <SpanName>{teamMember.email}</SpanName>
                      </Col>

                      <ColumnThird xs={1}>
                        <SpanType>
                          {teamMember.userId === selectedTeam?.owner.id
                            ? "Admin"
                            : "User"}
                        </SpanType>
                      </ColumnThird>

                      <ColumnFourth xs={1}>
                        {teamMember.userId !== selectedTeam?.owner.id &&
                          selectedTeam?.isOwner && (
                            <DeleteEmailComponent
                              teamId={teamMember.teamId}
                              userId={teamMember.userId}
                            />
                          )}
                      </ColumnFourth>
                    </RowSecond>
                  </>
                );
              })}
          </DivForName>

          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors: {
                email?: string;
              } = {};
              if (!values.email) {
                errors.email = "email required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }: any) => {
              setLoading(true);
              myAxios
                .post(`teams/invite-user`, {
                  email: values.email,
                  teamId: selectedTeam?.id,
                })
                .then((response) => {
                  NotificationManager.success("New user added successfully!");
                  setTeamMembers(response.data.data);
                  setLoading(false);
                  resetForm();
                })
                .catch((error) => {
                  NotificationManager.error(parseErrorResponse(error));
                  console.error(error, "error");
                });
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                {selectedTeam && selectedTeam.isOwner && (
                  <DivBottom>
                    <Col sm={8}>
                      <InputTag name="email" placeholder="email" />
                      {errors.email && <EmailError>{errors.email}</EmailError>}
                    </Col>

                    <ColBtnDiv sm={4}>
                      <InviteBtn type="submit">Send Invite</InviteBtn>
                    </ColBtnDiv>
                  </DivBottom>
                )}
              </Form>
            )}
          </Formik>

          {showDeleteConfirmation && (
            <ConfirmationBox
              setShow={setShowDeleteConfirmation}
              name="Confirmation"
              message="Are you sure, you want to delete member from team?, All related data will be lost"
              okButtonText="Delete"
              okButtonClick={() => {
                setShowDeleteConfirmation(false);
                deleteTeamMember();
              }}
            />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default TeamMembersList;
