import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UserOnBoardContext } from "../../context/user-onboarding-context";
import DoneSvg from "../../images/donebtn.svg";
import NextBtnSvg from "../../images/nextbtn.svg";
import PreviousDisabledBtnSvg from "../../images/previous-disabled.svg";
import PreviousBtnSvg from "../../images/previousbtn.svg";
import { Task } from "../../types";
import myAxios from "../../utils/myaxios";
const Containers = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(52, 52, 52, 0.6);
  z-index: 1111;
`;
const MainDiv = styled.div`
  position: relative;
`;

const BtnDiv = styled.div`
  position: absolute;
  top: 75%;
  left: 36%;
  width: 26%;
  height: 9%;
  background: #ffffff;
  border-radius: 10px;
`;
const DIvForBtns = styled.div`
  margin: 10px 0;
`;

const OnboardingImgTag = styled.img`
  position: absolute;
  top: 9%;
  left: 14%;
  width: 71%;
`;

const SkipSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #2f4c95;
  border: none;
  /* padding: 37px; */
  padding: 8%;

  cursor: pointer;
`;
const PreviousBtn = styled.img`
  width: 21%;
  height: 62%;
  margin: 0 18px;
  cursor: pointer;
`;

const NextBtn = styled.img`
  width: 21%;
  height: 62%;
  cursor: pointer;
`;

const UserOnboarding = (isOpen: any) => {
  const { setShowUserOnBoarding, userOnBoardList } =
    useContext(UserOnBoardContext);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isShowNextBtn, setShowNextBtn] = useState<boolean>(true);

  useEffect(() => {
    if (currentIndex === userOnBoardList.length - 1) {
      setShowNextBtn(false);
    }
    // console.log("userOnBoardList", userOnBoardList);
    myAxios
      .post(`user-onboardings/increase-watch-count`, {
        userOnBoardId: userOnBoardList[currentIndex]._id,
      })
      .then((response: AxiosResponse<Task>) => {
        // console.log("response", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [currentIndex, userOnBoardList]);

  return (
    <>
      <MainDiv>
        <Containers>
          <OnboardingImgTag
            src={userOnBoardList[currentIndex].onBoardFeatureDetails.fileLink}
            alt="user-onboarding"
          />

          <BtnDiv>
            <DIvForBtns>
              <SkipSpan
                onClick={() => {
                  setShowUserOnBoarding(false);
                  setCurrentIndex(0);
                }}
              >
                Skip tutorial
              </SkipSpan>

              {currentIndex === 0 ? (
                <PreviousBtn
                  src={PreviousDisabledBtnSvg}
                  alt="image"
                  style={{ cursor: "not-allowed" }}
                />
              ) : (
                <PreviousBtn
                  src={PreviousBtnSvg}
                  onClick={() => {
                    setCurrentIndex(currentIndex - 1);
                    setShowNextBtn(true);
                  }}
                />
              )}

              {isShowNextBtn ? (
                <NextBtn
                  src={NextBtnSvg}
                  onClick={() => {
                    setCurrentIndex(currentIndex + 1);
                  }}
                />
              ) : (
                <NextBtn
                  src={DoneSvg}
                  alt="done"
                  onClick={() => {
                    setShowUserOnBoarding(false);
                    setCurrentIndex(0);
                  }}
                />
              )}
            </DIvForBtns>
          </BtnDiv>
        </Containers>
      </MainDiv>
    </>
  );
};

export default UserOnboarding;
