import { AxiosResponse } from "axios";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { CommonContextProps, CommonContextValues, UserSetting } from "../types";
import myAxios from "../utils/myaxios";
import { UserContext } from "./user-context";
export const CommonContext = createContext({} as CommonContextValues);

const CommonContextProvider = (
  props: CommonContextProps
): ReactElement<CommonContextValues> => {
  const { userState } = useContext(UserContext);

  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Loading...");
  const [userSettings, setUserSettings] = useState<UserSetting>({
    stopProgressHelp: undefined,
  });

  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [topBarText, setTopBarText] = useState<string>("");

  useEffect(() => {
    if (userState && userState.user && userState.user.jwt) {
      myAxios
        .get(`/user-settings`)
        .then((response: AxiosResponse<any[]>) => {
          if (response.data && response.data.length) {
            let setting = response.data.find((d) => d.name === "comment-popup");
            if (setting) {
              setUserSettings({
                ...userSettings,
                stopProgressHelp: setting.value === "true" ? true : false,
              });
            } else {
              setUserSettings({
                ...userSettings,
                stopProgressHelp: false,
              });
            }
          } else {
            setUserSettings({
              ...userSettings,
              stopProgressHelp: false,
            });
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
    // eslint-disable-next-line
  }, [userState]);

  const showLoader = useCallback(
    (text: string | null) => {
      setLoader(true);
      if (text) setLoaderText(text);
    },
    [setLoader, setLoaderText]
  );

  const hideLoader = useCallback(() => {
    setLoader(false);
  }, [setLoader]);

  return (
    <CommonContext.Provider
      value={{
        showLoader,
        hideLoader,
        loader,
        loaderText,
        userSettings,
        setUserSettings,
        showSearchBox,
        setShowSearchBox,
        topBarText,
        setTopBarText,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};

export default CommonContextProvider;
