import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import JetBotPopupCrossIcon from "../../images/cross.svg";
import { PromptPreFix } from "../../types";
import { USETIFULL_JET_BOT_SUGGESTION } from "../../usetifull/class-names";
import myAxios from "../../utils/myaxios";
import { removeLineBreakAtStart } from "../../utils/utils";
import { parseErrorResponse } from "../utils/utills";

const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const JetBotPopUpMainDiv = styled.div``;

const JetBotPopUpInierDiv = styled.div`
  position: fixed;
  width: 40%;
  height: 85vh;
  background: #ffffff;
  box-shadow: -6px 9px 18px 10px rgb(0 0 0 / 15%);
  border-radius: 10px;
  z-index: 1111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  border: solid #fc6601;
  border-width: 1px;
  transform: translate(-50%, -50%);
  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 289px; */
    width: 70%;
    /* height:80%; */
  }
`;

const JetBotPopUpHeader = styled.div`
  width: 100%;
  height: 35px;
  background: #fdf0e2;
  border-radius: 10px 10px 0px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const JetBotPopUpArrow = styled.img`
  cursor: pointer;

  right: -3%;
  top: -12px;
  z-index: 11111;
  position: absolute;
  background-color: white;
  border-radius: 69%;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 90%; */
  }
`;

const JetBotPopUpSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #797979;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
const JetBotPopUpCenterDiv = styled.div`
  padding: 0;
  height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* height: 63%; */
    height: 37%;
  }
`;
const JetBotPopUpSpanText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #797979;
  padding: 12px;
`;

const JetBotPopUpSpanCancel = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  color: #2f4c95;
  /* margin: 4px 13px; */
  text-decoration: underline;
  cursor: pointer;
`;
const Div = styled.div`
  margin: 10px;
`;
const InputBox = styled.input`
  width: 97%;
  height: 40px;
  margin: 0;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;

const PromptPrefixDropDown = styled.select`
  width: 97%;
  height: 40px;
  margin: 0;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;
const JetBotSuggestionPopup = ({
  gptSuggestionsText,
  setGptSuggestionsText,
  setShowJetBotPopup,
  keepInDescription,
  searchText,
  toShowSubmitBtnJetbotPopup,
  showSuggestionPopUpInTaskDetail,
}: any) => {
  const { showLoaderForSearchSuggestion, setShowLoaderForSearchSuggestion } =
    useContext(TaskContext);

  const [text, setText] = useState<string>(searchText);
  const [promptPreFixes, setPromptPreFixes] = useState<PromptPreFix[]>([]);
  const [selectedPromptPreFix, setSelectedPromptPreFix] = useState<string>("");

  useEffect(() => {
    myAxios
      .get(`gpt-3-prompt-prefixes`)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data) {
            setPromptPreFixes(response.data.data);
          }
        }

        setShowLoaderForSearchSuggestion(false);
      })
      .catch((err) => {
        console.error("Getting error ", err);
        setShowLoaderForSearchSuggestion(false);
      });
    // eslint-disable-next-line
  }, []);

  const nextSuggestion = () => {
    if (text === undefined || text === "" || text.trim() === "") {
      NotificationManager.error(
        "Please enter details to search suggestion...",
        "Nothing to search",
        3000
      );
      return;
    }

    setShowLoaderForSearchSuggestion(true);

    const searchText = selectedPromptPreFix
      ? selectedPromptPreFix + " " + text
      : text;
    console.log("searchText ", searchText);
    myAxios
      .post(`gpt-3/search-suggestion`, {
        searchText: searchText,
      })
      .then((response) => {
        if (response.data.success) {
          let suggestionText = "";
          if (response.data.data) {
            response.data.data.forEach((dt: any) => {
              suggestionText = suggestionText + "\n" + dt.text;
            });
          }

          suggestionText = removeLineBreakAtStart(suggestionText);

          suggestionText = suggestionText.replaceAll("\n", "<br>");
          setGptSuggestionsText(suggestionText);
          // setShowJetBotPopup(true);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to search...",
            "",
            5000
          );
        }

        setShowLoaderForSearchSuggestion(false);
      })
      .catch((err) => {
        console.error("Getting error ", err);
        NotificationManager.error(parseErrorResponse(err));

        setShowLoaderForSearchSuggestion(false);
      });
  };

  const copyClipboardHtmlContent = () => {
    let doc: any = document;
    let text = doc.getElementById("JetBotText");
    let range, selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);

      selection?.removeAllRanges();
      selection?.addRange(range);
    }
    document.execCommand("copy");

    window?.getSelection()?.removeAllRanges();

    NotificationManager.success("Copied", "", 1500);
  };

  return (
    <>
      <JetBotPopUpMainDiv>
        <JetBotPopUpInierDiv>
          {showLoaderForSearchSuggestion && (
            <WatchParent1>
              <WatchParent>
                <Watch color="orange" height={50} width={50} />
              </WatchParent>
            </WatchParent1>
          )}
          <JetBotPopUpHeader>
            <JetBotPopUpArrow
              src={JetBotPopupCrossIcon}
              alt="dlt"
              onClick={() => {
                setShowJetBotPopup(false);
              }}
            />
            <JetBotPopUpSpan>JetBot Suggestion</JetBotPopUpSpan>
          </JetBotPopUpHeader>
          <Div>
            <PromptPrefixDropDown
              value={selectedPromptPreFix}
              onChange={(e) => {
                setSelectedPromptPreFix(e.target.value);
              }}
            >
              {" "}
              <option value={""}>Basic</option>
              {promptPreFixes &&
                promptPreFixes.map((promptPreFix: PromptPreFix) => {
                  return (
                    <option value={`${promptPreFix.prompt}`}>
                      {promptPreFix.label}
                    </option>
                  );
                })}
              {/*   <option value={0}>none</option>
                      <option value={1}>p:1</option>
                      <option value={2}>p:2</option>
                      <option value={3}>p:3</option>
                      <option value={4}>p:4</option>
                      <option value={5}>p:5</option> */}
            </PromptPrefixDropDown>
          </Div>
          <Div>
            <InputBox
              className={USETIFULL_JET_BOT_SUGGESTION}
              type="text"
              // value={searchText}
              defaultValue={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Type here to search"
            />
          </Div>
          <JetBotPopUpCenterDiv>
            <JetBotPopUpSpanText
              id="JetBotText"
              dangerouslySetInnerHTML={{
                __html: gptSuggestionsText ? gptSuggestionsText : "",
              }}
            ></JetBotPopUpSpanText>
          </JetBotPopUpCenterDiv>
          <Row style={{ margin: "0", position: "relative" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "10%",
                position: "fixed",
                bottom: "2px",
              }}
            >
              {toShowSubmitBtnJetbotPopup && (
                <JetBotPopUpSpanCancel
                  onClick={() => {
                    nextSuggestion();
                  }}
                >
                  Submit
                </JetBotPopUpSpanCancel>
              )}
              {!showSuggestionPopUpInTaskDetail && (
                <JetBotPopUpSpanCancel
                  onClick={() => {
                    keepInDescription();
                  }}
                >
                  Use Suggestion
                </JetBotPopUpSpanCancel>
              )}
              <JetBotPopUpSpanCancel
                onClick={() => {
                  nextSuggestion();
                }}
              >
                Next Suggestion
              </JetBotPopUpSpanCancel>
              <JetBotPopUpSpanCancel
                onClick={() => {
                  copyClipboardHtmlContent();
                }}
              >
                Copy
              </JetBotPopUpSpanCancel>
            </div>
          </Row>
        </JetBotPopUpInierDiv>
      </JetBotPopUpMainDiv>
    </>
  );
};

export default JetBotSuggestionPopup;
