import { createContext, ReactElement, useState } from "react";
import {
  AdminValues,
  AdminProps,
  AppFeature,
  CustomEmailDomain,
  OpenAISetting,
  TestGroup,
  CheckEnableFeature,
  PromptPreFix,
} from "../types";

export const AdminContext = createContext({} as AdminValues);

const AdminProvider = (props: AdminProps): ReactElement<AdminValues> => {
  const [appfeatures, setAppfeatures] = useState<AppFeature[]>([]);
  const [testGroups, setTestGroups] = useState<TestGroup[]>([]);
  const [saveTestGroup, setSaveTestGroup] = useState<TestGroup[]>([]);
  const [customEmails, setCustomEmails] = useState<CustomEmailDomain[]>([]);
  const [customDomains, setCustomDomains] = useState<CustomEmailDomain[]>([]);
  const [openAISettings, setOpenAISettings] = useState<OpenAISetting[]>([]);
  const [promptPreFixes, setPromptPreFixes] = useState<PromptPreFix[]>([]);
  const [selectedTestGroupId, setSelectedTestGroupId] = useState<string>("");
  const [CheckEnableFeatures, setCheckEnableFeatures] = useState<
    CheckEnableFeature[]
  >([]);

  const addAppFeature = (appFeature: AppFeature) => {
    setAppfeatures(
      appfeatures && appfeatures.length > 0
        ? [...appfeatures, appFeature]
        : [appFeature]
    );
  };
  const addTestGroups = (testGroup: TestGroup) => {
    setSaveTestGroup(
      saveTestGroup && saveTestGroup.length > 0
        ? [...saveTestGroup, testGroup]
        : [testGroup]
    );
  };
  const removeTestGroup = (id: string) => {
    const index = saveTestGroup.findIndex((el: any) => el._id === id);
    if (index > -1) {
      saveTestGroup.splice(index, 1);
    }
    // console.log("test group after delete", testGroups);
  };
  const removeAppFeature = (id: string) => {
    const index = appfeatures.findIndex((el: AppFeature) => el._id === id);
    if (index > -1) {
      appfeatures.splice(index, 1);
    }
  };

  const updateAppFeature = (appFeature: AppFeature) => {
    setAppfeatures(
      appfeatures.map((afeature: AppFeature) =>
        appFeature._id === afeature._id ? appFeature : afeature
      )
    );
  };
  const updateEnableFeature = (id: string) => {
    setCheckEnableFeatures(
      CheckEnableFeatures.map((efeature: CheckEnableFeature) =>
        id === efeature._id.toString()
          ? { ...efeature, active: !efeature.active }
          : efeature
      )
    );
  };

  const addCustomEmail = (customEmail: CustomEmailDomain) => {
    setCustomEmails(
      customEmails && customEmails.length > 0
        ? [...customEmails, customEmail]
        : [customEmail]
    );
  };
  const removeCustomEmail = (id: string) => {
    const index = customEmails.findIndex(
      (el: CustomEmailDomain) => el._id === id
    );
    if (index > -1) {
      customEmails.splice(index, 1);
    }
  };

  const updateCustomEmail = (customEmail: CustomEmailDomain) => {
    setCustomEmails(
      customEmails.map((cEmail: CustomEmailDomain) =>
        customEmail._id === cEmail._id ? customEmail : cEmail
      )
    );
  };

  const addCustomDomain = (customDomain: CustomEmailDomain) => {
    setCustomDomains(
      customDomains && customDomains.length > 0
        ? [...customDomains, customDomain]
        : [customDomain]
    );
  };
  const removeCustomDomain = (id: string) => {
    const index = customDomains.findIndex(
      (el: CustomEmailDomain) => el._id === id
    );
    if (index > -1) {
      customDomains.splice(index, 1);
    }
  };

  const updateCustomDomain = (customDomain: CustomEmailDomain) => {
    setCustomDomains(
      customDomains.map((cDomain: CustomEmailDomain) =>
        customDomain._id === cDomain._id ? customDomain : cDomain
      )
    );
  };

  return (
    <AdminContext.Provider
      value={{
        appfeatures,
        setAppfeatures,
        testGroups,
        setTestGroups,
        addTestGroups,
        removeTestGroup,
        addAppFeature,
        removeAppFeature,
        updateAppFeature,
        customEmails,
        setCustomEmails,
        customDomains,
        setCustomDomains,
        addCustomEmail,
        removeCustomEmail,
        updateCustomEmail,
        addCustomDomain,
        removeCustomDomain,
        updateCustomDomain,
        openAISettings,
        setOpenAISettings,
        promptPreFixes,
        setPromptPreFixes,
        saveTestGroup,
        setSaveTestGroup,
        selectedTestGroupId,
        setSelectedTestGroupId,
        CheckEnableFeatures,
        setCheckEnableFeatures,
        updateEnableFeature,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
