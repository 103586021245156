import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LeftShiftDisabledSvg from "../../images/left-disable.svg";
import LeftShiftSvg from "../../images/leftShift.svg";
import RightShiftDisabledSvg from "../../images/right-disable.svg";
import RightShiftSvg from "../../images/rightShift.svg";

import { TaskContext } from "../../context/task-context";
const PaginatinUl = styled.ul`
  /* @media (min-width: 320px) and (max-width: 450px) {
    margin-bottom: 4rem;
  } */
`;

const RightAndLeftImgTag = styled.img`
  margin: 0px 12px;
  width: 22px;
  height: 32px;
  border-radius: 0;
  cursor: pointer;
  &:hover {
    background-color: #eee;
    border-color: #ddd;
    width: 22px;
    height: 32px;
    margin: 0px 12px;
  }
  @media (min-width: 320px) and (max-width: 1199px) {
    width: 12px;
    height: 12px;
  }
`;

const RightAndLeftImgDisabledTag = styled.img`
  margin: 0px 12px;
  width: 22px;
  height: 32px;
  border-radius: 0;
  cursor: not-allowed;
  @media (min-width: 320px) and (max-width: 1199px) {
    width: 12px;
    height: 12px;
  }
`;
const LiNormal = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #2f4c95;
  padding: 6px 12px;
  margin: 0 7px;
  border-radius: 0;
  cursor: pointer;
  /* width: 22px;
  height: 29px; */
  text-align: center;
  &:hover {
    background-color: #eee;
    border-color: #ddd;
    border-radius: 3px;
  }
  @media (min-width: 320px) and (max-width: 1199px) {
    padding: 3px 7px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 3px 0;
    font-size: 13px;
  }
`;

const DivForDropDown = styled.div`
  margin: 0 18px;
`;
const SapanText = styled.span`
  color: rgba(47, 76, 149, 0.5);
  padding: 3px 0;
  @media (min-width: 320px) and (max-width: 1199px) {
    font-size: 12px;
    padding: 5px 0;
  }
`;

const LiSelected = styled.li`
  width: 28px;
  height: 29px;
  text-align: center;
  cursor: pointer;
  background-color: #2f4c95;
  color: white;
  border-radius: 3px;
  padding: 4px 7px;
  margin: 0 7px;
  font-size: 15px;
  line-height: 21px;
  @media (min-width: 320px) and (max-width: 1199px) {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 3px 0;
    font-size: 13px;
  }
`;

const Pagination = ({
  itemCount,
  perPageItem,
  pageChange,
  pageLimitChange,
  resetPagination,
}: any) => {
  const { pageLimit } = useContext(TaskContext);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [upperPageBound, setUpperPageBond] = useState<number>(3);
  const [lowerPageBound, setLowerPageBond] = useState<number>(0);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState<string>("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState<string>("");
  const [pageBound, setPageBound] = useState<number>(3);

  useEffect(() => {
    if (resetPagination) {
      setCurrentPage(1);
      setUpperPageBond(3);
      setLowerPageBond(0);
      setIsPrevBtnActive("disabled");
      setIsNextBtnActive("");
      setPageBound(3);
      // eslint-disable-next-line
      resetPagination = false;
    }
  }, [resetPagination]);

  useEffect(() => {
    if (itemCount <= perPageItem) {
      setIsPrevBtnActive("disabled");
      setIsNextBtnActive("disabled");
    }
  }, [itemCount, perPageItem]);

  const handleClick = (event: any) => {
    let listid = Number(event.target.id);
    setCurrentPage(listid);

    setPrevAndNextBtnClass(listid);
  };
  const setPrevAndNextBtnClass = (listid: any) => {
    let totalPage = Math.ceil(itemCount / perPageItem);
    setIsNextBtnActive("disabled");
    setIsPrevBtnActive("disabled");

    if (totalPage === listid && totalPage > 1) {
      setIsPrevBtnActive("");
    } else if (listid === 1 && totalPage > 1) {
      setIsNextBtnActive("");
    } else if (totalPage > 1) {
      setIsPrevBtnActive("");
      setIsNextBtnActive("");
    }
  };
  useEffect(() => {
    pageChange(currentPage);
  }, [currentPage, pageChange]);
  const btnIncrementClick = () => {
    setUpperPageBond(upperPageBound + pageBound);
    setLowerPageBond(lowerPageBound + pageBound);

    let listid = upperPageBound + 1;
    setCurrentPage(listid);

    setPrevAndNextBtnClass(listid);
  };
  const btnDecrementClick = () => {
    setUpperPageBond(upperPageBound - pageBound);
    setLowerPageBond(lowerPageBound - pageBound);

    let listid = upperPageBound - pageBound;
    setCurrentPage(listid);

    setPrevAndNextBtnClass(listid);
  };

  const btnPrevClick = () => {
    if ((currentPage - 1) % pageBound === 0) {
      setUpperPageBond(upperPageBound - pageBound);
      setLowerPageBond(lowerPageBound - pageBound);
    }
    let listid = currentPage - 1;
    setCurrentPage(listid);

    setPrevAndNextBtnClass(listid);
  };

  const btnNextClick = () => {
    if (currentPage + 1 > upperPageBound) {
      setUpperPageBond(upperPageBound + pageBound);
      setLowerPageBond(lowerPageBound + pageBound);
    }
    let listid = currentPage + 1;
    setCurrentPage(listid);

    setPrevAndNextBtnClass(listid);
  };

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(itemCount / perPageItem); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if ((number === 1 && currentPage === 1) || number === currentPage) {
      return (
        <LiSelected key={number} id={`${number}`} onClick={handleClick}>
          {number}
        </LiSelected>
      );
    } else if (number < upperPageBound + 1 && number > lowerPageBound) {
      return (
        <LiNormal key={number} id={`${number}`} onClick={handleClick}>
          {number}
        </LiNormal>
      );
    }
    return <></>;
  });
  let pageIncrementBtn = null;
  if (pageNumbers.length > upperPageBound) {
    pageIncrementBtn = (
      <li className="" onClick={btnIncrementClick}>
        &hellip; {/*  </a> */}
      </li>
    );
  }
  let pageDecrementBtn = null;
  if (lowerPageBound >= 1) {
    pageDecrementBtn = (
      <li className="" onClick={btnDecrementClick}>
        &hellip; {/* </a> */}
      </li>
    );
  }
  let renderPrevBtn = null;
  if (isPrevBtnActive === "disabled") {
    renderPrevBtn = (
      <li className={isPrevBtnActive} id="btnPrev">
        <RightAndLeftImgDisabledTag
          src={LeftShiftDisabledSvg}
          alt="LeftShiftSvg"
        />
      </li>
    );
  } else {
    renderPrevBtn = (
      <li className={isPrevBtnActive} onClick={btnPrevClick}>
        <RightAndLeftImgTag src={LeftShiftSvg} alt="LeftShiftSvg" />
      </li>
    );
  }
  let renderNextBtn = null;
  if (isNextBtnActive === "disabled") {
    renderNextBtn = (
      <li className={isNextBtnActive} id="btnNext">
        <RightAndLeftImgDisabledTag src={RightShiftDisabledSvg} alt="right" />
      </li>
    );
  } else {
    renderNextBtn = (
      <li className={isNextBtnActive} onClick={btnNextClick}>
        <RightAndLeftImgTag src={RightShiftSvg} alt="right" />
      </li>
    );
  }

  return (
    <>
      <PaginatinUl className="pagination">
        {renderPrevBtn}
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        {renderNextBtn}
        <DivForDropDown>
          <select
            className="DropDownNumber"
            defaultValue={pageLimit ? pageLimit : ""}
            onChange={(e) => {
              setCurrentPage(1);
              pageLimitChange(parseInt(e.target.value));
            }}
          >
            <option value={5}>5 Tasks</option>
            <option value={10}>10 Tasks</option>
            <option value={25}>25 Tasks</option>
            <option value={50}>50 Tasks</option>
            <option value={100}>100 Tasks</option>
          </select>
        </DivForDropDown>

        <SapanText>per page</SapanText>
      </PaginatinUl>
    </>
  );
};

export default Pagination;
