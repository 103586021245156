import { createContext, ReactElement, useState } from "react";
import { Team, TeamMember, TeamProps, TeamValues } from "../types";

export const TeamContext = createContext({} as TeamValues);

const TeamProvider = (props: TeamProps): ReactElement<TeamValues> => {
  const [userTeams, setUserTeams] = useState<Team[]>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>();
  const addUserTeams = (teams: Team[]) => {
    setUserTeams(teams);

    if (!selectedTeam) {
      teams.forEach((team: Team) => {
        // console.log("associatedTeam in context", associatedTeam);
        if (team.isOwner && team.type === "individual") {
          setSelectedTeam(team);
        }
      });
    }
  };

  const updateTeamName = (name: string, teamId: string) => {
    setUserTeams(
      userTeams?.map((team: Team) => {
        if (team.id === teamId) return { ...team, name: name };
        else return team;
      })
    );
  };



  

  return (
    <TeamContext.Provider
      value={{
        userTeams,
        setUserTeams,
        selectedTeam,
        setSelectedTeam,
        addUserTeams,
        teamMembers,
        setTeamMembers,
        updateTeamName,
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
