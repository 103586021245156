import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
// import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { UserContext } from "../../context/user-context";
import ToggleOff from "../../images/email-off.svg";
import ToggleOn from "../../images/email-on.svg";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const EmailNotificationSettingsContainer = styled(Col)`
  /*  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;

  position: relative; */
  position: relative;
  min-width: 50%;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 100%; */
    margin: 12px;
    /* height: 333px; */
    width: 94%;
  }
`;
const SettingsUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
`;
const Label = styled.li`
  color: #797979;
  font-size: 14px;
  font-weight: 400;
`;

const EmailNotificationTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
`;

const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #2f4c95;
`;

const SettingOnOffImgTag = styled.img`
  cursor: pointer;
`;

const EmailNotificationSettings = () => {
  const { userState } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [emailNotificationsSettings, setEmailNotificationSettings] =
    useState<any>();

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`email-notification-settings`)
          .then((response) => {
            if (response.data && response.data.success) {
              setEmailNotificationSettings(response.data.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting custom email");
          });
      }, 100);
    }
    // eslint-disable-next-line
  }, [userState]);

  const updateEmailNotificationSettings = (notificationSettings: any) => {
    setLoading(true);
    setTimeout(() => {
      myAxios
        .post(`email-notification-settings`, notificationSettings)
        .then((response) => {
          if (response.data && response.data.success) {
            setEmailNotificationSettings(response.data.data);
          }
          setLoading(false);
          NotificationManager.success(
            "Email notification settings updated successfully"
          );
        })
        .catch((error) => {
          setLoading(false);
          /*  NotificationManager.error(
            "Error in updating email notification settings"
          ); */
          NotificationManager.error(parseErrorResponse(error));
          console.error(error, "error in getting custom email");
        });
    }, 100);
  };

  return (
    <>
      <EmailNotificationSettingsContainer sm={4}>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}

        <HeadingText>Email Notification Settings</HeadingText>

        {emailNotificationsSettings && (
          <SettingsUl>
            <EmailNotificationTypeContainer>
              <Label>Daily report emails</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.dailyReportEmails
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    dailyReportEmails:
                      !emailNotificationsSettings.dailyReportEmails,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task assigned</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskDelegated
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskDelegated: !emailNotificationsSettings.taskDelegated,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            {/* <EmailNotificationTypeContainer>
              <Label>Task delegated new user</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskDelegatedNewUser
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskDelegatedNewUser:
                      !emailNotificationsSettings.taskDelegatedNewUser,
                  });
                }}
              />
            </EmailNotificationTypeContainer> */}
            <EmailNotificationTypeContainer>
              <Label>Task for CC user</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskForCCUser
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskForCCUser: !emailNotificationsSettings.taskForCCUser,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task update</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskUpdate ? ToggleOn : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskUpdate: !emailNotificationsSettings.taskUpdate,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task completed</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskCompleted
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskCompleted: !emailNotificationsSettings.taskCompleted,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task report</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskReport ? ToggleOn : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskReport: !emailNotificationsSettings.taskReport,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task hand off new user</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskHandOffNewUser
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskHandOffNewUser:
                      !emailNotificationsSettings.taskHandOffNewUser,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Task hand off current user</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.taskHandOffCurrentUser
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    taskHandOffCurrentUser:
                      !emailNotificationsSettings.taskHandOffCurrentUser,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Send casual nudge</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.sendCasualNudge
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    sendCasualNudge:
                      !emailNotificationsSettings.sendCasualNudge,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
            <EmailNotificationTypeContainer>
              <Label>Send urgent nudge</Label>
              <SettingOnOffImgTag
                src={
                  emailNotificationsSettings.sendUrgentNudge
                    ? ToggleOn
                    : ToggleOff
                }
                onClick={() => {
                  updateEmailNotificationSettings({
                    sendUrgentNudge:
                      !emailNotificationsSettings.sendUrgentNudge,
                  });
                }}
              />
            </EmailNotificationTypeContainer>
          </SettingsUl>
        )}
      </EmailNotificationSettingsContainer>
    </>
  );
};

export default EmailNotificationSettings;
