import { useState } from "react";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import SignUpProfileImg from "../../images/signupProfile.svg";
import UserGuide from "../../images/userguide.svg";
import ForgetPassword from "./forget-password";
import LoginWithMagicLink from "./login-magic-link"
import Login from "./login";
import Signup from "./signup";

const Container = styled.div`
  /* background: white; */
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;

  @media (max-width: 1199px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

const LeftMainDivs = styled(Col)`
  display: flex;
  flex-direction: column;
  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;
const RightMainDiv = styled(Col)`
  background: #fdf0e2;
  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;

const Bullet = styled.li`
  line-height: 24px;
  margin-bottom: 5px;
`;

const DivforRights = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 63%;

  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;

const RightsPara = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797979;
  @media (max-width: 320px) {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #2f4c95;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 169px;
    height: 15px;
    left: 526px;
    top: 680px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #2f4c95;
  }
`;

const DivForSpans = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0;
  }
`;

const FirstSpan = styled.span`
  width: 453px;
  /* height: 145px; */

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  padding-top: 28px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 15px;
    line-height: 25px;
    /* width: 61%;
    height: 74px;
    padding-left: 27px; */

    width: 100%;
    height: 61px;
    /* padding-left: 29px; */
    padding: 7px;
  }
`;
const SecondRowSpan = styled.span`
  /* width: 606px; */
  /* height: 24px; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 50%;
    font-size: 10px;
    line-height: 20px;
    padding: 7px;
  }
`;
const Para = styled.p`
  /* width: 606px; */
  /* height: 95px; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #797979;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    font-size: 10px;
    line-height: 20px;
    /* padding-left: 29px; */
    padding: 7px;
  }
`;

const SignupProfileImgTag = styled.img`
  width: 307px;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* margin-left: 4%; */
    width: 100%;
  }
`;

const SecondColDiv = styled.div`
  width: 308px;
  height: 268px;
  left: 929px;
  top: 298px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  color: #797979;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const DivForImgAndCols = styled.div`
  display: flex;

  @media (max-width: 1199px) and (min-width: 320px) {
    /* height: 100vh; */
    flex-direction: column;
  }
`;
const UserGuideTag = styled.img`
  width: 32px;
  height: 27px;
  margin: 3px;
`;
const LoginSignUpView = () => {
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const [showSignupMsgInLoginPage, setShowSignupMsgInLoginPage] = useState<
    string | undefined
  >("");
  const [toShowMagicLinkloginPage, setToShowMagicLinkloginPage] =
    useState<boolean>(true);
  const [toShowSignupPage, setToShowSignupPage] = useState<boolean>(false);
  const [toShowForgetPassPage, setToShowForgetPassPage] =
    useState<boolean>(false);
  return (
    <>
      <Container>
        <LeftMainDivs sm={isDesktopScreen ? 6 : 12}>
          {/* SHOWING ALL COMPONENTS (LOGIN WITH MAGIC LINK, LOGIN ,SIGNUP,FORGOT)--- */}
          {toShowMagicLinkloginPage && !toShowSignupPage ? (
            <LoginWithMagicLink
              setToShowMagicLinkloginPage={setToShowMagicLinkloginPage}
              setToShowSignupPage={setToShowSignupPage}
              setToShowForgetPassPage={setToShowForgetPassPage}
            />
          ) : (
            !toShowSignupPage &&
            !toShowForgetPassPage && (
              <Login
                setToShowMagicLinkloginPage={setToShowMagicLinkloginPage}
                setToShowSignupPage={setToShowSignupPage}
                showSignupMsgInLoginPage={showSignupMsgInLoginPage}
                setToShowForgetPassPage={setToShowForgetPassPage}
              />
            )
          )}

          {toShowSignupPage && (
            <Signup
              setToShowSignupPage={setToShowSignupPage}
              setToShowMagicLinkloginPage={setToShowMagicLinkloginPage}
              setShowSignupMsgInLoginPage={setShowSignupMsgInLoginPage}
              setToShowForgetPassPage={setToShowForgetPassPage}
            />
          )}

          {toShowForgetPassPage &&
            !toShowSignupPage &&
            !toShowMagicLinkloginPage && (
              <ForgetPassword
                setToShowMagicLinkloginPage={setToShowMagicLinkloginPage}
                setToShowForgetPassPage={setToShowForgetPassPage}
                setToShowSignupPage={setToShowSignupPage}
              />
            )}
        </LeftMainDivs>
        {isDesktopScreen && (
          <RightMainDiv sm={6}>
            <DivForSpans>
              <FirstSpan>
                Maximize Productivity with instant to-do list tracking
              </FirstSpan>
              <SecondRowSpan>Using Jetlist is Simple </SecondRowSpan>
              <Para>
                With JetList, you and your team will stay organized and remain
                efficient. You can collaborate, delegate tasks, assign due
                dates, and monitor progress with the unique collaboration
                features of JetList.
                <br /> Complete user guide is available here
                <a target={"_new"} href="https://help.myjetlist.com/">
                  <UserGuideTag src={UserGuide} alt="user" />
                </a>
              </Para>
            </DivForSpans>
            {/* <Row> */}
            <DivForImgAndCols>
              <Col md={6}>
                <SignupProfileImgTag src={SignUpProfileImg} />
              </Col>
              <Col md={6}>
                <SecondColDiv>
                  <ol>
                    <Bullet>
                      When you login for the first time, we will create an
                      account for you.
                    </Bullet>
                    <Bullet>
                      You can add other basic details like your name whenever
                      you want using the profile page.
                    </Bullet>
                    <Bullet>
                      Just enter your email and we will send you a link to login
                      on your email, that you can click to login directly
                    </Bullet>
                    <Bullet> Enjoy a 7 days free trial</Bullet>
                    <Bullet>
                      {" "}
                      Powerful for both business and personal use
                    </Bullet>
                  </ol>
                </SecondColDiv>
              </Col>
            </DivForImgAndCols>
            {/* </Row> */}
            <DivforRights>
              <RightsPara> © 2023. All Rights Reserved by Jetlist</RightsPara>
            </DivforRights>
          </RightMainDiv>
        )}
      </Container>
    </>
  );
};

export default LoginSignUpView;
