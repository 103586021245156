import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { AdminContext } from "../../context/admin-context";
import DeleteIcon from "../../images/delete-normal.svg";
import myAxios from "../../utils/myaxios";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";

const AddFeatureContainer = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  min-width: 62%;
  position: relative;
  margin-left: 33px;
`;
const HeadingText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #2f4c95;
`;

const Div = styled.div`
  margin-top: 20px;
`;

const InputBox = styled.input`
  width: 66%;
  height: 40px;
  margin: 0;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;
const AddAppFeature = styled.button`
  border: none;
  background: #2f4c95;
  color: white;
  padding: 8px 11px;
  margin: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const ButtonLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #08ca1b;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
const AddGroupSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
`;
const DivForText = styled.div`
  margin-top: 20px;
  margin-bottom: 14px;
`;

const GroupFeatureUlList = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
`;

const GroupFeatureLiList = styled.li`
  color: #3c3b5bcc;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
`;
const SpanBorder = styled.span`
  border-top: 1px solid #e6e6e6;
  width: 71%;
  margin-top: 6px;
  margin-bottom: 5px;
`;
const DeleteImage = styled.img.attrs({
  src: DeleteIcon,
})`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
`;

const ErrorSpan = styled.span`
  color: red;
`;

const AddFeatureGroup = ({
  setShowAnotherComponent: setShowFeatureComponent,
}: any) => {
  const {
    addTestGroups,
    testGroups,
    removeTestGroup,
    setSaveTestGroup,
    saveTestGroup,
    setSelectedTestGroupId,
  } = useContext(AdminContext);
  const [testFeatureGroup, setTestFeatureGroup] = useState<string>("");
  const [showLoaderTestGroup, setshowLoaderTestGroup] = useState(false);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    useState(false);
  const [deleteTestGroupId, setDeleteTestGroupId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [testGroupError, setTestGroupError] = useState("");

  const checkIfAppFeatureAlreadyExist = () => {
    if (testGroups) {
      const found = testGroups.some((el) => el.name === testFeatureGroup);
      if (found) {
        setTestGroupError("test group already added");
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  const addTestGroup = () => {
    if (testFeatureGroup === "" || testFeatureGroup.trim() === "") {
      setTestGroupError("Please enter app feature name");
      return;
    }

    if (checkIfAppFeatureAlreadyExist()) return;
    myAxios
      .post(`admin-settings/test-group`, {
        name: testFeatureGroup,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Test group saved successfully...",
            "",
            1500
          );
          setTestFeatureGroup("");
          addTestGroups(response.data.data);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to add...",
            "",
            5000
          );
        }
        setshowLoaderTestGroup(false);
      })
      .catch((err) => {
        console.error("Getting error ", err);
        NotificationManager.error(parseErrorResponse(err));
        setshowLoaderTestGroup(false);
      });
  };

  const handleDelete = (id: string) => {
    setLoading(true);
    setShowDeleteConfirmationBox(false);
    setTimeout(() => {
      myAxios
        .delete(`admin-settings/test-group/` + id)
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "test group deleted successfully...",
              "",
              1500
            );
            removeTestGroup(id);
            // setEmail("");
          } else {
            NotificationManager.error(response.data.message, "", 2000);
          }
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(parseErrorResponse(err));
          setLoading(false);
        });
    }, 100);
  };

  useEffect(() => {
    myAxios
      .get(`admin-settings/test-groups/`)
      .then((response) => {
        setSaveTestGroup(response.data.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (testFeatureGroup && testFeatureGroup.length > 0) {
      setTestGroupError("");
    }
  }, [testFeatureGroup]);

  return (
    <>
      <AddFeatureContainer>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}
        <HeadingText>Add New Feature Group</HeadingText>
        <Div>
          <InputBox
            type="text"
            placeholder="Group Name"
            value={testFeatureGroup}
            onChange={(e) => setTestFeatureGroup(e.target.value)}
          />

          <AddAppFeature
            onClick={() => {
              addTestGroup();
            }}
          >
            {!showLoaderTestGroup && "Add"}
            {showLoaderTestGroup && <ButtonLoader />}
          </AddAppFeature>
        </Div>
        <ErrorSpan> {testGroupError && testGroupError}</ErrorSpan>
        <DivForText>
          <AddGroupSpan>Added Groups </AddGroupSpan>
        </DivForText>

        <GroupFeatureUlList>
          {saveTestGroup &&
            saveTestGroup.length > 0 &&
            saveTestGroup.map((testGroupName) => {
              return (
                <>
                  <Row>
                    <Col sm={8}>
                      <GroupFeatureLiList
                        onClick={() => {
                          setShowFeatureComponent(true);
                          setSelectedTestGroupId(testGroupName._id);
                        }}
                      >
                        {testGroupName.name}
                      </GroupFeatureLiList>
                    </Col>

                    <Col>
                      <DeleteImage
                        onClick={() => {
                          setDeleteTestGroupId(testGroupName._id);
                          setShowDeleteConfirmationBox(true);
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <SpanBorder></SpanBorder>
                  </Row>
                </>
              );
            })}
        </GroupFeatureUlList>
        {showDeleteConfirmationBox && (
          <ConfirmationBox
            name="Confirmation"
            setShow={setShowDeleteConfirmationBox}
            message="Are you sure, you want to delete this test group?"
            okButtonText="Delete"
            okButtonClick={() => {
              handleDelete(deleteTestGroupId);
            }}
          />
        )}
      </AddFeatureContainer>
    </>
  );
};

export default AddFeatureGroup;
