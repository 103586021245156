import { AxiosResponse } from "axios";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { LOGGED_IN, UPDATE_TEAM, UPDATE_USER } from "../../consts";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import { User } from "../../types";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 15%;
  top: 20%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;
  z-index: 9998;
  //background-color: #46262F;
  position: absolute;
`;
const AppFeatureListContainer = styled.div`
  background: #ffffff;

  border-radius: 0px;
  padding: 30px;
  /* min-width: 850px; */
  /* min-width: 40%; */
  /* width:100% */
  //position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 100%;
    padding: 0px;
  }
`;
const ContainerCompany = styled.div`
  background: #ffffff;
  // border: 1.5px solid rgba(220, 220, 220, 0.3);
  // box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 850px; */
  min-width: 46%;
  /* width:100% */
  //position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 100%;
    padding: 0px;
  }
`;
const DivForForm = styled.div`
  width: 100%;
  //height: 471px;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    border: none;
    padding: 10px;
  }
`;

const CompanyForm = styled.div`
  width: 100%;

  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    border: none;
    padding: 10px;
  }
`;

const Label = styled.label`
  // margin-left: 5%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
  }
`;
const InputField = styled(Field)`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  //margin-left: 5%;
  padding-left: 5px;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 10px;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 85%; */
    height: 40px;
    padding-left: 10px;
    &::-webkit-input-placeholder {
      color: rgba(60, 59, 91, 0.29);
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
      padding-left: 10px;
    }
  }
`;

const Button = styled.button`
  /* width: 160px; */
  width: 22%;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  border: none;
  margin-top: 21px;
  /* // margin-left: 5%; */
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 40%;
    height: 40px;
    font-size: 12px;
    margin-bottom: 2rem;
    /* margin-left: 25px; */

    &:hover {
      background: #0e2151;
      border: none;
    }
  }
`;

const NameError = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  padding: 3px 22px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const CompanyError = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  /* padding: 3px 22px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const DivForBtn = styled.div`
  display: flex;
  justify-content: start;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: center;
  }
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: start;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const { userState, userDispatch } = useContext(UserContext);
  const { updateTeamName } = useContext(TeamContext);

  useEffect(() => {
    // console.log("userState data", userState);
  }, [userState]);

  return (
    <>
      <MainContainer>
        <AppFeatureListContainer>
          {loading && (
            <WatchParent1>
              <WatchParent>
                <Watch color="orange" height={50} width={50} />
              </WatchParent>
            </WatchParent1>
          )}

          <Formik
            enableReinitialize={true}
            initialValues={{
              id: userState.user?.id,
              firstName: userState.user?.firstName,
              lastName: userState.user?.lastName,
              email: userState.user?.email,
              timeZone: userState.user?.timeZone,
              mobileNumber: userState.user?.mobileNumber,
            }}
            validate={(values: User) => {
              const errors: {
                firstName?: string;
                lastName?: string;
              } = {};

              if (!values.firstName) {
                errors.firstName = "Required field first name";
              } else if (values.firstName && values.firstName.length < 1) {
                errors.firstName = "First name must be at least 1 characters";
              } else if (values.firstName && values.firstName.length > 50) {
                errors.firstName = "First name must be less than 50 characters";
              } else if (
                values.firstName &&
                !/^[a-zA-Z]+$/.test(values.firstName)
              ) {
                errors.firstName = "First name must be only letters";
              }

              if (!values.lastName) {
                errors.lastName = "Required field last name";
              } else if (values.lastName && values.lastName.length < 1) {
                errors.lastName = "last name must be at least 1 characters";
              } else if (values.lastName && values.lastName.length > 50) {
                errors.lastName = "last name must be less than 50 characters";
              } else if (
                values.lastName &&
                !/^[a-zA-Z]+$/.test(values.lastName)
              ) {
                errors.lastName = "last name must be only letters";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setLoading(true);
              myAxios
                .post("/userprofile", values)
                .then((response: AxiosResponse<User>) => {
                  const updatedUser = response.data;
                  userDispatch({
                    type: UPDATE_USER,
                    user: updatedUser,
                  });
                  NotificationManager.success("profile updated successfully");
                  setLoading(false);
                  setSubmitting(false);
                })
                .catch((error) => {
                  setLoading(false);
                  console.error("error in updating data", error);
                  setSubmitting(false);
                  NotificationManager.error(parseErrorResponse(error));
                });
            }}
          >
            {({ isSubmitting, setFieldValue, values, errors }) => (
              <Form>
                <DivForForm>
                  <Label>First Name</Label>
                  <InputField
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                  />
                  {errors.firstName && (
                    <NameError>{errors.firstName}</NameError>
                  )}
                  <Label>Last Name</Label>
                  <InputField
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                  />
                  {errors.lastName && <NameError>{errors.lastName}</NameError>}
                  <Label>Email ID </Label>

                  <InputField
                    disabled="true"
                    type="text"
                    placeholder="Email"
                    name="email"
                  />
                  <Label>Time Zone</Label>
                  <InputField
                    as="select"
                    value={values.timeZone ? values.timeZone : ""}
                    name="timeZone"
                    onChange={(e: any) => {
                      setFieldValue("timeZone", e.target.value);
                    }}
                  >
                    <option key={"empty"} value={""}>
                      Please Select
                    </option>
                    <option key={"America/New_York"} value={"America/New_York"}>
                      Eastern Time (GMT-5)
                    </option>
                    <option key={"America/Chicago"} value={"America/Chicago"}>
                      Central Time (GMT-6)
                    </option>
                    <option key={"America/Denver"} value={"America/Denver"}>
                      Mountain Standard Time (GMT-7)
                    </option>
                    <option
                      key={"America/Los_Angeles"}
                      value={"America/Los_Angeles"}
                    >
                      Pacific Standard Time (GMT-8)
                    </option>
                    <option
                      key={"America/Anchorage"}
                      value={"America/Anchorage"}
                    >
                      Alaska Standard Time (GMT-9)
                    </option>
                    <option key={"Asia/Calcutta"} value={"Asia/Calcutta"}>
                      Indian Standard Time (GMT+5:30)
                    </option>
                  </InputField>
                  <Label>Mobile Number</Label>

                  <InputField
                    type="text"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                  />
                </DivForForm>
                <DivForBtn>
                  <Button type="submit" disabled={isSubmitting}>
                    Update Profile
                  </Button>
                </DivForBtn>
              </Form>
            )}
          </Formik>
        </AppFeatureListContainer>

        {/* this is for company name */}
        <ContainerCompany>
          {loadingCompany && (
            <WatchParent1>
              <WatchParent>
                <Watch color="orange" height={50} width={50} />
              </WatchParent>
            </WatchParent1>
          )}

          <Formik
            enableReinitialize={true}
            initialValues={{
              companyname: userState.user?.companyTeam?.name
                ? userState.user?.companyTeam?.name
                : "",
            }}
            validate={(values: any) => {
              const errors: {
                companyname?: string;
              } = {};

              if (!values.companyname) {
                errors.companyname = "Required company name";
              } else if (values.companyname < 1) {
                errors.companyname =
                  "company name must be at least 1 characters";
              } else if (values.companyname && values.companyname.length > 50) {
                errors.companyname =
                  "company name  must be less than 50 characters";
              }

              return errors;
            }}
            onSubmit={(values: any) => {
              setLoadingCompany(true);
              if (!userState.user?.companyTeam) {
                myAxios
                  .post("teams", { name: values.companyname })
                  .then((responses) => {
                    NotificationManager.success(
                      "Company name created successfully!"
                    );

                    myAxios
                      .get(`userprofile`)
                      .then((response) => {
                        userDispatch({
                          type: LOGGED_IN,
                          user: { ...response.data },
                        });
                        localStorage.setItem(
                          "user",
                          JSON.stringify(response.data)
                        );
                        setLoadingCompany(false);
                        /* if (response.data.jwt) setAuthHeader(response.data.jwt);
            hideLoader(); */
                      })
                      .catch((error) => {
                        console.error(
                          error,
                          "error in getting templates tasks"
                        );
                        setLoadingCompany(false);
                      });
                  })
                  .catch((error) => {
                    NotificationManager.error(parseErrorResponse(error));
                    setLoadingCompany(false);
                  });
              } else {
                myAxios
                  .put(`teams/${userState.user?.companyTeam.id}`, {
                    name: values.companyname,
                  })
                  .then((responses) => {
                    // console.log("Team update response --- ", responses);
                    if (
                      userState.user &&
                      userState.user.companyTeam &&
                      userState.user.companyTeam.id
                    )
                      updateTeamName(
                        values.companyname,
                        userState.user?.companyTeam.id
                      );
                    // console.log("Just before dispatch update team");
                    userDispatch({
                      type: UPDATE_TEAM,
                      teamName: values.companyname,
                    });

                    NotificationManager.success(
                      "Company name updated successfully!"
                    );
                    setLoadingCompany(false);
                  })
                  .catch((error) => {
                    NotificationManager.error(parseErrorResponse(error));
                    setLoadingCompany(false);
                  });
              }
            }}
          >
            {({ setFieldValue, values, errors }) => (
              <Form>
                <CompanyForm>
                  <Label>Company Name</Label>
                  <InputField
                    type="text"
                    placeholder="Add company name"
                    name="companyname"
                  />
                  {errors.companyname && (
                    <CompanyError>{errors.companyname}</CompanyError>
                  )}
                </CompanyForm>
                <DivForBtn>
                  <Button type="submit" style={{ width: "50%" }}>
                    {!userState.user?.companyTeam?.name
                      ? "Add Company Name"
                      : "Update company name"}
                  </Button>
                </DivForBtn>
              </Form>
            )}
          </Formik>
        </ContainerCompany>
      </MainContainer>
    </>
  );
};

export default UserProfile;
