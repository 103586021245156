import { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { LOGGED_IN } from "../../consts";
import { UserContext } from "../../context/user-context";
import MagicLinkButton from "./magic-link-button";

import Logo from "../../images/logo.svg";
import OrLInes from "../../images/oricon.svg";

import { magic } from "../../magic";
import { setAuthHeader } from "../../utils/myaxios";

const DIV = styled.div`
  /* flex-basis:50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const LogoImg = styled.img`
  width: 123px;
  margin-top: 16px;

  @media (max-width: 1199px) and (min-width: 320px) {
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    width: 97px;
    height: 35px;
  }
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin: 11px 0;
  color: #fc6601;
  margin: 20px;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 0px;
    color: #fc6601;
  }
`;

const ThirdSpan = styled.span`
  width: 552px;
  width: 90%;
  margin: 0 33px;
  padding: 1px 0 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9b9b9b;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 251px;
    /* height: 15px; */
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #9b9b9b;
    text-align: center;
  }
`;

const SpanText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  color: #797979;
  width: 64%;
  margin-bottom: 33px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;
const DivSpanText = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonForCred = styled.button`
  width: 64%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #2f4c95;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #2f4c95;
  z-index: 11;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-top: 0px;
  }
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fc6601;
  margin-left: 7px;
  cursor: pointer;
`;
const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const LoginWithMagicLink = ({
  setToShowMagicLinkloginPage,
  setToShowSignupPage,
  setToShowForgetPassPage,
}: any) => {
  const { userDispatch } = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);
  const getUserDataFromServer = async (didToken: string) => {
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: didToken,
      }),
    });

    if (res.status === 200) {
      const data = await res.json();
      // Set the UserContext to the now logged in user
      let userMetadata = await magic.user.getMetadata();
      userDispatch({
        type: LOGGED_IN,
        user: { ...userMetadata, ...data },
      });

      if (data.jwt) setAuthHeader(data.jwt);

      return true;
    }

    return false;
  };
  async function handleLoginWithEmail(email: string) {
    try {
      setDisabled(true); // disable login button to prevent multiple emails from being triggered

      // Trigger Magic link to be sent to user

      const didToken = await magic.auth.loginWithMagicLink({
        email,
        showUI: true,
        redirectURI: `${process.env.REACT_APP_FRONTEND_URL}/callback`,
      });

      if (didToken) {
        getUserDataFromServer(didToken).then((res) => {});
      }

      // Validate didToken with server
    } catch (error) {
      setDisabled(false); // re-enable login button - user may have requested to edit their email
    }
  }
  return (
    <>
      <Container>
        <Row>
          <DIV>
            <LogoImg src={Logo} alt="logo" />

            <DivForPara>
              <LoginPara>Magic Link</LoginPara>
              {/* <SecondSpan>welcome to jetlist</SecondSpan> */}
              <ThirdSpan>
                We will send you a link on email that you can click to login
                directly (no password needed)
              </ThirdSpan>
            </DivForPara>
          </DIV>
          {/* <CenterDiv> */}
          <MagicLinkButton
            disabled={disabled}
            onEmailSubmit={handleLoginWithEmail}
          />
          {/* </CenterDiv> */}
          <DivSpanText>
            <SpanText>
              We will send you a link on email that you can click to login
              directly (no password needed)
            </SpanText>
          </DivSpanText>

          {/* <DivForLines>
            <SpanRIghtLine></SpanRIghtLine>
            <SpanForOr>Or</SpanForOr>
            <SpanLeftLine></SpanLeftLine>
          </DivForLines> */}
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>

          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                try {
                  setToShowMagicLinkloginPage(false);
                  setToShowForgetPassPage(false);
                } catch {}
              }}
            >
              Login With Credentials
            </ButtonForCred>
          </DivForLoginWithCredintial>

          <BottomDiv>
            <SpanAccount>Don't have an account |</SpanAccount>
            <SpanSignup
              onClick={() => {
                try {
                  setToShowSignupPage(true);
                  setToShowMagicLinkloginPage(false);
                } catch {}
              }}
            >
              SIGNUP
            </SpanSignup>
          </BottomDiv>
        </Row>
      </Container>
    </>
  );
};

export default LoginWithMagicLink;
