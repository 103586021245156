import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export const setAuthHeader = (authToken: string) => {
  if (authToken && authToken.length > 0)
    instance.defaults.headers.common["Authorization"] = "Bearer " + authToken;
    else
    instance.defaults.headers.common["Authorization"] = "";
};

export default instance;
