import styled from "styled-components";
import { Watch } from "react-loader-spinner";

const LoaderContainer = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  cursor: pointer;
`;

const WatchParent = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Text = styled.span`
  color: white;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin-top: 50px;
  font-size: 20px;
`;

type LoaderProps = {
  text?: string | null;
};  

const Loader = ({ text }: LoaderProps) => {
  return (
    <LoaderContainer>
      <WatchParent>
        <Watch color="orange" height={50} width={50} />
      </WatchParent>
      {text && <Text>{text}</Text>}
    </LoaderContainer>
  );
};

export default Loader;