import styled from "styled-components";
import { Formik, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import myAxios from "../../utils/myaxios";
import { Team } from "../../types";
import { Watch } from "react-loader-spinner";
import { UserProfileContext } from "../../context/user-profile-context";
const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #2f4c95;
`;

const DivForForm = styled.div`
  width: 80%;
  margin-top: 20px;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 94%;
    margin: 10px;
    padding: 10px;
    border: none;
  }
`;
const ParentRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 62px;
`;
const TeamList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 14px;
`;
const WatchParent = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;
const LabelSpan = styled.span`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-left: 7px;
`;
const Label = styled.label`
  cursor: pointer;
`;
const Radio = styled.input`
  cursor: pointer;
`;
const Preference = () => {
  const { userTeams, setSelectedTeam } = useContext(TeamContext);
  const { userState } = useContext(UserContext);
  const { preferenceData, setPreferenceData } = useContext(UserProfileContext);
  // const [preferenceData, setPreferenceData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const updatePersonalTeamPreferences = (
    isPersonal: any,
    selectedTeamId: any
  ) => {
    setLoading(true);
    myAxios
      .post(`/personal-team-preference`, {
        isPersonal,
        selectedTeamId,
      })
      .then((response: any) => {
        setPreferenceData(response.data.data);

        if (response.data.data.isPersonal) {
          setSelectedTeam(userTeams![0]);
        } else {
          setSelectedTeam(response.data.data.team);
        }

        setLoading(false);
        // console.log("respone post :--", response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error post:---", error);
      });
  };

  useEffect(() => {
    setLoading(true);
    myAxios
      .get(`/get-prefrences-personal-team`)
      .then((response) => {
        // console.log("response from get api", response.data.data);
        setPreferenceData(response.data.data);

        if (response.data.data.isPersonal) {
          setSelectedTeam(userTeams![0]);
        } else {
          setSelectedTeam(response.data.data.team);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error(error, "error in getting teams");
        setLoading(false);
      });
  }, [userState, setPreferenceData]);

  return (
    <>
      <DivForForm>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}
        <HeadingText>prefrences</HeadingText>

        <Formik
          initialValues={{
            preference:
              preferenceData && preferenceData.isPersonal ? "personal" : "team",
            selectedTeamId:
              preferenceData && preferenceData.team && preferenceData.team.id
                ? preferenceData.team.id
                : undefined,
          }}
          validate={(values) => {
            const errors = {};

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {}}
          enableReinitialize
        >
          {({ isSubmitting, values, handleChange, setFieldValue }) => (
            <Form>
              <ParentRadio>
                <div>
                  <Label>
                    <Radio
                      type="radio"
                      name="preference"
                      value="personal"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("preference", "personal");
                        setFieldValue("selectedTeamId", "");
                        updatePersonalTeamPreferences(true, undefined);
                        console.log("preferenceData", preferenceData);
                      }}
                      checked={values.preference === "personal"}
                    />
                    <LabelSpan>Personal</LabelSpan>
                  </Label>
                </div>
                <div>
                  <Label>
                    <Radio
                      type="radio"
                      name="preference"
                      value="team"
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("preference", "team");
                        // updatePersonalTeamPreferences(false, undefined);
                        if (userTeams && userTeams.length === 2) {
                          updatePersonalTeamPreferences(false, userTeams[1].id);
                          console.log("if", userTeams[1]);
                        } else {
                          updatePersonalTeamPreferences(false, undefined);
                          console.log("else else", userTeams![1]);
                        }
                      }}
                      checked={values.preference === "team"}
                    />
                    <LabelSpan>Team</LabelSpan>
                  </Label>
                </div>
              </ParentRadio>
              {values.preference === "team" &&
                userTeams &&
                userTeams.length >= 2 && (
                  <TeamList>
                    {/* {userTeams?.map(
                    (team: Team) =>
                      // team.name !== userState.user?.email && (
                      team.type !== "individual" && (
                        <div key={team.id}>
                          <input
                            type="radio"
                            name="selectedTeam"
                            value={team.id}
                            checked={values.selectedTeamId === team.id}
                            onChange={() => {
                              setFieldValue("selectedTeamId", team.id);
                              handleSubmitApi(false, team.id);
                            }}
                          />{" "}
                          <span>{team.name}</span>
                        </div>
                      )
                  )} */}

                    <select
                      className="preference-dropdown"
                      value={values.selectedTeamId}
                      onChange={(e) => {
                        let teamId = e.target.value;
                        setFieldValue("selectedTeamId", teamId);
                        updatePersonalTeamPreferences(false, teamId);
                        console.log("teamId", teamId);
                      }}
                    >
                      {userTeams?.map(
                        (team: Team, index: number) =>
                          team.type === "company" && (
                            <option
                              key={team.id}
                              value={team.id}
                              // selected={values.selectedTeamId === team.id}
                            >
                              {team.name}
                            </option>
                          )
                      )}
                    </select>
                  </TeamList>
                )}
            </Form>
          )}
        </Formik>
      </DivForForm>
    </>
  );
};

export default Preference;
