import { MonochromeIcons } from "@magiclabs/ui"; // removed Input , CallToAction
import { useState } from "react";
import styled from "styled-components";
import MagicArrowBtn from "../../images/MagicArrow.svg";
import MagicBlue from "../../images/magicblue.svg";
import Spinner from "../../images/spinner.svg";

const MainDiv = styled.div``;

const TheForm = styled.form``;

const DivForInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 64%;
  height: 41px;
  padding: 10px;
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 33px;
    font-weight: 400;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;

    &::-webkit-input-placeholder {
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
    }
  }
`;
const DivForBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
`;
const BtnPara = styled.span`
  width: 178px;
  height: 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  /* margin-top: 8px; */

  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 4px;
  }
`;

const MagicImg = styled.img`
  display: ${(props) => (props.className === "white" ? "block" : "none")};
`;

const CallToAction = styled.button`
  width: 64%;
  height: 50px;
  color: white;
  background: #2f4c95;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const DivForBtnParas = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    justify-content: center;
  }
`;

const SpinnerImg = styled.img`
  height: 35px;
`;

const MagicLinkButton = ({ onEmailSubmit, disabled }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    onEmailSubmit(email);
  };

  return (
    <>
      <MainDiv>
        <TheForm onSubmit={handleSubmit}>
          <DivForInput>
            <Input
              placeholder="Enter your email"
              size="sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DivForInput>
          <DivForBtn>
            <CallToAction
              leadingIcon={MonochromeIcons.PaperPlane}
              color="primary"
              size="sm"
              disabled={disabled}
              onClick={handleSubmit}
            >
              <DivForBtnParas>
                <MagicImg className="white" src={MagicArrowBtn} alt="magic" />
                <MagicImg className="blue" src={MagicBlue} alt="magic" />
                <BtnPara> Send Magic Link</BtnPara>
                {disabled && <SpinnerImg src={Spinner} alt="Loading" />}
              </DivForBtnParas>
            </CallToAction>
          </DivForBtn>
        </TheForm>
      </MainDiv>
    </>
  );
};

export default MagicLinkButton;
