const UrlToLink = ({ text }: { text: string }) => {
  if (!text) return null;

  const imgSource = text.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
  const aSource = text.match(/<a [^>]*href="[^"]*"[^>]*>/gm);
  const documentSource = text.match(/<document [^>]*href="[^"]*"[^>]*>/gm);
  // console.log('imgSource', imgSource)
  const videoSource = text.match(/<iframe [^>]*src="[^"]*"[^>]*>/gm);
  let urlRegex;

  if (documentSource) {
    text = text.replaceAll("<document", "<a");
    text = text.replaceAll("document>", "a>");
  }

  if (!imgSource && !videoSource && !aSource && !documentSource) {
    urlRegex =
      // eslint-disable-next-line
      /(\b(https?|www|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  }

  let replacedText = text;
  if (urlRegex) {
    replacedText = text.replace(
      urlRegex,
      '<a target="_blank" href="$1">$1</a>'
    );
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: replacedText,
        }}
      ></div>
    </>
  );
};

export default UrlToLink;
