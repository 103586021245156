import { SearchCriteria, SubTask, User } from "../types";

export const isValidEmail = (email: string) => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const oldRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  if (regx.test(email)) {
    return true;
  }
  return false;
};

export const isValidInHandoverOrNot = (email: string) => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (regx.test(email)) {
    return true;
  }
  return false;
};

export const getFullName = (user: User | undefined) => {
  if (!user) return null;

  if (user.firstName && user.lastName)
    return user.firstName + " " + user.lastName;
  else if (user.firstName) return user.firstName;
  else if (user.lastName) return user.lastName;
  else return null;
};

export const parseTags = (text: string) => {
  if (!text) return {};
  let detail = text;
  let tags: SearchCriteria = {};
  const toTagIdx = detail.toLowerCase().indexOf("to:");

  if (toTagIdx > -1) {
    let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx);
    if (toTagSpaceIdx !== -1) {
      tags.toEmail = detail.substring(toTagIdx + 3, toTagSpaceIdx);
      detail = detail.substring(0, toTagIdx) + detail.substring(toTagSpaceIdx);
    } else {
      // tag is present till the end
      tags.toEmail = detail.substring(toTagIdx + 3);
      detail = detail.substring(0, toTagIdx);
    }
  }

  const fromTagIdx = detail.toLowerCase().indexOf("from:");

  if (fromTagIdx > -1) {
    let fromTagSpaceIdx = detail.toLowerCase().indexOf(" ", fromTagIdx);
    if (fromTagSpaceIdx !== -1) {
      tags.fromEmail = detail.substring(fromTagIdx + 5, fromTagSpaceIdx);
      detail =
        detail.substring(0, fromTagIdx) + detail.substring(fromTagSpaceIdx);
    } else {
      // tag is present till the end
      tags.fromEmail = detail.substring(fromTagIdx + 5);
      detail = detail.substring(0, fromTagIdx);
    }
  }

  const groupTagIdx = detail.toLowerCase().indexOf("group:");

  if (groupTagIdx > -1) {
    let groupTagSpaceIdx = detail.toLowerCase().indexOf(" ", groupTagIdx);
    if (groupTagSpaceIdx !== -1) {
      tags.groupEmails = detail.substring(groupTagIdx + 6, groupTagSpaceIdx);
      detail =
        detail.substring(0, groupTagIdx) + detail.substring(groupTagSpaceIdx);
    } else {
      // tag is present till the end
      tags.groupEmails = detail.substring(groupTagIdx + 6);
      detail = detail.substring(0, groupTagIdx);
    }
  }

  const dueTagIdx = detail.toLowerCase().indexOf("due:");

  if (dueTagIdx > -1) {
    let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx);
    if (dueTagSpaceIdx !== -1) {
      const dueDays = parseInt(detail.substring(dueTagIdx + 4, dueTagSpaceIdx));
      if (!isNaN(dueDays)) tags.due = dueDays;
      detail =
        detail.substring(0, dueTagIdx) + detail.substring(dueTagSpaceIdx);
    } else {
      // tag is present till the end
      const dueDays = parseInt(detail.substring(dueTagIdx + 4));
      if (!isNaN(dueDays)) tags.due = dueDays;
      detail = detail.substring(0, dueTagIdx);
    }
  }

  const statusTagIdx = detail.toLowerCase().indexOf("s:");

  if (statusTagIdx > -1) {
    let statusTagSpaceIdx = detail.toLowerCase().indexOf(" ", statusTagIdx);
    if (statusTagSpaceIdx !== -1) {
      const status = parseInt(
        detail.substring(statusTagIdx + 2, statusTagSpaceIdx)
      );
      if (!isNaN(status)) tags.status = status;
      detail =
        detail.substring(0, statusTagIdx) + detail.substring(statusTagSpaceIdx);
    } else {
      // tag is present till the end
      const status = parseInt(detail.substring(statusTagIdx + 2));
      if (!isNaN(status)) tags.status = status;
      detail = detail.substring(0, statusTagIdx);
    }
  }

  const priorityTagIdx = detail.toLowerCase().indexOf("p:");

  if (priorityTagIdx > -1) {
    let priorityTagSpaceIdx = detail.toLowerCase().indexOf(" ", priorityTagIdx);
    if (priorityTagSpaceIdx !== -1) {
      const priority = parseInt(
        detail.substring(priorityTagIdx + 2, priorityTagSpaceIdx)
      );
      if (!isNaN(priority)) tags.priority = priority;
      detail =
        detail.substring(0, priorityTagIdx) +
        detail.substring(priorityTagSpaceIdx);
    } else {
      // tag is present till the end
      const priority = parseInt(detail.substring(priorityTagIdx + 2));
      if (!isNaN(priority)) tags.priority = priority;
      detail = detail.substring(0, priorityTagIdx);
    }
  }

  let categories = [];

  let hashTagIdx = detail.indexOf("#");
  while (hashTagIdx > -1) {
    let hashTagSpaceIdx = detail.indexOf(" ", hashTagIdx);

    if (hashTagSpaceIdx > -1) {
      categories.push(detail.substring(hashTagIdx + 1, hashTagSpaceIdx));
      detail =
        detail.substring(0, hashTagIdx) + detail.substring(hashTagSpaceIdx + 1);
    } else {
      categories.push(detail.substring(hashTagIdx + 1));
      detail = detail.substring(0, hashTagIdx);
    }

    hashTagIdx = detail.indexOf("#");
  }

  tags.categories = categories;
  tags.task = detail.trim();

  return tags;
};

export const cloneSubTasks = (subTasks: any) => {
  let cloneSubTasks: any[] = [];
  subTasks.forEach((subTask: any) => {
    cloneSubTasks.push({
      _id: subTask._id,
      name: subTask.name,
      description: subTask.description,
      isCompleted: subTask.isCompleted,
      order: subTask.order,
    } as SubTask);
  });

  return cloneSubTasks as [SubTask];
};

export const cloneObject: any = (source: any) => {
  /* if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy; */
  if (Object.prototype.toString.call(source) === "[object Array]") {
    const clone = [];
    for (var i = 0; i < source.length; i++) {
      clone[i] = cloneObject(source[i]);
    }
    return clone;
  } else if (typeof source == "object") {
    const clone: any = {};
    for (var prop in source) {
      if (source.hasOwnProperty(prop)) {
        clone[prop] = cloneObject(source[prop]);
      }
    }
    return clone;
  } else {
    return source;
  }
};

export const removeLineBreakAtStart = (text: string) => {
  let flag = true;
  let replacedText = text.trim();
  while (flag) {
    const lineBreakIndex = replacedText.indexOf("\n");
    if (lineBreakIndex === 0) {
      replacedText = replacedText.replace("\n", "");
      replacedText = replacedText.trim();
    } else {
      flag = false;
    }
  }
  return replacedText;
};

export const extractTextContent = (html: any) => {
  var span = document.createElement("span");
  span.innerHTML = html;
  return span.textContent || span.innerText;
};
