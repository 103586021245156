import { AxiosResponse } from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { setTimeout } from "timers";
import { TaskContext } from "../../context/task-context";
import { TeamContext } from "../../context/team-context";
import { UserContext } from "../../context/user-context";
import {
  USER_COMPLETE_TASK_EVENTS,
  USER_COPY_DUPLICATE_A_TASK_EVENTS,
  USER_START_A_TASK_EVENTS,
  USER_TURN_OF_TASK_NOTIFICATION_EVENTS,
  USER_USE_HAND_OVER_FEATURE_EVENTS,
  USER_USE_NUDGE_FEATURE_EVENTS,
  USER_USE_PERCENT_COMPLETE_TO_MOVE_PROGRESS_OF_TASK_EVENTS,
} from "../../customer-io/events";
import CommentGreyIcon from "../../images/commentGrey.svg";
import CommentRedIcon from "../../images/commentRedIcon.svg";
import CopyTaskHoverImageIcon from "../../images/copy-task-hover.svg";
import CopyTaskImageIcon from "../../images/copy-task-normal.svg";
import CrosshandOffBox from "../../images/cross.svg";
import DeleteTaskHoverImageIcon from "../../images/delete-task-hover.svg";
import DeleteTaskImageIcon from "../../images/delete-task-normal.svg";
import NudgeDisabledIcon from "../../images/disable-nudge.svg";
import Eye from "../../images/eye.svg";
import HandOffImg from "../../images/handoff.svg";
import MoreOptionOrange from "../../images/more-vertical-orange.svg";
import ThreeDot from "../../images/more-vertical.svg";
import NudgeIconForView from "../../images/nudgeIcon.svg";
import NudgeBlueIcon from "../../images/nudgeIconBlue.svg";
import NudgeRedIcon from "../../images/nudgeIconRed.svg";
import RecurringIcon from "../../images/recurring.svg";
import SendEMail from "../../images/send.svg";
import ShareIcon from "../../images/shareicon.svg";
import SubTaskIndicatorSvg from "../../images/subtask-indicator.svg";
import ToggleOffImg from "../../images/toggleoff.svg";
import ToggleOnImg from "../../images/toggleon.svg";
import TrashTaskIcon from "../../images/trash.svg";
import { SubTask, Task, TaskNudge } from "../../types";
import {
  USETIFULL_EMAIL_NOTIFICATION_PER_TASKS,
  USETIFULL_NUDGE_ACTIONS,
  USETIFULL_PERCENT_TASK_COMPLETE,
  USETIFULL_SHARE_TASK_URL,
  USETIFULL_TASK_COMMENT_INDICATOR,
  USETIFULL_TASK_DELETION,
  USETIFULL_TASK_DUPLICATION,
  USETIFULL_TASK_HANDOVER_ACTION,
  USETIFULL_TASK_NUDGE_COUNT,
  USETIFULL_VIEW_DELETED_TASK,
} from "../../usetifull/class-names";
import myAxios from "../../utils/myaxios";
import useDebounce from "../../utils/use-debounce";
import { isValidInHandoverOrNot } from "../../utils/utils";
import Pagination from "../pagination/pagination";
import UpdateRecurringTaskModel from "../recurring-task/update-recurring-task-model";
import DisabledTaskListItem from "../task/disabled-task-list-item";
import ConfirmationBox from "../utils/confirmation-box";

import {
  parseErrorResponse,
  removeUnwantedTagsForToolTip,
} from "../utils/utills";
declare var _cio: any;
interface SidebarWidth {
  isOpen?: boolean;
  showModel?: boolean;
}

const TrashRowContainer = styled(Container)`
  width: 1035px;
  margin-bottom: 20px;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "21%" : "auto")};

  position: ${(props: SidebarWidth) => (props.showModel ? "fixed" : "intial")};

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 97%;

    margin-left: auto;
  }
`;

const TaskContainer = styled(Container)`
  width: 953px;
  background: #f6f9ff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  align-items: center;
  /* margin-left: auto;
  margin-right: auto; */
  margin-bottom: 20px;

  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "23%" : "auto")};

  /* transform: scale(0.9);
  transition: opacity 100ms ease-in-out 0ms, transform 100ms; */

  position: ${(props: SidebarWidth) => (props.showModel ? "fixed" : "intial")};
  &:hover {
    border: 0px solid #c1c9df;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.24);
    /* background-size: 100% 2px; */
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 97%;

    margin-left: auto;
    /* height: 100px; */
    min-height: 100px;
  }
`;

const ParentRow = styled(Row)`
  padding: 8px;
  position: relative;

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0px;
  }
`;

const FirstRow = styled(Row)`
  padding-bottom: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
`;

const TaskDetail = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
    margin-top: 7px;
    cursor: pointer;
    min-height: 18px;
    /* max-width: 100%;
  min-height: 33px; */
  }
`;

const Detail = styled.p`
  color: #2f4c95;
  font-size: 16px;
  line-height: 33px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding-top: 6px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0;
  }
`;
const SpanText = styled.span`
  cursor: pointer;
`;
const Watcher = styled(Col).attrs({
  md: 1,
})``;

const IMGTag = styled.img`
  display: ${(props) => (props.className === "Blue" ? "block" : "none")};
  width: 25px;
  height: 24px;
`;

const Dots = styled(Col)`
  cursor: pointer;

  &:hover img.Blue {
    display: none;
  }
  &:hover img.Orange {
    display: block;
    width: 25px;
    height: 24px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    align-self: right;
    display: flex;
    justify-content: flex-end;

    & img {
      /* width: 19px; */
    }
  }
`;

const ColCatgory = styled(Col)`
  color: #fc6601;
  font-size: 14px;
  line-height: 16.5px;
  font-weight: 600;
  margin-top: 3px;
  /* flex-basis: 43%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid; */

  @media (max-width: 1199px) and (min-width: 320px) {
    color: #fc6601;
    font-size: 11px;
    line-height: 16.5px;
    font-weight: 500;
    /* flex-basis: 10%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-basis: 36%;
    flex: 1;
  }
`;

const SecondRow = styled(Row)`
  align-items: center;
  width: 100%;
`;
const ThirdRow = styled(Row)`
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1199px) and (min-width: 320px) {
    justify-content: space-around;
  }
`;

const TaskText = styled(Col)`
  color: #2f4c95;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  flex-shrink: 0;
  /* flex-grow: 0; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 8px;
    line-height: 14px;
    margin-top: 3px;
  }
`;

const UserEmail = styled(TaskText)`
  flex-basis: 30%;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 9px;
    line-height: 14px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const GroupDiv = styled.div`
  flex-basis: 30%;
  cursor: pointer;
  color: #2f4c95;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  &:hover {
    color: blue;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 9px;
    line-height: 14px;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const TimeLeft = styled(TaskText)`
  flex-basis: 14%;
  @media (max-width: 1199px) and (min-width: 320px) {
    flex-basis: 25%;

    font-size: 9px;
    line-height: 14px;
    /* margin-top: 3px; */
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const ProgressContainer = styled(Col)`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  cursor: pointer;
  padding: 8px 0;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* justify-content: flex-end; */
  }
`;

const ColColorBlue = styled.div`
  background: #2f4c95;
  width: 20px;
  height: 20px;
  border: 2px solid #2f4c95;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 12px;
    height: 12px;
  }
`;

const ColColor = styled.div`
  background: #ffe6cc;
  width: 20px;
  height: 20px;
  border: 2px solid #ffe6cc;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background: #2f4c95;
    border: 2px solid #2f4c95;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 12px;
    height: 12px;
  }
`;
const DivPriority = styled.div`
  height: 23px;
  width: 50px;
  background: ${(props) => (props.color ? props.color : "none")};
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 35px;
    height: 18px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 6px;
  }
`;

const EyeDiv = styled(Col)`
  width: auto;
  /* padding-right: 10px; */

  & img {
    width: 24px;
  }
`;
const DivForComment = styled.div`
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    position: relative;
  }
`;

const ToggleBtnOn = styled.img`
  cursor: pointer;
  height: 25px;
  transition-property: width;
  transition-duration: 2s;
  transition-delay: 2s;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 39px;
    justify-content: flex-end;
  }
`;

const DeleteTaskImg = styled.img`
  cursor: pointer;
  margin-left: 15px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 20px;
  }
`;

const CopyTaskImg = styled.img`
  cursor: pointer;
  margin-left: 15px;

  @media (max-width: 450px) and (min-width: 320px) {
    width: 20px;
  }
`;

const HandOffBtn = styled.img`
  width: 55px;
  height: 25px;
  background: #2f4c95;
  border-radius: 50px;
  cursor: pointer;
  background: #2f4c95;
  border-radius: 50px;
  cursor: pointer;
  /* margin-left: 8px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 39px;
    height: auto;
    margin-left: 4px;
  }
`;

const HandOffPopUpDiv = styled.div`
  position: absolute;
  z-index: 9;
  width: 400px;
  /* left: 0; */
  padding: 10px;
  top: 44px;
  left: 21px;
  background: #ffe6cc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 300px;
    height: auto;
    left: 14%;
    top: 39px;
  }
`;

const SpanForHandoff = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #3c3b5b;
  & select {
    width: 200px;
  }
  & span {
    font-size: 13px;
    margin: 10px 0px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    line-height: 19px;

    & span {
      font-size: 10px;
      margin: 10px 0px;
    }
  }
`;

const EmailInputBox = styled.input`
  width: 325px;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  /* margin-top: 5px; */
  padding: 10px;

  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 20px;
    @media (max-width: 1199px) and (min-width: 320px) {
      font-size: 12px;
    }
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 233px;
    height: 33px;
  }
`;

const SendEmailBtn = styled.img`
  width: 44px;
  height: 44px;
  background: #2f4c95;
  border-radius: 50px;
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 32px;
    height: 32px;
  }
`;

const CrossBTN = styled.img`
  display: block;
  width: 25px;
  height: 23px;
  position: absolute;
  cursor: pointer;
  top: -8%;
  left: 96%;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 94%; */
    width: 20px;
    height: 23px;
  }
`;
const CrossBTNForNudge = styled.img`
  /* display: block; */
  width: 25px;
  height: 23px;
  position: absolute;
  cursor: pointer;
  /* right: 0;
  top: 0; */
  right: 96%;
  top: -12px;
  @media (max-width: 1199px) and (min-width: 320px) {
    right: -7px;
    width: 20px;
    height: 23px;
  }
`;

const GroupUsersList = styled.div`
  width: auto;
  height: auto;
  background: #ffe6cc;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  border: 2px solid #ced7ef;
  left: 33.5%;
  padding: 11px;
  top: 88%;
  z-index: 1111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: auto;
    height: auto;
    background: #ffe6cc;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    border: 2px solid #ced7ef;
    left: 24%;
    z-index: 1111;
  }
`;

const SpanForName = styled.span`
  /* color: rgba(60, 59, 91, 0.29); */
  /* font-family: "Poppins"; */
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  /* padding-left: 20px; */
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;

const DivForNudgeNotifications = styled.div`
  width: 297px;
  height: auto;
  padding: 11px;
  background: #ffffff;
  border: 1px solid #ffe6cc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  left: 72.1%;
  top: 40%;
  z-index: 1111;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 33%; */
    left: 41%;
    top: 26%;
    width: 194px;
    height: 187px;
  }
`;
const DivForNudgeNotificationsDisabled = styled.div`
  width: 307px;
  height: auto;

  background: white;
  border: 1px solid #ffe6cc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  left: 72.1%;
  top: 40%;
  z-index: 1111;
  padding: 11px;
  @media (max-width: 1199px) and (min-width: 320px) {
    left: 41%;
    top: 26%;
    width: 194px;
    height: 187px;
  }
`;

const CommentRedTag = styled.img`
  width: auto;
  height: 20px;
  padding-right: 8px;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 42px;
    cursor: pointer;
  }
`;

const RecurringImgTag = styled.img`
  width: 50px;
  height: 22px;
  cursor: pointer;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 22px;
    cursor: pointer;
  }
`;

const CommentSpanCount = styled.span`
  color: #f6f9ff;
  position: absolute;
  left: 41%;
  bottom: 50%;
  border-radius: 62%;
  border: 2px solid #f6f9ff;
  width: 18px;
  height: 18px;
  background: #fc6601;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    top: -6px;
    height: 18px;
    width: 18px;
    font-size: 10px;
    left: 12px;
    cursor: pointer;
  }
`;

const CommentGreyTag = styled.img`
  width: auto;
  height: 20px;
  cursor: pointer;
  padding-right: 8px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 42px;
    cursor: pointer;
  }
`;

const NudgeNotificationImg = styled.img`
  height: 22px;
  cursor: pointer;
  width: 46px;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 49px;
  }
`;

const DivReceivedNotification = styled.div`
  width: 365px;
  min-height: 1px;
  max-height: 204px;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: absolute;
  left: 58.3%;
  top: 48%;
  z-index: 1111;
  /* overflow-y: scroll; */

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 213px;
    max-height: 175px;
    left: 40%;
    min-height: 1px;
    border-radius: 5px;
    top: 23%;
  }
`;

const SendNudgeImgTag = styled.img`
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 15px;
  }
`;

const SendNudgeSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2f4c95;
  padding-left: 11px;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;
const DisabledSendNudgeSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #dbdbdb;
  padding-left: 11px;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }
`;

const SendNudgePara = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: rgba(60, 59, 91, 0.5);
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
  }
`;
const DisabledSendNudgePara = styled.span`
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #dbdbdb;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
  }
`;

const HorizonLine = styled.hr`
  width: 265px;
  border: 1px solid rgba(60, 59, 91, 0.1);
  margin: 7px 12px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 170px;
  }
`;
const HorizonLineForRecieved = styled.hr`
  width: 324px;
  border: 1px solid rgba(60, 59, 91, 0.1);
  margin: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 189px;
  }
`;

const ReceivedNudgeImgTag = styled.img`
  width: 20px;
  height: 20px;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 14px;
  }
`;
const ReceivedNudgeUserName = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3c3b5b;
  padding-left: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
    /* padding-left: 26px; */
  }
`;
const Time = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(60, 59, 91, 0.5);
  padding-left: 12px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
  }
`;
const ReceivedNudgePara = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: rgba(60, 59, 91, 0.5);
  /* margin-left: 26px; */
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
    /* margin: 0; */
  }
`;
const DivForNudegeNotificationIcon = styled(Col)`
  position: relative;
  width: 46px;
`;
const DivCountNudgeReceived = styled.span`
  position: absolute;
  padding: 2px 1px;
  font-size: 9px;
  text-align: center;
  height: 18px;
  width: 18px;
  font-weight: 600;
  border: 2px solid #f6f9ff;
  background: #fc6601;
  border-radius: 50%;
  display: inline-block;
  color: white;
  right: 30.7%;
  z-index: 111;
  bottom: 83.2%;
  top: -7px;
  left: 25px;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    width: 17px;
    height: 17px;
    /* top: -5px; */
    left: 27px;
    padding: 0;
  }
`;

const SpanTag = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2f4c95;
  cursor: pointer;
  margin-top: 7px;

  &:hover {
    color: #fc6601;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
    /* margin: 0; */
  }
`;

const FirstCol = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 1px;
    max-width: 6px;
  }
`;
const SecondCol = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 6px;
    max-width: 161px;
  }
`;

const ShareIconimgTag = styled.img`
  width: 44px;
  height: 22px;
  cursor: pointer;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 18px;
  }
`;

const ShareURLDIV = styled.div`
  width: 163px;
  height: 39px;
  background: #ffe6cc;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
  position: absolute;
  border: 2px solid #ced7ef;
  padding: 6px;
  z-index: 11111;
  text-align: center;
  top: 33px;
  /* left: 0%; */
  right: -52px;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 20%; */
    width: 110px;
    height: 29px;
    padding: 0px;
    border-radius: 5px;
  }
`;

const SpanURL = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2f4c95;
  display: inline-block;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 11px;
  }
`;

const MainRow = styled(Row)`
  color: red;
  min-height: 1px;
  max-height: 204px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 1199px) and (min-width: 320px) {
    max-height: 158px;
  }
`;

const ShareIconMainDiv = styled(Col)`
  position: relative;
  width: 54px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 26px;
  }
`;
const SubTaskIndicatorImgTag = styled.img`
  padding: 0 10px;
`;

const DivForIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
  position: absolute;
  right: 12px;
  align-items: center;
  padding: 0;
  @media (max-width: 1199px) and (min-width: 320px) {
  }
`;

const TaskAndDescriHoverList = styled.div`
  width: auto;
  max-width: 100%;
  /* height: 100%; */
  background: #ffe6cc;
  border: 2px solid #ced7ef;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  padding: 0 11px;
  left: 11px;
  /* top: 38px; */
  /* bottom: 120px; */
  z-index: 11111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    /* height: auto; */
    background: #ffe6cc;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    border: 2px solid #ced7ef;
    left: 0;
    z-index: 1111;
  }
`;

const SpanTasks = styled.span`
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  /* & p { */
  white-space: normal;
  /* } */
`;
const SpanDescription = styled.span`
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  white-space: normal;
`;

const WatchParent = styled.div`
  left: 50%;
  top: 70%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;

const MiddleRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const ColHandOff = styled(Col)`
  width: auto;
`;
const ColToggleBtn = styled(Col)`
  width: auto;
`;

const ColForMobileViewPriority = styled(Col)`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex: 0;
`;

const RowMobileProgressBar = styled(Row)`
  background: #c1c7d73b;
  box-shadow: 0px 10px 10px rgba(218, 225, 241, 0.5);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  display: flex;
  margin: 4px 0px 0px;
`;

const ProgressCountSpanMobile = styled.span`
  width: 21px;
  height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #2f4c95;
`;
const SpanErrorHandOver = styled.span`
  width: 21px;
  height: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #df5c04;
`;
const PaginationMainDiv = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0;
  border-radius: 4px;
  align-items: center;
  margin: 3% 0px;
  @media (min-width: 320px) and (max-width: 1199px) {
    margin-bottom: 5rem;
  }
`;
const TrashDiv = styled.div`
  color: #2f4c95;
  padding: 3px 0;
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 1199px) {
    font-size: 12px;
    padding: 5px 0;
    /* margin: 0; */
  }
`;

const TrashItemUl = styled.ul`
  list-style-type: none;
`;

const TrashItemLi = styled.li`
  display: inline-block;
  padding-right: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2f4c95;
`;
const UpdateNotificationDiv = styled.div`
  display: flex;
  /* padding-right: 10px; */
  /* padding-bottom: 4%; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: green;
  /* margin-top: -22px; */
  z-index: 11111;
  /* position:absolute; */

  justify-content: start;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "23%" : "auto")};
`;

const ProgressLabel = styled.div`
  width: 158px;
  height: 30px;
  color: #dcdcdc;
  background: #909090;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  border-radius: 3px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
  position: absolute;
  /* border: 2px solid #ced7ef; */
  padding: 6px;
  /* left: -68px; */
  top: 45px;
  z-index: 11111;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    left: -49px;
    width: 110px;
    height: 29px;
    padding: 0px;
    border-radius: 5px;
  }
`;

const TaskList = ({ isOpen }: any) => {
  const {
    tasks,
    setSelectedTask,
    addTasks,
    setModel,
    showModel,
    updateTask,
    setToolTip,
    tabSelected,
    archivedChecked,
    criteria,
    searchCriteria,
    currentPaginationTaskInput,
    setCurrentPaginationTaskInput,
    taskCount,
    setTaskCount,
    pageLimit,
    setPageLimit,
    addAssignee,
    addAssignor,
    addDayFilters,
    addCategories,
    setCurrentTaskNumber,
    setCopyTask,
    setTaskCopyClick,
    setTabSelected,
    setArchivedChecked,
  } = useContext(TaskContext);
  const { userState, refreshTasks, setRefreshTasks } = useContext(UserContext);
  const { userTeams, selectedTeam } = useContext(TeamContext);

  const [loading, setLoading] = useState(false);

  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  const [handoffText, setHandoffText] = useState(
    "Use this option to add new user to current task along with existing user(s)"
  );

  /**
   * Fetch tasks
   */

  const [handOffPopUp, setHandOffPopUp] = useState(false);
  const [recurringTaskPopUp, setRecurringTaskPopUp] = useState(false);
  const [EmailFromInput, setEmailFromInput] = useState<String | null>("");
  const [handoverType, setHandoverType] = useState("Group_task");
  const [sendNudgeNotification, setSendNudgeNotification] = useState(false);
  const [recievedNudgeNotification, SetRecievedNudgeNotification] =
    useState(false);
  const [taskID, setTaskID] = useState<String | undefined>("");
  const [clickedID, setClikedID] = useState<String | undefined>("");
  const [receivedclickedID, SetReceivedclickedID] = useState<
    String | undefined
  >("");

  const [selectedRecurringId, setSelectedRecurringId] = useState<
    String | undefined
  >("");

  const [groupListOpen, setGroupListOpen] = useState(false);
  const [groupListClickedId, setGroupListClickedId] = useState();
  const [taskAndDescListOnOver, setTaskAndDescListOnOver] = useState(false);
  const [taskAndDescriptionID, setTaskAndDescriptionID] = useState<
    String | undefined
  >("");
  const [shareURLPopUp, setShareURLPopUp] = useState(false);
  const [shareURLID, setShareURLID] = useState<String | undefined>("");

  const [resetPagination, setResetPagination] = useState<boolean>(false);
  const [validationHandoverEmails, setValidationHandoverEmails] = useState<
    String | undefined
  >("");
  const [handoverEmailCanSumbit, setHandoverEmailCanSumbit] = useState(false);
  const [hoverCol, setHoverCol] = useState(false);
  const [hoverColNum, setHoverColNum] = useState(0);
  const [taskIdCol, setTaskId] = useState("");
  const [showDeleteTaskConfirmation, setShowDeleteTaskConfirmation] =
    useState<boolean>(false);
  const [deleteTaskParmanently, setDeleteTaskParmanently] =
    useState<boolean>(false);
  const [deletedtaskId, setDeletedTaskId] = useState<any>();

  const [toShowProgressLabel20, setShowProLabel20] = useState(false);
  const [toShowProgressLabel40, setShowProLabel40] = useState(false);
  const [toShowProgressLabel60, setShowProLabel60] = useState(false);
  const [toShowProgressLabel80, setShowProLabel80] = useState(false);
  const [toShowProgressLabel100, setShowProLabel100] = useState(false);

  const [updateTaskId, setUpdateTaskId] = useState<any>();
  let setTimeOut = 1000;
  const [progress, setProgress] = useState<any>(0);
  const [content, setContent] = useState<any>("");

  const { taskId } = useParams();

  const navigate = useNavigate();

  const newLinkRoute = (TaskId: any) => {
    navigate(`/${TaskId}`);
  };

  useEffect(() => {
    if (tabSelected) {
      setTaskCount(0);
      setLoading(true);
    }
    // eslint-disable-next-line
  }, [tabSelected]);

  /*  Update Pagination on tab change */

  useDebounce(
    () => {
      if (tabSelected) {
        setTaskCount(0);
        setResetPagination(true);
        let type = tabSelected;

        if (type === "completed" && archivedChecked) type = "archived";
        let currentPaginationInput = currentPaginationTaskInput; // DefaultPaginationTaskInput;
        currentPaginationInput.type = type;
        let isCriteriaNotAdded = true;
        if (criteria) {
          // Need to add criteria filters in the request input if any
          currentPaginationInput.criteria = {};

          if (criteria?.categoryList && criteria?.categoryList.length > 0) {
            currentPaginationInput.criteria.categories =
              criteria.categoryList.map((cat) => cat._id);
            isCriteriaNotAdded = false;
          } else {
            currentPaginationInput.criteria.categories = undefined;
          }

          if (criteria?.assigneeList && criteria?.assigneeList.length > 0) {
            currentPaginationInput.criteria.assigneeList =
              criteria.assigneeList.map((usr) => usr._id);
            isCriteriaNotAdded = false;
          } else {
            currentPaginationInput.criteria.assigneeList = undefined;
          }

          if (criteria?.assignorList && criteria?.assignorList.length > 0) {
            currentPaginationInput.criteria.assignorList =
              criteria.assignorList.map((usr) => usr._id);
            isCriteriaNotAdded = false;
          } else {
            currentPaginationInput.criteria.assignorList = undefined;
          }
          if (criteria?.daysRemainingList && criteria?.daysRemainingList) {
            currentPaginationInput.criteria.dayFilterTaskIds = [];
            currentPaginationInput.criteria.dayFilterIds = [];
            criteria?.daysRemainingList.forEach((dayFilter) => {
              currentPaginationInput.criteria.dayFilterIds.push(
                dayFilter.daysId
              );
              isCriteriaNotAdded = false;
            });
          } else {
            currentPaginationInput.criteria.dayFilterTaskIds = undefined;
            currentPaginationInput.criteria.dayFilterIds = undefined;
          }
        } else {
          currentPaginationInput.criteria = undefined;
        }

        if (searchCriteria) {
          if (!currentPaginationInput.criteria)
            currentPaginationInput.criteria = {};

          if (searchCriteria.toEmail && searchCriteria.toEmail.length > 0)
            currentPaginationInput.criteria.toEmail = searchCriteria.toEmail;
          else currentPaginationInput.criteria.toEmail = undefined;

          if (searchCriteria.fromEmail && searchCriteria.fromEmail.length > 0)
            currentPaginationInput.criteria.fromEmail =
              searchCriteria.fromEmail;
          else currentPaginationInput.criteria.fromEmail = undefined;

          if (
            searchCriteria.groupEmails &&
            searchCriteria.groupEmails.length > 0
          )
            currentPaginationInput.criteria.groupEmails =
              searchCriteria.groupEmails;
          else currentPaginationInput.criteria.groupEmails = undefined;

          if (searchCriteria.task && searchCriteria.task.length > 0)
            currentPaginationInput.criteria.task = searchCriteria.task;
          else currentPaginationInput.criteria.task = undefined;

          if (searchCriteria.due)
            currentPaginationInput.criteria.due = searchCriteria.due;
          else currentPaginationInput.criteria.due = undefined;

          if (searchCriteria.status)
            currentPaginationInput.criteria.status = searchCriteria.status;
          else currentPaginationInput.criteria.status = undefined;

          if (searchCriteria.priority)
            currentPaginationInput.criteria.priority = searchCriteria.priority;
          else currentPaginationInput.criteria.priority = undefined;

          if (
            searchCriteria?.categoriesIds &&
            searchCriteria?.categoriesIds.length > 0
          ) {
            currentPaginationInput.criteria.categories =
              searchCriteria.categoriesIds.map((cat) => cat);
            isCriteriaNotAdded = false;
          } else {
            currentPaginationInput.criteria.categories = undefined;
          }
        } else {
          if (isCriteriaNotAdded) currentPaginationInput.criteria = undefined;
        }

        if (currentPaginationInput.criteria) {
          currentPaginationInput.criteria.teamId = selectedTeam?.id;
        } else {
          currentPaginationInput.criteria = { teamId: selectedTeam?.id };
        }
        setCurrentPaginationTaskInput({ ...currentPaginationInput });
      }
    },
    [tabSelected, archivedChecked, criteria, searchCriteria, selectedTeam],
    800
  );

  useEffect(() => {
    if (handoverType === "Handoff_task") {
      let result: any = EmailFromInput?.indexOf(",");
      if (result! >= 0) {
        setHandoverEmailCanSumbit(false);
        setValidationHandoverEmails(
          "please provide single email for handoff task"
        );
      } else {
        setHandoverEmailCanSumbit(true);
        setValidationHandoverEmails(undefined);
      }

      setHandoffText(
        "Use this option to hand-over task to another user, existing task would be marked completed and new task would be created"
      );
    } else if (handoverType === "Delegate_task") {
      setHandoffText(
        "Use this option to assign task to another user, existing user would become listener for this task"
      );
      let res: any = EmailFromInput?.indexOf(",");
      if (res >= 0) {
        setValidationHandoverEmails(
          "please provide single email for delegate task"
        );

        setHandoverEmailCanSumbit(false);
      } else {
        setValidationHandoverEmails(undefined);
        setHandoverEmailCanSumbit(true);
      }
    } else if (handoverType === "Group_task") {
      setHandoffText(
        "Use this option to add new user to current task along with existing user(s)"
      );
      setValidationHandoverEmails(undefined);
    }
    // eslint-disable-next-line
  }, [handoverType]);

  useEffect(() => {
    setLoading(true);

    myAxios
      .post(`tasks/paginated-tasks`, {
        ...currentPaginationTaskInput,
        selectedTaskId: taskId,
      })
      .then((response: any) => {
        setLoading(false);
        if (response.data.data.tasks) addTasks(response.data.data.tasks);
        else addTasks([]);
        if (response.data.data.count) setTaskCount(response.data.data.count);
        else setTaskCount(0);

        if (response.data.data.selectedTask)
          if (response.data.data.selectedTask._id === taskId) {
            setSelectedTask(response.data.data.selectedTask);
            setModel(true);
            setCurrentTaskNumber(0);
          }
      })
      .catch((error) => {
        console.error("error ", error);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [currentPaginationTaskInput]);

  const pageChange = (currentPage: number) => {
    const skip = (currentPage - 1) * pageLimit;
    if (skip !== currentPaginationTaskInput.pagination.skip) {
      let currentPaginationInput = currentPaginationTaskInput;
      let page = {
        skip: (currentPage - 1) * pageLimit,
        limit: pageLimit,
      };
      currentPaginationInput.pagination = page;
      setCurrentPaginationTaskInput({ ...currentPaginationInput });
    }
  };
  const pageLimitChange = (pageLimit: number) => {
    setPageLimit(pageLimit);
  };
  useEffect(() => {
    if (pageLimit) {
      let currentPaginationInput = currentPaginationTaskInput;
      let page = {
        skip: 0,
        limit: pageLimit,
      };
      currentPaginationInput.pagination = page;
      setCurrentPaginationTaskInput({ ...currentPaginationInput });
    }
    // eslint-disable-next-line
  }, [pageLimit]);

  useEffect(() => {
    // console.log("refreshTasks tasklist:=", refreshTasks);

    if (refreshTasks) {
      // Need to refresh Menu Filter after addding new task
      myAxios
        .get(`menu-filters/${selectedTeam?.id}`)
        .then((response: any) => {
          addAssignee(response.data.data.assigneeList);
          addAssignor(response.data.data.assignorList);
          addDayFilters(response.data.data.daysMenuArray);
          addCategories(response.data.data.categories);
        })
        .catch((error) => {
          console.error("Getting Error ", error);
        });
      myAxios
        .post(`tasks/paginated-tasks`, {
          ...currentPaginationTaskInput,
          selectedTaskId: taskId,
        })
        .then((response: any) => {
          // console.log("response.data.data", response.data.data);

          if (response.data.data.tasks) addTasks(response.data.data.tasks);
          else addTasks([]);
          if (response.data.data.count) setTaskCount(response.data.data.count);
          else setTaskCount(0);
          // NotificationManager.success("New data available, fetching...");
          setRefreshTasks(false);
          if (response.data.data.selectedTask)
            if (response.data.data.selectedTask._id === taskId) {
              setSelectedTask(response.data.data.selectedTask);
              setModel(true);
              setCurrentTaskNumber(0);
            }
        })
        .catch((error) => {
          console.error("error ", error);
        });
    }
    // eslint-disable-next-line
  }, [addTasks, refreshTasks]);

  const updateProgressOnServer = (
    taskID: string | undefined,
    progress: number | undefined,
    cced: boolean | undefined,
    isDec?: boolean
  ) => {
    if (!cced) {
      if (taskID) {
        const selectedTask = tasks.find((tsk) => tsk._id === taskID);
        if (
          selectedTask &&
          selectedTask.progress === 0 &&
          progress &&
          progress >= 0
        ) {
          // Task completed, send event for task completed
          _cio.track(USER_START_A_TASK_EVENTS, {
            name: "user started task on task list",
          });
        }

        if (progress && progress >= 100) {
          // Task completed, send event for task completed
          _cio.track(USER_COMPLETE_TASK_EVENTS, {
            name: "user mark task completed on task list",
          });
        }

        if (selectedTask && progress && progress !== selectedTask.progress) {
          // Task completed, send event for task completed
          _cio.track(
            USER_USE_PERCENT_COMPLETE_TO_MOVE_PROGRESS_OF_TASK_EVENTS,
            {
              name: "User update task progress from task list",
            }
          );
        }
        setTimeout(() => {
          myAxios
            .put(`tasks-update/${taskID}`, {
              progress,
            })
            .then((response: AxiosResponse<Task>) => {
              setUpdateTaskId("");
              if (!isDec) {
                NotificationManager.success("Progress recorded successfully!");
              } else
                NotificationManager.success("Progress removed successfully!");
              updateTask(response.data);

              // Need to refresh Menu Filter after addding new task

              myAxios
                .get(`menu-filters/${selectedTeam?.id}`)
                .then((response: any) => {
                  addAssignee(response.data.data.assigneeList);
                  addAssignor(response.data.data.assignorList);
                  addDayFilters(response.data.data.daysMenuArray);
                  addCategories(response.data.data.categories);
                })
                .catch((error) => {});

              //  Also fetch latest task as selected pagination

              myAxios
                .post(`tasks/paginated-tasks`, {
                  ...currentPaginationTaskInput,
                  selectedTaskId: taskId,
                })
                .then((response: any) => {
                  if (response.data.data.tasks)
                    addTasks(response.data.data.tasks);
                  else addTasks([]);
                  if (response.data.data.count)
                    setTaskCount(response.data.data.count);
                  else setTaskCount(0);
                  if (response.data.data.selectedTask)
                    if (response.data.data.selectedTask._id === taskId) {
                      setSelectedTask(response.data.data.selectedTask);
                      setModel(true);
                      setCurrentTaskNumber(0);
                    }
                })
                .catch((error) => {
                  console.error("error ", error);
                });
            })
            .catch((error) => {
              NotificationManager.error(parseErrorResponse(error));
            });
        }, setTimeOut);
      }
    } else {
      NotificationManager.error("Watchers are not allowed to update task");
    }
  };

  const toggleTaskEmailNotification = (taskID: any, emailNotification: any) => {
    if (!emailNotification) {
      _cio.track(USER_TURN_OF_TASK_NOTIFICATION_EVENTS, {
        name: "user turn of task notification",
      });
    }
    myAxios
      .put(`tasks-email-notification/${taskID}`, {
        emailNofitication: emailNotification,
      })
      .then((response: AxiosResponse<Task>) => {
        if (emailNotification)
          NotificationManager.success("Notifications turned on for this task!");
        else
          NotificationManager.success(
            "Notifications turned off for this task!"
          );
        updateTask(response.data);
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const validateHandoverEmails = (emailText: string) => {
    if (!emailText || emailText.length === 0) {
      setHandoverEmailCanSumbit(false);
      setValidationHandoverEmails(undefined);
    } else {
      if (
        emailText.includes(",") &&
        handoverType !== "Handoff_task" &&
        handoverType !== "Delegate_task"
      ) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          setHandoverEmailCanSumbit(false);
          setValidationHandoverEmails("Valid email are required after ','");
        } else {
          const invalidEmails = emailText.split(",").filter((e) => {
            if (!isValidInHandoverOrNot(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            setHandoverEmailCanSumbit(false);
            setValidationHandoverEmails(
              "Following emails [" +
                invalidEmails.join(", ") +
                "] are not valid."
            );
          } else {
            setHandoverEmailCanSumbit(true);
            setValidationHandoverEmails(undefined);
          }
        }
      } else {
        //Single emails
        if (!isValidInHandoverOrNot(emailText)) {
          setHandoverEmailCanSumbit(false);
          setValidationHandoverEmails("Please enter valid email");
        } else {
          // email is valid
          setHandoverEmailCanSumbit(true);
          setValidationHandoverEmails(undefined);
        }
      }
    }
  };

  const handOffTask = (e: any) => {
    if (handoverEmailCanSumbit) {
      _cio.track(USER_USE_HAND_OVER_FEATURE_EVENTS, {
        name: "user use hand over feature",
      });

      myAxios
        .put(`handoff-users/${taskID}`, {
          EmailFromInput: EmailFromInput,
          value: handoverType,
          teamId: selectedTeam?.id,
        })
        .then((response) => {
          if (response.data.success) {
            setHandoverEmailCanSumbit(false);
            if (response.data && response.data.task) {
              //updateTask(response.data.task);
              setHandoverType("Group_task");
            }

            myAxios
              .get(`menu-filters/${selectedTeam?.id}`)
              .then((response: any) => {
                addAssignee(response.data.data.assigneeList);
                addAssignor(response.data.data.assignorList);
                addDayFilters(response.data.data.daysMenuArray);
                addCategories(response.data.data.categories);
              })
              .catch((error) => {});

            //  Also fetch latest task as selected pagination

            myAxios
              .post(`tasks/paginated-tasks`, currentPaginationTaskInput)
              .then((response: any) => {
                if (response.data.data.tasks)
                  addTasks(response.data.data.tasks);
                else addTasks([]);
                if (response.data.data.count)
                  setTaskCount(response.data.data.count);
                else setTaskCount(0);
              })
              .catch((error) => {
                console.error("error ", error);
              });

            let message =
              "Created hand-over task & marked existing task as complete!";

            if (handoverType === "Delegate_task") {
              message = "Delegated task to another user!";
            } else if (handoverType === "Handoff_task") {
              message =
                "Created hand-over task & marked existing task as complete!";
            }

            NotificationManager.success(message);
          } else {
            let message = "hand-over task";

            if (handoverType === "Delegate_task") {
              message = "Delegated";
            } else if (handoverType === "Handoff_task") {
              message = "hand-over task";
            }

            NotificationManager.error(
              response.data && response.data.message
                ? response.data.message
                : "Not able to " + message + " task "
            );
          }
        })
        .catch((error) => {
          console.error("error", error);
          NotificationManager.error(parseErrorResponse(error));
        });
      setHandOffPopUp(false);
    }
  };

  const handleChange = (e: any) => {
    e.stopPropagation();
    setHandoverType(e.target.value);
  };

  const handClickSendNudgeFun = () => {
    setSendNudgeNotification(!sendNudgeNotification);
  };
  const handClickRecievedNudgeFun = () => {
    SetRecievedNudgeNotification(!recievedNudgeNotification);
  };

  const handleClickGroupList = (ClickedId: any) => {
    setGroupListOpen(!groupListOpen);
    setGroupListClickedId(ClickedId);
  };

  const handleTaskAndDescriptionList = (taskIdOnHover: any) => {
    setTaskAndDescListOnOver(!taskAndDescListOnOver);
    setTaskAndDescriptionID(taskIdOnHover);
  };

  const handleSendNudgeData = (task: Task, type: string, message: string) => {
    //  for stroring in database -
    myAxios
      .post(`/send-nudge`, {
        message,
        task: task._id,
        type: type,
        groupUsers: task.groupUsers,
      })
      .then((response: AxiosResponse<TaskNudge[]>) => {
        if (type === "casual") {
          NotificationManager.success(" casual Nudge sent successfully!");
        } else {
          NotificationManager.success("urgent Nudge sent successfully!");
        }

        // response data would contain list of all nudges for this task
        // only change to task is addition of new nudge so we just have to
        // add nudges to current tach
        /* updateTask({
          ...task,
          taskNudges: response.data,
        }); */
        myAxios
          .get(`menu-filters/${selectedTeam?.id}`)
          .then((response: any) => {
            addAssignee(response.data.data.assigneeList);
            addAssignor(response.data.data.assignorList);
            addDayFilters(response.data.data.daysMenuArray);
            addCategories(response.data.data.categories);
          })
          .catch((error) => {});

        //  Also fetch latest task as selected pagination

        myAxios
          .post(`tasks/paginated-tasks`, currentPaginationTaskInput)
          .then((response: any) => {
            if (response.data.data.tasks) addTasks(response.data.data.tasks);
            else addTasks([]);
            if (response.data.data.count)
              setTaskCount(response.data.data.count);
            else setTaskCount(0);
          })
          .catch((error) => {
            console.error("error ", error);
          });
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });

    setSendNudgeNotification(false);
  };

  const handleShareTaskUrl = () => {
    setShareURLPopUp(!shareURLPopUp);
    setTimeout(() => {
      setShareURLPopUp(false);
    }, 2000);
  };

  const checkIfAnySubTasIskNotCompleted = (subTasks: SubTask[]) => {
    if (subTasks && subTasks.length > 0) {
      const subTask = subTasks.find((o) => !o.isCompleted);
      if (subTask) return true;
    }
    return false;
  };

  const handleHoverOnCol = (index: number, taskId: any) => {
    setTaskId(taskId);
    setHoverCol(true);
    setHoverColNum(index);
  };

  const calculateTaskIndex = (index: number) => {
    let calculatedIndex = currentPaginationTaskInput.pagination.skip;
    calculatedIndex = calculatedIndex + (index + 1);
    return calculatedIndex;
  };

  const taskClick = (task: Task, index: number) => {
    setModel(!showModel);
    setCurrentTaskNumber(calculateTaskIndex(index));
    setSelectedTask(task);
    newLinkRoute(task._id);
    setToolTip(true);
  };

  const deleteTaskClick = () => {
    setShowDeleteTaskConfirmation(false);
    setLoading(true);
    myAxios
      .put(`tasks/markTaskAsDeleted/${deletedtaskId}`, {
        ...currentPaginationTaskInput,
      })
      .then((response: any) => {
        if (response.data.success) {
          NotificationManager.success("Task deleted successfully!");
          if (response.data.data.tasks) addTasks(response.data.data.tasks);
          else addTasks([]);
          if (response.data.data.count) setTaskCount(response.data.data.count);
          else setTaskCount(0);
        } else {
          NotificationManager.error(response.data.message);
        }
        setLoading(false);
        setDeletedTaskId(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setDeletedTaskId(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const DeleteTaskComp = ({ taskId, index }: any) => {
    const [selectedImage, setSelectedImage] =
      useState<any>(DeleteTaskImageIcon);
    return (
      <>
        <DeleteTaskImg
          className={`${USETIFULL_TASK_DELETION}${index + 1}`}
          src={selectedImage}
          onMouseEnter={() => {
            setSelectedImage(DeleteTaskHoverImageIcon);
          }}
          onMouseLeave={() => {
            setSelectedImage(DeleteTaskImageIcon);
          }}
          onClick={() => {
            setShowDeleteTaskConfirmation(true);
            setDeletedTaskId(taskId);
          }}
        />
      </>
    );
  };

  const CopyTaskComp = ({ task, index }: any) => {
    const [selectedImage, setSelectedImage] = useState<any>(CopyTaskImageIcon);
    return (
      <>
        <a
          href="#mainTaskForm"
          className={`${USETIFULL_TASK_DUPLICATION}${index + 1}`}
        >
          <CopyTaskImg
            src={selectedImage}
            onMouseEnter={() => {
              setSelectedImage(CopyTaskHoverImageIcon);
            }}
            onMouseLeave={() => {
              setSelectedImage(CopyTaskImageIcon);
            }}
            onClick={() => {
              setTaskCopyClick(true);
              setCopyTask(task);
              _cio.track(USER_COPY_DUPLICATE_A_TASK_EVENTS, {
                name: "user use copy task button to copy task",
              });
              setTimeout(() => {
                navigate(`/`);
              }, 100);
            }}
          />
        </a>
      </>
    );
  };

  const removeTaskParmanently = () => {
    setShowDeleteTaskConfirmation(false);
    setDeleteTaskParmanently(false);
    setLoading(true);
    myAxios
      .put(`tasks/deleteTask/${deletedtaskId}`, {
        ...currentPaginationTaskInput,
      })
      .then((response: any) => {
        if (response.data.success) {
          NotificationManager.success("Task deleted successfully!");
          if (response.data.data.tasks) addTasks(response.data.data.tasks);
          else addTasks([]);
          if (response.data.data.count) setTaskCount(response.data.data.count);
          else setTaskCount(0);
        } else {
          NotificationManager.error(response.data.message);
        }

        setLoading(false);
        setDeletedTaskId(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setDeletedTaskId(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
  };
  const restoreDeletedTask = (id: string) => {
    setShowDeleteTaskConfirmation(false);
    setLoading(true);
    myAxios
      .put(`tasks/restoreDeletedTask/${id}`, {
        ...currentPaginationTaskInput,
      })
      .then((response: any) => {
        if (response.data.success) {
          NotificationManager.success("Task restored successfully!");
          if (response.data.data.tasks) addTasks(response.data.data.tasks);
          else addTasks([]);
          if (response.data.data.count) setTaskCount(response.data.data.count);
          else setTaskCount(0);
        } else {
          NotificationManager.error(response.data.message);
        }
        setLoading(false);
        setDeletedTaskId(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setDeletedTaskId(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    <>
      {loading && (
        <WatchParent>
          <Watch color="orange" height={50} width={50} />
        </WatchParent>
      )}
      {/* <Pagination /> */}
      {tasks &&
        tasks.length > 0 &&
        tasks.map((task: Task, index) => {
          let timeRemaining = "";
          // setSaveTaskID(task._id)

          let dayStr = " day";
          if (
            task?.recurringTaskData?.recurringInDay &&
            task?.recurringTaskData?.recurringInDay > 1
          ) {
            dayStr = " days";
          }

          const recurringHoverTitle = task?.recurringTaskData?.recurringInDay
            ? "This task recurs in \n every " +
              task?.recurringTaskData?.recurringInDay +
              dayStr
            : "";

          if (task.duedate) {
            let now = moment();

            let taskDueMoment = moment(task.duedate);

            if (taskDueMoment.isAfter(now)) {
              let monthsLeft = taskDueMoment.diff(now, "months");

              if (monthsLeft > 0) {
                timeRemaining = `${monthsLeft}mo`;
                taskDueMoment.subtract(monthsLeft, "months");
              }

              let daysLeft = taskDueMoment.diff(now, "days");
              if (daysLeft > 0) {
                timeRemaining += ` ${daysLeft}d`;
                taskDueMoment.subtract(daysLeft, "days");
              }

              let hoursLeft = taskDueMoment.diff(now, "hours");
              if (hoursLeft > 0 && monthsLeft === 0) {
                timeRemaining += ` ${hoursLeft}h`;
                taskDueMoment.subtract(hoursLeft, "hours");
              }

              let minutesLeft = taskDueMoment.diff(now, "minutes");
              if (minutesLeft >= 0 && monthsLeft === 0 && daysLeft === 0) {
                timeRemaining += ` ${minutesLeft}m`;
                taskDueMoment.subtract(minutesLeft, "minutes");
              }

              task.timeRemaining = timeRemaining + " left";
            } else {
              let monthsLeft = now.diff(taskDueMoment, "months");
              if (monthsLeft > 0) {
                timeRemaining = `${monthsLeft}mo`;
                taskDueMoment.add(monthsLeft, "months");
              }

              let daysLeft = now.diff(taskDueMoment, "days");
              if (daysLeft > 0) {
                timeRemaining += ` ${daysLeft}d`;
                taskDueMoment.add(daysLeft, "days");
              }

              let hoursLeft = now.diff(taskDueMoment, "hours");
              if (hoursLeft > 0 && monthsLeft === 0) {
                timeRemaining += ` ${hoursLeft}h`;
                taskDueMoment.add(hoursLeft, "hours");
              }

              let minutesLeft = now.diff(taskDueMoment, "minutes");
              if (minutesLeft >= 0 && monthsLeft === 0 && daysLeft === 0) {
                timeRemaining += ` ${minutesLeft}m`;
                taskDueMoment.add(minutesLeft, "minutes");
              }

              task.timeRemaining = timeRemaining + " ago";
            }
          }

          let opacity: any = task._id === deletedtaskId ? 0.4 : 1;

          let opacitys = !task.cced && task._id === updateTaskId ? 0.1 : 1;
          if (task.progress <= 0) {
            opacitys = !task.cced && task._id === updateTaskId ? 0.1 : 1;
          } else if (progress === 0) {
            opacitys = !task.cced && task._id === updateTaskId ? 0.1 : 1;
          } else if (progress === 100) {
            opacitys = !task.cced && task._id === updateTaskId ? 0.1 : 1;
          } else if (
            progress === 80 ||
            progress === 60 ||
            progress === 40 ||
            progress === 20
          ) {
            opacitys = !task.cced && task._id === updateTaskId ? 0.1 : 1;
          }

          if (tabSelected === "trash") {
            opacity = 0.5;

            return (
              <>
                <DisabledTaskListItem
                  key={task._id}
                  isOpen={isOpen}
                  task={task}
                />
                <TrashRowContainer isOpen={isOpen}>
                  <TrashItemUl>
                    <TrashItemLi
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        task._id && restoreDeletedTask(task._id);
                      }}
                    >
                      Restore Task
                    </TrashItemLi>
                    <TrashItemLi>|</TrashItemLi>
                    <TrashItemLi
                      onClick={() => {
                        setDeleteTaskParmanently(true);
                        setShowDeleteTaskConfirmation(true);
                        setDeletedTaskId(task._id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Delete Permanently
                    </TrashItemLi>
                  </TrashItemUl>
                </TrashRowContainer>
              </>
            );
          } else
            return (
              <div key={task._id} style={{}}>
                <TaskContainer
                  style={{ opacity: opacity }}
                  key={task._id}
                  isOpen={isOpen}
                  showModel={showModel}
                >
                  {task._id === updateTaskId && !task.cced && (
                    <UpdateNotificationDiv>
                      {task.detail + "..." + `  task moved on ` + content}
                    </UpdateNotificationDiv>
                  )}

                  <ParentRow>
                    <Col>
                      <FirstRow
                        onMouseOver={() => {
                          setTaskAndDescListOnOver(false);
                          setGroupListOpen(false);
                          setHoverCol(false);
                        }}
                        style={{ opacity: opacitys }}
                      >
                        {isDesktopScreen ? (
                          <Col sm={1}>
                            {task.priority === 5 ? (
                              <DivPriority color="#11b753">
                                <span>{`P${task.priority}`}</span>
                              </DivPriority>
                            ) : null}
                            {task.priority === 4 ? (
                              <DivPriority color="#eccc22">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 3 ? (
                              <DivPriority color="#dd8426">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 2 ? (
                              <DivPriority color="#ff4004">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 1 ? (
                              <DivPriority color="#a80000">
                                {`P${task.priority}`}
                              </DivPriority>
                            ) : null}
                          </Col>
                        ) : null}

                        <ColToggleBtn sm={1}>
                          <ToggleBtnOn
                            className={`${USETIFULL_EMAIL_NOTIFICATION_PER_TASKS}${
                              index + 1
                            }`}
                            src={
                              task.notificationOn ? ToggleOnImg : ToggleOffImg
                            }
                            alt="on"
                            title="email notification"
                            onClick={(e) => {
                              toggleTaskEmailNotification(
                                task._id,
                                !task.notificationOn
                              );
                            }}
                          />
                        </ColToggleBtn>

                        <ColHandOff sm={1}>
                          <HandOffBtn
                            className={`${USETIFULL_TASK_HANDOVER_ACTION}${
                              index + 1
                            }`}
                            src={HandOffImg}
                            title="handoff new users"
                            onClick={(e) => {
                              setTaskID(task._id!);
                              setHandOffPopUp(!handOffPopUp);
                            }}
                          />

                          {handOffPopUp && task._id === taskID ? (
                            <HandOffPopUpDiv
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <span>
                                <i className="HandOff UpSignHandoff"></i>
                              </span>
                              <CrossBTN
                                src={CrosshandOffBox}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setHandOffPopUp(!handOffPopUp);
                                  setHandoverType("Group_task");
                                }}
                              />
                              {/* <DropDownDiv> */}
                              <SpanForHandoff>
                                <select
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={handleChange}
                                  className="DropDown"
                                >
                                  <option value="Group_task">
                                    Add Group User
                                  </option>
                                  <option value="Delegate_task">
                                    Delegate Task
                                  </option>
                                  <option value="Handoff_task">
                                    Hand-over Task
                                  </option>
                                </select>
                                <span>{handoffText}</span>
                              </SpanForHandoff>

                              <EmailInputBox
                                type="text"
                                placeholder="Enter user email address..."
                                onChange={(e: any) => {
                                  setEmailFromInput(e.target.value);
                                  validateHandoverEmails(e.target.value);
                                }}
                              />

                              <SendEmailBtn
                                src={SendEMail}
                                onClick={(e) => {
                                  handOffTask(e);
                                }}
                              />

                              <SpanErrorHandOver>
                                {validationHandoverEmails}
                              </SpanErrorHandOver>
                            </HandOffPopUpDiv>
                          ) : null}
                        </ColHandOff>

                        {isDesktopScreen ? (
                          <ColCatgory sm={5}>
                            {task.categories && task.categories.length
                              ? task.categories
                                  .map((c) => {
                                    return c.name;
                                  })
                                  .join(" | ")
                              : ""}
                          </ColCatgory>
                        ) : null}

                        <DivForIcons>
                          {task.isTemplateTask && (
                            <SubTaskIndicatorImgTag
                              src={SubTaskIndicatorSvg}
                              alt="subtask-indicator"
                            />
                          )}

                          {task.cced ? (
                            <EyeDiv title="You are a watching this task">
                              <img src={Eye} alt="eye" />
                            </EyeDiv>
                          ) : (
                            <Watcher></Watcher>
                          )}

                          {task && task!.recurringTaskData && (
                            <RecurringImgTag
                              src={RecurringIcon}
                              alt="RecurringIcon"
                              title={recurringHoverTitle}
                              onClick={() => {
                                setSelectedRecurringId(
                                  task!.recurringTaskData?._id
                                );
                                setRecurringTaskPopUp(!recurringTaskPopUp);
                              }}
                            />
                          )}

                          {task &&
                          task.taskNudges &&
                          task.taskNudges.length > 0 ? (
                            <DivForNudegeNotificationIcon sm={1}>
                              <DivCountNudgeReceived
                                onClick={(e) => {
                                  SetReceivedclickedID(task._id!);
                                  handClickRecievedNudgeFun();
                                }}
                              >
                                {task.taskNudges.length}
                              </DivCountNudgeReceived>
                              <NudgeNotificationImg
                                className={`${USETIFULL_TASK_NUDGE_COUNT}${
                                  index + 1
                                }`}
                                src={NudgeRedIcon}
                                alt="img"
                                title="Nudge notifications"
                                onClick={(e) => {
                                  SetReceivedclickedID(task._id!);
                                  handClickRecievedNudgeFun();
                                }}
                              />
                            </DivForNudegeNotificationIcon>
                          ) : (
                            // <DivForNudegeNotificationIcon sm={1}>
                            <NudgeNotificationImg
                              className={`${USETIFULL_TASK_NUDGE_COUNT}${
                                index + 1
                              }`}
                              src={NudgeIconForView}
                              alt="img"
                              title="Nudge notifications"
                              onClick={(e) => {
                                SetReceivedclickedID(task._id!);
                                handClickRecievedNudgeFun();
                              }}
                            />
                            // </DivForNudegeNotificationIcon>
                          )}

                          <ShareIconMainDiv sm={1}>
                            <ShareIconimgTag
                              className={`${USETIFULL_SHARE_TASK_URL}${
                                index + 1
                              }`}
                              src={ShareIcon}
                              alt="shareImage"
                              title="Share Task"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${process.env.REACT_APP_FRONTEND_URL}/${task._id}`
                                );

                                setShareURLID(task._id!);
                                handleShareTaskUrl();
                              }}
                            />
                            {shareURLPopUp === true &&
                              shareURLID === task._id && (
                                <ShareURLDIV>
                                  <SpanURL>Task link copied</SpanURL>
                                </ShareURLDIV>
                              )}
                          </ShareIconMainDiv>

                          {task && task.comments && task.comments.length > 0 ? (
                            <DivForComment>
                              <CommentSpanCount
                                onClick={() => {
                                  taskClick(task, index);
                                }}
                              >
                                {task.comments.length}
                              </CommentSpanCount>
                              <CommentRedTag
                                className={`${USETIFULL_TASK_COMMENT_INDICATOR}${
                                  index + 1
                                }`}
                                src={CommentRedIcon}
                                alt="img"
                                title="Comments"
                                onClick={() => {
                                  taskClick(task, index);
                                  /* setModel(!showModel);
                              setCurrentTaskNumber(calculateTaskIndex(index));
                              setSelectedTask(task);
                              newLinkRoute(task._id);
                              setToolTip(true); */
                                }}
                              />
                            </DivForComment>
                          ) : (
                            <CommentGreyTag
                              className={`${USETIFULL_TASK_COMMENT_INDICATOR}${
                                index + 1
                              }`}
                              src={CommentGreyIcon}
                              alt="img"
                              title="Comments"
                              onClick={() => {
                                taskClick(task, index);
                                /*  setModel(!showModel);
                            setCurrentTaskNumber(calculateTaskIndex(index));
                            setSelectedTask(task);
                            newLinkRoute(task._id);
                            setToolTip(true); */
                              }}
                            />
                          )}

                          <Dots
                            className={`${USETIFULL_NUDGE_ACTIONS}${index + 1}`}
                            onClick={(e: any) => {
                              handClickSendNudgeFun();
                              setClikedID(task._id);
                            }}
                            sm={1}
                          >
                            <IMGTag
                              src={ThreeDot}
                              alt="threedot"
                              title="nudge notification"
                              className="Blue"
                            />
                            <IMGTag
                              src={MoreOptionOrange}
                              alt="threedot"
                              title="nudge notification"
                              className="Orange"
                            />
                          </Dots>
                        </DivForIcons>
                      </FirstRow>

                      {/* this row visible only in mobile--*/}
                      <MiddleRow style={{ opacity: opacitys }}>
                        {task.priority && !isDesktopScreen ? (
                          <ColForMobileViewPriority sm={1}>
                            {task.priority === 5 ? (
                              <DivPriority color="#11b753">
                                <span>{`P${task.priority}`}</span>
                              </DivPriority>
                            ) : null}
                            {task.priority === 4 ? (
                              <DivPriority color="#eccc22">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 3 ? (
                              <DivPriority color="#dd8426">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 2 ? (
                              <DivPriority color="#ff4004">{`P${task.priority}`}</DivPriority>
                            ) : null}
                            {task.priority === 1 ? (
                              <DivPriority color="#a80000">
                                {`P${task.priority}`}
                              </DivPriority>
                            ) : null}
                          </ColForMobileViewPriority>
                        ) : null}

                        {!isDesktopScreen &&
                        task.categories &&
                        task.categories.length ? (
                          <ColCatgory sm={5}>
                            {task.categories && task.categories.length
                              ? task.categories
                                  .map((c) => {
                                    return c.name;
                                  })
                                  .join(" | ")
                              : ""}
                          </ColCatgory>
                        ) : null}
                      </MiddleRow>
                      {/* ******** */}

                      <SecondRow
                        onMouseOver={() => {
                          setGroupListOpen(false);
                          setHoverCol(false);
                        }}
                        style={{ opacity: opacitys }}
                      >
                        <TaskDetail
                          onClick={() => {
                            taskClick(task, index);
                          }}
                        >
                          <Detail>
                            <SpanText
                              onMouseOver={() => {
                                handleTaskAndDescriptionList(task._id);
                              }}
                            >
                              {task.detail}
                            </SpanText>

                            {taskAndDescListOnOver &&
                            taskAndDescriptionID === task._id &&
                            (task.detail || task.isDescription) ? (
                              <TaskAndDescriHoverList
                                onMouseOver={() => {
                                  setTaskAndDescListOnOver(false);
                                }}
                              >
                                <span>
                                  <i className="taskOver UpOver"></i>
                                </span>
                                <SpanTasks>{task.detail}</SpanTasks>

                                <SpanDescription
                                  dangerouslySetInnerHTML={{
                                    __html: task.isDescription
                                      ? removeUnwantedTagsForToolTip(
                                          task.isDescription
                                        )
                                      : "",
                                  }}
                                ></SpanDescription>
                              </TaskAndDescriHoverList>
                            ) : null}
                          </Detail>
                        </TaskDetail>
                      </SecondRow>

                      <ThirdRow
                        onMouseOver={() => {
                          setTaskAndDescListOnOver(false);
                        }}
                        style={{ opacity: opacitys }}
                      >
                        {task.assignedFrom?.fullName ? (
                          <UserEmail>{task.assignedFrom?.fullName}</UserEmail>
                        ) : (
                          <UserEmail>{task.assignedFrom?.email}</UserEmail>
                        )}

                        {task.assignmentType === "to_and_group" ? (
                          <UserEmail>
                            {task.assignedTo?.fullName
                              ? task.assignedTo?.fullName
                              : task.assignedTo?.email}
                            , {`<<Group>>`}
                          </UserEmail>
                        ) : task.assignmentType === "group" ? (
                          <GroupDiv>
                            <span
                              onMouseEnter={() => {
                                handleClickGroupList(task._id);
                              }}
                              title="group users list"
                            >
                              {`<<Group>>`}
                            </span>
                          </GroupDiv>
                        ) : task.assignedTo?.fullName ? (
                          <UserEmail>{task.assignedTo?.fullName}</UserEmail>
                        ) : (
                          <UserEmail>{task.assignedTo?.email}</UserEmail>
                        )}

                        {groupListOpen && groupListClickedId === task._id && (
                          <GroupUsersList
                            onMouseOver={() => {
                              setGroupListOpen(false);
                            }}
                          >
                            <span>
                              <i className="groupOver UpOver"></i>
                            </span>
                            {task!.groupUsers!.map((user: any) => {
                              return (
                                <>
                                  {user.firstName && user.firstName ? (
                                    <SpanForName>
                                      {user.firstName} {user.lastName}
                                    </SpanForName>
                                  ) : (
                                    <SpanForName>{user.email}</SpanForName>
                                  )}
                                </>
                              );
                            })}
                          </GroupUsersList>
                        )}

                        <TimeLeft>{task.timeRemaining}</TimeLeft>

                        {isDesktopScreen ? (
                          <ProgressContainer>
                            <div
                              className={`${USETIFULL_PERCENT_TASK_COMPLETE}${
                                index + 1
                              }`}
                            ></div>

                            {toShowProgressLabel20 &&
                              taskIdCol === task._id &&
                              1 <= hoverColNum &&
                              hoverCol && (
                                <ProgressLabel>
                                  <span>
                                    <i className="arrowDownFirst Down"></i>
                                  </span>
                                  <SpanText>
                                    {task.progress20
                                      ? task.progress20
                                      : "20% Complete"}
                                  </SpanText>
                                </ProgressLabel>
                              )}

                            {toShowProgressLabel40 &&
                              hoverCol &&
                              2 <= hoverColNum &&
                              taskIdCol === task._id && (
                                <ProgressLabel>
                                  <span>
                                    <i className="arrowDownSecond Down"></i>
                                  </span>
                                  <SpanText>
                                    {task.progress40
                                      ? task.progress40
                                      : "40% Complete"}
                                  </SpanText>
                                </ProgressLabel>
                              )}

                            {toShowProgressLabel60 &&
                              hoverCol &&
                              3 <= hoverColNum &&
                              taskIdCol === task._id && (
                                <ProgressLabel>
                                  <span>
                                    <i className="arrowDownThird Down"></i>
                                  </span>
                                  <SpanText>
                                    {task.progress60
                                      ? task.progress60
                                      : "60% Complete"}
                                  </SpanText>
                                </ProgressLabel>
                              )}
                            {toShowProgressLabel80 &&
                              hoverCol &&
                              3 <= hoverColNum &&
                              taskIdCol === task._id && (
                                <ProgressLabel>
                                  <span>
                                    <i className="arrowDownFourth Down"></i>
                                  </span>
                                  <SpanText>
                                    {task.progress80
                                      ? task.progress80
                                      : "80% Complete"}
                                  </SpanText>
                                </ProgressLabel>
                              )}
                            {toShowProgressLabel100 &&
                              hoverCol &&
                              3 <= hoverColNum &&
                              taskIdCol === task._id && (
                                <ProgressLabel>
                                  <span>
                                    <i className="arrowDownFifth Down"></i>
                                  </span>
                                  <SpanText>
                                    {task.progress100
                                      ? task.progress100
                                      : "100% Complete"}
                                  </SpanText>
                                </ProgressLabel>
                              )}

                            {task.progress >= 20 ? (
                              <>
                                <ColColorBlue
                                  onMouseOver={() => {
                                    handleHoverOnCol(1, task?._id);
                                    setShowProLabel20(true);
                                  }}
                                  onMouseLeave={() => {
                                    setShowProLabel20(false);
                                    setHoverCol(false);
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let isDes = true;
                                    updateProgressOnServer(
                                      task?._id,
                                      0,
                                      task.cced,
                                      isDes
                                    );
                                    setUpdateTaskId(task._id);
                                    setProgress(0);
                                    setContent("todo");
                                  }}
                                />
                              </>
                            ) : hoverCol ? (
                              taskIdCol === task._id ? (
                                <>
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        20,
                                        task.cced
                                      );
                                      setUpdateTaskId(task._id);
                                      setProgress(20);
                                      setContent("inprogress");
                                    }}
                                    onMouseLeave={() => {
                                      setShowProLabel20(false);
                                      setHoverCol(false);
                                    }}
                                  />
                                </>
                              ) : (
                                <ColColor
                                  onMouseLeave={() => {
                                    setShowProLabel20(false);
                                  }}
                                />
                              )
                            ) : (
                              <ColColor
                                onMouseOver={() => {
                                  handleHoverOnCol(1, task?._id);
                                  setShowProLabel20(true);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateProgressOnServer(
                                    task?._id,
                                    20,
                                    task.cced
                                  );
                                  setUpdateTaskId(task._id);
                                  setProgress(20);
                                }}
                                onMouseLeave={() => {
                                  setShowProLabel20(false);
                                  setHoverCol(false);
                                }}
                              />
                            )}

                            {task.progress >= 40 ? (
                              <ColColorBlue
                                // testing
                                onMouseOver={() => {
                                  handleHoverOnCol(2, task?._id);
                                  setShowProLabel40(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverCol(false);
                                  setShowProLabel40(false);
                                  setShowProLabel20(false);
                                }}
                                // testing

                                onClick={(e) => {
                                  e.stopPropagation();
                                  let isDes = true;
                                  updateProgressOnServer(
                                    task?._id,
                                    20,
                                    task.cced,
                                    isDes
                                  );
                                  setUpdateTaskId(task._id);
                                  setProgress(20);
                                  setContent("inprogress");
                                }}
                              />
                            ) : hoverCol && 2 <= hoverColNum ? (
                              taskIdCol === task._id ? (
                                <>
                                  <ColColorBlue
                                    onMouseOver={() => {
                                      setShowProLabel40(true);
                                    }}
                                    onMouseLeave={() => {
                                      setHoverCol(false);
                                      setShowProLabel40(false);
                                      setShowProLabel20(false);
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        40,
                                        task.cced
                                      );
                                      setUpdateTaskId(task._id);
                                      setProgress(40);
                                      setContent("inprogress");
                                    }}
                                  />
                                </>
                              ) : (
                                <ColColor />
                              )
                            ) : (
                              <ColColor
                                // normal color
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateProgressOnServer(
                                    task?._id,
                                    40,
                                    task.cced
                                  );
                                  setUpdateTaskId(task._id);

                                  setProgress(40);
                                  setContent("inprogress");
                                }}
                                onMouseOver={() => {
                                  handleHoverOnCol(2, task?._id);
                                  setShowProLabel40(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverCol(false);
                                }}
                              />
                            )}

                            {task.progress >= 60 ? (
                              <ColColorBlue
                                onMouseOver={() => {
                                  handleHoverOnCol(3, task?._id);
                                  setShowProLabel60(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverCol(false);
                                  setShowProLabel60(false);
                                  setShowProLabel40(false);
                                  setShowProLabel20(false);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let isDes = true;
                                  updateProgressOnServer(
                                    task?._id,
                                    40,
                                    task.cced,
                                    isDes
                                  );
                                  setUpdateTaskId(task._id);
                                  setProgress(40);
                                  setContent("inprogress");
                                }}
                              />
                            ) : hoverCol && 3 <= hoverColNum ? (
                              taskIdCol === task._id ? (
                                <ColColorBlue
                                  onMouseOver={() => {
                                    setShowProLabel60(true);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverCol(false);
                                    setShowProLabel60(false);
                                    setShowProLabel40(false);
                                    setShowProLabel20(false);
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateProgressOnServer(
                                      task?._id,
                                      60,
                                      task.cced
                                    );
                                    setUpdateTaskId(task._id);
                                    setProgress(60);
                                    setContent("inprogress");
                                  }}
                                />
                              ) : (
                                <ColColor />
                              )
                            ) : (
                              <ColColor
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateProgressOnServer(
                                    task?._id,
                                    60,
                                    task.cced
                                  );
                                  setUpdateTaskId(task._id);
                                  setContent("inprogress");
                                }}
                                onMouseOver={() => {
                                  handleHoverOnCol(3, task?._id);
                                  setShowProLabel60(true);
                                }}
                                onMouseLeave={() => setHoverCol(false)}
                              />
                            )}

                            {task.progress >= 80 ? (
                              <ColColorBlue
                                onMouseOver={() => {
                                  handleHoverOnCol(4, task?._id);
                                  setShowProLabel80(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverCol(false);
                                  setShowProLabel80(false);
                                  setShowProLabel60(false);
                                  setShowProLabel40(false);
                                  setShowProLabel20(false);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let isDes = true;
                                  updateProgressOnServer(
                                    task?._id,
                                    60,
                                    task.cced,
                                    isDes
                                  );
                                  setUpdateTaskId(task._id);
                                  setProgress(60);
                                  setContent("inprogress");
                                }}
                              />
                            ) : hoverCol && 4 <= hoverColNum ? (
                              taskIdCol === task._id ? (
                                <ColColorBlue
                                  onMouseOver={() => {
                                    setShowProLabel80(true);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverCol(false);
                                    setShowProLabel80(false);
                                    setShowProLabel60(false);
                                    setShowProLabel40(false);
                                    setShowProLabel20(false);
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateProgressOnServer(
                                      task?._id,
                                      80,
                                      task.cced
                                    );
                                    setUpdateTaskId(task._id);
                                    setProgress(80);
                                    setContent("inprogress");
                                  }}
                                />
                              ) : (
                                <ColColor />
                              )
                            ) : (
                              <ColColor
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateProgressOnServer(
                                    task?._id,
                                    80,
                                    task.cced
                                  );
                                  setUpdateTaskId(task._id);
                                  setProgress(80);

                                  setContent("inprogress");
                                }}
                                onMouseOver={() =>
                                  handleHoverOnCol(4, task?._id)
                                }
                                onMouseLeave={() => setHoverCol(false)}
                              />
                            )}
                            {task.progress >= 100 ? (
                              <ColColorBlue
                                // testing

                                onMouseOver={() => {
                                  handleHoverOnCol(5, task?._id);
                                  setShowProLabel100(true);
                                }}
                                onMouseLeave={() => {
                                  setHoverCol(false);
                                  setShowProLabel100(false);
                                  setShowProLabel80(false);
                                  setShowProLabel60(false);
                                  setShowProLabel40(false);
                                  setShowProLabel20(false);
                                }}
                                // testing

                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateProgressOnServer(
                                    task?._id,
                                    100,
                                    task.cced
                                  );
                                  setUpdateTaskId(task._id);

                                  setProgress(100);
                                  setContent("completed");
                                }}
                              />
                            ) : (
                              <ColColor
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    task.isTemplateTask &&
                                    task.subTasks &&
                                    checkIfAnySubTasIskNotCompleted(
                                      task.subTasks
                                    )
                                  ) {
                                    NotificationManager.error(
                                      "Task can only be completed once all sub-tasks are marked completed!"
                                    );
                                  } else
                                    updateProgressOnServer(
                                      task?._id,
                                      100,
                                      task.cced
                                    );
                                  setUpdateTaskId(task._id);
                                  setProgress(100);
                                  setContent("completed");
                                }}
                                onMouseOver={() => {
                                  setShowProLabel100(true);
                                  handleHoverOnCol(4, task?._id);
                                }}
                                onMouseLeave={() => {
                                  setShowProLabel100(false);
                                  setHoverCol(false);
                                  setShowProLabel80(false);
                                  setShowProLabel60(false);
                                  setShowProLabel40(false);
                                  setShowProLabel20(false);
                                }}
                              />
                            )}
                            <CopyTaskComp task={task} index={index} />
                            {task.assignedFrom?._id === userState.user?.id && (
                              <DeleteTaskComp
                                taskId={task?._id}
                                index={index}
                              />
                            )}
                          </ProgressContainer>
                        ) : (
                          //-------------this is progress bar,,, it is  visible in mobile only  ----------------
                          <RowMobileProgressBar>
                            <Col sm={11} style={{ flex: "1" }}>
                              <ProgressContainer>
                                <div
                                  className={`${USETIFULL_PERCENT_TASK_COMPLETE}${
                                    index + 1
                                  }`}
                                ></div>
                                {task.progress >= 20 ? (
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      let isDes = true;
                                      updateProgressOnServer(
                                        task?._id,
                                        0,
                                        task.cced,
                                        isDes
                                      );
                                    }}
                                  />
                                ) : (
                                  <ColColor
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        20,
                                        task.cced
                                      );
                                    }}
                                  />
                                )}

                                {task.progress >= 40 ? (
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      let isDes = true;
                                      updateProgressOnServer(
                                        task?._id,
                                        20,
                                        task.cced,
                                        isDes
                                      );
                                    }}
                                  />
                                ) : (
                                  <ColColor
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        40,
                                        task.cced
                                      );
                                    }}
                                    onMouseOver={() =>
                                      handleHoverOnCol(2, task?._id)
                                    }
                                    onMouseLeave={() => setHoverCol(false)}
                                  />
                                )}
                                {task.progress >= 60 ? (
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      let isDes = true;
                                      updateProgressOnServer(
                                        task?._id,
                                        40,
                                        task.cced,
                                        isDes
                                      );
                                    }}
                                  />
                                ) : (
                                  <ColColor
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        60,
                                        task.cced
                                      );
                                    }}
                                    onMouseOver={() =>
                                      handleHoverOnCol(3, task?._id)
                                    }
                                    onMouseLeave={() => setHoverCol(false)}
                                  />
                                )}
                                {task.progress >= 80 ? (
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      let isDes = true;
                                      updateProgressOnServer(
                                        task?._id,
                                        60,
                                        task.cced,
                                        isDes
                                      );
                                    }}
                                  />
                                ) : (
                                  <ColColor
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        80,
                                        task.cced
                                      );
                                    }}
                                    onMouseOver={() =>
                                      handleHoverOnCol(4, task?._id)
                                    }
                                    onMouseLeave={() => setHoverCol(false)}
                                  />
                                )}
                                {task.progress >= 100 ? (
                                  <ColColorBlue
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateProgressOnServer(
                                        task?._id,
                                        100,
                                        task.cced
                                      );
                                    }}
                                  />
                                ) : (
                                  <ColColor
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        task.isTemplateTask &&
                                        task.subTasks &&
                                        checkIfAnySubTasIskNotCompleted(
                                          task.subTasks
                                        )
                                      ) {
                                        NotificationManager.error(
                                          "Task can only be completed once all sub-tasks are marked completed!"
                                        );
                                      } else
                                        updateProgressOnServer(
                                          task?._id,
                                          100,
                                          task.cced
                                        );
                                    }}
                                    onMouseOver={() =>
                                      handleHoverOnCol(5, task?._id)
                                    }
                                    onMouseLeave={() => setHoverCol(false)}
                                  />
                                )}
                                <ProgressCountSpanMobile>
                                  {task.progress}%
                                </ProgressCountSpanMobile>
                              </ProgressContainer>
                            </Col>
                            <Col
                              sm={1}
                              style={{
                                flex: "1",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <CopyTaskComp task={task} index={index} />
                              {task.assignedFrom?._id ===
                                userState.user?.id && (
                                <DeleteTaskComp
                                  taskId={task?._id}
                                  index={index}
                                />
                              )}
                            </Col>
                          </RowMobileProgressBar>
                        )}
                      </ThirdRow>
                    </Col>

                    {/* send nudge  notification  popup here */}

                    {sendNudgeNotification &&
                    task &&
                    task.assignedFrom?._id === userState.user?.id &&
                    (task.assignedTo?._id !== userState.user?.id ||
                      task.assignmentType === "group") &&
                    task._id === clickedID ? (
                      <DivForNudgeNotifications>
                        <div>
                          <CrossBTN
                            src={CrosshandOffBox}
                            onClick={(e) => {
                              handClickSendNudgeFun();
                            }}
                          />
                          {/* <span>
                        <i className="arrowSendNudge UpSignSend"></i>
                      </span> */}
                          <Row>
                            <SpanTag
                              onClick={(e) => {
                                e.stopPropagation();
                                setSendNudgeNotification(false);
                                taskClick(task, index);
                                /*  setModel(!showModel);
                            setCurrentTaskNumber(calculateTaskIndex(index));
                            setSelectedTask(task);
                            newLinkRoute(task._id); */
                              }}
                              title="TASK DETAILS"
                            >
                              CLICK TO VIEW TASK DETAILS
                            </SpanTag>
                            <HorizonLine />

                            <Col>
                              <SendNudgeImgTag src={NudgeBlueIcon} alt="img" />
                              <SendNudgeSpan
                                onClick={(e) => {
                                  _cio.track(USER_USE_NUDGE_FEATURE_EVENTS, {
                                    name: "user send casual nudge",
                                  });

                                  handleSendNudgeData(
                                    task,
                                    "casual",
                                    " Please update this task at your earliest convenience."
                                  );
                                }}
                              >
                                Send Casual Nudge
                              </SendNudgeSpan>
                            </Col>

                            <SendNudgePara>
                              Please update this task at your earliest
                              convenience.
                            </SendNudgePara>
                          </Row>
                          <Row>
                            <HorizonLine />
                          </Row>

                          <Row>
                            <Col>
                              <SendNudgeImgTag src={NudgeRedIcon} alt="img" />
                              <SendNudgeSpan
                                onClick={(e) => {
                                  _cio.track(USER_USE_NUDGE_FEATURE_EVENTS, {
                                    name: "user send urgent nudge",
                                  });
                                  handleSendNudgeData(
                                    task,
                                    "urgent",
                                    "Please update this task ASAP."
                                  );
                                }}
                              >
                                Send Urgent Nudge
                              </SendNudgeSpan>
                            </Col>
                            <SendNudgePara>
                              Please update this task ASAP.
                            </SendNudgePara>
                          </Row>
                        </div>
                      </DivForNudgeNotifications>
                    ) : (
                      sendNudgeNotification &&
                      task &&
                      task._id === clickedID && (
                        <DivForNudgeNotificationsDisabled>
                          <div>
                            <CrossBTN
                              src={CrosshandOffBox}
                              onClick={(e) => {
                                handClickSendNudgeFun();
                              }}
                            />

                            <Row>
                              <SpanTag
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                                title="TASK DETAILS"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSendNudgeNotification(false);
                                  /* setModel(!showModel);
                              setCurrentTaskNumber(calculateTaskIndex(index));
                              setSelectedTask(task);
                              newLinkRoute(task._id); */
                                  taskClick(task, index);
                                }}
                              >
                                CLICK TO VIEW TASK DETAILS
                              </SpanTag>
                              <HorizonLine />

                              <Col>
                                <SendNudgeImgTag
                                  src={NudgeDisabledIcon}
                                  alt="img"
                                />
                                <DisabledSendNudgeSpan>
                                  Send Casual Nudge
                                </DisabledSendNudgeSpan>
                              </Col>

                              <DisabledSendNudgePara>
                                Please update this task at your earliest
                                convenience.
                              </DisabledSendNudgePara>
                            </Row>
                            <Row>
                              <HorizonLine />
                            </Row>

                            <Row>
                              <Col>
                                <SendNudgeImgTag
                                  src={NudgeDisabledIcon}
                                  alt="img"
                                />
                                <DisabledSendNudgeSpan>
                                  Send Urgent Nudge
                                </DisabledSendNudgeSpan>
                              </Col>
                              <DisabledSendNudgePara>
                                Please update this task ASAP.
                              </DisabledSendNudgePara>
                            </Row>
                          </div>
                        </DivForNudgeNotificationsDisabled>
                      )
                    )}

                    {/* <p> this is recieved nudge  notification bar</p> */}
                    {
                      // task.assignedTo?._id === userState.user?.id &&
                      recievedNudgeNotification &&
                      task._id === receivedclickedID &&
                      task &&
                      task.taskNudges &&
                      task.taskNudges!.length > 0 ? (
                        <DivReceivedNotification>
                          <div>
                            <CrossBTNForNudge
                              src={CrosshandOffBox}
                              onClick={(e) => {
                                handClickRecievedNudgeFun();
                              }}
                            />

                            <MainRow>
                              {/* <span>
                            <i className="arrowReceivedNudge UpSignReceived"></i>
                          </span> */}
                              {task &&
                                task.taskNudges!.map((taskNudge: TaskNudge) => {
                                  let time = moment(
                                    taskNudge.createdAt
                                  ).fromNow();

                                  return (
                                    <>
                                      <Col>
                                        {taskNudge.from.firstName &&
                                        taskNudge.from.lastName &&
                                        taskNudge.from.email ? (
                                          <ReceivedNudgeUserName>
                                            {taskNudge.from.firstName}
                                            <ReceivedNudgeUserName>
                                              {taskNudge.from.lastName}
                                            </ReceivedNudgeUserName>
                                          </ReceivedNudgeUserName>
                                        ) : (
                                          <ReceivedNudgeUserName>
                                            {taskNudge.from.email}
                                          </ReceivedNudgeUserName>
                                        )}

                                        <Time>{time}</Time>
                                      </Col>
                                      <Row>
                                        <FirstCol md={1}>
                                          {taskNudge.type === "casual" && (
                                            <ReceivedNudgeImgTag
                                              src={NudgeBlueIcon}
                                              alt="img"
                                            />
                                          )}
                                          {taskNudge.type === "urgent" && (
                                            <ReceivedNudgeImgTag
                                              src={NudgeRedIcon}
                                              alt="img"
                                            />
                                          )}
                                        </FirstCol>
                                        <SecondCol md={11}>
                                          <ReceivedNudgePara>
                                            {taskNudge.message}
                                          </ReceivedNudgePara>
                                        </SecondCol>
                                      </Row>
                                      <Row>
                                        <HorizonLineForRecieved />
                                      </Row>
                                    </>
                                  );
                                })}
                            </MainRow>
                          </div>
                        </DivReceivedNotification>
                      ) : null
                    }

                    {recurringTaskPopUp &&
                      task &&
                      task!.recurringTaskData &&
                      task!.recurringTaskData._id === selectedRecurringId && (
                        <UpdateRecurringTaskModel
                          setRecurringTaskPopUp={setRecurringTaskPopUp}
                          recurringData={task!.recurringTaskData}
                          taskId={task._id}
                        />
                      )}
                  </ParentRow>

                  {/* {  task._id === updateTaskId &&
                       <UpdateNotificationDiv >{task.detail +` task moved on `+content}</UpdateNotificationDiv>
                   } */}
                </TaskContainer>
              </div>
            );
        })}

      {showDeleteTaskConfirmation && (
        <ConfirmationBox
          setShow={() => {
            setShowDeleteTaskConfirmation(false);
            setDeletedTaskId(undefined);
          }}
          name="Confirmation"
          message="Are you sure, you want to delete this task?"
          okButtonText="Delete"
          okButtonClick={() => {
            //handleDelete(deleteAppFeatureId);
            if (deleteTaskParmanently) removeTaskParmanently();
            else deleteTaskClick();
          }}
        />
      )}
      <PaginationMainDiv>
        {taskCount && taskCount > 0 ? (
          <Pagination
            itemCount={taskCount}
            perPageItem={pageLimit}
            pageChange={pageChange}
            pageLimitChange={pageLimitChange}
            resetPagination={resetPagination}
          />
        ) : (
          <></>
        )}
        <TrashDiv
          className={USETIFULL_VIEW_DELETED_TASK}
          onClick={() => {
            addTasks([]);
            setArchivedChecked(false);
            setTabSelected("trash");
          }}
        >
          <span>View Trash</span>
          <img alt="trash" src={TrashTaskIcon} style={{ paddingLeft: "2px" }} />
        </TrashDiv>
      </PaginationMainDiv>
    </>
  );
};

export default TaskList;
