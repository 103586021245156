import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";
import Preference from "./preference";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const AppFeatureListContainer = styled.div`
  /* background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px; */
  min-width: 50%;
  /* width:100% */
  margin-bottom: 10px;
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 100%;
  }
`;

const AddAppFeatureContainer = styled.div``;

const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #2f4c95;
`;
const DivForForm = styled.div`
  width: 80%;
  //height: 471px;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 50px;
  position: relative;
  border-radius: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 94%;
    /* height: 333px; */
    margin: 10px;
    padding: 10px;
    border: none;
  }
`;

const Label = styled.label`
  // margin-left: 5%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-top: 15px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
  }
`;
const InputField = styled(Field)`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  //margin-left: 5%;
  padding-left: 5px;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 10px;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 85%; */
    /* height: 30px; */
    padding-left: 10px;
    &::-webkit-input-placeholder {
      color: rgba(60, 59, 91, 0.29);
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
      padding-left: 10px;
    }
  }
`;

const Button = styled.button`
  /* width: 160px; */
  width: 48%;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  border: none;
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 40%;
    height: 40px;
    font-size: 12px;
    padding: 10px;
    &:hover {
      background: #0e2151;
      border: none;
    }
  }
`;

const NameError = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  // padding: 3px 22px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  /* const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  }); */
  return (
    <>
      <AppFeatureListContainer>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}

        <Formik
          enableReinitialize={true}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validate={(values: any) => {
            const errors: {
              password?: string;
              confirmPassword?: string;
            } = {};

            if (!values.password) {
              errors.password = "Required field password";
            } else if (values.password && values.password.length < 6) {
              errors.password = "Password must be at least 6 characters";
            } else if (values.password && values.password.length > 15) {
              errors.password = "Password must be less than 15 characters";
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = "Required field confirm password";
            }

            if (values.password !== values.confirmPassword)
              errors.confirmPassword =
                "Password and confirm password not matched";

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setLoading(true);
            myAxios
              .post("/update-password", values)
              .then((response: any) => {
                if (response.data.success) {
                  NotificationManager.success("profile updated successfully");
                } else {
                  NotificationManager.error(
                    response.data.message
                      ? response.data.message
                      : "Error in updating password"
                  );
                }
                setLoading(false);
                setSubmitting(false);
              })
              .catch((error) => {
                setLoading(false);
                console.error("error in updating data", error);
                setSubmitting(false);
                NotificationManager.error(parseErrorResponse(error));
              });
          }}
        >
          {({ isSubmitting, setFieldValue, values, errors }) => (
            <Form>
              <DivForForm>
                <AddAppFeatureContainer>
                  <HeadingText>Change Password</HeadingText>
                </AddAppFeatureContainer>
                <Label>Password</Label>
                <InputField
                  type="password"
                  placeholder="Password"
                  name="password"
                />
                {errors.password && <NameError>{errors.password}</NameError>}

                <Label>Confirm password</Label>
                <InputField
                  type="password"
                  placeholder="Confirm passowrd"
                  name="confirmPassword"
                />
                {errors.confirmPassword && (
                  <NameError>{errors.confirmPassword}</NameError>
                )}

                {/* this button visible on desktop only
                {isDesktopScreen && ( */}
                <Button type="submit" disabled={isSubmitting}>
                  Update password
                </Button>
                {/* )} */}

                {/* this button visible on desktop only
                {!isDesktopScreen && (
                  <div
                    style={{
                      display: "flex",
                      position: "fixed",
                      bottom: "10px",
                      right: "50%",
                    }}
                  >
                    <Button type="submit" disabled={isSubmitting}>
                      Update password
                    </Button>
                  </div>
                )} */}
              </DivForForm>
            </Form>
          )}
        </Formik>

        <Preference />
      </AppFeatureListContainer>
    </>
  );
};

export default ChangePassword;
