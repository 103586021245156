import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Category,
  Comment,
  DayFilter,
  FilterCriteria,
  PaginationTaskInput,
  RecurringTask,
  SearchCriteria,
  SubTask,
  Task,
  TaskContextProps,
  TaskContextValues,
  User,
} from "../types";

import { getFullName } from "../utils/utils";

import moment from "moment";
import {
  DefaultPaginationTaskInput,
  PageLimit,
} from "../components/pagination/pagination-constant";
import { UserContext } from "./user-context";

export const TaskContext = createContext({} as TaskContextValues);

const TaskContextProvider = (
  props: TaskContextProps
): ReactElement<TaskContextValues> => {
  const [display, setDisplay] = useState(false);
  const [projectTempTsk, setProjectTempTsk] = useState(false);
  const [taskComments, setTaskComments] = useState<Comment[]>([]);
  const [tasks, setAllTasks] = useState<Task[]>([]);

  const [archivedChecked, setArchivedChecked] = useState(false);
  const [allArchivedTasksCount, setAllArchivedTasksCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [showModel, setModel] = useState<boolean>(false);
  const [quickHelpModal, setQuickHelpModal] = useState<boolean>(false);
  const [showLoaderForSearchSuggestion, setShowLoaderForSearchSuggestion] =
    useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [tabSelected, setTabSelectedAnother] = useState<string>("todo");
  // TODO | COMPLETED | INPROGRESS

  const [allAssignee, setAllAssignee] = useState<User[]>([]);
  const [allAssignor, setAllAssignor] = useState<User[]>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);

  const [showToolTip, setToolTip] = useState<boolean>(true);
  const { userState } = useContext(UserContext);
  const [forceStopJetBot, setForceStopJetBot] = useState(true);
  const [currentPaginationTaskInput, setCurrentPaginationTaskInput] =
    useState<PaginationTaskInput>(DefaultPaginationTaskInput);
  const [taskCount, setTaskCount] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(PageLimit);
  const [currentTaskNumber, setCurrentTaskNumber] = useState<number>(0);
  const [allDaysRemaining, setAllDaysRemaining] = useState<DayFilter[]>([
    {
      daysId: 0,
      daysLabel: "Overdue",
      count: 0,
    },
    {
      daysId: 1,
      daysLabel: "In 24 hours",
      count: 0,
    },
    {
      daysId: 2,
      daysLabel: "In 48 hours",
      count: 0,
    },
    {
      daysId: 3,
      daysLabel: "In 72 hours",
      count: 0,
    },
    {
      daysId: 4,
      daysLabel: "In 3 days or more",
      count: 0,
    },
  ]);

  const [criteria, setCriteria] = useState<FilterCriteria | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria | null>(
    null
  );

  const [searchText, setSearchText] = useState<string>("");
  const [isTaskCopyClick, setTaskCopyClick] = useState<boolean>(false);
  const [copyTask, setCopyTask] = useState<Task | undefined>(undefined);

  const setTabSelected = (tabSel: string) => {
    setTabSelectedAnother(tabSel);
  };

  const applySearchFilters = useCallback(
    (searchCriteria: SearchCriteria) => {
      // reset menu criteria

      setCriteria({ assigneeList: [], assignorList: [] });

      if (
        searchCriteria.categories &&
        searchCriteria.categories.length > 0 &&
        allCategories &&
        allCategories.length > 0
      ) {
        searchCriteria.categoriesIds = [];

        searchCriteria.categories.forEach((cat) => {
          const catIndx = allCategories.findIndex(
            (c) => c.name === cat.toLowerCase()
          );

          if (
            catIndx > -1 &&
            allCategories[catIndx] &&
            allCategories[catIndx]._id &&
            searchCriteria.categoriesIds
          ) {
            searchCriteria.categoriesIds.push(allCategories[catIndx]._id);
          }
        });
      }

      setSearchCriteria(searchCriteria);
      if (searchCriteria) {
        if (searchCriteria!.status! <= 0) {
          setTabSelected("todo");
        } else if (
          searchCriteria!.status! >= 1 &&
          searchCriteria!.status! <= 4
        ) {
          setTabSelected("inprogress");
        } else if (
          searchCriteria!.status! > 4 &&
          searchCriteria!.status! <= 5
        ) {
          setTabSelected("completed");
        }
      }
    },
    [allCategories]
  );

  const applyFilters = useCallback((criteria: FilterCriteria | null) => {
    // reset search criteria

    setSearchCriteria(null);
    setSearchText("");

    setCriteria(criteria);
  }, []);

  useEffect(() => {
    if (searchCriteria) {
      applySearchFilters(searchCriteria);
    }
  }, [searchCriteria, applySearchFilters]);

  useEffect(() => {
    if (!searchCriteria) {
      applyFilters(criteria);
    }
  }, [applyFilters, criteria, searchCriteria]);

  const updateToggle = (taskId: any) => {
    // console.log("updateToggle for taskId ", taskId);
    const filterTaskData = tasks;
    if (filterTaskData) {
      const taskIndex = filterTaskData.findIndex(
        (fTask) => fTask._id === taskId
      );
      if (taskIndex > -1) {
        setAllTasks(filterTaskData);
      }
    }
  };

  const updateTask = (task: Task) => {
    // map existing task list and update this task
    addTasks(
      tasks.map((t) => {
        if (task._id === t._id) return { ...task };
        return t;
      })
    );
  };

  const updateRecurringTask = (
    taskId: string,
    recurringTask: RecurringTask
  ) => {
    addTasks(
      tasks.map((t) => {
        if (taskId === t._id) {
          return {
            ...t,
            recurringTaskData: {
              ...recurringTask,
            },
          };
        }
        return t;
      })
    );
  };

  // function for remove filter ---
  const removeCatFilter = (catId: any) => {
    const catIndex = criteria!.categoryList!.findIndex((c) => c._id === catId);

    if (catIndex > -1) {
      const newCriteria = { ...criteria };

      newCriteria.categoryList!.splice(catIndex, 1);
      setCriteria(newCriteria);
    }
  };

  const removeAssigneeFilter = (assigneeId: any) => {
    const assigneeIdx = criteria!.assigneeList!.findIndex(
      (assignee) => assignee._id === assigneeId
    );
    if (assigneeIdx > -1) {
      const newCriteria = { ...criteria };
      newCriteria.assigneeList!.splice(assigneeIdx, 1);
      setCriteria(newCriteria);
    }
  };

  const removeAssignorFilter = (assignorId: any) => {
    const assignorIdx = criteria!.assignorList!.findIndex(
      (assignee) => assignee._id === assignorId
    );
    if (assignorIdx > -1) {
      const newCriteria = { ...criteria };
      newCriteria.assignorList!.splice(assignorId, 1);
      setCriteria(newCriteria);
    }
  };

  const removeDueDateFilter = (daysID: any) => {
    const daysIdx = criteria!.daysRemainingList!.findIndex(
      (days) => days.daysId === daysID
    );

    if (daysIdx > -1) {
      const newCriteria = { ...criteria };

      newCriteria.daysRemainingList!.splice(daysIdx, 1);
      setCriteria(newCriteria);
    }
  };

  const addTask = useCallback(
    (newTasks: Task[]) => {
      newTasks.forEach((t) => {
        t.cced = false;
        t.notificationOn = true;

        if (t.notificationUsers && t.notificationUsers.length > 0) {
          if (
            t.notificationUsers.findIndex((ntu) => {
              if (ntu.hasOwnProperty("_id")) {
                return ntu._id === userState.user?.id;
              } else {
                return ntu === userState.user?.id;
              }
            }) > -1
          ) {
            // if user is not part of assigned to and assigned from and is part of cc users
            t.notificationOn = false;
          }
        }

        if (t.assignedTo) t.assignedTo.fullName = getFullName(t.assignedTo);
        if (t.assignedFrom)
          t.assignedFrom.fullName = getFullName(t.assignedFrom);

        if (userState.isAuthenticated && userState.user && userState.user.id) {
          if (t.ccUsers && t.ccUsers.length > 0) {
            if (
              t.assignedFrom?._id !== userState.user.id &&
              t.assignedTo?._id !== userState.user.id &&
              t.ccUsers.findIndex((cc) => cc._id === userState.user?.id) > -1
            ) {
              t.cced = true;
            }
          }
        }

        let assigneeIdx = allAssignee.findIndex(
          (u) => t.assignedTo && u._id === t.assignedTo._id
        );

        if (assigneeIdx === -1) {
          setAllAssignee((allAssignee) => [
            ...allAssignee,
            {
              _id: t.assignedTo?._id,
              email: t.assignedTo?.email,
              fullName: getFullName(t.assignedTo),
              count: 1,
            },
          ]);
        } else {
          // As assignee is already present just increment count for
          // that particular user and create a new array, just changing one reference

          setAllAssignee(
            allAssignee.map((as, idx) => {
              if (assigneeIdx !== idx) return as;
              return {
                ...as,
                count: as.count! + 1,
              };
            })
          );
        }

        if (t.groupUsers && t.groupUsers.length) {
          t.groupUsers.forEach((gu) => {
            let groupUserIdx = allAssignee.findIndex(
              (u) => gu._id && u._id === gu._id
            );
            if (groupUserIdx > -1 && gu._id !== t.assignedTo!._id) {
              setAllAssignee(
                allAssignee.map((as, idx) => {
                  if (groupUserIdx !== idx) return as;
                  return {
                    ...as,
                    count: as.count! + 1,
                  };
                })
              );
            } else if (groupUserIdx === -1) {
              setAllAssignee((allAssignee) => [
                ...allAssignee,
                {
                  _id: gu._id,
                  email: gu.email,
                  fullName: getFullName(gu),
                  count: 1,
                },
              ]);
            }
          });
        }

        let assignorIdx = allAssignee.findIndex(
          (u) => t.assignedFrom && u._id === t.assignedFrom._id
        );

        if (assignorIdx === -1) {
          setAllAssignor([
            ...allAssignor,
            {
              _id: t.assignedFrom?._id,
              email: t.assignedFrom?.email,
              fullName: getFullName(t.assignedFrom),
              count: 1,
            },
          ]);
        } else {
          setAllAssignor(
            allAssignor.map((as, idx) => {
              if (assignorIdx !== idx) return as;
              return {
                ...as,
                count: as.count! + 1,
              };
            })
          );
        }

        if (t.categories && t.categories.length) {
          let newCategories: Category[] = [];
          // console.log("newCategories", newCategories);
          t.categories.forEach((cat) => {
            if (cat.name.length > 0 && cat.name.trim().length > 0) {
              // console.log("cat", cat);
              let catIdx = allCategories.findIndex((c) => c._id === cat._id);

              if (catIdx === -1) {
                if (t.progress <= 0) {
                  newCategories.push({
                    _id: cat._id,
                    name: cat.name,
                    toDoCount: 1,
                    inProgressCount: 0,
                    completedCount: 0,
                  });
                } else if (t.progress >= 0 && t.progress <= 80) {
                  newCategories.push({
                    _id: cat._id,
                    name: cat.name,
                    inProgressCount: 1,
                    toDoCount: 0,
                    completedCount: 0,
                  });
                } else {
                  newCategories.push({
                    _id: cat._id,
                    name: cat.name,
                    completedCount: 1,
                    inProgressCount: 0,
                    toDoCount: 0,
                  });
                }
              } else {
                if (t.progress <= 0) {
                  allCategories[catIdx].toDoCount =
                    allCategories[catIdx].toDoCount! + 1;
                } else if (t.progress >= 0 && t.progress <= 80) {
                  allCategories[catIdx].inProgressCount =
                    allCategories[catIdx].inProgressCount! + 1;
                } else {
                  allCategories[catIdx].completedCount =
                    allCategories[catIdx].completedCount! + 1;
                }
              }
            }
          });

          setAllCategories([...allCategories, ...newCategories]);
        }

        let taskMoment = moment(t.duedate);

        let daysMenuArray: DayFilter[] = [...allDaysRemaining];

        if (taskMoment && t.progress < 100) {
          if (taskMoment.isSameOrBefore(moment())) {
            daysMenuArray[0].count! += 1;
          }

          let in24HoursMoment = moment().add(24, "hours");
          if (
            taskMoment.isSameOrBefore(in24HoursMoment) &&
            taskMoment.isAfter(moment())
          ) {
            daysMenuArray[1].count! += 1;
          }

          let in48HoursMoment = moment().add(48, "hours");
          if (
            taskMoment.isSameOrBefore(in48HoursMoment) &&
            taskMoment.isAfter(in24HoursMoment)
          ) {
            daysMenuArray[2].count! += 1;
          }

          let in72HoursMoment = moment().add(72, "hours");
          if (
            taskMoment.isSameOrBefore(in72HoursMoment) &&
            taskMoment.isAfter(in48HoursMoment)
          ) {
            daysMenuArray[3].count! += 1;
          }

          if (taskMoment.isAfter(in72HoursMoment)) {
            daysMenuArray[4].count! += 1;
          }

          setAllDaysRemaining(daysMenuArray);
        }
      });

      setAllTasks([...tasks, ...newTasks]);
    },
    [
      setAllTasks,
      setAllCategories,
      tasks,
      allAssignee,
      allAssignor,
      allCategories,
      allDaysRemaining,
      userState.isAuthenticated,
      userState.user,
    ]
  );

  const addTasks = useCallback(
    (tasksData: Task[]) => {
      // iterate on all tasks to find out unique assignee/assignor
      // reinitialize count

      let categoryMap = new Map<string, string>();
      let assigneeMenuMap = new Map<string, User>();
      let assignorMenuMap = new Map<string, User>();
      let categoryToDoCountMap = new Map<string, number>();
      let categoryInProgressCountMap = new Map<string, number>();
      let categoryCompletedCountMap = new Map<string, number>();
      let assigneeList: User[] = [],
        assignorList: User[] = [],
        categoryList: Category[] = [];

      let daysMenuArray: DayFilter[] = [
        {
          daysId: 0,
          daysLabel: "Overdue",
          count: 0,
        },
        {
          daysId: 1,
          daysLabel: "In 24 hours",
          count: 0,
        },
        {
          daysId: 2,
          daysLabel: "In 48 hours",
          count: 0,
        },
        {
          daysId: 3,
          daysLabel: "In 72 hours",
          count: 0,
        },
        {
          daysId: 4,
          daysLabel: "In 3 days or more",
          count: 0,
        },
      ];

      tasksData.forEach((tsk) => {
        // get distinct assignee for sub menu and also count against them
        // set cced as false by default
        tsk.cced = false;
        tsk.notificationOn = true;
        if (tsk.assignedTo)
          tsk.assignedTo.fullName = getFullName(tsk.assignedTo);
        if (tsk.assignedFrom)
          tsk.assignedFrom.fullName = getFullName(tsk.assignedFrom);

        if (userState.isAuthenticated && userState.user && userState.user.id) {
          if (tsk.ccUsers && tsk.ccUsers.length > 0) {
            if (
              tsk.assignedFrom?._id !== userState.user.id &&
              tsk.assignedTo?._id !== userState.user.id &&
              tsk.ccUsers.findIndex((cc) => cc._id === userState.user?.id) > -1
            ) {
              // if user is not part of assigned to and assigned from and is part of cc users
              tsk.cced = true;
            }
          }

          if (tsk.notificationUsers && tsk.notificationUsers.length > 0) {
            if (
              tsk.notificationUsers.findIndex((ntu) => {
                if (ntu.hasOwnProperty("_id")) {
                  return ntu._id === userState.user?.id;
                } else {
                  return ntu === userState.user?.id;
                }
              }) > -1
            ) {
              // if user is not part of assigned to and assigned from and is part of cc users
              tsk.notificationOn = false;
            }
          }
        }

        if (
          tsk.assignedTo &&
          tsk.assignedTo._id &&
          tsk.assignedTo.email &&
          tsk.progress < 100
        ) {
          if (!assigneeMenuMap.has(tsk.assignedTo._id)) {
            assigneeMenuMap.set(tsk.assignedTo._id, {
              email: tsk.assignedTo.email,
              fullName: getFullName(tsk.assignedTo),
              count: 0,
            });
          }

          assigneeMenuMap.set(tsk.assignedTo._id, {
            ...assigneeMenuMap.get(tsk.assignedTo._id),
            count: assigneeMenuMap.get(tsk.assignedTo._id)?.count! + 1,
          });
        }

        if (tsk.groupUsers && tsk.groupUsers.length) {
          tsk.groupUsers.forEach((u) => {
            if (!assigneeMenuMap.has(u._id!)) {
              assigneeMenuMap.set(u._id!, {
                email: u.email,
                fullName: getFullName(u),
                count: 0,
              });
            }

            if (u._id !== tsk.assignedTo!._id) {
              assigneeMenuMap.set(u._id!, {
                ...assigneeMenuMap.get(u._id!),
                count: assigneeMenuMap.get(u._id!)?.count! + 1,
              });
            }
          });
        }

        // get distinct assignor for sub menu and also count against them
        if (
          tsk.assignedFrom &&
          tsk.assignedFrom._id &&
          tsk.assignedFrom.email &&
          tsk.progress < 100
        ) {
          if (!assignorMenuMap.has(tsk.assignedFrom._id)) {
            assignorMenuMap.set(tsk.assignedFrom._id, {
              email: tsk.assignedFrom.email,
              fullName: getFullName(tsk.assignedFrom),
              count: 0,
            });
          }

          assignorMenuMap.set(tsk.assignedFrom._id, {
            ...assignorMenuMap.get(tsk.assignedFrom._id),
            count: assignorMenuMap.get(tsk.assignedFrom._id)?.count! + 1,
          });
        }

        // get distinct categories for sub menu and also count against them
        if (
          tsk.categories &&
          tsk.categories.length
          // && tsk.progress < 100
        ) {
          tsk.categories.forEach((cat) => {
            if (cat._id) {
              if (cat && cat.name.length > 0) {
                if (!categoryMap.has(cat._id))
                  categoryMap.set(cat._id, cat.name);

                // testing-------------------
                if (!categoryToDoCountMap.has(cat._id)) {
                  categoryToDoCountMap.set(cat._id, 0);
                }

                if (!categoryInProgressCountMap.has(cat._id)) {
                  categoryInProgressCountMap.set(cat._id, 0);
                }
                if (!categoryCompletedCountMap.has(cat._id)) {
                  categoryCompletedCountMap.set(cat._id, 0);
                }

                if (tsk.progress <= 0) {
                  categoryToDoCountMap.set(
                    cat._id,
                    categoryToDoCountMap.get(cat._id)! + 1
                  );
                }
                if (tsk.progress > 0 && tsk.progress <= 80) {
                  categoryInProgressCountMap.set(
                    cat._id,
                    categoryInProgressCountMap.get(cat._id)! + 1
                  );
                }

                if (tsk.progress === 100) {
                  // console.log("tsk.progress", tsk.progress);
                  categoryCompletedCountMap.set(
                    cat._id,
                    categoryCompletedCountMap.get(cat._id)! + 1
                  );
                }

                // testing------------
              }
            }
          });
        }

        let taskMoment = moment(tsk.duedate);

        if (taskMoment && tsk.progress < 100) {
          if (taskMoment.isSameOrBefore(moment())) {
            daysMenuArray[0].count! += 1;
          }

          let in24HoursMoment = moment().add(24, "hours");
          if (
            taskMoment.isSameOrBefore(in24HoursMoment) &&
            taskMoment.isAfter(moment())
          ) {
            daysMenuArray[1].count! += 1;
          }

          let in48HoursMoment = moment().add(48, "hours");
          if (
            taskMoment.isSameOrBefore(in48HoursMoment) &&
            taskMoment.isAfter(in24HoursMoment)
          ) {
            daysMenuArray[2].count! += 1;
          }

          let in72HoursMoment = moment().add(72, "hours");
          if (
            taskMoment.isSameOrBefore(in72HoursMoment) &&
            taskMoment.isAfter(in48HoursMoment)
          ) {
            daysMenuArray[3].count! += 1;
          }

          if (taskMoment.isAfter(in72HoursMoment)) {
            daysMenuArray[4].count! += 1;
          }
        }
      });

      // setAllDaysRemaining(daysMenuArray);

      assigneeMenuMap.forEach((value, key) => {
        assigneeList.push({
          _id: key,
          email: value.email,
          fullName: value.fullName,
          count: value.count,
        });
      });

      assignorMenuMap.forEach((value, key) => {
        assignorList.push({
          _id: key,
          email: value.email,
          count: value.count,
          fullName: value.fullName,
        });
      });

      categoryMap.forEach((value, key) => {
        categoryList.push({
          _id: key,
          name: value,
          // count: categoryCountMap.get(key)!,
          toDoCount: categoryToDoCountMap.get(key)!,
          inProgressCount: categoryInProgressCountMap.get(key)!,
          completedCount: categoryCompletedCountMap.get(key)!,
        });
      });

      setAllTasks(tasksData); // set all tasks
    },
    [setAllTasks, userState]
  );
  // for handling archived tasks in task list

  const addArchivedTasks = useCallback(
    (tasksData: Task[]) => {
      // iterate on all tasks to find out unique assignee/assignor
      // reinitialize count

      let categoryMap = new Map<string, string>();
      let assigneeMenuMap = new Map<string, User>();
      let assignorMenuMap = new Map<string, User>();

      let categoryCountMap = new Map<string, number>();
      let assigneeList: User[] = [],
        assignorList: User[] = [],
        categoryList: Category[] = [];

      let daysMenuArray: DayFilter[] = [
        {
          daysId: 0,
          daysLabel: "Overdue",
          count: 0,
        },
        {
          daysId: 1,
          daysLabel: "In 24 hours",
          count: 0,
        },
        {
          daysId: 2,
          daysLabel: "In 48 hours",
          count: 0,
        },
        {
          daysId: 3,
          daysLabel: "In 72 hours",
          count: 0,
        },
        {
          daysId: 4,
          daysLabel: "In 3 days or more",
          count: 0,
        },
      ];

      tasksData.forEach((tsk) => {
        // get distinct assignee for sub menu and also count against them
        // set cced as false by default
        tsk.cced = false;
        tsk.notificationOn = true;

        if (tsk.assignedTo)
          tsk.assignedTo.fullName = getFullName(tsk.assignedTo);
        if (tsk.assignedFrom)
          tsk.assignedFrom.fullName = getFullName(tsk.assignedFrom);

        if (userState.isAuthenticated && userState.user && userState.user.id) {
          if (tsk.ccUsers && tsk.ccUsers.length > 0) {
            if (
              tsk.assignedFrom?._id !== userState.user.id &&
              tsk.assignedTo?._id !== userState.user.id &&
              tsk.ccUsers.findIndex((cc) => cc._id === userState.user?.id) > -1
            ) {
              // if user is not part of assigned to and assigned from and is part of cc users
              tsk.cced = true;
            }
          }

          if (tsk.notificationUsers && tsk.notificationUsers.length > 0) {
            if (
              tsk.notificationUsers.findIndex((ntu) => {
                if (ntu.hasOwnProperty("_id")) {
                  return ntu._id === userState.user?.id;
                } else {
                  return ntu === userState.user?.id;
                }
              }) > -1
            ) {
              // if user is not part of assigned to and assigned from and is part of cc users
              tsk.notificationOn = false;
            }
          }
        }

        if (
          tsk.assignedTo &&
          tsk.assignedTo._id &&
          tsk.assignedTo.email &&
          tsk.progress < 100
        ) {
          if (!assigneeMenuMap.has(tsk.assignedTo._id)) {
            assigneeMenuMap.set(tsk.assignedTo._id, {
              email: tsk.assignedTo.email,
              fullName: getFullName(tsk.assignedTo),
              count: 0,
            });
          }

          assigneeMenuMap.set(tsk.assignedTo._id, {
            ...assigneeMenuMap.get(tsk.assignedTo._id),
            count: assigneeMenuMap.get(tsk.assignedTo._id)?.count! + 1,
          });
        }

        // get distinct assignor for sub menu and also count against them
        if (
          tsk.assignedFrom &&
          tsk.assignedFrom._id &&
          tsk.assignedFrom.email &&
          tsk.progress < 100
        ) {
          if (!assignorMenuMap.has(tsk.assignedFrom._id)) {
            assignorMenuMap.set(tsk.assignedFrom._id, {
              email: tsk.assignedFrom.email,
              fullName: getFullName(tsk.assignedFrom),
              count: 0,
            });
          }

          assignorMenuMap.set(tsk.assignedFrom._id, {
            ...assignorMenuMap.get(tsk.assignedFrom._id),
            count: assignorMenuMap.get(tsk.assignedFrom._id)?.count! + 1,
          });
        }

        // get distinct categories for sub menu and also count against them
        if (tsk.categories && tsk.categories.length && tsk.progress < 100) {
          tsk.categories.forEach((cat) => {
            if (cat._id) {
              if (cat && cat.name.length > 0) {
                if (!categoryMap.has(cat._id))
                  categoryMap.set(cat._id, cat.name);
                if (!categoryCountMap.has(cat._id))
                  categoryCountMap.set(cat._id, 0);
                categoryCountMap.set(
                  cat._id,
                  categoryCountMap.get(cat._id)! + 1
                );
              }
            }
          });
        }

        let taskMoment = moment(tsk.duedate);

        if (taskMoment && tsk.progress < 100) {
          if (taskMoment.isSameOrBefore(moment())) {
            daysMenuArray[0].count! += 1;
          }

          let in24HoursMoment = moment().add(24, "hours");
          if (
            taskMoment.isSameOrBefore(in24HoursMoment) &&
            taskMoment.isAfter(moment())
          ) {
            daysMenuArray[1].count! += 1;
          }

          let in48HoursMoment = moment().add(48, "hours");
          if (
            taskMoment.isSameOrBefore(in48HoursMoment) &&
            taskMoment.isAfter(in24HoursMoment)
          ) {
            daysMenuArray[2].count! += 1;
          }

          let in72HoursMoment = moment().add(72, "hours");
          if (
            taskMoment.isSameOrBefore(in72HoursMoment) &&
            taskMoment.isAfter(in48HoursMoment)
          ) {
            daysMenuArray[3].count! += 1;
          }

          if (taskMoment.isAfter(in72HoursMoment)) {
            daysMenuArray[4].count! += 1;
          }
        }
      });

      //setAllDaysRemaining(daysMenuArray);

      assigneeMenuMap.forEach((value, key) => {
        assigneeList.push({
          _id: key,
          email: value.email,
          fullName: value.fullName,
          count: value.count,
        });
      });

      assignorMenuMap.forEach((value, key) => {
        assignorList.push({
          _id: key,
          email: value.email,
          count: value.count,
          fullName: value.fullName,
        });
      });

      categoryMap.forEach((value, key) => {
        categoryList.push({
          _id: key,
          name: value,
          // count: categoryCountMap.get(key)!,
        });
      });

      // setAllAssignee(assigneeList);
      // setAllAssignor(assignorList);
      // setAllCategories(categoryList);

      setAllTasks(tasksData); // set all tasks
    },
    [setAllTasks, userState]
  );

  const addCategories = useCallback(
    (categories: Category[]) => {
      setAllCategories(categories);
    },
    [setAllCategories]
  );

  const addAssignee = useCallback(
    (assignee: User[]) => {
      setAllAssignee(assignee);
    },
    [setAllAssignee]
  );

  const addAssignor = useCallback((assignor: User[]) => {
    setAllAssignor(assignor);
  }, []);

  const addDayFilters = useCallback((daysMenuArray: DayFilter[]) => {
    setAllDaysRemaining(daysMenuArray);
  }, []);

  const updateSubTasks = (taskId: string, subTasks: SubTask[]) => {
    setAllTasks(
      tasks.map((task) => {
        if (task._id === taskId)
          return {
            ...task,
            subTasks: subTasks,
          };

        return task;
      })
    );
  };

  return (
    <TaskContext.Provider
      value={{
        display,
        setDisplay,
        tasks,
        addTasks,
        addTask,
        updateRecurringTask,
        showModel,
        setModel,
        selectedTask,
        setSelectedTask,
        updateTask,
        updateToggle,
        tabSelected,
        setTabSelected,
        allAssignee,
        allAssignor,
        allCategories,
        applyFilters,
        allDaysRemaining,
        applySearchFilters,
        setSearchCriteria,
        criteria,
        searchCriteria,
        searchText,
        setSearchText,
        taskComments,
        setTaskComments,
        removeCatFilter,
        removeAssigneeFilter,
        removeAssignorFilter,
        removeDueDateFilter,
        archivedChecked,
        setArchivedChecked,
        pageIndex,
        setPageIndex,
        pageSize,
        setPageSize,
        addArchivedTasks,
        allArchivedTasksCount,
        setAllArchivedTasksCount,
        showToolTip,
        setToolTip,
        addCategories,
        addAssignee,
        addAssignor,
        addDayFilters,
        currentPaginationTaskInput,
        setCurrentPaginationTaskInput,
        taskCount,
        setTaskCount,
        pageLimit,
        setPageLimit,
        currentTaskNumber,
        setCurrentTaskNumber,
        quickHelpModal,
        setQuickHelpModal,
        showLoaderForSearchSuggestion,
        setShowLoaderForSearchSuggestion,
        forceStopJetBot,
        setForceStopJetBot,
        copyTask,
        setCopyTask,
        isTaskCopyClick,
        setTaskCopyClick,
        updateSubTasks,
        projectTempTsk,
        setProjectTempTsk,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskContextProvider;
