import mixpanel from "mixpanel-browser";
import { useContext, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BackgroundWrapper from "./components/BackgroundWrapper";
import Callback from "./components/Callback";
import LoginSignUpView from "./components/login-signup-magic/login-signup-view";
import Loader from "./components/utils/loader";
import CommonContextProvider, { CommonContext } from "./context/common-context";
import ProjectTemplatesProvider from "./context/project-template-context";
import TaskContextProvider from "./context/task-context";
import UserContextProvider from "./context/user-context";
import UserOnBoardProvider from "./context/user-onboarding-context";
import UserProfileContextProvider from "./context/user-profile-context";
import TeamContextProvider from "./context/team-context";

const Main = () => {
  const { loader, loaderText } = useContext(CommonContext);

  useEffect(() => {
    // console.log("##### loader changed as ", loader);
  }, [loader]);

  useEffect(() => {
    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production { debug: true }
    // console.log("Setting up mixPanel...")
    mixpanel.init("c1e377c4193d27dfb3bac48e536688cf");
    //mixpanel.track("Sign up");
  }, []);

  return (
    <>
      {loader && <Loader text={loaderText ? loaderText : "Loading..."} />}
      <Router>
        <Routes>
          <Route path="/callback" element={<Callback />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/login" element={<LoginSignUpView />} />

          <Route path="/*" element={<BackgroundWrapper />} />
        </Routes>
      </Router>
      <NotificationContainer />
    </>
  );
};

const App = () => {
  return (
    <UserContextProvider>
      <TeamContextProvider>  
        <UserProfileContextProvider>
          <CommonContextProvider>
            <UserOnBoardProvider>
              <TaskContextProvider>
                <ProjectTemplatesProvider>
                  <Main />
                </ProjectTemplatesProvider>
              </TaskContextProvider>
            </UserOnBoardProvider>
          </CommonContextProvider>
        </UserProfileContextProvider>
      </TeamContextProvider>
    </UserContextProvider>
  );
};

export default App;
