import { useContext } from "react";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import freeView from "../../images/freeTask.svg";

interface SidebarWidth {
  isOpen?: boolean;
}

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
`;

const Img = styled.img`
  width: 147px;
  height: 147px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 121px;
    height: 134px;
  }
`;
const Spanfirst = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #8f8f8f;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 13px;
    line-height: 19px;
  }
`;
const SpanSecond = styled.span`
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #8f8f8f;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 13px;
    line-height: 19px;
  }
`;
const NoTaskAvailableView = ({ isOpen }: any) => {
  const { tasks, archivedChecked, tabSelected } = useContext(TaskContext);

  return (
    <>
      {tasks && tasks.length === 0 && archivedChecked === false && (
        <Div isOpen={isOpen}>
          <Img src={freeView} alt="freeview" />
          {tabSelected === "trash" ? (
            <Spanfirst>No trashed tasks found</Spanfirst>
          ) : (
            <>
              <Spanfirst>Looks like you have no new tasks</Spanfirst>
              <SpanSecond>
                Create task and assign them to anyone or yourself
              </SpanSecond>
            </>
          )}
        </Div>
      )}

      {tasks && tasks.length === 0 && archivedChecked === true && (
        <Div>
          <Spanfirst>"No archived tasks found"</Spanfirst>
        </Div>
      )}
    </>
  );
};
export default NoTaskAvailableView;
