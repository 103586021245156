import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import ToggleOff from "../../images/toggle-off.svg";
import ToggleOn from "../../images/toggle-on.svg";
import { AppFeature } from "../../types";
import myAxios from "../../utils/myaxios";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const AppFeatureListContainer = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 850px; */
  min-width: 80%;
  /* width:100% */
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    min-width: 100%;
  }
`;
const AppFeatureUlList = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
`;
const AppFeatureListHeading = styled.li`
  color: #797979;
  font-size: 12px;
  font-weight: 400;
`;
const AppFeatureLiList = styled.li`
  color: #3c3b5bcc;
  font-size: 16px;
  font-weight: 400;
`;
const InputBox = styled.input`
  /* width: 87%; */
  width: 76%;
  height: 40px;
  margin: 0;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;
const AddAppFeature = styled.button`
  border: none;
  background: #2f4c95;
  color: white;
  padding: 8px 11px;
  margin: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const RowListContainer = styled(Row)`
  border-bottom: 1px solid #e6e6e6;
  margin-top: 15px;
  padding-bottom: 15px;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ButtonLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #08ca1b;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
const AddAppFeatureContainer = styled.div``;
const Div = styled.div`
  margin-top: 20px;
`;
const HeadingText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #2f4c95;
`;
const SubHeadingText = styled.span`
  color: #2f4c95;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
const EditDelContainer = styled.div``;

const ErrorSpan = styled.span`
  color: red;
`;

const AppFeatureList = () => {
  const { userState } = useContext(UserContext);
  const {
    appfeatures,
    setAppfeatures,
    addAppFeature,
    removeAppFeature,
    updateAppFeature,
  } = useContext(AdminContext);
  const [appFeatureName, setAppFeatureName] = useState("");

  const [appFeatureError, setAppFeatureError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAddButtonLoading, setShowAddButtonLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // eslint-disable-next-line
  const [deleteAppFeatureId, setDeleteAppFeatureId] = useState<string>("");
  const TIME_OUT = 100;

  const checkIfAppFeatureAlreadyExist = () => {
    if (appfeatures) {
      const found = appfeatures.some((el) => el.name === appFeatureName);
      if (found) {
        setAppFeatureError("app feature already added");
        return true;
      } else return false;
    } else {
      return false;
    }
  };
  const addAppFeatureClick = () => {
    if (appFeatureName === "" || appFeatureName.trim() === "") {
      setAppFeatureError("Please enter app feature name");
      return;
    }

    if (checkIfAppFeatureAlreadyExist()) return;
    setShowAddButtonLoading(true);
    myAxios
      .post(`admin-settings/app-feature`, {
        name: appFeatureName,
        productionMode: false,
        developmentMode: false,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "App feature added successfully...",
            "",
            1500
          );
          addAppFeature(response.data.data);
          setAppFeatureName("");
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to add...",
            "",
            5000
          );
        }
        setShowAddButtonLoading(false);
      })
      .catch((err) => {
        console.error("Getting error ", err);
        NotificationManager.error(parseErrorResponse(err));
        setShowAddButtonLoading(false);
      });
  };

  const handleDelete = (id: string) => {
    setShowDeleteConfirmation(false);

    setLoading(true);
    setTimeout(() => {
      myAxios
        .delete(`admin-settings/app-feature/` + id)
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "App feature deleted successfully...",
              "",
              1500
            );
            removeAppFeature(id);
          } else {
            NotificationManager.error(response.data.message, "", 5000);
          }
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(parseErrorResponse(err));
          setLoading(false);
        });
    }, TIME_OUT);
  };

  const updateProductionMode = (id: string, productionMode: boolean) => {
    setLoading(true);
    setTimeout(() => {
      myAxios
        .put(`admin-settings/app-feature/` + id, {
          productionMode: productionMode,
        })
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "App feature updated successfully...",
              "",
              1500
            );
            updateAppFeature(response.data.data);
            setAppFeatureName("");
          } else {
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Somthing went wrong, not able to update...",
              "",
              5000
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Getting error ", err);
          NotificationManager.error(parseErrorResponse(err));
          setLoading(false);
        });
    }, TIME_OUT);
  };

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`admin-settings/app-feature-list`)
          .then((response) => {
            if (response.data && response.data.data) {
              setAppfeatures(response.data.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting app features");
          });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [userState]);

  useEffect(() => {
    if (appFeatureName && appFeatureName.length > 0) {
      setAppFeatureError("");
    }
  }, [appFeatureName]);

  return (
    <>
      <AppFeatureListContainer>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}
        <AddAppFeatureContainer>
          <HeadingText>Add New Feature</HeadingText>
          <Div>
            <InputBox
              type="email"
              value={appFeatureName}
              onChange={(e) => setAppFeatureName(e.target.value)}
              placeholder="Feature Name"
            />

            <AddAppFeature onClick={addAppFeatureClick}>
              {!showAddButtonLoading && "Add"}
              {showAddButtonLoading && <ButtonLoader />}
            </AddAppFeature>
          </Div>
          <ErrorSpan> {appFeatureError && appFeatureError}</ErrorSpan>
        </AddAppFeatureContainer>
        <div style={{ marginTop: "25px" }}>
          <SubHeadingText>Added App Features</SubHeadingText>
        </div>

        <AppFeatureUlList>
          {appfeatures && appfeatures.length > 0 && (
            <Row>
              <Col>
                <AppFeatureListHeading>Featutre Name</AppFeatureListHeading>
              </Col>

              <Col>
                <AppFeatureListHeading>Production</AppFeatureListHeading>
              </Col>
            </Row>
          )}

          {appfeatures &&
            appfeatures.length > 0 &&
            appfeatures.map((appFeature: AppFeature) => {
              return (
                <RowListContainer key={appFeature._id}>
                  <Col>
                    {" "}
                    <AppFeatureLiList>{appFeature.name}</AppFeatureLiList>
                  </Col>

                  <Col>
                    <EditDelContainer>
                      {appFeature.productionMode ? (
                        <img
                          alt="production mode on"
                          src={ToggleOn}
                          className="img-responsive center-block"
                          onClick={() => {
                            updateProductionMode(
                              appFeature._id,
                              !appFeature.productionMode
                            );
                          }}
                        />
                      ) : (
                        <img
                          alt="production mode off"
                          src={ToggleOff}
                          className="img-responsive center-block"
                          onClick={() => {
                            updateProductionMode(
                              appFeature._id,
                              !appFeature.productionMode
                            );
                          }}
                        />
                      )}
                    </EditDelContainer>
                  </Col>
                </RowListContainer>
              );
            })}
        </AppFeatureUlList>
        {showDeleteConfirmation && (
          <ConfirmationBox
            setShow={setShowDeleteConfirmation}
            name="Confirmation"
            message="Are you sure, you want to delete this app feature?"
            okButtonText="Delete"
            okButtonClick={() => {
              handleDelete(deleteAppFeatureId);
            }}
          />
        )}
      </AppFeatureListContainer>
    </>
  );
};

export default AppFeatureList;
