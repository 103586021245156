import { useMediaQuery } from "react-responsive";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackArrow from "../../images/backarrow.svg";
import EmailNotificationSettings from "./email-notification-settings";
import UserProfile from "./user-profile";
import ChangePassword from "./change-password";
import { useState } from "react";
import EmailListView from "./email-list-view";
import GroupListView from "./group-list-view";
import { useContext } from "react";
import { TeamContext } from "../../context/team-context";

const Container = styled.div`
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "253px" : "60px")};

  @media (max-width: 1199px) and (min-width: 320px) {
    margin-left: ${(props: SidebarWidth) => (props.isOpen ? "0" : "0")};
  }
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 60px;
  background: #ffe6cc;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
  }
`;
interface SidebarWidth {
  isOpen?: boolean;
}
const InnerDiv = styled.div`
  //padding: 21px 30px;
  padding-top: 15px;
  padding-left: 32px;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 10px 15px;
    display: flex;
    align-items: center;
  }
`;

const TemplateSpan = styled.span`
  //display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding-left: 10px;
    font-size: 12px;
  }
`;
const TabContainer = styled.div`
  overflow: hidden;
  background-color: #fdf0e2;
  padding-left: 23px;
  padding-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
const TabContainerFprMobile = styled.div`
  overflow: hidden;
  /* background-color: #fdf0e2; */
  padding-left: 23px;
  padding-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.5px 16px;
  min-width: 100px;
  transition: 0.3s;
  font-size: 17px;
  position: relative;
  background-color: #fdf0e2;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #2F4C95" : "3px solid #FDF0E2"};
  border-top-left-radius: ${(props) => (props.selected ? "5px" : "0px")};
  border-top-right-radius: ${(props) => (props.selected ? "5px" : "0px")};
  color: ${(props) => (props.selected ? "#2F4C95" : "#ABAEC3")};
  font-weight: 700;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 12px;
  }



`;
const TabForMobile = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.5px 16px;
  min-width: 100px;
  transition: 0.3s;
  font-size: 12px;
  position: relative;
  background: none;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #FC6601" : "3px solid #FDF0E2"};
  border-top-left-radius: ${(props) => (props.selected ? "5px" : "0px")};
  border-top-right-radius: ${(props) => (props.selected ? "5px" : "0px")};
  color: ${(props) => (props.selected ? "#FC6601" : "#ABAEC3")};
  font-weight: 700;
`;

const UserProfileContainer = styled.div`
  display: flex;
  padding: 10px 50px 0 27px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0px;
  }
`;

const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 23px;
  }
`;
const UserSettingsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* justify-content: start; */
  padding: 10px 0 0 0;
  margin: 0 35px;
`;
const UserSettingTab = () => {
  // let navigate = useNavigate();
  // const location = useLocation();

  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const [showEmailNotificationsComponent, setShowEmailNotificationsComponent] =
    useState(true);
  return (
    <>
      {!isDesktopScreen && (
        <>
          <TabContainerFprMobile>
            <TabForMobile
              onClick={() => {
                setShowEmailNotificationsComponent(true);
              }}
              selected={showEmailNotificationsComponent}
            >
              Email notifications
            </TabForMobile>
            <TabForMobile
              onClick={() => {
                setShowEmailNotificationsComponent(false);
              }}
              selected={showEmailNotificationsComponent === false}
            >
              Change password
            </TabForMobile>
          </TabContainerFprMobile>
        </>
      )}
      {showEmailNotificationsComponent && !isDesktopScreen ? (
        <EmailNotificationSettings />
      ) : (
        !isDesktopScreen && <ChangePassword />
      )}

      {/* in this condition this component not visbible in mobile view  */}
      {isDesktopScreen && (
        <UserSettingsContainer>
          <EmailNotificationSettings />
          <ChangePassword />
        </UserSettingsContainer>
      )}
    </>
  );
};
const UserProfileTab = () => {
  return (
    <>
      <UserProfileContainer>
        <UserProfile />
      </UserProfileContainer>
    </>
  );
};
const UserGroupsTab = () => {
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const [showEmailNotificationsComponent, setShowEmailNotificationsComponent] =
    useState(true);
  return (
    <>
      {!isDesktopScreen && (
        <>
          <TabContainerFprMobile>
            <TabForMobile
              onClick={() => {
                setShowEmailNotificationsComponent(true);
              }}
              selected={showEmailNotificationsComponent}
            >
              List Of Emails
            </TabForMobile>
            <TabForMobile
              onClick={() => {
                setShowEmailNotificationsComponent(false);
              }}
              selected={showEmailNotificationsComponent === false}
            >
              List Of Groups
            </TabForMobile>
          </TabContainerFprMobile>
        </>
      )}
      {showEmailNotificationsComponent && !isDesktopScreen ? (
        <EmailListView />
      ) : (
        !isDesktopScreen && <GroupListView />
      )}

      {isDesktopScreen && (
        <UserProfileContainer>
          <EmailListView />
          <GroupListView />
        </UserProfileContainer>
      )}
    </>
  );
};
const UserProfileView = ({ isOpen }: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const { selectedTeam } = useContext(TeamContext);

  return (
    <>
      <Container isOpen={isOpen}>
        <HeaderDiv>
          <InnerDiv>
            {!isDesktopScreen && (
              <BackArrowTag
                src={BackArrow}
                alt="backArrow"
                onClick={() => {
                  navigate("/");
                }}
              />
            )}
            {!isDesktopScreen && <TemplateSpan>Profile view</TemplateSpan>}
          </InnerDiv>
        </HeaderDiv>

        <TabContainer>
          <Tab
            onClick={() => {
              navigate("/user-profile");
            }}
            selected={location.pathname === "/user-profile"}
          >
            User Profile
          </Tab>
          <Tab
            onClick={() => {
              navigate("/user-profile/user-settings");
            }}
            selected={location.pathname === "/user-profile/user-settings"}
          >
            User Settings
          </Tab>
          {selectedTeam && selectedTeam.type === "company" && (
            <></>
            /*  <Tab
              onClick={() => {
                navigate("/user-profile/user-groups");
              }}
              selected={location.pathname === "/user-profile/user-groups"}
            >
              User Groups
            </Tab> */
          )}
        </TabContainer>
        <Routes>
          <Route path="/" element={<UserProfileTab />} />
        </Routes>
        <Routes>
          <Route path="/user-settings" element={<UserSettingTab />} />
        </Routes>
        {selectedTeam && selectedTeam.type === "company" && (
          <Routes>
            <Route path="/user-groups" element={<UserGroupsTab />} />
          </Routes>
        )}
      </Container>
    </>
  );
};

export default UserProfileView;
