import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import DeleteIcon from "../../images/delete-normal.svg";
import { CustomEmailDomain } from "../../types";
import myAxios from "../../utils/myaxios";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 55%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const AddDomain = styled.button`
  border: none;
  background: #2f4c95;
  color: white;
  padding: 8px 11px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const DomainLiListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
  position: relative;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const DomaintoggelContainer = styled.div``;
const Div = styled.div`
  margin-top: 20px;
`;
const HeadingText = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #2f4c95;
`;
const SubHeadingText = styled.span`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #2f4c95;
`;
const EditDelContainer = styled.div``;
const DomainListContainer = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  height: 100%;
`;
const DomainContainer = styled(Col)`
  /* min-width: 32%; */
  position: relative;
`;
const DomainUlList = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
`;
const DomainLiList = styled.li`
  color: #3c3b5bcc;
  font-size: 16px;
  font-weight: 400;
`;
const InputBox = styled.input`
  width: 82%;
  height: 40px;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 5px;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;
const ErrorSpan = styled.span`
  color: red;
`;
const DeleteImage = styled.img.attrs({
  src: DeleteIcon,
})`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

const ButtonLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #08ca1b;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const CustomDomainList = () => {
  const { userState } = useContext(UserContext);
  const {
    customDomains,
    setCustomDomains,
    addCustomDomain,
    removeCustomDomain,
    selectedTestGroupId,
  } = useContext(AdminContext);
  const [domain, setDomain] = useState("");
  const [domainError, setDomainError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAddButtonLoading, setShowAddButtonLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteCustomDomainId, setDeleteCustomDomainId] = useState<string>("");
  // eslint-disable-next-line
  const domainPattern = /^\@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
  const TIME_OUT = 100;
  const checkIfDomainAlreadyExist = () => {
    if (customDomains && customDomains.length > 0) {
      const found = customDomains.some((el) => el.value === domain);
      if (found) {
        setDomainError("domain already added");
        return true;
      } else return false;
    } else {
      return false;
    }
  };
  const handleAddDomain = () => {
    if (domain === "") {
      setDomainError("Please enter domain name");
      return;
    }
    if (domain.match(domainPattern)) {
      if (checkIfDomainAlreadyExist()) return;
      setShowAddButtonLoading(true);
      myAxios
        .post(`admin-settings/custom-domain`, {
          domain: domain,
          groupId: selectedTestGroupId,
        })
        .then((response: any) => {
          if (response.data.success) {
            NotificationManager.success(
              "Domain added successfully...",
              "",
              1500
            );
            addCustomDomain(response.data.data);
            setDomain("");
          } else {
            NotificationManager.error(response.data.message, "", 2000);
          }
          setShowAddButtonLoading(false);
        })
        .catch((err: any) => {
          NotificationManager.error(parseErrorResponse(err));
          setShowAddButtonLoading(false);
        });
    } else {
      NotificationManager.warning("Please enter valid domain...", "", 5000);
      setDomainError("Please enter valid domain");
    }
  };

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`admin-settings/custom-domain-list/` + selectedTestGroupId)
          .then((response) => {
            if (response.data && response.data.success) {
              setCustomDomains(response.data.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting custom domains");
          });
      }, TIME_OUT);
    }
    // eslint-disable-next-line
  }, [userState]);

  const handleDelete = (id: string) => {
    setLoading(true);
    setShowDeleteConfirmation(false);
    setTimeout(() => {
      myAxios
        .delete(`admin-settings/custom-domain-email/` + id)
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "Domain deleted successfully...",
              "",
              1500
            );
            removeCustomDomain(id);
            setDomain("");
          } else {
            NotificationManager.error(response.data.message, "", 2000);
          }
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(parseErrorResponse(err));
          setLoading(false);
        });
    }, TIME_OUT);
  };

  useEffect(() => {
    if (domain.match(domainPattern)) {
      if (!checkIfDomainAlreadyExist()) setDomainError("");
    } else {
      if (domain) setDomainError("Please enter valid domain");
      else setDomainError("");
    }
    // eslint-disable-next-line
  }, [domain]);

  return (
    <DomainContainer sm={4}>
      {loading && (
        <WatchParent1>
          <WatchParent>
            <Watch color="orange" height={50} width={50} />
          </WatchParent>
        </WatchParent1>
      )}
      <DomainListContainer>
        <DomaintoggelContainer>
          <HeadingText>Custom Domains</HeadingText>
          <Div>
            <InputBox
              type="domain"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="Enter domain name"
            />
            <AddDomain onClick={handleAddDomain}>
              {!showAddButtonLoading && "Add"}
              {showAddButtonLoading && <ButtonLoader />}
            </AddDomain>
          </Div>
          <ErrorSpan> {domainError && domainError}</ErrorSpan>
        </DomaintoggelContainer>
        <div style={{ marginTop: "25px" }}>
          <SubHeadingText>Added domain</SubHeadingText>
        </div>

        <DomainUlList>
          {customDomains &&
            customDomains.length > 0 &&
            customDomains?.map((customDomain: CustomEmailDomain) => {
              return (
                <>
                  <DomainLiListContainer key={customDomain._id}>
                    <DomainLiList>{customDomain.value}</DomainLiList>
                    <EditDelContainer>
                      <DeleteImage
                        onClick={() => {
                          setDeleteCustomDomainId(customDomain._id);
                          setShowDeleteConfirmation(true);
                        }}
                      />
                    </EditDelContainer>
                  </DomainLiListContainer>
                </>
              );
            })}
        </DomainUlList>
      </DomainListContainer>
      {showDeleteConfirmation && (
        <ConfirmationBox
          setShow={setShowDeleteConfirmation}
          name="Confirmation"
          message="Are you sure, you want to delete this domain?"
          okButtonText="Delete"
          okButtonClick={() => {
            handleDelete(deleteCustomDomainId);
          }}
        />
      )}
    </DomainContainer>
  );
};

export default CustomDomainList;
