import { createContext, ReactElement, useState } from "react";
import {
  SubTemplate,
  TaskTemplate,
  TaskTemplatesProps,
  TaskTemplatesValues,
} from "../types";

export const ProjectTemplatesContext = createContext({} as TaskTemplatesValues);

const ProjectTemplatesProvider = (
  props: TaskTemplatesProps
): ReactElement<TaskTemplatesValues> => {
  const [openSidebarPopup, setSidebarPopup] = useState(false);

  const [allTaskTemplates, setAllTaskTemplates] = useState<TaskTemplate[]>([]);

  const [selectedTaskTemplates, setSelectedTaskTemplates] =
    useState<TaskTemplate | null>(null);

  const deleteTemplate = (templateId: TaskTemplate) => {
    let updateTemplateList = allTaskTemplates.filter((tskTemp: any) => {
      return tskTemp._id !== templateId;
    });

    setAllTaskTemplates(updateTemplateList);
  };

  const addNewTemplate = (template: TaskTemplate) => {
    if (allTaskTemplates && allTaskTemplates.length > 0)
      setAllTaskTemplates([...allTaskTemplates, template]);
    else setAllTaskTemplates([template]);
  };

  const updateTemplate = (template: TaskTemplate) => {
    let updateTemplateList = allTaskTemplates.map((tskTemp: any) => {
      return tskTemp._id === template._id ? template : tskTemp;
    });
    setAllTaskTemplates([...updateTemplateList]);
  };

  const updateSubTemplates = (id: string, subTemplates: SubTemplate) => {
    let updateTemplateList = allTaskTemplates.map((template: any) => {
      return template._id === id
        ? { ...template, subTemplates: subTemplates }
        : template;
    });
    setAllTaskTemplates([...updateTemplateList]);
  };

  return (
    <ProjectTemplatesContext.Provider
      value={{
        openSidebarPopup,
        setSidebarPopup,
        allTaskTemplates,
        setAllTaskTemplates,
        addNewTemplate,
        updateTemplate,
        deleteTemplate,
        selectedTaskTemplates,
        setSelectedTaskTemplates,
        updateSubTemplates,
      }}
    >
      {props.children}
    </ProjectTemplatesContext.Provider>
  );
};

export default ProjectTemplatesProvider;
