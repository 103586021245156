import { Field, FieldArray, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LOGGED_OUT } from "../consts";
import { CommonContext } from "../context/common-context";
import { TaskContext } from "../context/task-context";
import { UserContext } from "../context/user-context";
import AdminSvg from "../images/Admin.svg";
import AdminWhiteSvg from "../images/AdminWhite.svg";
import BackImgSvg from "../images/backarrow.svg";
import CalenderSvg from "../images/calender.svg";
import CategorySvg from "../images/category.svg";
import CheckSvg from "../images/check.svg";
import DownArrow from "../images/downarrow.svg";
import HourGlassSvg from "../images/Hourglass.svg";
import JetListSvg from "../images/JetList.svg";
import JetListLogoSvg from "../images/Jetlist_logo.svg";
import leftArrowSvg from "../images/leftArroww.svg";
import LogoutSvg from "../images/logout.svg";
import MenuLineSvg from "../images/menuline.svg";
import HelpInfo from "../images/qucikHelpInfo.svg";
// import SearchSvg from "../images/search.svg";
import taskTemplateBlueSvg from "../images/task-template-blue.svg";
import taskTemplateWhiteSvg from "../images/task-template.svg";
import AssignorSvg from "../images/userdown.svg";
import UserGuide from "../images/userguide.svg";
import AssigneeSvg from "../images/userup.svg";
import WhiteCalenderSvg from "../images/whiteCalender.svg";
import WhiteCategorySvg from "../images/whiteCategory.svg";
import WhiteLogoutSvg from "../images/whiteLogout.svg";
// import WhiteSearchSvg from "../images/whiteSearch.svg";
import WhiteAssignorSvg from "../images/whiteUserdown.svg";
import WhiteAssigneeSvg from "../images/whiteUserup.svg";
import TeamLogoBlueSvg from "../images/team-logo-blue.svg";
import TeamLogoWhiteSvg from "../images/team-logo-white.svg";
import { magic } from "../magic";
import { FilterCriteria } from "../types";
import myAxios, { setAuthHeader } from "../utils/myaxios";
import ConfirmationBox from "./utils/confirmation-box";
import HomeBlueSvg from "../images/homeblue.svg";
import HomeWhiteSvg from "../images/homeselected.svg";
import { useMediaQuery } from "react-responsive";
import {
  USER_FILTER_TASKS_BY_ASSIGNEE_EVENTS,
  USER_FILTER_TASKS_BY_ASSIGNER_EVENTS,
  USER_FILTER_TASKS_BY_CATEGORY_EVENTS,
  USER_FILTER_TASKS_BY_DUE_DATE_EVENTS,
} from "../customer-io/events";
import {
  USETIFULL_ASSIGNEE_FILTER_SIDE_BAR,
  USETIFULL_ASSIGNER_FILTER_SIDE_BAR,
  USETIFULL_CATEGORY_FILTER_SIDE_BAR,
  USETIFULL_DUEDATE_FILTER_SIDE_BAR,
  USETIFULL_PROJECT_TEMPLATE_SIDE_BAR,
} from "../usetifull/class-names";
import packageJson from "../../package.json";
import { TeamContext } from "../context/team-context";
declare var _cio: any;

const UserGuideTag = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 13px;
`;
const UserGuideDIv = styled.div`
  z-index: 1;
  margin-top: 14px;
  margin-bottom: 11px;
  padding-top: 4px;
`;

const AdminImage = styled.img``;

const LogoImg = styled.img.attrs({
  src: JetListLogoSvg && JetListLogoSvg,
})`
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 25px;
    height: 36px;
    position: absolute;
    left: 43%;
  }
`;
const CheckImg = styled.img``;
const LeftArrowImg = styled.img`
  width: 100%;
`;
const HourGlassImg = styled.img``;
const MenuLineImg = styled.img``;
const SearchImg = styled.img.attrs({
  src: HomeBlueSvg && HomeBlueSvg,
})``;
const CategoryImg = styled.img.attrs({
  src: CategorySvg && CategorySvg,
})``;
const CalenderImg = styled.img.attrs({
  src: CalenderSvg && CalenderSvg,
})``;
const AssigneeImg = styled.img.attrs({
  src: AssigneeSvg && AssigneeSvg,
})``;
const AssignorImg = styled.img.attrs({
  src: AssignorSvg && AssignorSvg,
})``;
const LogoutImg = styled.img`
  color: #ffffff;
  margin-left: 6px;
`;
const WhiteSearchImg = styled.img.attrs({
  src: HomeWhiteSvg && HomeWhiteSvg,
})``;
const WhiteCategoryImg = styled.img.attrs({
  src: WhiteCategorySvg && WhiteCategorySvg,
})``;
const WhiteCalenderImg = styled.img.attrs({
  src: WhiteCalenderSvg && WhiteCalenderSvg,
})``;
const WhiteAssigneeImg = styled.img.attrs({
  src: WhiteAssigneeSvg && WhiteAssigneeSvg,
})``;
const WhiteAssignorImg = styled.img.attrs({
  src: WhiteAssignorSvg && WhiteAssignorSvg,
})``;
const WhiteLogoutImg = styled.img``;
const TaskTemplateBlueImg = styled.img.attrs({
  src: taskTemplateBlueSvg && taskTemplateBlueSvg,
})`
  margin: 0 -19px;
`;
const TaskTemplateWhiteImg = styled.img.attrs({
  src: taskTemplateWhiteSvg && taskTemplateWhiteSvg,
})``;
const TeamLogoImg = styled.img.attrs({
  src: TeamLogoBlueSvg && TeamLogoBlueSvg,
})``;
const TeamLogoImgWhite = styled.img.attrs({
  src: TeamLogoWhiteSvg && TeamLogoWhiteSvg,
})``;

LogoutImg.defaultProps = {
  src: LogoutSvg,
};
WhiteLogoutImg.defaultProps = {
  src: WhiteLogoutSvg,
};
CheckImg.defaultProps = {
  src: CheckSvg,
};
HourGlassImg.defaultProps = {
  src: HourGlassSvg,
};
MenuLineImg.defaultProps = {
  src: MenuLineSvg,
};
LeftArrowImg.defaultProps = {
  src: leftArrowSvg,
};

interface SidebarWidth {
  isOpen?: boolean;
  showModel?: boolean;
}

const MenuContainer = styled.div`
  width: ${(props: SidebarWidth) => (!props.isOpen ? "71px;" : "253px")};
  height: 100%;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  z-index: ${(props: SidebarWidth) => (props.showModel ? "0" : "111")};
  transition: width 0.5s;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: ${(props: SidebarWidth) => (props.isOpen ? "block;" : "none;")};
    width: ${(props: SidebarWidth) => (!props.isOpen ? "10px;" : "150px")};
  }
`;

const MainDivForScrolling = styled.div`
  /* height: 81vh; */
  height: ${(props: SidebarWidth) => (!props.isOpen ? "100%" : "80vh")};
  overflow-y: ${(props: SidebarWidth) => (!props.isOpen ? "hidden" : "auto")};
  // overflow-y: "scroll";
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  @media (max-height: 400px) and (min-height: 220px) {
    height: ${(props: SidebarWidth) => (!props.isOpen ? "100%" : "60vh")};
  }
`;

const MainMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
`;

const MenuItem = styled.li`
  list-style: none;
  padding: 14px 15px;

  display: flex;
  cursor: pointer;
  // position: relative;

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 2px 15px;
    /* margin-bottom: 5rem; */
  }
`;

const MenuItemLogOut = styled.li`
  list-style: none;
  padding: 0px 15px;
  /* min-height: 50px; */
  display: flex;
  cursor: pointer;
  margin-bottom: 13px;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 2px 15px;
  }
`;

const MenuPageItem = styled.li`
  list-style: none;
  padding: 0px 15px;
  min-height: 50px;
  display: flex;
  cursor: pointer;
  /* margin-top: 5px; */
  align-items: center;
`;

const MenuItemLogo = styled.li`
  list-style: none;
  padding: 20px 15px;
  display: flex;
  cursor: pointer;
  padding-bottom: 0px;
`;

const MenuCalenderItem = styled.li`
  list-style: none;
  padding: 18px 15px;
  display: flex;
  cursor: pointer;
  position: relative;
`;

const MenuAssigneeItem = styled.li`
  list-style: none;
  padding: 14px 15px;
  display: flex;
  cursor: pointer;
  position: relative;
`;

const MenuAssignerItem = styled.li`
  list-style: none;
  padding: 18px 15px;
  display: flex;
  cursor: pointer;
  position: relative;
`;

const MenuItemCategory = styled.li`
  list-style: none;
  padding: 10px 15px;
  display: flex;
  cursor: pointer;
  position: relative;
`;

const BackImage = styled.img`
  display: none;
  transition: width 0.5s;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 16px;
    height: 14px;
    margin-top: 7%;
    display: block;
    transition: width 0.5s;
  }
`;

const CrossImg = styled.img`
  right: -12px;
  position: absolute;
  display: block;
  top: 86px;
  background: white;
  border-radius: 14px;
  transition: 0.5s;
  transform: ${(props: SidebarWidth) =>
    !props.isOpen ? "rotate(180deg)" : "rotate(360deg)"};
  @media (max-width: 1199px) and (min-width: 320px) {
    top: 85px;
    left: 135px;
    display: none;
  }
`;
const HR = styled.hr`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    border: 1px solid #fc6601;
    margin: 0;
    padding: 0;
    display: block;
    margin: 18px 0;
  }
`;

const StyledMenuItemLogo = styled.img`
  padding: 0 10px;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: none;
  }
`;

const StyledMenuItemSearch = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;
const StyledMenuItemCategory = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;

const StyledMenuItemCalender = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;
const StyledMenuItemUserUp = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;
const StyledMenuItemUserDown = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;
const StyledMenuItemTaskTemplate = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;
const StyledMenuItemLogOut = styled.p`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 17px;
`;

const SubMenuCloseAssigneer = styled.ul`
  background: #fff;
  /* max-height: 182px; */
  height: 450px;
  min-height: 1px;
  min-width: 150px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  // position: absolute;
  // top: 20.6%;
  // left: 97%;
  padding-left: 0;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`;

const SubMenuAssigneer = styled.ul`
  background: #fff;
  /* max-height: 182px; */
  height: 450px;
  // min-height: 1px;
  min-width: 150px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  // position: absolute;
  // top: 75.5%;
  // left: 97%;
  padding-left: 0;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`;

const ContainerAssigneer = styled.div`
  position: absolute;
  top: 20.6%;
  left: 101.5%;
  background: #fff;
  width: 215px;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const ContainerAssigneerClose = styled.div`
  position: absolute;
  top: 20.6%;
  left: 104%;
  background: #fff;
  width: 215px;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const SubMenuAssigneerItem = styled.li`
  background: #f4f4f4;
  color: white;
  list-style: none;
  white-space: nowrap;
  /* overflow: hidden; */
  display: flex;
  text-overflow: ellipsis;
  align-items: baseline;
  justify-content: space-between;
  padding: 4px 9px 4px 9px;
  border-bottom: 1px solid #2f4c951a;
  padding-left: 15px;
`;

const SubMenuAssignee = styled.ul`
  background: #fff;
  height: 450px;
  // min-height: 1px;
  min-width: 150px;
  overflow-y: scroll;
  // position: absolute;
  // top: 63.8%;
  // left: 98%;
  padding-left: 0;
`;

const ContainerAssignee = styled.div`
  position: absolute;
  top: 20.6%;
  left: 101.5%;
  width: 215px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;
const ContainerAssigneeClose = styled.div`
  position: absolute;
  top: 20.6%;
  left: 104%;
  background-color: #fff;
  width: 215px;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const SubMenuAssigneeClose = styled.ul`
  /* background: #fff; */
  background: #f4f4f4;
  height: 450px;
  min-height: 1px;
  min-width: 150px;
  overflow-y: scroll;
  // position: absolute;
  // top: 20.6%;
  // left: 98%;
  padding-left: 0;
`;

const SubMenuDue = styled.ul`
  background: #fff;
  /* max-height: 182px; */
  height: 450px;
  // min-height: 1px;
  min-width: 205px;
  overflow-y: scroll;
  // position:absolute;
  // top:39.4%;
  // left:98%;
  padding: 0px;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
`;

const SubMenuDueClose = styled.ul`
  background: #fff;
  /* max-height: 182px; */
  height: 450px;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  // min-height: 1px;
  min-width: 205px;
  overflow-y: scroll;
  // position:absolute;
  // top:20.4%;
  // left:98%;
  padding: 0px;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
`;

const ContainerDue = styled.div`
  top: 20.4%;
  left: 101.5%;
  position: absolute;
  min-width: 215px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const ContainerDueClose = styled.div`
  top: 20.4%;
  left: 104%;
  position: absolute;
  min-width: 215px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const SubMenuCategory = styled.ul`
  background: #fff;
  // max-height: 350px;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  min-width: 205px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  height: 450px;
  padding-left: 0px;
`;

const ContainerCategory = styled.div`
  top: 20.6%;
  position: absolute;
  left: 101.5%;
  min-width: 215px;
  // padding-left: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const ContainerCategoryClose = styled.div`
  top: 20.6%;
  position: absolute;
  left: 104%;
  min-width: 215px;
  // padding-left: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 15%);
`;

const SubMenuCloseCategory = styled.ul`
  background: #fff;
  // max-height: 350px;
  height: 450px;
  min-width: 205px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    background: #f0f0f0;
  }
  // top: 20.6%;
  // position: absolute;
  // left: 102%;
  padding-left: 0px;
`;

const HeadingDiv = styled.div`
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const HeadingText = styled.h5`
  color: #fc6601;
  font-size: 14px;
  margin: 0;
`;

const SubMenuAssigneeItem = styled.li`
  background: #f4f4f4;
  // padding: 0px 4px;
  color: white;
  list-style: none;
  white-space: nowrap;
  /* overflow: hidden; */
  display: flex;
  text-overflow: ellipsis;
  align-items: baseline;
  justify-content: space-between;
  padding: 4px 9px 4px 9px;
  border-bottom: 1px solid #2f4c951a;
  padding-left: 15px;
`;

const SubMenuCategoryItem = styled.li`
  background: #f4f4f4;
  color: white;
  list-style: none;
  white-space: nowrap;
  /* overflow: hidden; */
  display: flex;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #2f4c951a;
  padding: 0px 8px;
  padding-top: 4px;
  padding-left: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubMenuDueItem = styled.li`
  // padding: 0px 4px;
  background: #f4f4f4;
  color: white;
  list-style: none;
  white-space: nowrap;
  background-color: #f4f4f4;
  display: flex;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #2f4c951a;
  // margin: 6px 8px 5px 10px;
  padding: 0px 8px;
  padding-top: 10px;
  padding-left: 15px;
`;

const SpanCategory = styled.span`
  font-size: 14px;
  // line-height: 40px;
  color: #2f4c95;
  font-weight: 400;
  padding: 0 10px;
  padding-left: 0px;
  /* display:flex; */
`;

const SpanCount = styled.span`
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  /* padding: 0 10px; */
  cursor: pointer;
  color: #2f4c95;
  padding: 0px 4px;
  width: 30px;
`;

const SpanTag = styled.span`
  color: #2f4c95;
  font-size: 14px;
  line-height: 16.24px;
  margin: 4px 9px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  /* border-top: 1.5px solid #2f4c9514; */
`;

const SidebarHeader = styled.div`
  // font-family: 'Gill Sans MT';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f4c9580;
  /* padding-top: 12px; */
  padding-left: 10px;
`;

const SidebarHeaderFilter = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f4c9580;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
`;

const DataEmptyText = styled.h3`
  color: #fc6601;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

const DataEmptySubText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #797979;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;
const Version = styled.span`
  color: #2f4c95;
  padding: 3px 3px;
  /* margin-left: 15px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const VersionSpan = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f4c9580;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
`;
const FirstVersionSpan = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

// start of code snippet-----
const TaskMenu = ({ isOpen, setIsOpen, setIsOnSideBar, isOnSidebar }: any) => {
  let navigate = useNavigate();
  let currentVersion = packageJson.version;
  const location = useLocation();
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  const { selectedTeam } = useContext(TeamContext);

  const [activeMenuItem, setActiveMenuItem] = useState<string>();
  const { userDispatch, userState } = useContext(UserContext);
  const { showLoader, hideLoader } = useContext(CommonContext);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [showCategorySubmenu, setCategorySubmenu] = useState(false);
  const [showDueSubmenu, setShowDueSubmenu] = useState(false);
  const [showAssignee, setShowAssignee] = useState(false);
  const [showAssigner, setShowAssigner] = useState(false);
  const [showLogOutDailog, setShowLogOutDailog] = useState(false);

  const {
    allAssignee,
    allAssignor,
    tabSelected,
    applyFilters,
    allCategories,
    allDaysRemaining,
    criteria,
    setTabSelected,
    addCategories,
    addAssignee,
    addAssignor,
    addDayFilters,
    showModel,
    addTasks,
    setArchivedChecked,
    setProjectTempTsk,
  } = useContext(TaskContext);

  // console.log("allCategories in sidemenu", allCategories);

  const handleOnClick = async (menuItem: string) => {
    if (
      menuItem !== "user-profile" &&
      menuItem !== "project-templates" &&
      menuItem !== "admin-panel" &&
      menuItem !== "team"
    ) {
      navigate("/");
    } else if (menuItem === "project-templates") {
      navigate("project-templates");
    } else if (menuItem === "admin-panel") {
      setCategorySubmenu(false);
      setShowDueSubmenu(false);
      setShowAssignee(false);
      setShowAssigner(false);
      navigate("admin-panel");
    } else if (menuItem === "team") {
      setCategorySubmenu(false);
      setShowDueSubmenu(false);
      setShowAssignee(false);
      setShowAssigner(false);
      navigate("team");
    } else {
      navigate("user-profile");
    }
    setActiveMenuItem(menuItem);

    switch (menuItem) {
      case "logout":
        setShowLogOutDailog(false);
        showLoader("Signing out...");
        localStorage.clear();
        setAuthHeader("");
        await magic.user.logout();
        hideLoader();
        userDispatch({
          type: LOGGED_OUT,
        });
        navigate("/login");

        break;
    }
  };

  const toggle = () => {
    setCategorySubmenu(false);
    setShowDueSubmenu(false);
    setShowAssignee(false);
    setShowAssigner(false);
    setIsOpen(!isOpen);
  };

  const handleOpenSubmenu = (menuName: string) => {
    if (menuName) {
      handleOnClick(menuName);
    }
    if (activeMenuItem === menuName) {
      handleOnClick("none");
    }
  };

  useEffect(() => {
    myAxios
      .get(`menu-filters/${selectedTeam?.id}`)
      .then((response: any) => {
        addCategories(response.data.data.categories);
        addAssignee(response.data.data.assigneeList);
        addAssignor(response.data.data.assignorList);
        addDayFilters(response.data.data.daysMenuArray);
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, [selectedTeam]);

  const handleClickOutSide = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setCategorySubmenu(false);
      setShowDueSubmenu(false);
      setShowAssignee(false);
      setShowAssigner(false);
      setActiveMenuItem("");
    }
  };

  const handleClickTemplate = () => {
    setProjectTempTsk(true);
    if (isOpen) {
      setCategorySubmenu(false);
      setShowDueSubmenu(false);
      setShowAssignee(false);
      setShowAssigner(false);
      handleOnClick("project-templates");
    } else {
      setCategorySubmenu(false);
      setShowDueSubmenu(false);
      setShowAssignee(false);
      setShowAssigner(false);
      navigate("/project-templates");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, []);

  return (
    <>
      <MenuContainer
        isOpen={isOpen}
        showModel={showModel}
        ref={wrapperRef}
        onMouseEnter={() => setIsOnSideBar(true)}
        onMouseLeave={() => setIsOnSideBar(false)}
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={
            criteria
              ? criteria
              : {
                  assigneeList: [],
                  assignorList: [],
                  categoryList: [],
                  daysRemainingList: [],
                }
          }
          onSubmit={(values: FilterCriteria, { resetForm }) => {
            applyFilters(values, null);
          }}
        >
          {({
            isSubmitting,
            values,
            submitForm,

            /* and other goodies */
          }) => {
            // set criteria as soon as values are changed to trigger filter

            return (
              // <Form>
              <MainMenu>
                <MenuItemLogo>
                  <BackImage src={BackImgSvg} onClick={toggle} />
                  <LogoImg
                    src={JetListLogoSvg}
                    alt=" JetListLogo"
                    onClick={() => {
                      if (tabSelected === "trash") {
                        addTasks([]);
                        setArchivedChecked(false);
                        setTabSelected("todo");
                      }
                      navigate("/");
                    }}
                  />
                  {isOpen && (
                    <StyledMenuItemLogo
                      src={JetListSvg}
                      alt="JetList"
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  )}
                </MenuItemLogo>

                <HR />
                <MenuItem>
                  <CrossImg
                    isOpen={isOpen}
                    src={DownArrow}
                    alt="cross"
                    onClick={toggle}
                  />
                </MenuItem>

                <MainDivForScrolling isOpen={isOpen}>
                  {!isDesktopScreen && (
                    <>
                      <UserGuideDIv title="user guide">
                        <a target={"_new"} href="https://help.myjetlist.com/">
                          <UserGuideTag src={UserGuide} alt="user" />
                          <SpanTag>user guide</SpanTag>
                        </a>
                      </UserGuideDIv>
                      <UserGuideDIv title="quick help">
                        <a
                          target={"_new"}
                          href="https://quickhelp.myjetlist.com/"
                        >
                          <UserGuideTag src={HelpInfo} alt="user" />
                          <SpanTag>quick help</SpanTag>
                        </a>
                      </UserGuideDIv>
                    </>
                  )}

                  {/* search */}
                  <MenuItem
                    onClick={() => {
                      navigate("/");
                      setProjectTempTsk(false);
                    }}
                    className={activeMenuItem === "home" ? "active" : ""}
                  >
                    {activeMenuItem === "home" ? (
                      <WhiteSearchImg />
                    ) : (
                      <SearchImg />
                    )}

                    {isOpen && (
                      <StyledMenuItemSearch>Home</StyledMenuItemSearch>
                    )}
                  </MenuItem>
                  <Line />
                  <SidebarHeader>Actions</SidebarHeader>
                  <MenuPageItem
                    onClick={handleClickTemplate}
                    className={
                      location.pathname === "/project-templates" ? "active" : ""
                    }
                  >
                    {location.pathname === "/project-templates" ? (
                      <TaskTemplateBlueImg
                        title={isOpen ? "" : "Project Templates"}
                      />
                    ) : (
                      <TaskTemplateWhiteImg
                        className={USETIFULL_PROJECT_TEMPLATE_SIDE_BAR}
                        title={isOpen ? "" : "Project Templates"}
                      />
                    )}
                    {isOpen && (
                      <StyledMenuItemTaskTemplate onClick={handleClickTemplate}>
                        Project Templates
                      </StyledMenuItemTaskTemplate>
                    )}
                  </MenuPageItem>

                  {userState.user && userState.user.type === "admin" && (
                    <MenuItem
                      onClick={() => handleOnClick("admin-panel")}
                      className={
                        location.pathname.includes("/admin-panel")
                          ? "active"
                          : ""
                      }
                    >
                      {isOpen ? (
                        <AdminImage
                          src={
                            location.pathname.includes("/admin-panel")
                              ? AdminWhiteSvg
                              : AdminSvg
                          }
                          alt="admin-icon"
                          height="22px"
                          width="22px"
                        />
                      ) : (
                        <AdminImage
                          src={
                            location.pathname.includes("/admin-panel")
                              ? AdminWhiteSvg
                              : AdminSvg
                          }
                          alt="admin-icon"
                          height="22px"
                          width="22px"
                          title="Admin Panel"
                        />
                      )}
                      {isOpen && (
                        <StyledMenuItemTaskTemplate
                          onClick={() => handleOnClick("admin-penel")}
                        >
                          Admin Panel
                        </StyledMenuItemTaskTemplate>
                      )}
                    </MenuItem>
                  )}

                  {/* TEAM---- */}

                  {selectedTeam && selectedTeam.type === "company" && (
                    <MenuItem
                      onClick={() => {
                        handleOnClick("team");
                        setProjectTempTsk(false);
                      }}
                      className={
                        location.pathname.includes("/team") ? "active" : ""
                      }
                    >
                      {location.pathname === "/team" ? (
                        <TeamLogoImgWhite
                          alt="team"
                          height="22px"
                          width="22px"
                          title="team"
                        />
                      ) : (
                        <TeamLogoImg alt="team" height="22px" width="22px" />
                      )}

                      {isOpen && (
                        <StyledMenuItemTaskTemplate
                          onClick={() => handleOnClick("team")}
                        >
                          Teams
                        </StyledMenuItemTaskTemplate>
                      )}
                    </MenuItem>
                  )}

                  <Line />
                  <SidebarHeaderFilter>
                    {isOpen ? "Task Filters" : "Filters"}
                  </SidebarHeaderFilter>
                  <MenuItemCategory
                    onClick={() => isOpen && handleOpenSubmenu("category")}
                    className={
                      activeMenuItem === "category"
                        ? "active"
                        : showCategorySubmenu
                        ? "active"
                        : ""
                    }
                  >
                    {activeMenuItem === "category" ? (
                      <WhiteCategoryImg title={isOpen ? "" : "Category"} />
                    ) : showCategorySubmenu ? (
                      <WhiteCategoryImg
                        onClick={() => setCategorySubmenu(false)}
                        title={isOpen ? "" : "Category"}
                      />
                    ) : (
                      <CategoryImg
                        className={USETIFULL_CATEGORY_FILTER_SIDE_BAR}
                        onClick={() => {
                          setActiveMenuItem("");
                          setShowDueSubmenu(false);
                          setShowAssignee(false);
                          setShowAssigner(false);
                          setCategorySubmenu(!showCategorySubmenu);
                          navigate("/");
                        }}
                        title={isOpen ? "" : "Category"}
                      />
                    )}
                    {isOpen && (
                      <>
                        <StyledMenuItemCategory>
                          Category
                        </StyledMenuItemCategory>
                      </>
                    )}
                  </MenuItemCategory>
                  {showCategorySubmenu && !isOpen ? (
                    <FieldArray
                      name="categoryList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerCategoryClose>
                            {allCategories && allCategories.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Categories</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            <SubMenuCloseCategory>
                              {allCategories && allCategories.length > 0 ? (
                                allCategories.map((cat, index) => (
                                  <SubMenuCategoryItem key={cat._id}>
                                    <LabelContainer>
                                      <SpanCategory>{cat.name}</SpanCategory>
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {cat.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {cat.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {cat.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assigneeList.${index}`}
                                      value={cat}
                                      checked={
                                        values.categoryList &&
                                        values.categoryList.findIndex(
                                          (v) => v._id === cat._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_CATEGORY_EVENTS,
                                            {
                                              name: "user use category drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(cat);
                                        } else {
                                          if (values.categoryList) {
                                            const idx =
                                              values.categoryList.findIndex(
                                                (c) => c._id === cat._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuCategoryItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Categories</DataEmptyText>
                                  <DataEmptySubText>
                                    No Categories Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuCloseCategory>
                          </ContainerCategoryClose>
                        );
                      }}
                    ></FieldArray>
                  ) : null}
                  {activeMenuItem === "category" && isOpen && (
                    <FieldArray
                      name="categoryList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerCategory>
                            {allCategories && allCategories.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Cateogries</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            <SubMenuCategory>
                              {/* <LeftArrow /> */}
                              {allCategories && allCategories.length > 0 ? (
                                allCategories.map((cat, index) => (
                                  <SubMenuCategoryItem key={cat._id}>
                                    <LabelContainer>
                                      <SpanCategory>{cat.name}</SpanCategory>
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {cat.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {cat.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {cat.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assigneeList.${index}`}
                                      value={cat}
                                      checked={
                                        values.categoryList &&
                                        values.categoryList.findIndex(
                                          (v) => v._id === cat._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_CATEGORY_EVENTS,
                                            {
                                              name: "user use category drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(cat);
                                        } else {
                                          if (values.categoryList) {
                                            const idx =
                                              values.categoryList.findIndex(
                                                (c) => c._id === cat._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuCategoryItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Categories</DataEmptyText>
                                  <DataEmptySubText>
                                    No Categories Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuCategory>
                          </ContainerCategory>
                        );
                      }}
                    ></FieldArray>
                  )}
                  <MenuCalenderItem
                    onClick={() => isOpen && handleOpenSubmenu("calender")}
                    className={
                      activeMenuItem === "calender"
                        ? "active"
                        : showDueSubmenu
                        ? "active"
                        : ""
                    }
                  >
                    {activeMenuItem === "calender" ? (
                      <WhiteCalenderImg title={isOpen ? "" : "Due"} />
                    ) : showDueSubmenu ? (
                      <WhiteCalenderImg
                        onClick={() => setShowDueSubmenu(false)}
                        title={isOpen ? "" : "Due"}
                      />
                    ) : (
                      <CalenderImg
                        className={USETIFULL_DUEDATE_FILTER_SIDE_BAR}
                        onClick={() => {
                          setActiveMenuItem("");
                          setCategorySubmenu(false);
                          setShowAssignee(false);
                          setShowAssigner(false);
                          setShowDueSubmenu(!showDueSubmenu);
                          navigate("/");
                        }}
                        title={isOpen ? "" : "Due"}
                      />
                    )}

                    {isOpen && (
                      <>
                        <StyledMenuItemCalender>Due</StyledMenuItemCalender>
                      </>
                    )}
                  </MenuCalenderItem>
                  {showDueSubmenu && !isOpen ? (
                    <FieldArray
                      name="daysRemainingList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerDueClose>
                            <HeadingDiv>
                              <HeadingText>All Calendar</HeadingText>
                            </HeadingDiv>
                            <SubMenuDueClose>
                              {allDaysRemaining && allDaysRemaining.length > 0
                                ? allDaysRemaining.map((day, index) => (
                                    <SubMenuDueItem key={day.daysId}>
                                      <LabelContainer>
                                        <SpanCategory>
                                          {day.daysLabel} {/* ({day.count}) */}
                                        </SpanCategory>
                                        <CountContainer>
                                          <MenuLineImg />
                                          <SpanCount
                                            title="To-Do"
                                            onClick={() => {
                                              setTabSelected("todo");
                                            }}
                                          >
                                            {day.toDoCount}
                                          </SpanCount>
                                          <HourGlassImg />
                                          <SpanCount
                                            title="In-Progress"
                                            onClick={() => {
                                              setTabSelected("inprogress");
                                            }}
                                          >
                                            {day.inProgressCount}
                                          </SpanCount>
                                          <CheckImg />
                                          <SpanCount
                                            title="Completed"
                                            onClick={() => {
                                              setTabSelected("completed");
                                            }}
                                          >
                                            {day.completedCount}
                                          </SpanCount>
                                        </CountContainer>
                                      </LabelContainer>
                                      <Field
                                        type="checkbox"
                                        name={`assigneeList.${index}`}
                                        value={day}
                                        checked={
                                          values.daysRemainingList &&
                                          values.daysRemainingList.findIndex(
                                            (v) => v.daysId === day.daysId
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                        onChange={(
                                          e: React.FormEvent<HTMLInputElement>
                                        ) => {
                                          if (e.currentTarget.checked) {
                                            _cio.track(
                                              USER_FILTER_TASKS_BY_DUE_DATE_EVENTS,
                                              {
                                                name: "user use due date drop down to filter tasks",
                                              }
                                            );
                                            arrayHelpers.push(day);
                                          } else {
                                            if (values.daysRemainingList) {
                                              const idx =
                                                values.daysRemainingList.findIndex(
                                                  (c) => c.daysId === day.daysId
                                                );
                                              arrayHelpers.remove(idx);
                                            }
                                          }
                                          submitForm();
                                        }}
                                      />
                                    </SubMenuDueItem>
                                  ))
                                : null}
                            </SubMenuDueClose>
                          </ContainerDueClose>
                        );
                      }}
                    ></FieldArray>
                  ) : null}
                  {activeMenuItem === "calender" && isOpen && (
                    <FieldArray
                      name="daysRemainingList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerDue>
                            <HeadingDiv>
                              <HeadingText>All Calendar</HeadingText>
                            </HeadingDiv>
                            <SubMenuDue>
                              {/* <LeftArrowDue /> */}
                              {allDaysRemaining && allDaysRemaining.length > 0
                                ? allDaysRemaining.map((day, index) => (
                                    <SubMenuDueItem key={day.daysId}>
                                      <LabelContainer>
                                        <SpanCategory>
                                          {day.daysLabel} {/* ({day.count}) */}
                                        </SpanCategory>
                                        <CountContainer>
                                          <MenuLineImg />
                                          <SpanCount
                                            title="To-Do"
                                            onClick={() => {
                                              setTabSelected("todo");
                                            }}
                                          >
                                            {day.toDoCount}
                                          </SpanCount>
                                          <HourGlassImg />
                                          <SpanCount
                                            title="In-Progress"
                                            onClick={() => {
                                              setTabSelected("inprogress");
                                            }}
                                          >
                                            {day.inProgressCount}
                                          </SpanCount>
                                          <CheckImg />
                                          <SpanCount
                                            title="Completed"
                                            onClick={() => {
                                              setTabSelected("completed");
                                            }}
                                          >
                                            {day.completedCount}
                                          </SpanCount>
                                        </CountContainer>
                                      </LabelContainer>
                                      <Field
                                        type="checkbox"
                                        name={`assigneeList.${index}`}
                                        value={day}
                                        checked={
                                          values.daysRemainingList &&
                                          values.daysRemainingList.findIndex(
                                            (v) => v.daysId === day.daysId
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                        onChange={(
                                          e: React.FormEvent<HTMLInputElement>
                                        ) => {
                                          if (e.currentTarget.checked) {
                                            _cio.track(
                                              USER_FILTER_TASKS_BY_DUE_DATE_EVENTS,
                                              {
                                                name: "user use due date drop down to filter tasks",
                                              }
                                            );
                                            arrayHelpers.push(day);
                                          } else {
                                            if (values.daysRemainingList) {
                                              const idx =
                                                values.daysRemainingList.findIndex(
                                                  (c) => c.daysId === day.daysId
                                                );
                                              arrayHelpers.remove(idx);
                                            }
                                          }
                                          submitForm();
                                        }}
                                      />
                                    </SubMenuDueItem>
                                  ))
                                : null}
                            </SubMenuDue>
                          </ContainerDue>
                        );
                      }}
                    ></FieldArray>
                  )}

                  <MenuAssigneeItem
                    onClick={() => isOpen && handleOpenSubmenu("userup")}
                    className={
                      activeMenuItem === "userup"
                        ? "active"
                        : showAssignee
                        ? "active"
                        : ""
                    }
                  >
                    {activeMenuItem === "userup" ? (
                      <WhiteAssigneeImg title={isOpen ? "" : "Assignee"} />
                    ) : !showAssignee ? (
                      <AssigneeImg
                        className={USETIFULL_ASSIGNEE_FILTER_SIDE_BAR}
                        onClick={() => {
                          setActiveMenuItem("");
                          setCategorySubmenu(false);
                          setShowDueSubmenu(false);
                          setShowAssigner(false);
                          setShowAssignee(!showAssignee);
                          navigate("/");
                        }}
                        title={isOpen ? "" : "Assignee"}
                      />
                    ) : (
                      <WhiteAssigneeImg
                        onClick={() => setShowAssignee(false)}
                        title={isOpen ? "" : "Assignee"}
                      />
                    )}

                    {isOpen && (
                      <>
                        <StyledMenuItemUserUp>Assignee</StyledMenuItemUserUp>
                      </>
                    )}
                  </MenuAssigneeItem>
                  {showAssignee && !isOpen ? (
                    <FieldArray
                      name="assigneeList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerAssigneeClose>
                            {allAssignee && allAssignee.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Assignee</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            <SubMenuAssigneeClose>
                              {allAssignee && allAssignee.length > 0 ? (
                                allAssignee.map((ass, index) => (
                                  <SubMenuAssigneeItem key={ass._id}>
                                    <LabelContainer>
                                      {ass.fullName ? (
                                        <SpanCategory>
                                          {ass.fullName}
                                        </SpanCategory>
                                      ) : (
                                        <SpanCategory>{ass.email}</SpanCategory>
                                      )}

                                      {/* <SpanCount>({ass.count})</SpanCount> */}
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {ass.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {ass.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {ass.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assigneeList.${index}`}
                                      value={ass}
                                      checked={
                                        values.assigneeList &&
                                        values.assigneeList.findIndex(
                                          (v) => v._id === ass._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_ASSIGNEE_EVENTS,
                                            {
                                              name: "user use assignee drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(ass);
                                        } else {
                                          if (values.assigneeList) {
                                            const idx =
                                              values.assigneeList.findIndex(
                                                (u) => u._id === ass._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuAssigneeItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Assignee</DataEmptyText>
                                  <DataEmptySubText>
                                    No Assignee Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuAssigneeClose>
                          </ContainerAssigneeClose>
                        );
                      }}
                    ></FieldArray>
                  ) : null}
                  {activeMenuItem === "userup" && isOpen && (
                    <FieldArray
                      name="assigneeList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerAssignee>
                            {allAssignee && allAssignee.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Assignee</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            {/* <LeftArrowAssignee /> */}
                            <SubMenuAssignee>
                              {allAssignee && allAssignee.length > 0 ? (
                                allAssignee.map((ass, index) => (
                                  <SubMenuAssigneeItem key={ass._id}>
                                    <LabelContainer>
                                      {ass.fullName ? (
                                        <SpanCategory>
                                          {ass.fullName}
                                        </SpanCategory>
                                      ) : (
                                        <SpanCategory>{ass.email}</SpanCategory>
                                      )}

                                      {/* <SpanCount>({ass.count})</SpanCount> */}
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {ass.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {ass.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {ass.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assigneeList.${index}`}
                                      value={ass}
                                      checked={
                                        values.assigneeList &&
                                        values.assigneeList.findIndex(
                                          (v) => v._id === ass._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_ASSIGNEE_EVENTS,
                                            {
                                              name: "user use assignee drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(ass);
                                        } else {
                                          if (values.assigneeList) {
                                            const idx =
                                              values.assigneeList.findIndex(
                                                (u) => u._id === ass._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuAssigneeItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Assignee</DataEmptyText>
                                  <DataEmptySubText>
                                    No Assignee Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuAssignee>
                          </ContainerAssignee>
                        );
                      }}
                    ></FieldArray>
                  )}

                  {/* userdown */}
                  <MenuAssignerItem
                    onClick={() => isOpen && handleOpenSubmenu("userdown")}
                    className={
                      activeMenuItem === "userdown"
                        ? "active"
                        : showAssigner
                        ? "active"
                        : ""
                    }
                  >
                    {activeMenuItem === "userdown" ? (
                      <WhiteAssignorImg title={isOpen ? "" : "Assigner"} />
                    ) : !showAssigner ? (
                      <AssignorImg
                        className={USETIFULL_ASSIGNER_FILTER_SIDE_BAR}
                        onClick={() => {
                          setActiveMenuItem("");
                          setCategorySubmenu(false);
                          setShowDueSubmenu(false);
                          setShowAssignee(false);
                          setShowAssigner(!showAssigner);
                          navigate("/");
                        }}
                        title={isOpen ? "" : "Assigner"}
                      />
                    ) : (
                      <WhiteAssignorImg
                        onClick={() => setShowAssigner(false)}
                        title={isOpen ? "" : "Assigner"}
                      />
                    )}
                    {isOpen && (
                      <>
                        <StyledMenuItemUserDown>
                          Assigner
                        </StyledMenuItemUserDown>
                      </>
                    )}
                  </MenuAssignerItem>
                  {showAssigner && !isOpen ? (
                    <FieldArray
                      name="assignorList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerAssigneerClose>
                            {allAssignor && allAssignor.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Assigneer</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            <SubMenuCloseAssigneer>
                              {allAssignor && allAssignor.length > 0 ? (
                                allAssignor.map((ass, index) => (
                                  <SubMenuAssigneerItem key={ass._id}>
                                    <LabelContainer>
                                      {ass.fullName ? (
                                        <SpanCategory>
                                          {ass.fullName}
                                        </SpanCategory>
                                      ) : (
                                        <SpanCategory>{ass.email}</SpanCategory>
                                      )}
                                      {/*  <SpanCount>({ass.count})</SpanCount> */}
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {ass.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {ass.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {ass.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assignorList.${index}`}
                                      value={ass}
                                      checked={
                                        values.assignorList &&
                                        values.assignorList.findIndex(
                                          (v) => v._id === ass._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_ASSIGNER_EVENTS,
                                            {
                                              name: "user use assigner drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(ass);
                                        } else {
                                          if (values.assignorList) {
                                            const idx =
                                              values.assignorList.findIndex(
                                                (u) => u._id === ass._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuAssigneerItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Assigneer</DataEmptyText>
                                  <DataEmptySubText>
                                    No Assigneer Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuCloseAssigneer>
                          </ContainerAssigneerClose>
                        );
                      }}
                    ></FieldArray>
                  ) : null}
                  {activeMenuItem === "userdown" && isOpen && (
                    <FieldArray
                      name="assignorList"
                      render={(arrayHelpers) => {
                        return (
                          <ContainerAssigneer>
                            {allAssignor && allAssignor.length > 0 ? (
                              <HeadingDiv>
                                <HeadingText>All Assigneer</HeadingText>
                              </HeadingDiv>
                            ) : null}
                            <SubMenuAssigneer>
                              {/* <LeftArrowAssigneer /> */}
                              {allAssignor && allAssignor.length > 0 ? (
                                allAssignor.map((ass, index) => (
                                  <SubMenuAssigneerItem key={ass._id}>
                                    <LabelContainer>
                                      {ass.fullName ? (
                                        <SpanCategory>
                                          {ass.fullName}
                                        </SpanCategory>
                                      ) : (
                                        <SpanCategory>{ass.email}</SpanCategory>
                                      )}
                                      {/*  <SpanCount>({ass.count})</SpanCount> */}
                                      <CountContainer>
                                        <MenuLineImg />
                                        <SpanCount
                                          title="To-Do"
                                          onClick={() => {
                                            setTabSelected("todo");
                                          }}
                                        >
                                          {ass.toDoCount}
                                        </SpanCount>
                                        <HourGlassImg />
                                        <SpanCount
                                          title="In-Progress"
                                          onClick={() => {
                                            setTabSelected("inprogress");
                                          }}
                                        >
                                          {ass.inProgressCount}
                                        </SpanCount>
                                        <CheckImg />
                                        <SpanCount
                                          title="Completed"
                                          onClick={() => {
                                            setTabSelected("completed");
                                          }}
                                        >
                                          {ass.completedCount}
                                        </SpanCount>
                                      </CountContainer>
                                    </LabelContainer>
                                    <Field
                                      type="checkbox"
                                      name={`assignorList.${index}`}
                                      value={ass}
                                      checked={
                                        values.assignorList &&
                                        values.assignorList.findIndex(
                                          (v) => v._id === ass._id
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(
                                        e: React.FormEvent<HTMLInputElement>
                                      ) => {
                                        if (e.currentTarget.checked) {
                                          _cio.track(
                                            USER_FILTER_TASKS_BY_ASSIGNER_EVENTS,
                                            {
                                              name: "user use assigner drop down to filter tasks",
                                            }
                                          );
                                          arrayHelpers.push(ass);
                                        } else {
                                          if (values.assignorList) {
                                            const idx =
                                              values.assignorList.findIndex(
                                                (u) => u._id === ass._id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }
                                        submitForm();
                                      }}
                                    />
                                  </SubMenuAssigneerItem>
                                ))
                              ) : (
                                <EmptyContainer>
                                  <DataEmptyText>All Assigneer</DataEmptyText>
                                  <DataEmptySubText>
                                    No Assigneer Found
                                  </DataEmptySubText>
                                </EmptyContainer>
                              )}
                            </SubMenuAssigneer>
                          </ContainerAssigneer>
                        );
                      }}
                    ></FieldArray>
                  )}
                  <Line />
                  <MenuItemLogOut
                    onClick={() => {
                      setShowLogOutDailog(true);
                    }}
                    className={activeMenuItem === "logout" ? "active" : ""}
                  >
                    {activeMenuItem === "logout" ? (
                      <WhiteLogoutImg title={isOpen ? "" : "Logout"} />
                    ) : (
                      <LogoutImg title={isOpen ? "" : "Logout"} />
                    )}
                    {isOpen && (
                      <StyledMenuItemLogOut>Logout</StyledMenuItemLogOut>
                    )}
                  </MenuItemLogOut>
                  <div>
                    {!isOpen ? (
                      <FirstVersionSpan>
                        <VersionSpan>v</VersionSpan>

                        <Version>1.0.1</Version>
                      </FirstVersionSpan>
                    ) : (
                      <div>
                        <VersionSpan>
                          v <Version>1.0.1</Version>
                        </VersionSpan>
                      </div>
                    )}
                  </div>
                </MainDivForScrolling>
              </MainMenu>
            );
          }}
        </Formik>
      </MenuContainer>
      {showLogOutDailog && (
        <ConfirmationBox
          setShow={setShowLogOutDailog}
          name="Confirmation"
          message="Are you sure, you want to log out?"
          okButtonText="Yes"
          okButtonClick={() => handleOnClick("logout")}
        />
      )}
    </>
  );
};
export default TaskMenu;
