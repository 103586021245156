import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Quill } from "react-quill";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { TaskContext } from "../../context/task-context";
import FileIcon from "../../images/file-ioc.svg";
import JetListImage from "../../images/JetList.svg";
import MenuImage from "../../images/menu.svg";
import searchMobileIcon from "../../images/searchmobile.svg";
import MainMobileTaskForm from "../mobile-task-form/main-mobile-taskform";
//import Profile from "../Profile";
import MobileSearchBox from "../top-bar/mobile-search-bar";
// import SearchBox from "./SearchBox";
import NoTaskAvailableView from "./no-task-available-view";
import TaskDetails from "./task-details";
import TaskTabs from "./task-tabs";
// import TaskForm from "./TaskForm";
import JetBotIcon from "../../images/jet-bot.svg";
import TaskList from "./task-list";
// import SubTaskIndicatorSvg from "../images/subtask-indicator.svg";
import { UserContext } from "../../context/user-context";

import MainTaskForm from "./main-task-form";

import { useNavigate } from "react-router-dom";
import {
  USER_PROFILE_SETTING_CLICK_EVENTS,
  USE_JETBOT_FROM_TOP_BAR_EVENTS,
} from "../../customer-io/events";
import JetBotSuggestionPopup from "../jet-bot/jet-bot-suggestion-popup";
import { GPT_3_OPENAI } from "../utils/app-feature-list";
import { checkIfFeatureOpen } from "../utils/utills";
import { TeamContext } from "../../context/team-context";
import { Team } from "../../types";
declare var _cio: any;
interface SidebarWidth {
  isOpen?: boolean;
  isClickOnSearch?: boolean;
  showUserProfile?: boolean;
  isOnSidebar?: boolean;
  isTask?: boolean;
}

const BlockEmbed = Quill.import("blots/block/embed");
class FileBlot extends BlockEmbed {
  static create(url: any) {
    let node = super.create();
    node.setAttribute("href", url);
    node.setAttribute("target", url);
    node.setAttribute(
      "style",
      `background-image: url(${FileIcon}); border-radius: 3px; margin: 5px,2px,2px,2px; padding: 18px 20px;overflow: visible; white-space: pre-wrap;`
    );
    return node;
  }

  static value(node: any) {
    return node.getAttribute("href");
  }
}
interface SidebarWidth {
  isOpen?: boolean;
  isOnTaskArea?: boolean;
  isOnSidebar?: boolean;
  isTask?: boolean;
  task?: boolean;
}

FileBlot.blotName = "document";
FileBlot.tagName = "document";
Quill.register(FileBlot, true);

const Wrapper = styled.div`
  width: 100%;
  /* height: 255px; */
  height: ${(props: SidebarWidth) => (props.task ? "255px" : "23%")};

  background: #fdf0e2;
  @media (max-width: 1199px) and (min-width: 320px) {
    height: 134px;
  }
`;

const ToplineWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
`;

const Gap = styled.div`
  flex: 1;
`;

const TaglineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h1`
  color: #fc6601;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  font-style: bold;
  margin: 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
  }
`;
const PARAGRAPH = styled.span`
  color: #2f4c95;
  font-size: 22px;
  line-height: 33px;
  font-weight: 400;
  @media (max-width: 1199px) and (min-width: 320px) {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
  }
`;
const TopDiv = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    /* justify-content: space-between; */
    justify-content: start;
    flex: 1;
    padding: 10px;
  }
`;
const ProfileDiv = styled(Col)`
  @media (max-width: 1199px) and (min-width: 320px) {
    display: flex;
    justify-content: end;
    flex: 1;
    align-items: center;
  }
`;

const JetListImg = styled.img`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: block;
    width: 50px;
    margin: 0 11px;
  }
`;
const MenuImg = styled.img`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: block;
  }
`;
const SearchImg = styled.img`
  display: none;
  @media (max-width: 1199px) and (min-width: 320px) {
    display: block;
    z-index: 1111;
    width: 24px;
    position: absolute;
    right: 47%;
  }
`;

const DivForPara = styled.div`
  /* margin-top: 4%; */
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "12%" : "0px")};
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-left: 0px;
    margin-top: 0;
  }
`;

const MainWrapper = styled.div`
  position: ${(props: SidebarWidth) => (props.isOnSidebar ? "fixed" : "fixed")};
  width: 100%;
  top: 0;
  bottom: 0;
  overflow-y: ${(props: SidebarWidth) =>
    props.isOnSidebar ? (!props.isTask ? "unset" : "scroll") : "unset"};
  overflow-x: hidden;
  min-height: 100%;
`;

const TopRow = styled(Row)`
  display: flex;
  /* padding: 10px; */
  width: 100%;
  flex-direction: row;
  background: #ffe6cc;
  height: 40px;
`;
const DivBorder = styled.div`
  @media (max-width: 1199px) and (min-width: 320px) {
    background: #ffffff;
    border-top: 1px solid #ffe6cc;
    position: fixed;
    /* top: 92%; */
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0px;
    padding: 13px;
  }
`;

const UserProfileDiv = styled.div`
  margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  // position: absolute;
  // right: 13%;
  // top: 17px;
  padding-left: 1%;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 4px;

    /* margin-top: 3px;
    margin-left: 4px; */
  }
`;

const UserCircle = styled.div`
  padding: 4px;
  font-size: 14px;
  text-align: center;
  height: 30px;
  width: 30px;
  font-weight: 600;
  background-color: ${(props: SidebarWidth) =>
    props.showUserProfile ? "#fdf0e2" : "#D0D0D0"};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 4px;
    margin-left: 4px;
    height: 24px;
    width: 24px;
    font-size: 12px;
  }
`;
const JetBotImgTag = styled.img`
  width: 24px;
  margin-left: 15px;
  top: 4px;
  right: 16px;
  cursor: pointer;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-left: 0;
  }
`;

const TeamSwitchDropdownDiv = styled.div`
  /* margin-right: ${(props: SidebarWidth) => (props.isOpen ? "-4%" : "0px")};
  padding-left: 24px;
  padding-right: 24px; */
`;

const TaskArea = ({ isOpen, setIsOpen, isOnSidebar }: any) => {
  const { userState } = useContext(UserContext);
  const { tasks, projectTempTsk } = useContext(TaskContext);
  const { selectedTeam, userTeams, setSelectedTeam } = useContext(TeamContext);
  const [isOpenSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const [showUserProfile, setUserProfile] = useState(true);
  const [showJetBotPopup, setShowJetBotPopup] = useState<boolean>(false);
  const [gptSuggestionsText, setGptSuggestionsText] = useState<
    string | undefined
  >(undefined);
  const [toShowSubmitBtnJetbotPopup, setToShowSubmitBtnJetbotPopup] =
    useState<boolean>(false);
  const sideBarToggle = () => {
    setIsOpen(!isOpen);
  };

  let navigate = useNavigate();

  const searchboxToggle = () => {
    setOpenSearchBox(!isOpenSearchBox);
  };

  const isMobileScreen = useMediaQuery({ query: "(min-width: 320px)" });
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  const isMobileSearchScreen = useMediaQuery({ query: "(min-width: 320px)" });
  const isDesktopSearchScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  window.onscroll = function () {
    myFunction();
  };
  // console.log("  window.onscroll",  window.onscroll)
  var navbar = document.getElementById("navbar");
  // console.log("navbar",navbar)
  var sticky: any = navbar?.offsetTop;
  // console.log("sticky",sticky)
  // console.log("window.pageYOffset",window.pageYOffset)
  const myFunction = () => {
    if (window.pageYOffset >= sticky) {
      navbar?.classList.add("sticky");
    } else {
      navbar?.classList.remove("sticky");
    }
  };
  return (
    <>
      <MainWrapper
        isOpen={isOpen}
        isTask={tasks.length > 4 ? true : false}
        isOnSidebar={isOnSidebar}
      >
        <Wrapper task={tasks.length <= 0 ? true : false}>
          <ToplineWrapper>
            <Gap />
            {/* {isDesktopSearchScreen && (
              <SearchBox
                isOpen={isOpen}
                quickHelpModal={quickHelpModal}
                setQuickHelpModal={setQuickHelpModal}
                isOnSidebar={isOnSidebar}
                isTask={tasks.length >= 2 ? true : false}
              />
            )} */}

            {/* {isMobileSearchScreen && (
              <MobileSearchBox isOpenSearchBox={isOpenSearchBox} />
            )} */}

            {/*  <Profile /> */}
          </ToplineWrapper>
          <TaglineWrapper>
            {/* this is header     ---------feature --- search box , profile and jetbot */}
            <TopRow>
              <TopDiv sm={4}>
                <MenuImg src={MenuImage} alt="menu" onClick={sideBarToggle} />
                <JetListImg src={JetListImage} alt="jetlist" />
              </TopDiv>

              <ProfileDiv sm={8}>
                {/* <div style={{position:"relative"}}> */}

                <SearchImg
                  src={searchMobileIcon}
                  alt="searchbox"
                  onClick={searchboxToggle}
                />

                {isMobileSearchScreen && (
                  <MobileSearchBox isOpenSearchBox={isOpenSearchBox} />
                )}

                {/* </div> */}

                {isMobileSearchScreen && (
                  <UserProfileDiv title="User profile">
                    <UserCircle
                      showUserProfile={showUserProfile}
                      onClick={() => {
                        // Send track event to customer.io
                        _cio.track(USER_PROFILE_SETTING_CLICK_EVENTS, {
                          name: "user-profile-setting-button",
                        });
                        navigate("user-profile");
                      }}
                      onMouseOver={() => setUserProfile(false)}
                      onMouseOut={() => setUserProfile(true)}
                    >
                      {userState.user && userState.user.shortName
                        ? userState.user.shortName
                        : ""}
                    </UserCircle>
                  </UserProfileDiv>
                )}
                {!isDesktopSearchScreen && (
                  <div>
                    {checkIfFeatureOpen(
                      userState?.user?.appFeatures,
                      GPT_3_OPENAI
                    ) && (
                      <UserProfileDiv>
                        <JetBotImgTag
                          src={JetBotIcon}
                          alt="robo"
                          title="Ask Suggestion to Jet-Bot"
                          onClick={() => {
                            //searchSuggestion(values.detail);
                            _cio.track(USE_JETBOT_FROM_TOP_BAR_EVENTS, {
                              name: "use jetbot from top bar",
                            });
                            setGptSuggestionsText("");
                            setShowJetBotPopup(true);
                            setToShowSubmitBtnJetbotPopup(true);
                          }}
                        />
                      </UserProfileDiv>
                    )}
                  </div>
                )}
                {!isDesktopSearchScreen && (
                  <TeamSwitchDropdownDiv>
                    {/* <select
                      className="team-switch-dropdown"
                      onChange={(e) => {
                        if (userTeams) {
                          const index: number = Number(e.target.value);
                          setSelectedTeam(userTeams[index]);
                        }
                      }}
                    >
                      {userTeams?.map((team: Team, index: number) =>
                        team.id === selectedTeam?.id ? (
                          <option key={team.id} value={index} selected>
                            {team.name}
                          </option>
                        ) : (
                          <option key={team.id} value={index}>
                            {team.name}
                          </option>
                        )
                      )}
                    </select> */}
                    <select
                      className="team-switch-dropdown"
                      onChange={(e) => {
                        if (userTeams) {
                          const index: number = Number(e.target.value);
                          setSelectedTeam(userTeams[index]);

                          localStorage.setItem(
                            "userTeams",
                            JSON.stringify(userTeams[index])
                          );

                          // to unmounte the subscription component
                          if (
                            (userTeams[index].type !== "company" &&
                              !projectTempTsk) ||
                            !userTeams[index].isOwner
                          ) {
                            navigate("/");
                          }
                        }
                      }}
                    >
                      {userTeams?.map((team: Team, index: number) =>
                        team.id === selectedTeam?.id ? (
                          <option key={team.id} value={index} selected>
                            {team.name}
                          </option>
                        ) : (
                          <option key={team.id} value={index}>
                            {team.name}
                          </option>
                        )
                      )}
                    </select>
                  </TeamSwitchDropdownDiv>
                )}
              </ProfileDiv>
            </TopRow>

            {!isDesktopScreen && (
              <DivForPara isOpen={isOpen}>
                <Text> Create A Task & Assign </Text>
                <PARAGRAPH>
                  Assign tasks to anyone or yourself in a few clicks
                </PARAGRAPH>
              </DivForPara>
            )}
            {isDesktopScreen && tasks.length <= 0 && (
              <DivForPara isOpen={isOpen}>
                <Text> Create A Task & Assign </Text>
                <PARAGRAPH>
                  Assign tasks to anyone or yourself in a few clicks
                </PARAGRAPH>
              </DivForPara>
            )}
          </TaglineWrapper>
        </Wrapper>

        {isDesktopScreen && <MainTaskForm isOpen={isOpen} />}

        <div id="navbar">
          <TaskTabs isOpen={isOpen} />
        </div>
        <NoTaskAvailableView isOpen={isOpen} />

        {/* <TaskListDiv> */}
        <div className="content">
          <TaskList isOpen={isOpen} />
          {/* </TaskListDiv> */}
        </div>
        <TaskDetails isopen={isOpen} />
        {isMobileScreen && (
          <DivBorder>
            <MainMobileTaskForm isMobileScreen={isMobileScreen} />
          </DivBorder>
        )}

        {showJetBotPopup && (
          <JetBotSuggestionPopup
            gptSuggestionsText={gptSuggestionsText}
            setShowJetBotPopup={setShowJetBotPopup}
            showSuggestionPopUpInTaskDetail={true}
            setGptSuggestionsText={setGptSuggestionsText}
            toShowSubmitBtnJetbotPopup={toShowSubmitBtnJetbotPopup}
          />
        )}
      </MainWrapper>
    </>
  );
};

export default TaskArea;
