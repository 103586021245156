import styled from "styled-components";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ExpandedTaskForm from "./expanded-task-form";
import BasicTaskForm from "./basic-task-form";
import { TaskContext } from "../../context/task-context";
import { USETIFULL_EXPANDED_TASK_CREATION } from "../../usetifull/class-names";

interface SidebarWidth {
  isOpen?: boolean;
}

const TaskBox = styled.div`
  background: #ffe6cc;
  border: 5px solid white;
  border-radius: 0 11px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: auto;
  width: 953px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -47.5px;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "23%" : "auto")};
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    position: relative;
  }
`;

const BasicTabNormal = styled.button`
  box-sizing: border-box;

  position: absolute;
  width: 10%;
  bottom: 100%;
  height: 50px;
  left: -5px;
  background: #ffffff;
  /* border: 5px solid #ffffff; */
  border-left: 5px solid #ffffff;
  border-top: 5px solid #ffffff;
  border-bottom: none;
  border-right: none;

  border-radius: 10px 0px 0px 0px;
`;

const TabText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #797979;
`;
const BasicTabSelected = styled.button`
  box-sizing: border-box;

  position: absolute;
  width: 10%;
  bottom: 100%;
  height: 50px;
  left: -5px;
  background: #ffe6cc;
  /* border: 5px solid #ffffff; */
  border-left: 5px solid #ffffff;
  border-top: 5px solid #ffffff;
  border-bottom: none;
  border-right: none;
  border-radius: 10px 0px 0px 0px;
`;
const ExpandedTabNormal = styled.button`
  box-sizing: border-box;

  position: absolute;
  width: 12%;
  height: 50px;
  left: 9%;
  bottom: 100%;

  background: #ffffff;
  /* border: 5px solid #ffffff; */
  border-left: none;
  border-top: 5px solid #ffffff;
  border-bottom: none;
  border-right: 5px solid #ffffff;
  /* border-left: 5px solid #ffffff; */
  border-radius: 0px 10px 0px 0px;
`;
const ExpandedTabSelected = styled.button`
  box-sizing: border-box;

  position: absolute;
  width: 12%;
  height: 50px;
  left: 9%;
  bottom: 100%;

  background: #ffe6cc;
  /* border: 5px solid #ffffff; */
  border-left: none;
  border-top: 5px solid #ffffff;
  border-bottom: none;
  border-right: 5px solid #ffffff;
  /* border-left: 5px solid #ffffff; */
  border-radius: 0px 10px 0px 0px;
`;

const MainTaskForm = ({ isOpen }: any) => {
  const { copyTask, setCopyTask, isTaskCopyClick, setTaskCopyClick } =
    useContext(TaskContext);
  const BASIC_FORM = "basic_form";
  const EXPANDED_FORM = "expanded_form";

  const [currentTaskForm, setCurrentTaskForm] = useState<string>(BASIC_FORM);

  const [isDescription, setIsDescription] = useState<any>("");

  const [expandedFormData, setExpandedFormData] = useState<any>();
  const [basicFormData, setBasicFormData] = useState<any>();

  useEffect(() => {
    if (isTaskCopyClick) {
      if (copyTask) {
        setBasicFormData({
          detail: copyTask.detail,
          assignedToEmail: copyTask.assignedTo ? copyTask.assignedTo.email : "",
          groupEmails:
            copyTask.groupUsers && copyTask.groupUsers.length > 0
              ? copyTask.groupUsers
                  .map((c) => {
                    return c.email;
                  })
                  .join(",")
              : "",
          date: "",
          duedate: moment().add(1, "day").toDate(),
          duedateText: copyTask.duedateText,
          cats: copyTask.categories
            ? copyTask.categories.map((ct) => ct.name)
            : [],
          priority: copyTask.priority,
          description: copyTask.isDescription,
          isDescription: copyTask.isDescription,
        });
        setIsDescription(copyTask.isDescription);
      }
      setCurrentTaskForm(EXPANDED_FORM);
      setCopyTask(undefined);
      setTaskCopyClick(false);
    }
    // eslint-disable-next-line
  }, [isTaskCopyClick, setCopyTask, setTaskCopyClick]);

  return (
    <>
      <TaskBox id="mainTaskForm" isOpen={isOpen}>
        {currentTaskForm === BASIC_FORM ? (
          <>
            <ExpandedTabNormal
              className={USETIFULL_EXPANDED_TASK_CREATION}
              onClick={() => {
                setCurrentTaskForm(EXPANDED_FORM);
                setExpandedFormData(basicFormData);
              }}
            >
              <TabText> Expanded</TabText>
            </ExpandedTabNormal>

            <BasicTabSelected>
              <TabText> Basic</TabText>
            </BasicTabSelected>

            <BasicTaskForm
              isDescription={isDescription}
              setIsDescription={setIsDescription}
              setBasicFormData={setBasicFormData}
              initValues={expandedFormData}
            />
          </>
        ) : (
          <>
            <BasicTabNormal
              onClick={() => {
                setCurrentTaskForm(BASIC_FORM);
                setBasicFormData(expandedFormData);
              }}
            >
              <TabText> Basic</TabText>
            </BasicTabNormal>
            <ExpandedTabSelected>
              <TabText> Expanded</TabText>
            </ExpandedTabSelected>
            <ExpandedTaskForm
              isDescription={isDescription}
              setIsDescription={setIsDescription}
              setCurrentFormData={setExpandedFormData}
              initValues={basicFormData}
            />
          </>
        )}
      </TaskBox>
    </>
  );
};

export default MainTaskForm;
