import axios from "axios";
import { Field, Formik } from "formik";
import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LOGGED_IN } from "../../consts";
import { UserContext } from "../../context/user-context";
import HidePassEye from "../../images/hidepasseye.svg";
import Logo from "../../images/logo.svg";
import OrLInes from "../../images/oricon.svg";
import ShowPassEye from "../../images/showpasseye.svg";
import Spinner from "../../images/spinner.svg";
import { setAuthHeader } from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";

const ThirdSpan = styled.span`
  margin: 0 33px;
  padding: 1px 0 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9b9b9b;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 251px;
    height: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #9b9b9b;
    text-align: center;
  }
`;

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;
const ButtonForCred = styled.button`
  width: 64%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #2f4c95;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #2f4c95;
  cursor: pointer;
  z-index: 11111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fc6601;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const LogoImg = styled.img`
  width: 123px;
  margin-top: 16px;

  @media (max-width: 1199px) and (min-width: 390px) {
  }
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fc6601;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 43px;
    color: #fc6601;
  }
`;

const LoginSignupFieldsDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding-left: 0;
    padding: 15px 0;
  }
`;
const Input = styled(Field)`
  width: 64%;
  height: 41px;
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  margin: 8px;
  border-radius: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    margin: 8px 0;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
  }
`;
const DivForLoginButton = styled.div`
  padding: 10px 0;
  width: 100%;
  text-align: center;
  margin-bottom: 55px;
  @media (max-width: 1199px) and (min-width: 320px) {
    margin-bottom: 0;
  }
`;
const ShowPasswordImgTag = styled.img`
  position: absolute;
  top: 42%;
  right: 21%;
  z-index: 1;

  @media (max-width: 1199px) and (min-width: 320px) {
    right: 5%;
  }
`;
const LoginButton = styled.button`
  width: 64%;
  height: 50px;
  color: white;
  background: #2f4c95;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const SpanErrorLoginSignup = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 64%;
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const SpanErrorForPassword = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  /* width: 64%; */
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const SpinnerLoginImg = styled.img`
  height: 35px;
  margin-left: 15px;
`;

const SpanMsg = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: green;
`;
const DivForPassShowHide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const SpanForgetPass = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #fc6601;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;
const ForgetPassRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 69%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const Login = ({
  setToShowMagicLinkloginPage,
  setToShowSignupPage,
  showSignupMsgInLoginPage,
  setToShowForgetPassPage,
}: any) => {
  const { userDispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const [toShowPasswordLogin, setToShowPasswordLogin] =
    useState<boolean>(false);

  const [loginLoader, setLoginLoader] = useState(false);
  const toShowPasswordInPlainText = () => {
    var x: any = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  // const login = (values: any, resetForm: any) => {
  //   setLoginLoader(true);
  //   axios
  //     .post(`${process.env.REACT_APP_SERVER_URL}/auth/local`, {
  //       identifier: values.email,
  //       password: values.password,
  //     })
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         setLoginLoader(false);
  //         resetForm();

  //         if (response.data && response.data.jwt) {
  //           NotificationManager.success(" successfully login!");
  //           userDispatch({
  //             type: LOGGED_IN,
  //             user: { ...response.data, email: values.email },
  //           });
  //           localStorage.setItem("user", JSON.stringify(response.data));

  //           if (response.data.jwt) setAuthHeader(response.data.jwt);
  //           navigate("/");
  //         } else {
  //           NotificationManager.error(
  //             response.data.message
  //               ? response.data.message
  //               : "Some thing went wrong, Please try again!"
  //           );
  //         }
  //       } else {
  //         setLoginLoader(false);
  //         NotificationManager.error("Some thing went wrong, Please try again!");
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error("error", error);
  //       setLoginLoader(false);
  //       NotificationManager.error(parseErrorResponse(error));
  //     });
  // };

  const login = (values: any, resetForm: any) => {
    setLoginLoader(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/local`, {
        identifier: values.email,
        password: values.password,
      })
      .then((response: any) => {
        if (response.status === 200) {
          localStorage.setItem(
            "userTeams",
            JSON.stringify(response.data.individualTeam)
          );

          setLoginLoader(false);
          resetForm();

          if (response.data && response.data.jwt) {
            NotificationManager.success(" successfully login!");
            userDispatch({
              type: LOGGED_IN,
              user: { ...response.data, email: values.email },
            });
            localStorage.setItem("user", JSON.stringify(response.data));

            if (response.data.jwt) setAuthHeader(response.data.jwt);
            navigate("/");
          } else {
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Some thing went wrong, Please try again!"
            );
          }
        } else {
          setLoginLoader(false);
          NotificationManager.error("Some thing went wrong, Please try again!");
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        setLoginLoader(false);
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  return (
    <>
      <Container>
        <Row>
          <DIV>
            <LogoImg src={Logo} alt="logo" />

            <DivForPara>
              <LoginPara>Login</LoginPara>
              {/* <SecondSpan>welcome to jetlist</SecondSpan> */}
              <ThirdSpan>
                Enter your login credentials below to start using the app.
              </ThirdSpan>
              <SpanMsg>{showSignupMsgInLoginPage}</SpanMsg>
            </DivForPara>
          </DIV>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values: any) => {
              const errors: {
                email?: string;
                password?: string;
              } = {};

              if (!values.email) {
                errors.email = " email required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "password required";
              } else if (values.password.length <= 5) {
                errors.password = "minimum 6 characters required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              login(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <LoginSignupFieldsDiv>
                  <Input type="email" placeholder="email" name="email" />

                  {errors.email && (
                    <SpanErrorLoginSignup>
                      {errors.email && touched.email && errors.email}
                    </SpanErrorLoginSignup>
                  )}

                  <DivForPassShowHide>
                    <ShowPasswordImgTag
                      src={toShowPasswordLogin ? ShowPassEye : HidePassEye}
                      onClick={() => {
                        toShowPasswordInPlainText();
                        setToShowPasswordLogin(!toShowPasswordLogin);
                      }}
                    />
                    <Input
                      type="password"
                      id="myInput"
                      placeholder="password"
                      name="password"
                    />{" "}
                    <br />
                  </DivForPassShowHide>
                  <ForgetPassRow>
                    <Col sm={6}>
                      {errors.password && (
                        <SpanErrorForPassword>
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </SpanErrorForPassword>
                      )}
                    </Col>

                    <Col
                      sm={6}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <SpanForgetPass
                        onClick={() => {
                          setToShowForgetPassPage(true);
                          setToShowMagicLinkloginPage(false);
                          setToShowSignupPage(false);
                        }}
                      >
                        Forgot Password?
                      </SpanForgetPass>
                    </Col>
                  </ForgetPassRow>

                  <DivForLoginButton>
                    <LoginButton>
                      LOGIN
                      {loginLoader && (
                        <SpinnerLoginImg src={Spinner} alt="Loading" />
                      )}
                    </LoginButton>
                  </DivForLoginButton>
                </LoginSignupFieldsDiv>
                {/* // )} */}
              </form>
            )}
          </Formik>
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>

          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                try {
                  setToShowMagicLinkloginPage(true);
                } catch {}
              }}
            >
              Login With Magic Link
            </ButtonForCred>
          </DivForLoginWithCredintial>

          <BottomDiv>
            <SpanAccount>Don't have an account |</SpanAccount>
            <SpanSignup
              onClick={() => {
                setToShowSignupPage(true);
              }}
            >
              SIGNUP
            </SpanSignup>
          </BottomDiv>

          {/* <DivforRights>
            <RightsPara> © 2022. All Rights Reserved by Jetlist</RightsPara>
          </DivforRights> */}
        </Row>
      </Container>
    </>
  );
};

export default Login;
