export const USETIFULL_BASIC_TASK_CREATION = "basicTaskCreation";
export const USETIFULL_BASIC_TASK_ADVANCE_OPTION = "basicTaskAdvanceOption";
export const USETIFULL_EXPANDED_TASK_CREATION = "expandedTaskCreation";

export const USETIFULL_TODO_TASKS = "todoTasksTab";
export const USETIFULL_IN_PROGRESS_TASK = "inProgressTasksTab";
export const USETIFULL_COMPLETED_TASKS = "completedTasksTab";
export const USETIFULL_ARCHIVED_TASKS = "viewArchivedTasks";
export const USETIFULL_VIEW_DELETED_TASK = "viewDeletedTask";

export const USETIFULL_JETBOT_SEARCH_BAR = "searchBarJetBot";
export const USETIFULL_USER_GUIDE_SEARCH_BAR = "searchBarUserGuide";
export const USETIFULL_SHORTCUTS_SEARCH_BAR = "searchBarQuickHelp";
export const USETIFULL_USER_PROFILE_SEARCH_BAR = "searchBarUserProfile";
export const USETIFULL_SEARCH_TASK_SEARCH_BAR = "searchBarTaskSearch";

export const USETIFULL_PROJECT_TEMPLATE_SIDE_BAR = "projectTemplateSideBar";
export const USETIFULL_CATEGORY_FILTER_SIDE_BAR = "categoryFilterSideBar";
export const USETIFULL_DUEDATE_FILTER_SIDE_BAR = "dueDateFilterSidebar";
export const USETIFULL_ASSIGNEE_FILTER_SIDE_BAR = "assigneeFilterSideBar";
export const USETIFULL_ASSIGNER_FILTER_SIDE_BAR = "assignerFilterSideBar";



export const USETIFULL_EMAIL_NOTIFICATION_PER_TASKS = "emailNotificationForTask-";
export const USETIFULL_TASK_HANDOVER_ACTION = "taskHandoverForTask-";
export const USETIFULL_TASK_NUDGE_COUNT = "taskNudgeCountForTask-";
export const USETIFULL_SHARE_TASK_URL = "shareTaskUrlForTask-";
export const USETIFULL_TASK_COMMENT_INDICATOR = "taskCommentIndicatorForTask-";
export const USETIFULL_NUDGE_ACTIONS = "nudgeActionForTask-";
export const USETIFULL_PERCENT_TASK_COMPLETE = "percentTaskCompleteForTask-";
export const USETIFULL_TASK_DUPLICATION = "taskDuplicationForTask-";
export const USETIFULL_TASK_DELETION = "taskDeletionForTask-";



export const USETIFULL_JETBOT = "jetBotAtTaskDetails";
export const USETIFULL_JET_BOT_SUGGESTION = "jetBotSuggestion";
