import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import styled from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import ToggleOff from "../../images/toggle-off.svg";
import ToggleOn from "../../images/toggle-on.svg";
import myAxios from "../../utils/myaxios";

const WatchParent = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;
const CheckEnableFeaturesContainer = styled(Col)`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 32%; */
  position: relative;
`;
const HeadingText = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #2f4c95;
`;
const ColDiv = styled(Col)`
  margin-top: 20px;
`;
const TextSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(60, 59, 91, 0.8);
  padding-left: 19px;
`;
const ToggleImgTag = styled.img`
  cursor: pointer;
`;

const CheckEnableFeatures = () => {
  const {
    selectedTestGroupId,
    CheckEnableFeatures,
    setCheckEnableFeatures,
    updateEnableFeature,
  } = useContext(AdminContext);
  const { userState } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const TIME_OUT = 100;
  // console.log("response from state", CheckEnableFeatures);

  useEffect(() => {
    if (userState && userState.user) {
      // setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`admin-settings/test-group-app-features/` + selectedTestGroupId)
          .then((response) => {
            if (response.data && response.data.success) {
              setCheckEnableFeatures(response.data.data);
            }
            // setLoading(false);
          })
          .catch((error) => {
            // setLoading(false);
            console.error(error, "error in getting custom domains");
          });
      }, TIME_OUT);
    }
    // eslint-disable-next-line
  }, [userState]);

  const enableToggleBtn = (Id: any) => {
    setTimeout(() => {
      setLoading(true);
      myAxios
        .post(`admin-settings/test-group-app-features/`, {
          appFeatureId: Id,
          groupId: selectedTestGroupId,
          active: true,
        })
        .then((response) => {
          // console.log("response", response);
          if (response.data && response.data.success) {
            updateEnableFeature(Id);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error, "error in getting custom domains");
        });
    }, TIME_OUT);
  };
  const disabledToggleBtn = (Id: any) => {
    setTimeout(() => {
      setLoading(true);
      myAxios
        .post(`admin-settings/test-group-app-features/`, {
          appFeatureId: Id,
          groupId: selectedTestGroupId,
          active: false,
        })
        .then((response) => {
          if (response.data && response.data.success) {
            updateEnableFeature(Id);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error, "error in getting custom domains");
        });
    }, TIME_OUT);
  };

  return (
    <CheckEnableFeaturesContainer sm={4}>
      {loading && (
        <WatchParent1>
          <WatchParent>
            <Watch color="orange" height={50} width={50} />
          </WatchParent>
        </WatchParent1>
      )}
      <HeadingText>Check to Enable Features</HeadingText>

      {CheckEnableFeatures &&
        CheckEnableFeatures.length > 0 &&
        CheckEnableFeatures.map((enableFeature) => {
          return (
            <Row>
              <ColDiv sm={10}>
                <TextSpan> {enableFeature.name}</TextSpan>
              </ColDiv>
              <ColDiv sm={1}>
                {enableFeature.active ? (
                  <ToggleImgTag
                    src={ToggleOn}
                    onClick={() => {
                      disabledToggleBtn(enableFeature._id);
                    }}
                  />
                ) : (
                  <ToggleImgTag
                    src={ToggleOff}
                    onClick={() => {
                      enableToggleBtn(enableFeature._id);
                    }}
                  />
                )}
              </ColDiv>
            </Row>
          );
        })}
    </CheckEnableFeaturesContainer>
  );
};

export default CheckEnableFeatures;
