import { Field, Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { CommonContext } from "../../context/common-context";
import { TaskContext } from "../../context/task-context";
import { UserContext } from "../../context/user-context";
import JetBotIcon from "../../images/jet-bot.svg";
import JetBotAnimation from "../../images/jetbot.gif";
import GlobalStyle from "../../style/style";
import { Group, Task } from "../../types";
import myAxios from "../../utils/myaxios";
import {
  isValidEmail,
  isValidInHandoverOrNot,
  removeLineBreakAtStart,
} from "../../utils/utils";
import { GPT_3_OPENAI } from ".././utils/app-feature-list";
import {
  checkIfFeatureOpen,
  parseErrorResponse,
  removeTagsFromTaskDetail,
  validateDayName,
  validateTime,
} from ".././utils/utills";
import Loader from "../utils/loader";
// @ts-ignore
import TextInput from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";

import { TeamContext } from "../../context/team-context";
import { UserProfileContext } from "../../context/user-profile-context";
import {
  SHORTCUT_LINK_CLICK_EVENTS,
  USER_ASSIGN_GROUP_TASK_EVENTS,
  USER_ASSIGN_SELF_A_TASK_EVENTS,
  USER_ASSIGN_SOMEONE_ELSE_A_TASK_EVENTS,
  USER_ASSIGN_TEAM_MULTIPLE_ASSIGNEE_TASK_EVENTS,
  USER_USE_CATEGORY_TAG_FEATURE_EVENTS,
  USER_USE_PRIORITY_TAG_FEATURE_EVENTS,
  USER_USE_TEMPLATE_IN_A_TASK_EVENTS,
  USE_JETBOT_IN_BASIC_TASK_EVENTS,
} from "../../customer-io/events";
import CrossJetBotSvg from "../../images/cross.svg";
import {
  USETIFULL_BASIC_TASK_ADVANCE_OPTION,
  USETIFULL_BASIC_TASK_CREATION,
  USETIFULL_JETBOT,
} from "../../usetifull/class-names";
import { regRestrictSpecialCharacters } from ".././utils/constants";
import JetBotSuggestionPopup from "../jet-bot/jet-bot-suggestion-popup";
import RichTextEditorView from "../rich-text-editor-view";

declare var _cio: any;

const MainContainer = styled.div`
  padding: 14px;
  width: 100%;
`;

const TheForm = styled.form`
  display: flex;
`;

const FormFirstRow = styled(Row)`
  align-items: center;
`;

const FormSecondRow = styled(Row)`
  margin-top: 5px;
`;

const TaskDetailInputWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  position: relative;
`;

const TaskFormInput = styled(Field)`
  border-radius: 5px;
  border: 1px;
  background: white;

  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  margin: ${(props) => (props.margin ? props.margin : "8px 9px")};
  /* padding: 10px; */
  padding-right: 40px;
  padding-left: 10px;
  &::-webkit-input-placeholder {
    color: rgba(60, 59, 91, 0.29);
    /* font-size: 22px; */
    font-size: 17px;
    line-height: 33px;
    font-weight: 400;
    padding-left: 5px;
  }
`;

const TaskError = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 12px;
  padding: 3px;
`;
const Paragraph = styled.p`
  background: #2f4c95;
  color: white;
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  border: 1px solid #2f4c95;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
`;

const Button = styled.button`
  background: #2f4c95;
  width: 193px;
  /* height: 81px; */
  /* height: 60px; */
  margin: 0 8px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  text-align: center;
  padding: 2px;
  display: inline-grid;

  &:hover {
    background: #0e2151;
    ${Paragraph} {
      background: #0e2151;
      border: 1px solid #0e2151;
    }
  }
`;

const Cancelspan = styled.span`
  color: #fc6601;
  font-size: 14px;
  line-height: 34px;
  font-weight: 600;
  cursor: pointer;
  text-decoration-line: underline;
  margin-left: 9%;
`;

const SecondRowColOne = styled(Col)`
  /* background:white; */
`;

const BasicModeLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #797979;
`;

const AdvancedOptionTag = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #fc6601;
  margin-left: 5%;
  cursor: pointer;
`;

const ShortcutSpanTag = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #797979;
`;
const DivForAdvancedOption = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-top: 1%; */
  align-items: center;

  /* margin-left: 6%; */
`;

const JetBotImgTag = styled.img`
  width: 29px;
  position: absolute;
  top: 4px;
  right: 16px;
  cursor: pointer;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const JetBotLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #fc6601;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10px;
  right: 24px;
`;

const JetBotPopUpInnerDiv = styled.div`
  position: absolute;
  background: #ffffff;
  /* box-shadow: -6px 9px 18px 10px rgb(0 0 0 / 15%); */
  border-radius: 10px;
  z-index: 1111;
  right: 10px;
  top: -58px;
  padding: 15px 15px 2px 15px;
  border: solid #fc6601;
  border-width: 1px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;
const JetBotPopHeader = styled.p`
  color: #797979;
  font-weight: 600;
  font-size: 14px;
  line-height: 2px;
`;
const JetBotPopText = styled.p`
  color: #797979;
  font-weight: 400;
  font-size: 12px;
  line-height: 1px;
`;
const CrossImgTag = styled.img`
  position: absolute;
  background: white;
  border-radius: 50%;
  right: -10px;
  top: -10px;
  cursor: pointer;
  width: 20px;
`;

const AutoCompleteBasicTaskDetail = ({
  value,
  setFieldValue,
  valueChangeListener,
  validateForm,
  assignee,
}: any) => {
  const { groups } = useContext(UserProfileContext);

  const { addUserTeams, setTeamMembers, teamMembers } = useContext(TeamContext);

  //let ass = ""
  const [emailWithComma, setEmailWithComma] = useState<string>("");
  const [localVal, setLoaclVal] = useState<string>();
  const toOption = "to:";
  const groupOption = "group:";
  let tigger = [toOption, groupOption];
  let options: any = {};
  options[toOption] = teamMembers
    ? teamMembers.map((email: any) => email.email)
    : [];
  options[groupOption] = groups ? groups.map((group) => group.name) : [];

  if (emailWithComma && emailWithComma.length > 0) {
    options[emailWithComma] = teamMembers
      ? teamMembers.map((email: any) => email.email)
      : [];
    tigger = [toOption, groupOption, emailWithComma];
  }

  useEffect(() => {
    // this is used to empty box  after task creation
    if (!value || value.length === 0) setLoaclVal(value);
  }, [value]);

  useEffect(() => {
    if (assignee && assignee.length > 0) {
      var lastChar = assignee.slice(-1);
      let assig = assignee;
      let ass = "";
      if (lastChar === ",") {
        assig = assignee.slice(0, -1);
      }

      if (assig.includes(",")) {
        // More then ne email already typed so no need to add more options
        ass = "";
      } else {
        //Single emails
        if (!isValidInHandoverOrNot(assig)) {
          ass = "";
        } else {
          // email is valid

          ass = assig;
        }
      }
      if (ass && ass.length > 0) setEmailWithComma(`to:${ass},`);
    }
  }, [assignee]);

  return (
    <TextInput
      style={{
        minHeight: "40px",
        height: "40px",
        maxHeight: "40px",
        paddingLeft: "5px",
        paddingTop: "5px",
        paddingRight: "40px",
        border: "none",
        borderRadius: "5px",
      }}
      onChange={(e: any) => {
        setLoaclVal(e);
        valueChangeListener(e, setFieldValue);
        setFieldValue("detail", e);
        setTimeout(() => {
          validateForm();
        }, 200);
      }}
      defaultValue={value}
      trigger={tigger}
      value={localVal}
      options={options}
      offsetX={10}
      offsetY={30}
      // passThroughEnter={true}
    />
  );
};

const BasicTaskForm = ({
  isDescription,
  setIsDescription,
  setBasicFormData,
  initValues,
}: any) => {
  const {
    addTasks,
    addTask,
    setTaskCount,
    addCategories,
    addAssignee,
    addAssignor,
    addDayFilters,
    currentPaginationTaskInput,
    forceStopJetBot,
    setForceStopJetBot,
  } = useContext(TaskContext);

  const taskDetailsInput = useRef();

  const { hideLoader, showLoader } = useContext(CommonContext);
  const { userState } = useContext(UserContext);
  const { groups } = useContext(UserProfileContext);
  const { selectedTeam, teamMembers, setTeamMembers, addUserTeams } =
    useContext(TeamContext);
  // console.log("associatedTeamId", associatedTeamId);
  // call via checkbox to  read only the input field
  const [loading, setLoading] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>("");
  const [showJetBotLoader, setShowJetBotLoader] = useState<boolean>(false);

  const [gptSuggestionsText, setGptSuggestionsText] = useState<
    string | undefined
  >(undefined);
  const [showJetBotPopup, setShowJetBotPopup] = useState<boolean>(false);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [groupbtnShow, setGroupBtn] = useState<boolean>(false);

  const [validatioBasicTaskForm, setValidatioBasicTaskForm] = useState<
    String | undefined
  >("");
  // eslint-disable-next-line
  const [basicTaskformCanSubmit, setBasicTaskformCanSubmit] = useState(false);

  const [labels, setLabels] = useState<{
    dueIn?: string;
    assignee?: string | null;
    toLabel?: boolean;
    dueInDays?: string | null;
    dueLabel?: boolean;
    checkbox?: boolean;
    isChecked?: boolean;
    groupBtn?: boolean;
  }>({
    dueIn: "Due in 1 day",
    assignee: null,
    toLabel: false,
    dueInDays: "1",
    dueLabel: false,
    groupBtn: false,
  });

  const changeLabels = (labels: {
    dueIn?: string;
    assignee?: string | null;
    toLabel?: boolean;
    dueLabel?: boolean;
    dueInDays?: string | null;
    progress?: number | null;
    checkbox?: boolean;
  }) => {
    setLabels((prevLabels) => ({
      ...prevLabels,
      ...labels,
    }));
  };

  // Esc Key listener

  const escKeyListener = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        // 👇️ your logic here
        escKeyListener();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const basicTaskFormValidation = (emailText: string) => {
    if (!emailText || emailText.length === 0) {
      setBasicTaskformCanSubmit(false);
      setValidatioBasicTaskForm(undefined);
    } else {
      if (emailText.includes(",")) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          setBasicTaskformCanSubmit(false);
          setValidatioBasicTaskForm("Valid email are required after ','");
        } else {
          const invalidEmails = emailText.split(",").filter((e) => {
            if (!isValidInHandoverOrNot(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            setBasicTaskformCanSubmit(false);
            setValidatioBasicTaskForm(
              "Following emails [" +
                invalidEmails.join(", ") +
                "] are not valid."
            );
          } else {
            setBasicTaskformCanSubmit(true);
            setValidatioBasicTaskForm(undefined);
          }
        }
      } else {
        //Single emails

        if (!isValidInHandoverOrNot(emailText)) {
          setBasicTaskformCanSubmit(false);
          setValidatioBasicTaskForm("Please enter valid email");
        } else {
          // email is valid
          setBasicTaskformCanSubmit(true);
          setValidatioBasicTaskForm(undefined);
        }
      }
    }
  };

  const searchSuggestion = (searchText: string) => {
    searchText = removeTagsFromTaskDetail(searchText);

    if (searchText === "" || searchText.trim() === "") {
      NotificationManager.error(
        "Please enter task details to search suggestion...",
        "",
        3000
      );
      return;
    }

    // Send track event to customer.io
    _cio.track(USE_JETBOT_IN_BASIC_TASK_EVENTS, {
      name: "Jetbot in basic task",
    });

    setSearchText(searchText);
    setShowJetBotLoader(true);
    setGptSuggestionsText(undefined);
    myAxios
      .post(`gpt-3/search-suggestion`, {
        searchText: searchText,
      })
      .then((response) => {
        if (response.data.success) {
          let suggestionText = "";
          if (response.data.data) {
            response.data.data.forEach((dt: any) => {
              suggestionText = suggestionText + "\n" + dt.text;
            });
          }
          suggestionText = removeLineBreakAtStart(suggestionText);

          suggestionText = suggestionText.replaceAll("\n", "<br>");
          setGptSuggestionsText(suggestionText);
          setShowJetBotPopup(true);
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to search...",
            "",
            5000
          );
        }
        setShowJetBotLoader(false);
      })
      .catch((err) => {
        NotificationManager.error(parseErrorResponse(err));
        setShowJetBotLoader(false);
      });
  };

  const keepInDescription = () => {
    setShowJetBotPopup(false);
    setShowOptions(true);
    setIsDescription(gptSuggestionsText);
  };

  useEffect(() => {
    if (initValues) {
      if (initValues.assignedToEmail) {
        setGroupBtn(false);
        changeLabels({
          assignee: initValues.assignedToEmail + "",
          toLabel: true,
        });
      }

      if (initValues.groupEmails && initValues.groupEmails.length > 0) {
        setGroupBtn(true);
        changeLabels({
          assignee: initValues.groupEmails + "",
          toLabel: true,
        });
      }
    }
  }, [initValues]);

  const createDetailsString = (initValues: any) => {
    let details = "";

    if (initValues) {
      if (initValues.detail) {
        details = removeTagsFromTaskDetail(initValues.detail);
      }

      if (initValues.assignedToEmail) {
        details = details + " to:" + initValues.assignedToEmail;
      }

      if (initValues.priority) {
        details = details + " p:" + initValues.priority;
      }

      if (initValues.cats && initValues.cats.length > 0) {
        initValues.cats.forEach((cat: any) => {
          details = details + " #" + cat;
        });
      }

      if (initValues.groupEmails && initValues.groupEmails.length > 0) {
        details = details + " group:" + initValues.groupEmails;
      }

      if (
        initValues.groupName &&
        initValues.groupName.length > 0 &&
        initValues.groupName !== "0"
      ) {
        details = details + " group:" + initValues.groupName;
      }

      if (initValues.ccUserMail) {
        details = details + " cc:" + initValues.ccUserMail;
      }

      // basicFormValues.templateName = templateName;

      if (
        initValues.templateName &&
        initValues.templateName.length > 0 &&
        initValues.templateName !== "0"
      ) {
        details = details + " tmpl:" + initValues.templateName;
      }

      if (initValues.isRecurring) {
        let recurringText = "";
        if (initValues.recurringInDay) {
          recurringText = " r:" + initValues.recurringInDay;
          if (initValues.recurringUntil && initValues.recurringUntil !== "-1")
            recurringText = recurringText + "-" + initValues.recurringUntil;
          if (initValues.recurringCustomDay) {
            recurringText = recurringText + "@" + initValues.recurringCustomDay;
            if (initValues.recurringCustomTime)
              recurringText =
                recurringText + "" + initValues.recurringCustomTime;
          }
        }

        details = details + recurringText;
      }
    }

    return details;
  };

  const onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const valueChangeListener = (value: string, setFieldValue: any) => {
    let basicFormValues: any = {};
    let detail = value;

    basicFormValues.detail = value;
    const toTagIdx1 = detail.toLowerCase().indexOf("to:");
    setValidatioBasicTaskForm(undefined);
    // handle to: tag
    if (toTagIdx1 > -1) {
      // find index of next space
      let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx1);

      if (toTagSpaceIdx > -1) {
        if (toTagSpaceIdx > toTagIdx1 + 3) {
          const assignee = detail.substring(toTagIdx1 + 3, toTagSpaceIdx);

          setGroupBtn(false);
          basicFormValues.assignee = assignee;
          basicFormValues.assignedToEmail = assignee;
          setFieldValue("assignee", assignee);
          setFieldValue("assignedToEmail", assignee);
          basicTaskFormValidation(assignee);
          changeLabels({
            assignee,
            toLabel: true,
          });
        } else {
          setGroupBtn(false);
          setFieldValue("assignee", "");
          setFieldValue("assignedToEmail", "");
          basicFormValues.assignee = "";
          basicFormValues.assignedToEmail = "";
          changeLabels({
            assignee: "",
            toLabel: true,
          });
        }
      } else {
        // just use anything after to as assignee email
        if (detail.length > toTagIdx1 + 3) {
          const assignee = detail.substring(toTagIdx1 + 3, detail.length);

          basicTaskFormValidation(assignee);
          setGroupBtn(false);
          basicFormValues.assignee = assignee;
          basicFormValues.assignedToEmail = assignee;
          setFieldValue("assignee", assignee);
          setFieldValue("assignedToEmail", assignee);
          changeLabels({
            assignee,
            toLabel: true,
          });
        } else {
          setGroupBtn(false);
          setFieldValue("assignee", "");
          setFieldValue("assignedToEmail", "");
          basicFormValues.assignee = "";
          basicFormValues.assignedToEmail = "";
          changeLabels({
            assignee: "",
            toLabel: true,
          });
        }
      }
    } else {
      setGroupBtn(false);
      setFieldValue("assignee", null);
      setFieldValue("assignedToEmail", "");
      basicFormValues.assignee = "";
      basicFormValues.assignedToEmail = "";
      changeLabels({
        toLabel: false,
        assignee: null,
      });
    }

    // category tag handling--

    // for group emails

    const groupTagIdx = detail.toLowerCase().indexOf("group:");
    setValidatioBasicTaskForm(undefined);

    // handle group: tag
    if (groupTagIdx > -1) {
      // find index of next space
      let groupTagSpaceIdx = detail.toLowerCase().indexOf(" ", groupTagIdx);

      if (groupTagSpaceIdx > -1) {
        if (groupTagSpaceIdx > groupTagIdx + 6) {
          const assignee = detail.substring(groupTagIdx + 6, groupTagSpaceIdx);
          setGroupBtn(true);
          // basicTaskFormValidation(assignee);
          // setFieldValue("assignee", assignee);
          changeLabels({
            assignee,
            toLabel: true,
          });
        } else {
          setGroupBtn(true);
          setFieldValue("assignee", "");
          changeLabels({
            assignee: "",
            toLabel: true,
          });
        }
      } else {
        // just use anything after to as assignee email
        if (detail.length > groupTagIdx + 6) {
          const assignee = detail.substring(groupTagIdx + 6, detail.length);
          setGroupBtn(true);
          // basicTaskFormValidation(assignee);
          // setFieldValue("assignee", assignee);
          changeLabels({
            assignee,
            toLabel: true,
          });
        } else {
          setGroupBtn(true);

          changeLabels({
            assignee: "",
            toLabel: true,
          });
        }
      }
    }

    // cc user email validations

    const ccEmailIdx = detail.toLowerCase().indexOf("cc:");
    setValidatioBasicTaskForm(undefined);
    // handle group: tag
    if (ccEmailIdx > -1) {
      // find index of next space
      let groupTagSpaceIdx = detail.toLowerCase().indexOf(" ", ccEmailIdx);

      if (groupTagSpaceIdx > -1) {
        if (groupTagSpaceIdx > ccEmailIdx + 6) {
          const assignee = detail.substring(ccEmailIdx + 6, groupTagSpaceIdx);

          basicTaskFormValidation(assignee);
        }
      } else {
        // just use anything after to as assignee email
        if (detail.length > ccEmailIdx + 6) {
          const assignee = detail.substring(ccEmailIdx + 6, detail.length);

          basicTaskFormValidation(assignee);
        }
      }
    }

    const dueTagIdx1 = detail.toLowerCase().indexOf("due:");
    if (dueTagIdx1 > -1) {
      let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx1);

      if (dueTagSpaceIdx > -1) {
        if (dueTagSpaceIdx > dueTagIdx1 + 4) {
          const dueInDays = detail.substring(dueTagIdx1 + 4, dueTagSpaceIdx);

          const durationMatch = dueInDays.match(/(\d+)h/);

          if (durationMatch) {
            const hours = parseInt(durationMatch[1], 10);

            const duration = moment.duration(hours, "hours").humanize();

            setFieldValue("duedate", moment().add(hours, "hours").toDate());
            basicFormValues.duedate = moment().add(hours, "hours").toDate();
          } else {
            let daysNumber: number = parseInt(dueInDays);
            setFieldValue("dueInDays", dueInDays);
            basicFormValues.dueInDays = dueInDays;
            if (daysNumber > 0) {
              setFieldValue(
                "duedate",
                moment().add(daysNumber, "days").toDate()
              );
              basicFormValues.duedate = moment()
                .add(daysNumber, "days")
                .toDate();
            }

            changeLabels({
              dueInDays,
              dueIn: `Due in ${dueInDays} ${daysNumber > 1 ? "days" : "day"}`,
              dueLabel: true,
            });
          }
        } else {
          basicFormValues.dueInDays = 1;
          setFieldValue("dueInDays", "1");
          changeLabels({
            dueInDays: "1",
            dueIn: `Due in 1 day`,
            dueLabel: true,
          });
        }
      } else {
        // just use anything after to as due number
        if (detail.length > dueTagIdx1 + 4) {
          const dueInDays = detail.substring(dueTagIdx1 + 4, detail.length);

          const durationMatch = dueInDays.match(/(\d+)h/);

          if (durationMatch) {
            const hours = parseInt(durationMatch[1], 10);

            const duration = moment.duration(hours, "hours").humanize();

            setFieldValue("duedate", moment().add(hours, "hours").toDate());
            basicFormValues.duedate = moment().add(hours, "hours").toDate();

            changeLabels({
              dueInDays,
              dueIn: `Due in ${dueInDays} ${hours > 1 ? "hours" : "hour"}`,
              dueLabel: true,
            });
          } else {
            const daysNumber = parseInt(dueInDays);
            if (daysNumber > 0) {
              setFieldValue(
                "duedate",
                moment().add(daysNumber, "days").toDate()
              );
              basicFormValues.duedate = moment()
                .add(daysNumber, "days")
                .toDate();
            }
            setFieldValue("dueInDays", dueInDays);
            basicFormValues.dueInDays = dueInDays;

            changeLabels({
              dueInDays,
              dueIn: `Due in ${dueInDays} ${daysNumber > 1 ? "days" : "day"}`,
              dueLabel: true,
            });
          }
        } else {
          setFieldValue("dueInDays", "1");
          basicFormValues.dueInDays = 1;
          changeLabels({
            dueInDays: "1",
            dueIn: `Due in 1 day`,
            dueLabel: true,
          });
        }
      }
    } else {
      setFieldValue("dueInDays", "1");
      basicFormValues.dueInDays = 1;
      changeLabels({
        dueInDays: "1",
        //  dueIn: `Due in 1 day`,
        dueLabel: false,
      });
    }

    const recurringIdx1 = detail.toLowerCase().indexOf("r:");

    if (recurringIdx1 > -1) {
      const recurringSpaceIdx = detail.toLowerCase().indexOf(" r:");

      const validRecurringTag =
        recurringIdx1 === 0 || recurringSpaceIdx === 0 || recurringSpaceIdx > 0;

      if (validRecurringTag) {
        let recurringSpaceIdx = detail
          .toLowerCase()
          .indexOf(" ", recurringIdx1);

        let recurringText;

        if (recurringSpaceIdx !== -1) {
          recurringText = detail.substring(
            recurringIdx1 + 2,
            recurringSpaceIdx
          );
        } else {
          recurringText = detail.substring(recurringIdx1 + 2, detail.length);
        }

        if (recurringText.length > 0) {
          const recurringFirstParse = recurringText.replace("r:", "");

          const recurringData = recurringFirstParse.split("-");

          if (recurringData.length > 0) {
            const recurringDayParse = recurringData[0].split("@");

            if (recurringDayParse.length > 0) {
              const daysNumber = parseInt(recurringDayParse[0]);

              if (daysNumber > 0 && !isNaN(daysNumber)) {
                setFieldValue(
                  "duedate",
                  moment().add(daysNumber, "days").toDate()
                );
                basicFormValues.duedate = moment()
                  .add(daysNumber, "days")
                  .toDate();
              }
              if (!isNaN(parseInt(recurringDayParse[0]))) {
                setFieldValue("dueInDays", parseInt(recurringDayParse[0]));
                basicFormValues.dueInDays = parseInt(recurringDayParse[0]);
                changeLabels({
                  dueInDays: parseInt(recurringDayParse[0]) + "",
                  dueIn: `Due in ${parseInt(recurringDayParse[0])} ${
                    daysNumber > 1 ? "days" : "day"
                  }`,
                  dueLabel: true,
                });
              }
            }
          }
        }
      }
    }

    // if (!values.duedate) {
    // task would be due in 1 day if not provided
    // copy assignedfrom id and assigned to id to assignedto and from as backend would expect id in that

    basicFormValues.duedate = moment().add(1, "day").toDate();
    // }

    /**
     * We will try to set due date to next nearest round figure of 30 minutes interval
     */

    var taskDueMoment = moment(basicFormValues.duedate);

    if (taskDueMoment.isValid()) {
      if (taskDueMoment.minutes() >= 0 && taskDueMoment.minutes() <= 30) {
        basicFormValues.duedate = taskDueMoment
          .startOf("hours")
          .add(30, "minutes")
          .toDate();
      } else {
        basicFormValues.duedate = taskDueMoment
          .startOf("hours")
          .add(1, "hours")
          .toDate();
      }
    }

    basicFormValues.duedateText = moment(basicFormValues.duedate).format(
      "YYYY-MM-DD HH:mm"
    );

    // if (!values.assignToSomeone && values.assignee)
    // basicFormValues.assignedToEmail = values.assignee;

    // we will now remove all tags from detail (if any)

    // let detail = values.detail;

    const toTagIdx = detail.toLowerCase().indexOf("to:");

    if (toTagIdx > -1) {
      let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx);

      if (toTagSpaceIdx !== -1) {
        detail =
          detail.substring(0, toTagIdx) +
          detail.substring(toTagSpaceIdx + 1, detail.length);
      } else {
        // tag is present till the end
        detail = detail.substring(0, toTagIdx);
      }
    }

    const dueTagIdx = detail.toLowerCase().indexOf("due:");

    if (dueTagIdx > -1) {
      let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx);
      if (dueTagSpaceIdx !== -1) {
        detail =
          detail.substring(0, dueTagIdx) +
          detail.substring(dueTagSpaceIdx + 1, detail.length);
      } else {
        detail = detail.substring(0, dueTagIdx);
      }
    }

    let progressIdx = detail.toLowerCase().indexOf("s:");
    let progressInc = 0;

    if (progressIdx > 0) {
      // we would have to ensure that the progress tag starts with space if it is in the middle of the string
      progressIdx = detail.toLocaleLowerCase().indexOf(" s:");
      progressInc = 1;
    }

    if (progressIdx > -1) {
      let progressSpaceIdx = detail
        .toLowerCase()
        .indexOf(" ", progressIdx + progressInc);

      if (progressSpaceIdx > -1) {
        basicFormValues.progressCount = parseInt(
          detail.substring(progressIdx + 2 + progressInc, progressSpaceIdx)
        );

        detail =
          detail.substring(0, progressIdx) +
          (progressInc > 0
            ? detail.substring(progressSpaceIdx, detail.length)
            : detail.substring(progressSpaceIdx + 1, detail.length));
      } else {
        basicFormValues.progressCount = parseInt(
          detail.substring(progressIdx + 2 + progressInc, detail.length)
        );

        detail = detail.substring(0, progressIdx);
      }
    }

    const groupIdx = detail.toLowerCase().indexOf("group:");

    if (groupIdx > -1) {
      let groupSpaceIdx = detail.toLowerCase().indexOf(" ", groupIdx);

      let groupText;
      let groupName;

      if (groupSpaceIdx > -1) {
        const txt = detail.substring(groupIdx + 6, groupSpaceIdx);

        if (txt && txt.length > 0) {
          if (txt.includes(",")) {
            groupText = txt;
          } else {
            let isGroup = false;

            const indx = groups.findIndex((grp: Group) => grp.name === txt);
            if (indx > -1) isGroup = true;
            if (isGroup) groupName = txt;
            else groupText = txt;
          }
        }

        detail =
          detail.substring(0, groupIdx) +
          detail.substring(groupSpaceIdx + 1, detail.length);
      } else {
        //  groupText = detail.substring(groupIdx + 6, detail.length);

        const txt = detail.substring(groupIdx + 6, detail.length);

        if (txt && txt.length > 0) {
          if (txt.includes(",")) {
            groupText = txt;
          } else {
            let isGroup = false;

            const indx = groups.findIndex((grp: Group) => grp.name === txt);
            if (indx > -1) isGroup = true;
            if (isGroup) groupName = txt;
            else groupText = txt;
          }
        }

        detail = detail.substring(0, groupIdx);
      }

      basicFormValues.groupEmails = groupText;
      basicFormValues.groupName = groupName;
    }

    let priorityIdx = detail.toLowerCase().indexOf("p:");
    let priorityInc = 0;

    if (priorityIdx > 0) {
      priorityIdx = detail.toLocaleLowerCase().indexOf(" p:");
      priorityInc = 1;
    }

    if (priorityIdx > -1) {
      let prioritySpaceIdx = detail
        .toLowerCase()
        .indexOf(" ", priorityIdx + priorityInc);

      if (prioritySpaceIdx > -1) {
        basicFormValues.priority = parseInt(
          detail.substring(priorityIdx + 2 + priorityInc, prioritySpaceIdx)
        );

        detail =
          detail.substring(0, priorityIdx) +
          (priorityInc > 0
            ? detail.substring(prioritySpaceIdx, detail.length)
            : detail.substring(prioritySpaceIdx + 1, detail.length));
      } else {
        basicFormValues.priority = parseInt(
          detail.substring(priorityIdx + 2 + priorityInc, detail.length)
        );

        detail = detail.substring(0, priorityIdx);
      }
    }

    let ccUserMail = "";
    const ccMailIdx = detail.toLowerCase().indexOf("cc:");
    if (ccMailIdx > -1) {
      let ccMailSpaceIdx = detail.toLowerCase().indexOf(" ", ccMailIdx);
      if (ccMailSpaceIdx !== -1) {
        ccUserMail = detail.substring(ccMailIdx + 3, ccMailSpaceIdx);
        detail =
          detail.substring(0, ccMailIdx) +
          detail.substring(ccMailSpaceIdx + 1, detail.length);
      } else {
        ccUserMail = detail.substring(ccMailIdx + 3, detail.length);
        detail = detail.substring(0, ccMailIdx);
      }

      basicFormValues.ccUserMail = ccUserMail;
    }

    // see if there are any categories added against this task

    let categories = [];

    let hashTagIdx = detail.indexOf("#");
    while (hashTagIdx > -1) {
      let hashTagSpaceIdx = detail.indexOf(" ", hashTagIdx);

      if (hashTagSpaceIdx > -1) {
        categories.push(detail.substring(hashTagIdx + 1, hashTagSpaceIdx));
        detail =
          detail.substring(0, hashTagIdx) +
          detail.substring(hashTagSpaceIdx + 1);
      } else {
        categories.push(detail.substring(hashTagIdx + 1));
        detail = detail.substring(0, hashTagIdx);
      }

      hashTagIdx = detail.indexOf("#");
    }

    let isRecurring = false;
    let recurringInDay;
    let recurringUntil;
    let isRecurringCustomStart = false;
    let recurringCustomDay;
    let recurringCustomTime;
    const recurringIdx = detail.toLowerCase().indexOf("r:");

    if (recurringIdx > -1) {
      let recurringSpaceIdx = detail.toLowerCase().indexOf(" ", recurringIdx);

      let recurringText;

      if (recurringSpaceIdx !== -1) {
        recurringText = detail.substring(recurringIdx + 2, recurringSpaceIdx);
        detail =
          detail.substring(0, recurringIdx) +
          detail.substring(recurringSpaceIdx + 1, detail.length);
      } else {
        recurringText = detail.substring(recurringIdx + 2, detail.length);
        detail = detail.substring(0, recurringIdx);
      }

      if (recurringText.length > 0) {
        const recurringData = recurringText.split("-");

        if (recurringData.length === 2) {
          isRecurring = true;
          if (recurringData[0].includes("@")) {
            const recurringCustomData = recurringData[0].split("@");
            recurringInDay = parseInt(recurringCustomData[0]);

            if (recurringCustomData[1].length > 3) {
              isRecurringCustomStart = true;
              recurringCustomDay = recurringCustomData[1].substring(0, 3);
              recurringCustomTime = recurringCustomData[1].substring(
                3,
                recurringCustomData[1].length
              );
            }
            recurringUntil = parseInt(recurringData[1]);
          } else if (recurringData[1].includes("@")) {
            recurringInDay = parseInt(recurringData[0]);
            const recurringCustomData = recurringData[1].split("@");
            recurringUntil = parseInt(recurringCustomData[0]);

            if (recurringCustomData[1].length > 3) {
              isRecurringCustomStart = true;
              recurringCustomDay = recurringCustomData[1].substring(0, 3);
              recurringCustomTime = recurringCustomData[1].substring(
                3,
                recurringCustomData[1].length
              );
            }
          } else {
            recurringInDay = parseInt(recurringData[0]);
            recurringUntil = parseInt(recurringData[1]);
          }
        } else {
          isRecurring = true;
          if (recurringData[0].includes("@")) {
            const recurringCustomData = recurringData[0].split("@");
            recurringInDay = parseInt(recurringCustomData[0]);

            if (recurringCustomData[1].length > 3) {
              isRecurringCustomStart = true;
              recurringCustomDay = recurringCustomData[1].substring(0, 3);
              recurringCustomTime = recurringCustomData[1].substring(
                3,
                recurringCustomData[1].length
              );
            }
          } else recurringInDay = parseInt(recurringData[0]);
          recurringUntil = -1;
        }
      }
    }

    // see if there are any template added against this task

    let isTemplateTask = false;
    let templateName;
    const templateIdx = detail.toLowerCase().indexOf("tmpl:");
    if (templateIdx > -1) {
      let templateSpaceIdx = detail.toLowerCase().indexOf(" ", templateIdx);

      if (templateSpaceIdx !== -1) {
        templateName = detail.substring(templateIdx + 5, templateSpaceIdx);

        detail =
          detail.substring(0, templateIdx) +
          detail.substring(templateSpaceIdx + 1, detail.length);
      } else {
        templateName = detail.substring(templateIdx + 5, detail.length);
        detail = detail.substring(0, templateIdx);
      }
    }

    if (templateName && templateName.length > 0) isTemplateTask = true;

    // values.detail = detail;
    basicFormValues.cats = categories;
    basicFormValues.isDescription = isDescription;
    basicFormValues.isRecurring = isRecurring;
    basicFormValues.recurringInDay = recurringInDay;
    basicFormValues.recurringUntil = recurringUntil;
    basicFormValues.isRecurringCustomStart = isRecurringCustomStart;
    basicFormValues.recurringCustomDay = recurringCustomDay;
    basicFormValues.recurringCustomTime = recurringCustomTime;
    basicFormValues.isTemplateTask = isTemplateTask;
    basicFormValues.templateName = templateName;

    let website = "http://35.173.187.119:3000";

    basicFormValues.websiteURL = website;

    basicFormValues.detailsWithOutTags = detail;

    // handleChange(e);
    setBasicFormData({ ...basicFormValues });
  };

  const appliedGroupValidation = (errors: any, emailText: string) => {
    if (!emailText || emailText.length === 0) {
      errors.detail = "No email or group name added against group: tag ";
    } else {
      if (emailText.includes(",")) {
        // Group email
        const emptyEmails = emailText.split(",").filter((e) => {
          if (!e || e.trim().length === 0) return true;
          return false;
        });

        if (emptyEmails && emptyEmails.length > 0) {
          errors.detail = "Valid email are required after ',' ";
        } else {
          const invalidEmails = emailText.split(",").filter((e) => {
            if (!isValidInHandoverOrNot(e)) return true;
            return false;
          });

          if (invalidEmails && invalidEmails.length > 0) {
            let isGroupName = false;

            // Check is group name exist
            invalidEmails.forEach((item) => {
              const indx = groups.findIndex((grp) => grp.name === item.trim());

              if (indx > -1) isGroupName = true;
            });

            if (isGroupName)
              errors.detail =
                "Either use group name or use comma separated emails.";
            else
              errors.detail =
                "Following emails or group names [" +
                invalidEmails.join(", ") +
                "] are not valid.";
          } else {
            // errors.detail = "";
          }
        }
      } else {
        //Single emails

        if (!isValidInHandoverOrNot(emailText)) {
          // entered email not valid, so heck for group name

          let isGroupName = false;

          // Check is group name exist

          const indx = groups.findIndex((grp) => grp.name === emailText.trim());

          if (indx > -1) isGroupName = true;

          if (!isGroupName)
            errors.detail = "Please enter valid email or group name.";
          else {
            const emails = groups[indx] && groups[indx].groupEmails;

            if (emails && emails?.length > 0) {
            } else errors.detail = "No email found in the selected group ";
          }
        } else {
          // email is valid
          // errors.detail = "";
        }
      }
    }
  };

  const validateBasicTaskForm = (values: Task) => {
    const errors: {
      detail?: string;
      duedate?: string;
      assignedToEmail?: string;
      assignToSomeone?: boolean;
    } = {};

    if (!values.detail) {
      errors.detail = "No task summary given";
    }

    let detail = values.detail;

    if (values.assignToSomeone) {
      if (!values.duedate) errors.duedate = "Please select due date of task";

      if (!values.assignedToEmail) {
        errors.assignedToEmail = "Please provide email address of assignee";
      } else if (!isValidEmail(values.assignedToEmail)) {
        errors.assignedToEmail = "Invalid Email Format";
      }
    } else if (values.assignee != null && !errors.detail) {
      if (values.assignee.length === 0)
        errors.detail = "No email added against to: tag ";
      else {
        if (values.assignee.indexOf(",") > -1) {
          const invalidEmails = values.assignee.split(",").filter((e) => {
            if (!isValidEmail(e)) return true;
            return false;
          });

          if (invalidEmails.length > 0) {
            if (invalidEmails.length > 1)
              errors.detail =
                "Following emails against to: tag [" +
                invalidEmails.join(", ") +
                "] are not valid.";
            else
              errors.detail =
                "Following email against to: tag [" +
                invalidEmails[0] +
                "] is not valid.";
          }
        } else {
          if (!isValidEmail(values.assignee)) {
            errors.detail =
              "Following email against to: tag [" +
              values.assignee +
              "] is not valid.";
          }
        }
      }
    }

    const groupTagIdx = detail.toLowerCase().indexOf("group:");

    // handle group: tag
    if (groupTagIdx > -1) {
      // find index of next space
      let groupTagSpaceIdx = detail.toLowerCase().indexOf(" ", groupTagIdx);

      if (groupTagSpaceIdx > -1) {
        if (groupTagSpaceIdx > groupTagIdx + 6) {
          const assignee = detail.substring(groupTagIdx + 6, groupTagSpaceIdx);

          // Apply validation here

          appliedGroupValidation(errors, assignee);
        } else {
          errors.detail = "No email or group name added against group: tag ";
        }
      } else {
        // just use anything after to as assignee email
        if (detail.length > groupTagIdx + 6) {
          const assignee = detail.substring(groupTagIdx + 6, detail.length);
          appliedGroupValidation(errors, assignee);
        } else {
          errors.detail = "No email or group name added against group: tag ";
        }
      }
    }

    // if (values.dueInDays && isNaN(values.dueInDays) && !errors.detail) {
    //   errors.detail =
    //     "Due days added against due: tag '" +
    //     values.dueInDays +
    //     "' is not a number";
    // }

    if (!errors.detail) {
      const toTagIdx = detail.toLowerCase().indexOf("to:");

      if (toTagIdx > -1) {
        let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx);

        if (toTagSpaceIdx > -1) {
          detail =
            detail.substring(0, toTagIdx) +
            detail.substring(toTagSpaceIdx + 1, detail.length);
        } else {
          // tag is present till the end
          detail = detail.substring(0, toTagIdx);
        }
      }
    }

    if (!errors.detail) {
      const dueTagIdx = detail.toLowerCase().indexOf("due:");

      if (dueTagIdx > -1) {
        let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx);

        if (dueTagSpaceIdx > -1) {
          detail =
            detail.substring(0, dueTagIdx) +
            detail.substring(dueTagSpaceIdx + 1, detail.length);
        } else {
          detail = detail.substring(0, dueTagIdx);
        }
      }
    }

    if (!errors.detail) {
      let progressIdx = detail.toLowerCase().indexOf("s:");
      let progressInc = 0;

      if (progressIdx > 0) {
        // we would have to ensure that the progress tag starts with space if it is in the middle of the string
        progressIdx = detail.toLocaleLowerCase().indexOf(" s:");
        progressInc = 1;
      }

      if (progressIdx > -1) {
        let progressSpaceIdx = detail
          .toLowerCase()
          .indexOf(" ", progressIdx + progressInc);

        let progressCount;
        if (progressSpaceIdx > -1) {
          progressCount = detail.substring(
            progressIdx + 2 + progressInc,
            progressSpaceIdx
          );

          detail =
            detail.substring(0, progressIdx) +
            (progressInc > 0
              ? detail.substring(progressSpaceIdx, detail.length)
              : detail.substring(progressSpaceIdx + 1, detail.length));
        } else {
          progressCount = detail.substring(
            progressIdx + 2 + progressInc,
            detail.length
          );

          detail = detail.substring(0, progressIdx);
        }

        if (isNaN(parseInt(progressCount))) {
          errors.detail =
            "Progress added against s: tag '" +
            progressCount +
            "' is not a number";
        } else if (parseInt(progressCount) < 0 || parseInt(progressCount) > 5) {
          errors.detail =
            "Progress added against s: tag '" +
            progressCount +
            "' should have value in 0-5 range.";
        }
      }
    }

    if (!errors.detail) {
      const groupIdx = detail.toLowerCase().indexOf("group:");

      if (groupIdx > -1) {
        let groupSpaceIdx = detail.toLowerCase().indexOf(" ", groupIdx);

        if (groupSpaceIdx > -1) {
          detail =
            detail.substring(0, groupIdx) +
            detail.substring(groupSpaceIdx + 1, detail.length);
        } else {
          detail = detail.substring(0, groupIdx);
        }
      }
    }

    if (!errors.detail) {
      let priorityIdx = detail.toLowerCase().indexOf("p:");
      let priorityInc = 0;

      if (priorityIdx > 0) {
        priorityIdx = detail.toLocaleLowerCase().indexOf(" p:");
        priorityInc = 1;
      }

      if (priorityIdx > -1) {
        let prioritySpaceIdx = detail
          .toLowerCase()
          .indexOf(" ", priorityIdx + priorityInc);

        let priority;
        if (prioritySpaceIdx > -1) {
          priority = detail.substring(
            priorityIdx + 2 + priorityInc,
            prioritySpaceIdx
          );

          detail =
            detail.substring(0, priorityIdx) +
            (priorityInc > 0
              ? detail.substring(prioritySpaceIdx, detail.length)
              : detail.substring(prioritySpaceIdx + 1, detail.length));
        } else {
          priority = detail.substring(
            priorityIdx + 2 + priorityInc,
            values.detail.length
          );

          detail = detail.substring(0, priorityIdx);
        }

        if (isNaN(parseInt(priority))) {
          errors.detail =
            "Priority added against p: tag '" + priority + "' is not a number";
        } else if (parseInt(priority) < 1 || parseInt(priority) > 5) {
          errors.detail =
            "Priority added against p: tag '" +
            priority +
            "' should have value in 1-5 range.";
        }
      }
    }

    if (!errors.detail) {
      let hashTagIdx = detail.indexOf("#");
      while (hashTagIdx > -1) {
        var cat = null;
        let hashTagSpaceIdx = detail.indexOf(" ", hashTagIdx);

        if (hashTagSpaceIdx > -1) {
          cat = detail.substring(hashTagIdx + 1, hashTagSpaceIdx);

          detail =
            detail.substring(0, hashTagIdx) +
            detail.substring(hashTagSpaceIdx + 1, detail.length);
        } else {
          cat = detail.substring(hashTagIdx + 1);
          detail = detail.substring(0, hashTagIdx);
        }

        if (cat.length === 0) {
          errors.detail =
            "# Should be followed by a category name, for example #work";
          break;
        }

        if (!regRestrictSpecialCharacters.test(cat)) {
          errors.detail =
            "Special characters are not allowed in the category name";
          break;
        }

        if (hashTagSpaceIdx === -1) {
          break;
        }
        hashTagIdx = detail.indexOf("#");
      }
    }

    // Validation for recurring task

    if (!errors.detail) {
      const recurringIdx = detail.toLowerCase().indexOf("r:");

      if (recurringIdx > -1) {
        let recurringSpaceIdx = detail.toLowerCase().indexOf(" ", recurringIdx);

        let recurringText;

        if (recurringSpaceIdx !== -1) {
          recurringText = detail.substring(recurringIdx + 2, recurringSpaceIdx);
          detail =
            detail.substring(0, recurringIdx) +
            detail.substring(recurringSpaceIdx + 1, detail.length);
        } else {
          recurringText = detail.substring(recurringIdx + 2, detail.length);
          detail = detail.substring(0, recurringIdx);
        }

        if (recurringText.length > 0) {
          const recurringData = recurringText.split("-");

          if (recurringData.length === 2) {
          }

          if (recurringData.length === 2) {
            if (recurringData[0].includes("@")) {
              const recurringCustomData = recurringData[0].split("@");

              if (recurringCustomData[1].length > 3) {
                if (!validateDayName(recurringCustomData[1].substring(0, 3))) {
                  errors.detail = `Recurring custom start day name not valid, it should be in 3 digit like- sun, mon, tue, wed, thu, fri, sat`;
                }

                if (
                  !validateTime(
                    recurringCustomData[1].substring(
                      3,
                      recurringCustomData[1].length
                    )
                  )
                ) {
                  errors.detail = `Recurring custom start time not valid, it should be like- 1230, 1000, 0115 `;
                }
              } else {
                errors.detail = `Recurring custom start day/time not valid, it should be like- "r:2@tue1230"`;
              }
            } else if (recurringData[1].includes("@")) {
              const recurringCustomData = recurringData[1].split("@");

              if (recurringCustomData[1].length > 3) {
                if (!validateDayName(recurringCustomData[1].substring(0, 3))) {
                  errors.detail = `Recurring custom start day name not valid, it should be in 3 digit like- sun, mon, tue, wed, thu, fri, sat`;
                }

                if (
                  !validateTime(
                    recurringCustomData[1].substring(
                      3,
                      recurringCustomData[1].length
                    )
                  )
                ) {
                  errors.detail = `Recurring custom start time not valid, it should be like- 1230, 1000, 0115 `;
                }
              } else {
                errors.detail = `Recurring custom start day/time not valid, it should be like- "r:2@tue1230"`;
              }
            }
          } else {
            if (recurringData[0].includes("@")) {
              const recurringCustomData = recurringData[0].split("@");

              if (recurringCustomData[1].length > 3) {
                if (!validateDayName(recurringCustomData[1].substring(0, 3))) {
                  errors.detail = `Recurring custom start day name not valid, it should be in 3 digit like- sun, mon, tue, wed, thu, fri, sat `;
                }

                if (
                  !validateTime(
                    recurringCustomData[1].substring(
                      3,
                      recurringCustomData[1].length
                    )
                  )
                ) {
                  errors.detail = `Recurring custom start time not valid, it should be like- 1230, 1000, 0115 `;
                }
              } else {
                errors.detail = `Recurring custom start day/time not valid, it should be like- "r:2@tue1230"`;
              }
            }
          }
        }
      }
    }

    if (!errors.detail && detail.trim().length === 0) {
      errors.detail = "No task summary given";
    }

    return errors;
  };

  const submitBasicTask = (
    values: Task,
    resetForm: any,
    setFieldValue: any
  ) => {
    // if (!values.teamId) {

    values.teamId = selectedTeam?.id;

    // }

    if (!values.duedate) {
      // task would be due in 1 day if not provided
      // copy assignedfrom id and assigned to id to assignedto and from as backend would expect id in that

      values.duedate = moment().add(1, "day").toDate();
    }

    /**
     * We will try to set due date to next nearest round figure of 30 minutes interval
     */

    var taskDueMoment = moment(values.duedate);

    const isToday = taskDueMoment.isSame(moment(), "day");

    if (taskDueMoment.isValid() && !isToday) {
      if (taskDueMoment.minutes() >= 0 && taskDueMoment.minutes() <= 30) {
        values.duedate = taskDueMoment
          .startOf("hours")
          .add(30, "minutes")
          .toDate();
      } else {
        values.duedate = taskDueMoment
          .startOf("hours")
          .add(1, "hours")
          .toDate();
      }
    }

    values.duedateText = moment(values.duedate).format("YYYY-MM-DD HH:mm");

    if (!values.assignToSomeone && values.assignee)
      values.assignedToEmail = values.assignee;

    // we will now remove all tags from detail (if any)

    let detail = values.detail;

    const toTagIdx = detail.toLowerCase().indexOf("to:");

    if (toTagIdx > -1) {
      let toTagSpaceIdx = detail.toLowerCase().indexOf(" ", toTagIdx);

      if (toTagSpaceIdx !== -1) {
        detail =
          detail.substring(0, toTagIdx) +
          detail.substring(toTagSpaceIdx + 1, detail.length);
      } else {
        // tag is present till the end
        detail = detail.substring(0, toTagIdx);
      }
    }

    const dueTagIdx = detail.toLowerCase().indexOf("due:");

    if (dueTagIdx > -1) {
      let dueTagSpaceIdx = detail.toLowerCase().indexOf(" ", dueTagIdx);
      if (dueTagSpaceIdx !== -1) {
        detail =
          detail.substring(0, dueTagIdx) +
          detail.substring(dueTagSpaceIdx + 1, detail.length);
      } else {
        detail = detail.substring(0, dueTagIdx);
      }
    }

    let progressIdx = detail.toLowerCase().indexOf("s:");
    let progressInc = 0;

    if (progressIdx > 0) {
      // we would have to ensure that the progress tag starts with space if it is in the middle of the string
      progressIdx = detail.toLocaleLowerCase().indexOf(" s:");
      progressInc = 1;
    }

    if (progressIdx > -1) {
      let progressSpaceIdx = detail
        .toLowerCase()
        .indexOf(" ", progressIdx + progressInc);

      if (progressSpaceIdx > -1) {
        values.progressCount = parseInt(
          detail.substring(progressIdx + 2 + progressInc, progressSpaceIdx)
        );

        detail =
          detail.substring(0, progressIdx) +
          (progressInc > 0
            ? detail.substring(progressSpaceIdx, detail.length)
            : detail.substring(progressSpaceIdx + 1, detail.length));
      } else {
        values.progressCount = parseInt(
          detail.substring(progressIdx + 2 + progressInc, detail.length)
        );

        detail = detail.substring(0, progressIdx);
      }
    }

    /*  const groupIdx = detail.toLowerCase().indexOf("group:");

    if (groupIdx > -1) {
      let groupSpaceIdx = detail.toLowerCase().indexOf(" ", groupIdx);

      let groupText;
      if (groupSpaceIdx > -1) {
        groupText = detail.substring(groupIdx + 6, groupSpaceIdx);

        detail =
          detail.substring(0, groupIdx) +
          detail.substring(groupSpaceIdx + 1, detail.length);
      } else {
        groupText = detail.substring(groupIdx + 6, values.detail.length);

        detail = detail.substring(0, groupIdx);
      }

      values.groupEmails = [groupText];
    } */

    const groupIdx = detail.toLowerCase().indexOf("group:");

    if (groupIdx > -1) {
      let groupSpaceIdx = detail.toLowerCase().indexOf(" ", groupIdx);

      let groupText;
      let group: any;
      if (groupSpaceIdx > -1) {
        const txt = detail.substring(groupIdx + 6, groupSpaceIdx);

        if (txt && txt.length > 0) {
          if (txt.includes(",")) {
            groupText = txt;
          } else {
            let isGroup = false;

            const indx = groups.findIndex((grp: Group) => grp.name === txt);
            if (indx > -1) isGroup = true;
            if (isGroup) group = groups[indx];
            else groupText = txt;
          }
        }

        detail =
          detail.substring(0, groupIdx) +
          detail.substring(groupSpaceIdx + 1, detail.length);
      } else {
        //  groupText = detail.substring(groupIdx + 6, detail.length);

        const txt = detail.substring(groupIdx + 6, detail.length);

        if (txt && txt.length > 0) {
          if (txt.includes(",")) {
            groupText = txt;
          } else {
            let isGroup = false;

            const indx = groups.findIndex((grp: Group) => grp.name === txt);
            if (indx > -1) isGroup = true;
            if (isGroup) group = groups[indx];
            else groupText = txt;
          }
        }

        detail = detail.substring(0, groupIdx);
      }

      // basicFormValues.groupEmails = groupText;
      // basicFormValues.groupName = groupName;
      const groupEmails =
        group && group.groupEmails
          ? group.groupEmails.map((email: any) => email.email).join(",")
          : "";

      values.groupEmails =
        groupEmails && groupEmails.length > 0 ? [groupEmails] : [groupText];
    }

    let priorityIdx = detail.toLowerCase().indexOf("p:");
    let priorityInc = 0;

    if (priorityIdx > 0) {
      priorityIdx = detail.toLocaleLowerCase().indexOf(" p:");
      priorityInc = 1;
    }

    if (priorityIdx > -1) {
      let prioritySpaceIdx = detail
        .toLowerCase()
        .indexOf(" ", priorityIdx + priorityInc);

      if (prioritySpaceIdx > -1) {
        values.priority = parseInt(
          detail.substring(priorityIdx + 2 + priorityInc, prioritySpaceIdx)
        );

        detail =
          detail.substring(0, priorityIdx) +
          (priorityInc > 0
            ? detail.substring(prioritySpaceIdx, detail.length)
            : detail.substring(prioritySpaceIdx + 1, detail.length));
      } else {
        values.priority = parseInt(
          detail.substring(priorityIdx + 2 + priorityInc, detail.length)
        );

        detail = detail.substring(0, priorityIdx);
      }
    }

    let ccUserMail = "";
    const ccMailIdx = detail.toLowerCase().indexOf("cc:");
    if (ccMailIdx > -1) {
      let ccMailSpaceIdx = detail.toLowerCase().indexOf(" ", ccMailIdx);
      if (ccMailSpaceIdx !== -1) {
        ccUserMail = detail.substring(ccMailIdx + 3, ccMailSpaceIdx);
        detail =
          detail.substring(0, ccMailIdx) +
          detail.substring(ccMailSpaceIdx + 1, detail.length);
      } else {
        ccUserMail = detail.substring(ccMailIdx + 3, detail.length);
        detail = detail.substring(0, ccMailIdx);
      }
      values.detail = detail;
      values.ccUserMail = ccUserMail;
    }

    // see if there are any categories added against this task

    let categories = [];

    let hashTagIdx = detail.indexOf("#");
    while (hashTagIdx > -1) {
      let hashTagSpaceIdx = detail.indexOf(" ", hashTagIdx);

      if (hashTagSpaceIdx > -1) {
        categories.push(detail.substring(hashTagIdx + 1, hashTagSpaceIdx));
        detail =
          detail.substring(0, hashTagIdx) +
          detail.substring(hashTagSpaceIdx + 1);
      } else {
        categories.push(detail.substring(hashTagIdx + 1));
        detail = detail.substring(0, hashTagIdx);
      }

      hashTagIdx = detail.indexOf("#");
    }

    let isRecurring = false;
    let recurringInDay;
    let recurringUntil;
    let isRecurringCustomStart = false;
    let recurringCustomDay;
    let recurringCustomTime;
    const recurringIdx = detail.toLowerCase().indexOf("r:");

    if (recurringIdx > -1) {
      let recurringSpaceIdx = detail.toLowerCase().indexOf(" ", recurringIdx);

      let recurringText;

      if (recurringSpaceIdx !== -1) {
        recurringText = detail.substring(recurringIdx + 2, recurringSpaceIdx);
        detail =
          detail.substring(0, recurringIdx) +
          detail.substring(recurringSpaceIdx + 1, detail.length);
      } else {
        recurringText = detail.substring(recurringIdx + 2, detail.length);
        detail = detail.substring(0, recurringIdx);
      }

      if (recurringText.length > 0) {
        const recurringData = recurringText.split("-");

        if (recurringData.length === 2) {
          isRecurring = true;
          if (recurringData[0].includes("@")) {
            const recurringCustomData = recurringData[0].split("@");
            recurringInDay = parseInt(recurringCustomData[0]);

            if (recurringCustomData[1].length > 3) {
              isRecurringCustomStart = true;
              recurringCustomDay = recurringCustomData[1].substring(0, 3);
              recurringCustomTime = recurringCustomData[1].substring(
                3,
                recurringCustomData[1].length
              );
            }
          } else recurringInDay = parseInt(recurringData[0]);
          recurringUntil = parseInt(recurringData[1]);
        } else {
          isRecurring = true;
          if (recurringData[0].includes("@")) {
            const recurringCustomData = recurringData[0].split("@");
            recurringInDay = parseInt(recurringCustomData[0]);

            if (recurringCustomData[1].length > 3) {
              isRecurringCustomStart = true;
              recurringCustomDay = recurringCustomData[1].substring(0, 3);
              recurringCustomTime = recurringCustomData[1].substring(
                3,
                recurringCustomData[1].length
              );
            }
          } else recurringInDay = parseInt(recurringData[0]);
          recurringUntil = -1;
        }
      }
    }

    // see if there are any template added against this task

    let isTemplateTask = false;
    let templateName;
    const templateIdx = detail.toLowerCase().indexOf("tmpl:");
    if (templateIdx > -1) {
      let templateSpaceIdx = detail.toLowerCase().indexOf(" ", templateIdx);

      if (templateSpaceIdx !== -1) {
        templateName = detail.substring(templateIdx + 5, templateSpaceIdx);

        detail =
          detail.substring(0, templateIdx) +
          detail.substring(templateSpaceIdx + 1, detail.length);
      } else {
        templateName = detail.substring(templateIdx + 5, detail.length);
        detail = detail.substring(0, templateIdx);
      }
    }

    if (templateName && templateName.length > 0) isTemplateTask = true;

    values.detail = detail;
    values.cats = categories;
    values.isDescription = isDescription;
    values.isRecurring = isRecurring;
    values.recurringInDay = recurringInDay;
    values.recurringUntil = recurringUntil;
    values.isRecurringCustomStart = isRecurringCustomStart;
    values.recurringCustomDay = recurringCustomDay;
    values.recurringCustomTime = recurringCustomTime;
    values.isTemplateTask = isTemplateTask;
    values.templateName = templateName;

    let website = "http://35.173.187.119:3000";

    values.websiteURL = website;

    const stopServerCall = false;

    if (stopServerCall) {
      initValues = undefined;
      setBasicFormData(undefined);
      resetForm();
      setIsDescription("");
      setFieldValue("duedate", moment().add(1, "day").toDate());

      setLabels({
        dueIn: "Due in 1 day",
      });
      return;
    }

    if (isTemplateTask && templateName && templateName.length > 0) {
      _cio.track(USER_USE_TEMPLATE_IN_A_TASK_EVENTS, {
        name: "user created task using template on basic task form",
      });
    }

    //   if (basicTaskformCanSubmit) {

    if (values.cats && values.cats.length > 0) {
      // user created task using category tag #
      _cio.track(USER_USE_CATEGORY_TAG_FEATURE_EVENTS, {
        name: "user created task using category tag(#)",
      });
    }

    if (values.priority && values.priority > 0 && values.priority < 6) {
      // user created task using category tag #
      _cio.track(USER_USE_PRIORITY_TAG_FEATURE_EVENTS, {
        name: "user created task using priority tag (p:)",
      });
    }

    //  // check if user assign task to self/group/other

    if (values.groupEmails.length > 0) {
      // task is group type

      _cio.track(USER_ASSIGN_GROUP_TASK_EVENTS, {
        name: "user assign a task to group users at basic task form",
      });
    } else if (values.assignedToEmail) {
      if (values.assignedToEmail.trim() === userState.user?.email) {
        // task is self type
        _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
          name: "user assign a task to self at basic task form",
        });
      } else {
        if (values.assignedToEmail.includes(",")) {
          _cio.track(USER_ASSIGN_TEAM_MULTIPLE_ASSIGNEE_TASK_EVENTS, {
            name: "user assign a task to multiple users at basic task form",
          });
        } else {
          // task is other type or assign some one else
          _cio.track(USER_ASSIGN_SOMEONE_ELSE_A_TASK_EVENTS, {
            name: "user assign a task to someone else at basic task form",
          });
        }

        if (
          userState.user?.email &&
          values.assignedToEmail.includes(userState.user?.email)
        ) {
          // task is self type
          _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
            name: "user assign a task to self at basic task form",
          });
        }
      }
    } else {
      // task is self type
      _cio.track(USER_ASSIGN_SELF_A_TASK_EVENTS, {
        name: "user assign a task to self at basic task form",
      });
    }

    myAxios
      .post(`tasks`, values)
      .then((response) => {
        if (response.data.success) {
          if (response.data.data) addTask(response.data.data);
          NotificationManager.success(
            response.data?.message
              ? response.data?.message
              : "Task successfully added!"
          );

          // Need to refresh Menu Filter after addding new task

          myAxios
            .get(`menu-filters/${selectedTeam?.id}`)
            .then((response: any) => {
              addCategories(response.data.data.categories);
              addAssignee(response.data.data.assigneeList);
              addAssignor(response.data.data.assignorList);
              addDayFilters(response.data.data.daysMenuArray);
            })
            .catch((error) => {});

          //  Also fetch latest task as selected pagination

          myAxios
            .post(`tasks/paginated-tasks`, currentPaginationTaskInput)
            .then((response: any) => {
              if (response.data.data.tasks) addTasks(response.data.data.tasks);
              else addTasks([]);
              if (response.data.data.count)
                setTaskCount(response.data.data.count);
              else setTaskCount(0);
            })
            .catch((error) => {
              console.error("error ", error);
            });
        } else {
          NotificationManager.error(
            response.data?.message
              ? response.data?.message
              : "Failed to add task!"
          );
        }
        initValues = undefined;
        setBasicFormData(undefined);
        resetForm();
        values.detail = "";
        values.assignedToEmail = "";
        values.groupEmails = [];
        setIsDescription(null);
        setFieldValue("duedate", moment().add(1, "day").toDate());
        setLabels({
          dueIn: "Due in 1 day",
        });
        setTimeout(() => {
          setFieldValue("detail", "");
        }, 300);
      })
      .catch((error) => {
        console.error(" Getting Error ", error);
        initValues = undefined;
        setBasicFormData(undefined);
        resetForm();
        values.detail = "";
        values.assignedToEmail = "";
        values.groupEmails = [];
        setIsDescription("");
        setFieldValue("duedate", moment().add(1, "day").toDate());
        setLabels({
          dueIn: "Due in 1 day",
        });
        NotificationManager.error(parseErrorResponse(error));
      });
    //   }
  };

  return (
    <>
      <Formik
        // validateOnChange={false}
        // validateOnBlur={false}
        initialValues={
          initValues
            ? {
                detail: createDetailsString(initValues),
                duedate: initValues.duedate
                  ? initValues.duedate
                  : moment().add(1, "day").toDate(),
                progress: 0,
                comment: "",
                date: "",
                priority: initValues.priority,
                ccUserMail: initValues.ccUserMail ? initValues.ccUserMail : "",
                description: initValues.isDescription,
                isDescription: initValues.isDescription,
                notificationUser: [],
                assignedToEmail:
                  initValues.assignedToEmail &&
                  initValues.assignedToEmail.length > 0
                    ? initValues.assignedToEmail
                    : "",
                groupEmails:
                  initValues.groupEmails && initValues.groupEmails.length > 0
                    ? initValues.groupEmails
                    : "",
                isTemplateTask: initValues.templateName ? true : false,
                templateName: initValues.templateName
                  ? initValues.templateName
                  : "",
                teamId: selectedTeam?.id,
              }
            : {
                detail: "",
                duedate: moment().add(1, "day").toDate(),
                progress: 0,
                comment: "",
                date: "",
                priority: 0,
                ccUserMail: "",
                description: "",
                groupEmails: [],
                isDescription: "",
                notificationUser: [],
                isTemplateTask: false,
                templateName: "",
                teamId: selectedTeam?.id,
              }
        }
        validate={(values: Task) => {
          return validateBasicTaskForm(values);
        }}
        onSubmit={(values: Task, { resetForm, setFieldValue }) => {
          console.log("values", values);
          submitBasicTask(values, resetForm, setFieldValue);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          values,
          setFieldValue,
          validateForm,
        }) => {
          return (
            <TheForm onKeyDown={onKeyDown} onSubmit={handleSubmit}>
              <GlobalStyle />
              <MainContainer>
                <FormFirstRow>
                  <BasicModeLabel>Task Summary</BasicModeLabel>
                  <TaskDetailInputWrapper>
                    {/* <InputLabel>Task Summary</InputLabel> */}
                    <TaskFormInput
                      component={AutoCompleteBasicTaskDetail}
                      innerRef={taskDetailsInput}
                      type="text"
                      name="detail"
                      assignee={values.assignedToEmail}
                      value={values.detail}
                      className={USETIFULL_BASIC_TASK_CREATION}
                      placeholder="Task Summary"
                      margin="0px"
                      setFieldValue={setFieldValue}
                      valueChangeListener={valueChangeListener}
                      validateForm={validateForm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleBlur(e);
                      }}
                    />

                    {checkIfFeatureOpen(
                      userState?.user?.appFeatures,
                      GPT_3_OPENAI
                    ) && (
                      <>
                        {showJetBotLoader ? (
                          <JetBotLoader />
                        ) : (
                          <>
                            {values.detail &&
                              values.detail.length > 2 &&
                              !showJetBotPopup &&
                              forceStopJetBot && (
                                <JetBotPopUpInnerDiv>
                                  <CrossImgTag
                                    src={CrossJetBotSvg}
                                    alt="cross"
                                    onClick={() => {
                                      setForceStopJetBot(false);
                                    }}
                                  />
                                  <JetBotPopHeader>
                                    Hello, I am JetBot
                                  </JetBotPopHeader>
                                  <JetBotPopText>
                                    I can suggest a description based on your
                                    task summary. Interested, click me!
                                  </JetBotPopText>
                                  <span>
                                    <i className="tooltipDown"></i>
                                  </span>
                                </JetBotPopUpInnerDiv>
                              )}
                            <JetBotImgTag
                              className={USETIFULL_JETBOT}
                              src={
                                values.detail &&
                                values.detail.length > 2 &&
                                !showJetBotPopup &&
                                forceStopJetBot
                                  ? JetBotAnimation
                                  : JetBotIcon
                              }
                              alt="robo"
                              title="Ask Suggestion to Jet-Bot"
                              onClick={() => {
                                searchSuggestion(values.detail);
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </TaskDetailInputWrapper>

                  <Col>
                    {!values.assignToSomeone && !labels.toLabel ? (
                      <Button type="submit" disabled={isSubmitting}>
                        Assign to Self
                        <Paragraph className="Para">({labels.dueIn})</Paragraph>
                      </Button>
                    ) : (
                      <Button type="submit" disabled={isSubmitting}>
                        {groupbtnShow ? "Assign to Group" : "Assign to"}
                        <Paragraph className="Para">
                          {labels.assignee}
                        </Paragraph>
                        <Paragraph className="Para">({labels.dueIn})</Paragraph>
                      </Button>
                    )}
                  </Col>
                </FormFirstRow>

                <Row>
                  {errors.detail && <TaskError>{errors.detail}</TaskError>}
                  <TaskError>{validatioBasicTaskForm}</TaskError>
                </Row>

                {!showOptions && (
                  <DivForAdvancedOption>
                    <ShortcutSpanTag
                      onClick={() => {
                        // Send track event to customer.io
                        _cio.track(SHORTCUT_LINK_CLICK_EVENTS, {
                          name: "shortcut link click",
                        });
                      }}
                    >
                      Note: In this field you can create task only using shorcut
                      tags. Click &nbsp;
                      <a
                        className="Anchortag"
                        target="_blank"
                        rel="noreferrer"
                        href="https://quickhelp.myjetlist.com/"
                      >
                        here
                      </a>
                      &nbsp; to check shortcuts
                    </ShortcutSpanTag>
                    <AdvancedOptionTag
                      className={USETIFULL_BASIC_TASK_ADVANCE_OPTION}
                      onClick={() => {
                        setShowOptions(!showOptions);
                      }}
                    >
                      Advance Option
                    </AdvancedOptionTag>

                    <div></div>
                  </DivForAdvancedOption>
                )}

                <>
                  {showOptions && (
                    <FormSecondRow>
                      <SecondRowColOne>
                        {loading && (
                          <Loader text={"Uploading description image..."} />
                        )}
                        <RichTextEditorView
                          placeholder="Description (Optional)"
                          text={isDescription}
                          setText={setIsDescription}
                          showLoader={showLoader}
                          hideLoader={hideLoader}
                          setLoading={setLoading}
                        />
                      </SecondRowColOne>
                    </FormSecondRow>
                  )}

                  {showOptions && (
                    <DivForAdvancedOption>
                      <ShortcutSpanTag
                        onClick={() => {
                          // Send track event to customer.io
                          _cio.track(SHORTCUT_LINK_CLICK_EVENTS, {
                            name: "shortcut link click",
                          });
                        }}
                      >
                        Note: In this field you can create task only using
                        shorcut tags. Click &nbsp;
                        <a
                          className="Anchortag"
                          target={"_blank"}
                          rel="noreferrer"
                          href="https://quickhelp.myjetlist.com/"
                        >
                          here
                        </a>
                        &nbsp; to check shortcuts
                      </ShortcutSpanTag>
                      <Cancelspan
                        onClick={() => {
                          setFieldValue("showOptions", !values.showOptions);
                          setShowOptions(!showOptions);
                        }}
                      >
                        Cancel
                      </Cancelspan>
                      <div></div>
                    </DivForAdvancedOption>
                  )}
                </>
              </MainContainer>
            </TheForm>
          );
        }}
      </Formik>
      {showJetBotPopup && (
        <JetBotSuggestionPopup
          gptSuggestionsText={gptSuggestionsText}
          setShowJetBotPopup={setShowJetBotPopup}
          keepInDescription={keepInDescription}
          searchText={searchText}
          setGptSuggestionsText={setGptSuggestionsText}
          setForceStopJetBot={setForceStopJetBot}
        />
      )}
    </>
  );
};

export default BasicTaskForm;
