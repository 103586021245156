import { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import myAxios from "../utils/myaxios";
import { acceptedDocumentTypes, quillFormats } from "./utils/constants";

const RichTextEditorView = ({
  text,
  setText,
  placeholder,
  showLoader,
  hideLoader,
  setLoading,
}: any) => {
  const [quillObj, setQuillObj] = useState<any>();
  const [uploadImageURL, setUploadImageURL] = useState<string | undefined>(
    undefined
  );
  const [uploadedPdfURL, setUploadedPdfURL] = useState<string | undefined>(
    undefined
  );
  const [uplaodDescriptionVideoUrl, setUplaodDescriptionVideoUrl] =
    useState<any>();

  // Uploading document

  useEffect(() => {
    if (uploadedPdfURL && quillObj) {
      try {
        quillObj
          .getEditor()
          .clipboard.dangerouslyPasteHTML(
            quillObj.getEditor().getSelection().index + 10,
            `</br></br>`
          ); // used this for extra spacing before placing pdf
      } catch (error) {
        console.error(error);
      }

      quillObj
        .getEditor()
        .insertEmbed(
          quillObj.getEditor().getSelection().index + 10,
          "document",
          uploadedPdfURL
        );

      quillObj
        .getEditor()
        .clipboard.dangerouslyPasteHTML(
          quillObj.getEditor().getSelection().index + 10,
          `</br></br></br></br>`
        ); // used this for extra spacing after placing pdf
    }
    setUploadedPdfURL(undefined);
  }, [uploadedPdfURL, quillObj]);

  // uplaoding image
  useEffect(() => {
    if (uploadImageURL && quillObj) {
      quillObj.getEditor().insertEmbed(quillObj.index, "image", uploadImageURL);
      quillObj
        .getEditor()
        .clipboard.dangerouslyPasteHTML(
          quillObj.getEditor().getSelection().index + 10,
          `</br></br></br></br>`
        );
      quillObj
        .getEditor()
        .setSelection(quillObj.getEditor().getSelection().index + 15);
    }
    setUploadImageURL(undefined);
  }, [uploadImageURL, quillObj]);

  useEffect(() => {
    if (uplaodDescriptionVideoUrl && quillObj) {
      quillObj
        .getEditor()
        .insertEmbed(quillObj.index, "video", uplaodDescriptionVideoUrl);
    }
    setUplaodDescriptionVideoUrl(undefined);
  }, [uplaodDescriptionVideoUrl, quillObj]);

  const uploadImage = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        setLoading(true);
      }
      myAxios.post("/upload", formData).then((res) => {
        setLoading(false);

        if (res.data && res.data.length > 0) {
          setUploadImageURL(res.data[0].url);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  const base64DescriptionImageConvertion = async (base64: any) => {
    if (base64.includes('<img src="data:image/png;base64')) {
      let converttedBase64 = base64.substring(
        base64.indexOf('<img src="data:image/png;base64')
      );
      converttedBase64 = converttedBase64.substring(
        0,
        converttedBase64.indexOf(">") + 1
      );

      converttedBase64 = converttedBase64
        .replace("<p>", "")
        .replace("</p>", "")
        .replace("<img", "")
        .replace("src=", "")
        .replace(">", "")
        .replace(/"/g, "");

      const base64Response = await fetch(converttedBase64);
      const file = await base64Response.blob();
      const formData = new FormData();
      formData.append("files", file);
      convertedDescriptionImageSendToServer(formData);
    } else {
      // setExpandedDescription(base64);
      setText(base64);
    }
  };

  const convertedDescriptionImageSendToServer = (formData: any) => {
    showLoader("Uploading image...");
    myAxios
      .post("/upload", formData)
      .then((res) => {
        hideLoader();
        setUploadImageURL(res.data[0].url);
        // setIsDescription(commentText)
      })
      .catch((error) => {
        console.error("Gettings error at the time of uploading file ", error);
      });
  };

  const handleChanges = (event: any) => {
    base64DescriptionImageConvertion(event);
  };

  const uplaodVideo = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      showLoader("Uploading video ...");

      return myAxios.post("/upload", formData).then((res) => {
        if (res.data && res.data.length > 0) {
          setUplaodDescriptionVideoUrl(res.data[0].url);
          hideLoader();
        }
      });
    };
  };

  const uploadFile = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", acceptedDocumentTypes);
    input.click();

    input.onchange = async () => {
      let file: any = input && input.files && input.files[0];
      let formData = new FormData();

      formData.append("files", file);

      if (file) {
        showLoader("Uploading document...");
      }
      myAxios.post("/upload", formData).then((res) => {
        hideLoader();

        if (res.data && res.data.length > 0) {
          setUploadedPdfURL(res.data[0].url);
        }
      });
    };
    // eslint-disable-next-line
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["video"],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: [] }],
          ["clean"],
        ],
        handlers: {
          image: uploadImage,
          video: () => {
            uplaodVideo();
          },
          link: uploadFile,
        },
        table: true,
      },
      // eslint-disable-next-line
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <ReactQuill
        ref={(el) => {
          setQuillObj(el);
        }}
        className="QuillDescriptionExpandedMode"
        placeholder={placeholder}
        onChange={handleChanges}
        modules={modules}
        formats={quillFormats}
        value={text ? text : ""}
        theme="snow"
        style={{ flex: "auto", padding: "0" }}
      />
    </>
  );
};

export default RichTextEditorView;
