import { Field, FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import AddPrefixPrompt from "../../images/addCategoriesBlue.svg";
import RemoveSubTasksInputFieldSvg from "../../images/removeinput.svg";
import { OpenAISetting, PromptPreFix } from "../../types";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";

const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 70%;
  width: 679px;

  //background-color: #46262F;
  position: absolute;
`;

const AppFeatureListContainer = styled.div`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 850px; */
  /* width: 679px; */
  position: relative;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const AddAppFeatureContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const HeadingText = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #2f4c95;
`;

const OpenSettingContainer = styled.div``;

const KeyField = styled(Field)`
  border: none;
  color: #2f4c95;
  background: white;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const ValueField = styled(Field)`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const PreFixPromptLabel = styled(Field)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const TextAreaField = styled(Field)`
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const ValueSelectField = styled.select`
  width: 380px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: inline;
  padding: 5px;
  margin: 10px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 10px 0;
    width: 100%;
  }
`;

const UpadateBtn = styled.button`
  border: none;
  width: 133px;
  height: 40px;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  /* margin: 23px 30px; */
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 20px;
  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const ErrorTag = styled.div`
  color: red;
  margin: 0px 6px;
  font-size: 11px;
  padding: 3px 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    margin: 0px 6px;
    font-size: 10px;
  }
`;
const DivForSubmitBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;
const DivSubTask = styled.div`
  padding: 31px 21px;
  display: flex;
  justify-content: space-between;
  width: 98%;
  position: relative;
  @media (max-width: 450px) and (min-width: 320px) {
    padding: 3px 14px;
  }
`;
const Span = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(60, 59, 91, 0.8);
`;
const AddNewSubTasksImg = styled.img`
  cursor: pointer;
  position: absolute;
  right: 37px;
  top: 34.5%;
  @media (max-width: 450px) and (min-width: 320px) {
    right: 9px;
    top: 6.5%;
  }
`;
const RemoveSubTaskImg = styled.img`
  /*  top: 6.4%; */
  cursor: pointer;
  /*  position: absolute;
  right: 9px; */
`;
const Gpt3Settings = () => {
  const { userState } = useContext(UserContext);
  const {
    openAISettings,
    setOpenAISettings,
    promptPreFixes,
    setPromptPreFixes,
  } = useContext(AdminContext);

  const [openSettingErrors, setOpenSettingErrors] = useState<OpenAISetting[]>(
    []
  );
  const [promptPreFixErrors, setPromptPrefixErros] = useState<PromptPreFix[]>(
    []
  );
  const [deletedPromptPreFixeIds, setDeletedPromptPreFixeIds] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const saveOpenAISettings = (values: any) => {
    setLoading(true);
    myAxios
      .post(`admin-settings/save-update-openai-settings`, {
        ...values,
        deletedPromptPreFixeIds,
      })
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            "Settings save successfully...",
            "",
            1500
          );
          if (response.data && response.data.data) {
            setOpenAISettings(response.data.data.openAISettings);
            setPromptPreFixes(response.data.data.promptPreFixes);
            setDeletedPromptPreFixeIds([]);
          }
        } else {
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Somthing went wrong, not able to add...",
            "",
            2000
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err, "error in getting save openai-settings");
        NotificationManager.error(parseErrorResponse(err));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`admin-settings/openai-settings`)
          .then((response) => {
            if (response.data && response.data.data) {
              setOpenAISettings(response.data.data.openAISettings);
              setPromptPreFixes(response.data.data.promptPreFixes);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting get openai-settings");
          });
      }, 1000);
    }
  }, [userState, setOpenAISettings, setPromptPreFixes]);

  return (
    <>
      <AppFeatureListContainer>
        <AddAppFeatureContainer>
          <HeadingText>OpenAI(GPT-3) Settings</HeadingText>
        </AddAppFeatureContainer>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            openaiSettings: openAISettings && openAISettings,
            promptPreFixes: promptPreFixes && promptPreFixes,
          }}
          validate={(values) => {
            const errors: {
              openaiSettings?: OpenAISetting[];
              promptPreFixes?: PromptPreFix[];
            } = {};

            let openSettErrors: OpenAISetting[] = [];
            let isError = false;
            values.openaiSettings.forEach((openSetting: OpenAISetting) => {
              if (openSetting.key === "prompt_prefix") {
                openSettErrors.push({ value: "" });
              } else {
                const val = openSetting.value + "";
                if (val && val.length > 0) {
                  openSettErrors.push({ value: "" });
                } else {
                  isError = true;

                  openSettErrors.push({
                    value: `"${openSetting.key}" value is required`,
                  });
                }
              }
            });

            setOpenSettingErrors(openSettErrors);
            if (isError) {
              errors.openaiSettings = openSettErrors;
              // errors.subTemplates = [subtemplErrors]
            }
            isError = false;
            let promptPreFixes: PromptPreFix[] = [];
            values.promptPreFixes.forEach((promptPreFix: PromptPreFix) => {
              const label = promptPreFix.label ? promptPreFix.label : "";
              const prompt = promptPreFix.prompt ? promptPreFix.prompt : "";

              if (label && label.length > 0 && prompt && prompt.length > 0) {
                promptPreFixes.push({ label: "", prompt: "" });
              } else {
                isError = true;

                if (label === undefined && prompt === undefined) {
                  promptPreFixes.push({
                    label: `label is required`,
                    prompt: `prompt is required`,
                  });
                } else if (
                  !label &&
                  !(label.length > 0) &&
                  !prompt &&
                  !(prompt.length > 0)
                ) {
                  promptPreFixes.push({
                    label: `label is required`,
                    prompt: `prompt is required`,
                  });
                } else if (!label && !(label.length > 0)) {
                  promptPreFixes.push({
                    label: `label is required`,
                  });
                } else {
                  promptPreFixes.push({
                    prompt: `prompt is required`,
                  });
                }
              }
            });

            setPromptPrefixErros(promptPreFixes);
            if (isError) {
              errors.promptPreFixes = promptPreFixes;
              // errors.subTemplates = [subtemplErrors]
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            saveOpenAISettings(values);
          }}
        >
          {({ isSubmitting, values, setFieldValue, errors }) => (
            <Form>
              <FieldArray name="openaiSettings">
                {(fieldArrayProps) => {
                  const { form } = fieldArrayProps;
                  const { values } = form;
                  const { openaiSettings } = values;

                  return (
                    <OpenSettingContainer>
                      {openaiSettings.map((openSetting: any, index: number) => (
                        <>
                          <div key={index} style={{ margin: "0px 10px" }}>
                            <div>
                              <KeyField
                                type="text"
                                name={`openaiSettings[${index}].key`}
                                disabled
                              />
                              {openSetting.key === "model" ? (
                                <ValueSelectField
                                  name={`openaiSettings[${index}].value`}
                                  defaultValue={openSetting.value}
                                  // className="ExpandedpriorityDropDown"
                                  // value
                                  onChange={(e) => {
                                    setFieldValue(
                                      `openaiSettings[${index}].value`,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="text-davinci-003">
                                    text-davinci-003
                                  </option>
                                  <option value="text-curie-001">
                                    text-curie-001
                                  </option>
                                  <option value="text-babbage-001">
                                    text-babbage-001
                                  </option>
                                  <option value="text-ada-001">
                                    text-ada-001
                                  </option>
                                </ValueSelectField>
                              ) : openSetting.key === "prompt_prefix" ? (
                                <TextAreaField
                                  type="text"
                                  component="textarea"
                                  name={`openaiSettings[${index}].value`}
                                />
                              ) : (
                                <ValueField
                                  type="number"
                                  name={`openaiSettings[${index}].value`}
                                />
                              )}

                              {openSettingErrors &&
                                openSettingErrors.length > 0 &&
                                openSettingErrors[index] &&
                                openSettingErrors[index].value && (
                                  <ErrorTag>
                                    {openSettingErrors[index].value}
                                  </ErrorTag>
                                )}
                            </div>
                          </div>
                        </>
                      ))}
                    </OpenSettingContainer>
                  );
                }}
              </FieldArray>

              <FieldArray name="promptPreFixes">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { promptPreFixes } = values;

                  return (
                    <div>
                      <DivSubTask>
                        <Span>Prompt prefix list</Span>
                        <AddNewSubTasksImg
                          src={AddPrefixPrompt}
                          alt="add"
                          onClick={(value: any) => {
                            push("");
                            // focus = true;
                          }}
                        />
                      </DivSubTask>

                      {promptPreFixes.map(
                        (promptPreFix: any, index: number) => (
                          <div
                            style={{
                              border: "1px solid #e6e6e6",
                              padding: "3px",
                              display: "flex",
                              marginTop: "2px",
                            }}
                          >
                            <div
                              style={{
                                width: "89%",
                                float: "left",
                                padding: "0rem 2rem",
                              }}
                            >
                              <PreFixPromptLabel
                                type="text"
                                placeHolder="prompt name"
                                name={`promptPreFixes[${index}].label`}
                              />
                              {promptPreFixErrors &&
                                promptPreFixErrors.length > 0 &&
                                promptPreFixErrors[index] &&
                                promptPreFixErrors[index].label && (
                                  <ErrorTag>
                                    {promptPreFixErrors[index].label}
                                  </ErrorTag>
                                )}
                              <TextAreaField
                                type="text"
                                placeHolder="prompt value"
                                component="textarea"
                                name={`promptPreFixes[${index}].prompt`}
                              />
                              {promptPreFixErrors &&
                                promptPreFixErrors.length > 0 &&
                                promptPreFixErrors[index] &&
                                promptPreFixErrors[index].prompt && (
                                  <ErrorTag>
                                    {promptPreFixErrors[index].prompt}
                                  </ErrorTag>
                                )}
                            </div>
                            <div
                              style={{
                                width: "10%",
                                float: "left",
                              }}
                            >
                              <RemoveSubTaskImg
                                src={RemoveSubTasksInputFieldSvg}
                                alt="remove"
                                onClick={() => {
                                  remove(index);
                                  if (promptPreFixes[index]._id)
                                    setDeletedPromptPreFixeIds(
                                      deletedPromptPreFixeIds
                                        ? [
                                            ...deletedPromptPreFixeIds,
                                            promptPreFixes[index]._id,
                                          ]
                                        : [promptPreFixes[index]._id]
                                    );
                                }}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  );
                }}
              </FieldArray>

              <DivForSubmitBtn>
                <UpadateBtn type="submit">Update</UpadateBtn>
              </DivForSubmitBtn>
            </Form>
          )}
        </Formik>
      </AppFeatureListContainer>
    </>
  );
};

export default Gpt3Settings;
