import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { UserContext } from "../../context/user-context";
import { UserProfileContext } from "../../context/user-profile-context";
import myAxios from "../../utils/myaxios";
import AddNewGroupsPopup from "./add-new-groups";
import SearchIconSvg from "../../images/searchicon.svg";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { AccordionContext } from "react-bootstrap";
import AccordionDownSvg from "../../images/accordionDownArrow.svg";
import AccordionUpSvg from "../../images/accordionUpArrow.svg";
import EditIcon from "../../images/editiconorange.svg";
import DeleteIcon from "../../images/deleteiconorange.svg";
import ConfirmationBox from "../utils/confirmation-box";
import EditGroup from "./edit-group";
import { Email, Group } from "../../types";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const EmailNotificationSettingsContainer = styled(Col)`
  /*  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;

  position: relative; */
  position: relative;
  min-width: 50%;
  background: #ffffff;
  border: 2px solid #ffe6cc;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  /* height: 510px; */

  @media (max-width: 1199px) and (min-width: 320px) {
    /* width: 100%; */
    margin: 12px;
    /* height: 333px; */
    width: 94%;
  }
`;
const SettingsUl = styled.ul`
  list-style-type: none;
  padding: 0;
  /* margin-top: 8px; */
`;
// const Label = styled.li`
//   color: #797979;
//   font-size: 14px;
//   font-weight: 400;
// `;

const EmailNotificationTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px; */
`;

const HeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #2f4c95;
  display: flex;
  align-items: center;
`;

// const SettingOnOffImgTag = styled.img`
//   cursor: pointer;
// `;
const HeadingDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CreateBtn = styled.button`
  width: 164px;
  height: 40px;
  color: white;
  background: #2f4c95;
  border-radius: 5px;
  border: none;
`;
const LineHori = styled.div`
  width: 100%;
  margin-top: 10px;
  border-bottom: 0.5px solid #797979;
`;
const SearchBoxInput = styled.input`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  position: relative;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;
const SearchIconTag = styled.img`
  position: absolute;
  right: 2%;
  top: 37%;
`;

const ToggleAccordionImgTag = styled.img`
  width: 20px;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
`;
const LabelParagraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 36px;
  color: #797979;
  border-bottom: 0.5px solid rgba(121, 121, 121, 0.2);
`;
const LabelSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2f4c95;
  padding-left: 4px;
`;
const SpanEditDelete = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
  cursor: pointer;
`;
const EditDeleteImgTag = styled.img`
  padding-left: 10px;
  cursor: pointer;
`;
const EmailSpanCount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #797979;
  padding-left: 4px;
`;

const GroupListView = () => {
  const { userState } = useContext(UserContext);

  const { emails, groups, setGroups, deleteGroup } =
    useContext(UserProfileContext);
  // console.log("emails from group list view", emails);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddGoupPopup, setShowAddGoupPopup] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<any>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [groupNameDeleteId, setGroupNameDeleteId] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [showGroupEditPopup, setShowGroupEditPopup] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [emailsWithChecked, setEmailsWithChecked] = useState<Email[]>();

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`groups`)
          .then((response) => {
            if (response.data && response.data.success) {
              setGroups(response.data.data);
              // addNewGroupName(response.data.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting custom email");
          });
      }, 100);
    }
    // eslint-disable-next-line
  }, [userState]);
  const CustomToggle = ({ children, eventKey, callback, handleClick }: any) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey);

      handleClick();
    });

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <>
        <ToggleAccordionImgTag
          src={isCurrentEventKey ? AccordionUpSvg : AccordionDownSvg}
          alt="down"
          onClick={decoratedOnClick}
        />
      </>
    );
  };

  const deletePopUpFun = () => {
    setShowDeleteModel(!showDeleteModel);
  };

  const deleteGroupName = (groupId: any) => {
    myAxios
      .delete(`/groups/${groupId}`)
      .then((response: any) => {
        setShowDeleteModel(false);
        if (response.data.success) {
          deleteGroup(groupId);
          NotificationManager.success("group deleted successfully");
        } else {
          NotificationManager.error(
            response.data?.message ? response.data?.message : "error"
          );
        }
      })
      .catch((error: any) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };

  const filteredGroupName = groups.filter((group: any) => {
    return group.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <EmailNotificationSettingsContainer sm={4}>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}

        <HeadingDiv>
          <HeadingText>List of email groups</HeadingText>
          <CreateBtn
            onClick={() => {
              setShowAddGoupPopup(true);
            }}
          >
            + Add New Group
          </CreateBtn>
        </HeadingDiv>

        <LineHori></LineHori>
        <div style={{ position: "relative" }}>
          <SearchBoxInput
            type="text"
            placeholder="Search group"
            onChange={handleChange}
          />
          <SearchIconTag src={SearchIconSvg} />
        </div>
        {showAddGoupPopup && (
          <AddNewGroupsPopup
            setShowAddGoupPopup={setShowAddGoupPopup}
            showAddGoupPopup={showAddGoupPopup}
          />
        )}
        {/* <div style={{ height: "331px", overflowY: "scroll" }}> */}
        {filteredGroupName &&
          filteredGroupName.length > 0 &&
          filteredGroupName.map((group: Group, index: any) => {
            return (
              <Accordion
                defaultActiveKey={"0"}
                activeKey={activeKey}
                style={{ marginTop: "10px" }}
              >
                <Card>
                  <Card.Header style={{ height: "56px" }}>
                    <SettingsUl>
                      <EmailNotificationTypeContainer>
                        <LabelSpan>{group.name}</LabelSpan>
                        <div>
                          <CustomToggle
                            eventKey={`${index}`}
                            handleClick={() => {
                              if (activeKey === `${index}`) {
                                setActiveKey(null);
                              } else {
                                setActiveKey(`${index}`);
                              }
                            }}
                          ></CustomToggle>
                        </div>
                      </EmailNotificationTypeContainer>

                      <EmailSpanCount>
                        {group.groupEmails?.length}
                      </EmailSpanCount>
                      <EmailSpanCount>Emails</EmailSpanCount>
                    </SettingsUl>
                  </Card.Header>

                  <Accordion.Collapse eventKey={`${index}`}>
                    <Card.Body style={{ background: "#FDF0E2" }}>
                      {group.groupEmails &&
                        group.groupEmails?.length > 0 &&
                        group.groupEmails.map((emails: any) => {
                          return (
                            <>
                              <LabelParagraph>{emails.email}</LabelParagraph>
                            </>
                          );
                        })}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          onClick={() => {
                            const emailsWithChecked1 = [
                              ...emails.map((e) => ({ ...e })),
                            ];
                            if (group.groupEmails)
                              for (
                                let i = 0;
                                i < emailsWithChecked1.length;
                                i++
                              ) {
                                group.groupEmails.forEach((email: Email) => {
                                  if (
                                    emailsWithChecked1[i].email === email.email
                                  )
                                    emailsWithChecked1[i].checked = true;
                                });
                              }
                            setEmailsWithChecked([...emailsWithChecked1]);
                            setSelectedGroup(group);
                            setShowGroupEditPopup(true);
                          }}
                        >
                          <SpanEditDelete>Edit Group</SpanEditDelete>
                          <EditDeleteImgTag src={EditIcon} alt="delete" />
                        </div>
                        <div
                          onClick={() => {
                            deletePopUpFun();
                            setGroupNameDeleteId(group._id);
                          }}
                        >
                          <SpanEditDelete>Delete Group</SpanEditDelete>
                          <EditDeleteImgTag src={DeleteIcon} alt="delete" />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })}
        {/* </div> */}
        {showDeleteModel && (
          <ConfirmationBox
            setShow={setShowDeleteModel}
            okButtonClick={() => {
              deleteGroupName(groupNameDeleteId);
            }}
            name="Confirmation"
            message="Are you sure, you want to delete this group ?"
            okButtonText="Delete Group"
          />
        )}

        {showGroupEditPopup && (
          <EditGroup
            setShowGroupEditPopup={setShowGroupEditPopup}
            showGroupEditPopup={showGroupEditPopup}
            emails={emailsWithChecked}
            group={selectedGroup}
          />
        )}
      </EmailNotificationSettingsContainer>
    </>
  );
};

export default GroupListView;
