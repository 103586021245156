import momentTZ from "moment-timezone";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LOGGED_IN } from "../consts";
import AdminProvider from "../context/admin-context";
import { CommonContext } from "../context/common-context";
import { TaskContext } from "../context/task-context";
import { ProjectTemplatesContext } from "../context/project-template-context";
import { UserOnBoardContext } from "../context/user-onboarding-context";
import { UserContext } from "../context/user-context";
import { magic } from "../magic";
import GlobalStyle from "../style/style";
import myAxios, { setAuthHeader } from "../utils/myaxios";
import AdminView from "./admin/admin-view";
import MiddleMobileTaskForm from "./mobile-task-form/middle-task-form";
import CreateTaskTemplates from "./project-templates/template-view";
import TopBar from "./top-bar/top-bar";
import SideMenu from "./side-menu";
import TaskArea from "./task/task-area";
import UserOnboarding from "./user-onboarding/User-Onboarding";
import UserProfileView from "./user-profile/user-profile-view";
import { USER_ONBAORDING } from "./utils/app-feature-list";
import { checkIfFeatureOpen } from "./utils/utills";
import { UserProfileContext } from "../context/user-profile-context";
import TeamMembersList from "./teams/team-members-list";
import { TeamContext } from "../context/team-context";
import { Team } from "../types";
declare var _cio: any;
const BackgroundWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userState, userDispatch } = useContext(UserContext);

  const { setShowSearchBox, setTopBarText } = useContext(CommonContext);

  const {
    tasks,
    showModel,
    currentPaginationTaskInput,
    setCurrentPaginationTaskInput,
  } = useContext(TaskContext);

  const { showLoader, hideLoader } = useContext(CommonContext);
  const {
    setShowUserOnBoarding,
    isShowUserOnBaording,
    userOnBoardList,
    setUserOnBoardList,
  } = useContext(UserOnBoardContext);
  const { setAllTaskTemplates } = useContext(ProjectTemplatesContext);
  const { setEmails, setGroups, setPreferenceData } =
    useContext(UserProfileContext);

  const { selectedTeam, addUserTeams, setTeamMembers, setSelectedTeam } =
    useContext(TeamContext);

  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOnSidebar, setIsOnSideBar] = useState(false);
  const isDesktopSearchScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const navigateToLogin = useCallback(() => {
    showLoader("Credentials not verified or expired, please login again...");
    setTimeout(() => {
      hideLoader();
      navigate("/login");
    }, 2000);
  }, [navigate, showLoader, hideLoader]);

  // If user is already logged in, redirect to profile page
  useEffect(() => {
    // console.log("wrapper useEffect() CALLED");

    const getUserDataFromServer = async (didToken: string) => {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: didToken,
          timeZone: momentTZ.tz.guess(),
        }),
      });

      if (res.status === 200) {
        const data = await res.json();
        // Set the UserContext to the now logged in user
        let userMetadata = await magic.user.getMetadata();
        userDispatch({
          type: LOGGED_IN,
          user: { ...userMetadata, ...data },
        });

        if (data.jwt) setAuthHeader(data.jwt);

        return true;
      }

      return false;
    };

    if (!userState || !userState.isAuthenticated) {
      const localUser = localStorage.getItem("user");
      //console.log("localUser ", localUser);
      const user = localUser ? JSON.parse(localUser) : undefined;
      //console.log("user ", user);
      showLoader("Verifying credentials...");
      if (user && user.jwt) {
        // Need to refresh token and user information at the time of hard refresh

        if (user.jwt) setAuthHeader(user.jwt);

        myAxios
          .get(`userprofile`)
          .then((response) => {
            userDispatch({
              type: LOGGED_IN,
              user: { ...response.data },
            });
            localStorage.setItem("user", JSON.stringify(response.data));

            if (response.data.jwt) setAuthHeader(response.data.jwt);
            hideLoader();
          })
          .catch((error) => {
            console.error(error, "error in getting templates tasks");
            hideLoader();
            navigateToLogin();
          });
      } else if (!checkInProgress) {
        setCheckInProgress(true);
        magic.user.isLoggedIn().then((isAuthenticated) => {
          if (isAuthenticated) {
            // already authenticated get meta data & login user
            // get user meta data
            magic.user.getIdToken().then((token) => {
              if (token) {
                getUserDataFromServer(token).then((res) => {
                  if (res) {
                    hideLoader();
                  } else navigateToLogin();
                });
              } else {
                navigateToLogin();
              }
            });
          } else {
            navigateToLogin();
          }
        });
      }
    } else {
      hideLoader();
    }
  }, [
    navigate,
    userState,
    userDispatch,
    showLoader,
    hideLoader,
    navigateToLogin,
    checkInProgress,
  ]);

  const updatePersonalTeamPreferences = () => {
    myAxios
      .post(`/personal-team-preference`, {
        isPersonal: true,
        selectedTeamId: undefined,
      })
      .then((response: any) => {
        setPreferenceData(response.data.data);

        // console.log("respone post :--", response.data.data);
      })
      .catch((error) => {
        console.error("error post:---", error);
      });
  };

  useEffect(() => {
    if (userState && userState.user) {
      if (userState.user?.email) {
        const d = new Date();
        const seconds = Math.round(d.getTime() / 1000);
        _cio.identify({
          // Required attributes
          id: userState.user.email, // Use either cio_id, id, or email.

          // Strongly recommended attributes
          created_at: seconds, // Timestamp in your system that represents when
          // the user first signed up. You'll want to send it
          // as seconds since the epoch.

          // Example attributes (you can name attributes what you wish)
          first_name: userState.user.firstName,
          last_name: userState.user.lastName,
          plan: "basic", // To use the example segments, set this to 'free' or 'premium'.
        });
      }

      const lastSelected = JSON.parse(
        localStorage.getItem("userTeams") ?? "[]"
      );
      myAxios
        .get(`teams`)
        .then((response) => {
          addUserTeams(response.data.data);
          if (!lastSelected) {
            // console.log("inside if");
            addUserTeams(response.data.data);
          } else {
            // console.log("inside else ");

            const lastSelectedTeam = response.data.data.find(
              (team: any) => team.id === lastSelected.id
            );
            // console.log("lastSelectedTeam", lastSelectedTeam);
            if (lastSelectedTeam) {
              setSelectedTeam(lastSelectedTeam);
            }
          }

          response.data.data.forEach((team: Team) => {
            // console.log("associate:-", associate);
            // console.log("response.data.data teams:-", team.type);

            if (team.type === "individual") {
              updatePersonalTeamPreferences();
            }

            let currentPaginationInput = currentPaginationTaskInput;
            if (currentPaginationInput.criteria) {
              currentPaginationInput.criteria.teamId = team.id;
            } else {
              currentPaginationInput.criteria = {
                teamId: team.id,
              };
            }
            setCurrentPaginationTaskInput({ ...currentPaginationInput });

            // getting all data
            if (team.isOwner) {
              myAxios
                .get(`teams/get-team-members/${team.id}`)
                .then((response) => {
                  // console.log(
                  //   "response.data.data get team member",
                  //   response.data.data
                  // );
                  if (
                    response.data.success &&
                    response.data.data &&
                    response.data.data.length > 0
                  ) {
                    setTeamMembers(response.data.data);
                  }
                })
                .catch((error) => {
                  console.error(error, "error in getting team members");
                });
            }
          });
        })
        .catch((error) => {
          console.error(error, "error in getting teams");
        });

      //   }, [setAllTaskTemplates]);

      // Get Email List

      myAxios
        .get(`emails`)
        .then((response) => {
          if (response.data && response.data.success) {
            setEmails(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error, "error in getting custom email");
        });

      myAxios
        .get(`groups`)
        .then((response) => {
          if (response.data && response.data.success) {
            setGroups(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error, "error in getting custom email");
        });

      // First check if user has permission to view user onboarding feature
      if (checkIfFeatureOpen(userState?.user?.appFeatures, USER_ONBAORDING)) {
        // Get on boarding feature informations for default category
        myAxios
          .post(`user-onboardings`, {
            categoryOrder: 1,
          })
          .then((response: any) => {
            if (
              response.data.success &&
              response.data.data &&
              response.data.data.length > 0
            ) {
              setShowUserOnBoarding(true);
            }
            if (response.data.data) setUserOnBoardList(response.data.data);
          })
          .catch((error) => {
            console.error("error ", error);
          });
      }
    }

    // eslint-disable-next-line
  }, [userState]);

  useEffect(() => {
    if (selectedTeam) {
      myAxios
        .get(`project-templates/${selectedTeam.id}`)
        .then((response) => {
          setAllTaskTemplates(response.data.data);
        })
        .catch((error) => {
          console.error(error, "error in getting templates tasks");
        });

      myAxios
        .get(`teams/get-team-members/${selectedTeam.id}`)
        .then((response) => {
          if (
            response.data.success &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            setTeamMembers(response.data.data);
          }
        })
        .catch((error) => {
          console.error(error, "error in getting team members");
        });
    }
  }, [selectedTeam]);

  // console.log("associatedTeamId in background wrapper", associatedTeamId);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowSearchBox(true);
      setTopBarText("");
    } else {
      setShowSearchBox(false);
      if (location.pathname.includes("user-profile")) {
        setTopBarText("Profile");
      } else if (location.pathname.includes("admin-panel")) {
        setTopBarText("Admin");
      } else if (location.pathname.includes("project-templates")) {
        setTopBarText("Project Template");
      } else if (location.pathname.includes("team")) {
        setTopBarText("Teams");
      } else {
        setTopBarText("");
      }
    }
  }, [location, setShowSearchBox, setTopBarText]);

  return userState && userState.isAuthenticated ? (
    <>
      <GlobalStyle />
      {isDesktopSearchScreen &&
        userOnBoardList &&
        userOnBoardList.length > 0 &&
        isShowUserOnBaording && <UserOnboarding isOpen={isOpen} />}

      <SideMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isOnSidebar={isOnSidebar}
        setIsOnSideBar={setIsOnSideBar}
      />

      {isDesktopSearchScreen && !showModel && (
        <TopBar
          isOpen={isOpen}
          isOnSidebar={isOnSidebar}
          isTask={tasks.length >= 2 ? true : false}
        />
      )}
      <Routes>
        <Route
          path="/user-profile/*"
          element={<UserProfileView isOpen={isOpen} />}
        />

        <Route
          path="/project-templates"
          element={
            <CreateTaskTemplates isOpen={isOpen} setIsOpen={setIsOpen} />
          }
        />

        <Route
          path="/admin-panel/*"
          element={
            <AdminProvider>
              <AdminView isOpen={isOpen} setIsOpen={setIsOpen} />
            </AdminProvider>
          }
        />

        <Route
          path="/team"
          element={<TeamMembersList isOpen={isOpen} setIsOpen={setIsOpen} />}
        />

        <Route
          path="/:taskId"
          element={<TaskArea isOpen={isOpen} setIsOpen={setIsOpen} />}
        />
        <Route
          path="/middle-mobile-taskform"
          element={<MiddleMobileTaskForm />}
        />
        {/* <Route
          path="/middle-mobile-taskform/mobile-expanded-taskform"
          element={<MobileBasicTaskForm />}
        />
        <Route
          path="/middle-mobile-taskform/mobile-expnaded-taskform"
          element={<MobileExpandedTaskForm />}
        /> */}
        <Route
          path="/"
          element={
            <TaskArea
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isOnSidebar={isOnSidebar}
              setIsOnSideBar={setIsOnSideBar}
            />
          }
        />
      </Routes>
    </>
  ) : null;
};

export default BackgroundWrapper;
