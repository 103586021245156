import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import { NotificationManager } from "react-notifications";
import styled, { keyframes } from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import DeleteIcon from "../../images/delete-normal.svg";
import { CustomEmailDomain } from "../../types";
import myAxios from "../../utils/myaxios";
import ConfirmationBox from "../utils/confirmation-box";
import { parseErrorResponse } from "../utils/utills";
const WatchParent = styled.div`
  left: 50%;
  top: 50%;

  //background-color: #46262F;
  position: absolute;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;
const WatchParent1 = styled.div`
  height: 100%;
  width: 100%;

  //background-color: #46262F;
  position: absolute;
`;
const DeleteImage = styled.img.attrs({
  src: DeleteIcon,
})`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
const EmailListContainer = styled(Col)`
  background: #ffffff;
  border: 1.5px solid rgba(220, 220, 220, 0.3);
  box-shadow: 0px 3px 3px rgba(31, 6, 42, 0.08);
  border-radius: 0px;
  padding: 30px;
  /* min-width: 32%; */
  position: relative;
`;
const EmailUlList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
`;
const EmailLiList = styled.li`
  color: #3c3b5bcc;
  font-size: 16px;
  font-weight: 400;
`;
const InputBox = styled.input`
  width: 82%;
  height: 40px;
  border-right: none;
  border-radius: 4px;
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 5px;
  &:focus {
    outline: none;
    border-color: #e6e6e6;
  }
`;
const AddEmail = styled.button`
  border: none;
  background: #2f4c95;
  color: white;
  padding: 8px 11px;
  margin: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const EmailLiListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const ButtonLoader = styled.div`
  border: 4px solid white;
  border-radius: 50%;
  /* margin-left: 6px; */
  border-top: 4px solid #08ca1b;
  width: 20px;
  height: 20px;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
const EmailtoggelContainer = styled.div``;
const Div = styled.div`
  margin-top: 20px;
`;
const HeadingText = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #2f4c95;
`;
const SubHeadingText = styled.span`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #2f4c95;
`;
const EditDelContainer = styled.div``;

const ErrorSpan = styled.span`
  color: red;
`;

const CustomEmailList = () => {
  const { userState } = useContext(UserContext);
  const {
    customEmails,
    setCustomEmails,
    removeCustomEmail,
    addCustomEmail,
    selectedTestGroupId,
  } = useContext(AdminContext);
  const [email, setEmail] = useState("");
  // console.log("selectedTestGroupId",selectedTestGroupId)
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAddButtonLoading, setShowAddButtonLoading] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteCustomDomainId, setDeleteCustomDomainId] = useState<string>("");
  const TIME_OUT = 100;
  // eslint-disable-next-line
  const emailPattern =
    // eslint-disable-next-line
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const checkIfEmailAlreadyExist = () => {
    if (customEmails && customEmails.length > 0) {
      const found = customEmails.some((el) => el.value === email);
      if (found) {
        setEmailError("email already added");
        return true;
      } else return false;
    } else {
      return false;
    }
  };
  const handleAddEmail = () => {
    if (email === "") {
      setEmailError("Please enter email");
      return;
    }

    if (email.match(emailPattern)) {
      if (checkIfEmailAlreadyExist()) return;
      setShowAddButtonLoading(true);
      myAxios
        .post(`admin-settings/custom-email`, {
          email: email,
          groupId: selectedTestGroupId,
        })
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "Email added successfully...",
              "",
              1500
            );
            addCustomEmail(response.data.data);
            setEmail("");
          } else {
            NotificationManager.error(response.data.message, "", 2000);
          }
          setShowAddButtonLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(parseErrorResponse(err));
          setShowAddButtonLoading(false);
        });
    } else {
      NotificationManager.warning("Please enter valid email...", "", 2000);
      setEmailError("Please enter valid email");
    }
  };

  const handleDelete = (id: string) => {
    setLoading(true);
    setShowDeleteConfirmation(false);
    setTimeout(() => {
      myAxios
        .delete(`admin-settings/custom-domain-email/` + id)
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(
              "email deleted successfully...",
              "",
              1500
            );
            removeCustomEmail(id);
            setEmail("");
          } else {
            NotificationManager.error(response.data.message, "", 2000);
          }
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(parseErrorResponse(err));
          setLoading(false);
        });
    }, TIME_OUT);
  };

  useEffect(() => {
    if (userState && userState.user) {
      setLoading(true);
      setTimeout(() => {
        myAxios
          .get(`admin-settings/custom-email-list/` + selectedTestGroupId)
          .then((response) => {
            if (response.data && response.data.success) {
              setCustomEmails(response.data.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error, "error in getting custom email");
          });
      }, TIME_OUT);
    }
    // eslint-disable-next-line
  }, [userState]);

  useEffect(() => {
    if (email.match(emailPattern)) {
      if (!checkIfEmailAlreadyExist()) setEmailError("");
    } else {
      if (email) setEmailError("Please enter valid email");
      else setEmailError("");
    }
    // eslint-disable-next-line
  }, [email]);

  return (
    <>
      <EmailListContainer sm={4}>
        {loading && (
          <WatchParent1>
            <WatchParent>
              <Watch color="orange" height={50} width={50} />
            </WatchParent>
          </WatchParent1>
        )}
        <EmailtoggelContainer>
          <HeadingText>Custom Emails</HeadingText>
          <Div>
            <InputBox
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
            />
            <AddEmail onClick={handleAddEmail}>
              {!showAddButtonLoading && "Add"}
              {showAddButtonLoading && <ButtonLoader />}
            </AddEmail>
          </Div>
          <ErrorSpan> {emailError && emailError}</ErrorSpan>
        </EmailtoggelContainer>
        <div style={{ marginTop: "25px" }}>
          <SubHeadingText>Added email</SubHeadingText>
        </div>
        <EmailUlList>
          {customEmails &&
            customEmails.map((emailData: CustomEmailDomain) => {
              return (
                <EmailLiListContainer key={emailData._id}>
                  <EmailLiList>{emailData.value}</EmailLiList>
                  <EditDelContainer>
                    <DeleteImage
                      onClick={() => {
                        setDeleteCustomDomainId(emailData._id);
                        setShowDeleteConfirmation(true);
                      }}
                    />
                  </EditDelContainer>
                </EmailLiListContainer>
              );
            })}
        </EmailUlList>
        {showDeleteConfirmation && (
          <ConfirmationBox
            setShow={setShowDeleteConfirmation}
            name="Confirmation"
            message="Are you sure, you want to delete this email?"
            okButtonText="Delete"
            okButtonClick={() => {
              handleDelete(deleteCustomDomainId);
            }}
          />
        )}
      </EmailListContainer>
    </>
  );
};

export default CustomEmailList;
