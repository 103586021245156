import { Row } from "react-bootstrap";
import styled from "styled-components";
import DltrossSvg from "../../images/cross.svg";

const MainDiv = styled.div`
  /* position: relative;
  display: block;
  width: 100%;
  height: 100%;

  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999; */
  /* position: relative;
  height:100vh; */
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  width: 367px;
  background: #ffffff;
  box-shadow: 1px 9px 18px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  z-index: 1111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;

const DltHeader = styled.div`
  width: 367px;
  height: 35px;
  background: #fdf0e2;
  border-radius: 10px 10px 0px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 289px;
  }
`;
const Arrow = styled.img`
  width: 51px;
  height: 21px;
  cursor: pointer;
  left: 93%;
  top: -6px;
  z-index: 1111;
  position: absolute;
  @media (max-width: 1199px) and (min-width: 320px) {
    left: 90%;
  }
`;

const SpanDltPopup = styled.span`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SpanTextDlt = styled.span`
  /* width: 156px; */
  height: 36px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #2f4c95;
  margin: 13px 0;
  padding: 12px;
`;
const OkButton = styled.button`
  /* width: 76px;
  height: 30px; */
  background: #2f4c95;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 15px;

  &:hover {
    background: #0e2151;
    border: none;
  }
`;
const SpanCancel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2f4c95;
  margin: 4px 13px;
  cursor: pointer;
`;

const ConfirmationBox = ({
  setShow,
  okButtonClick,
  name,
  message,
  okButtonText,
  cancelButtonText,
}: any) => {
  return (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShow(false);
              }}
            />
            <SpanDltPopup>{name}</SpanDltPopup>
          </DltHeader>
          <Row>
            <CenterDiv>
              <SpanTextDlt>{message}</SpanTextDlt>
            </CenterDiv>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10%",
              }}
            >
              <OkButton
                type="button"
                onClick={() => {
                  okButtonClick();
                }}
              >
                {okButtonText ? okButtonText : "Ok"}
              </OkButton>
              <SpanCancel
                onClick={() => {
                  setShow(false);
                }}
              >
                {cancelButtonText ? cancelButtonText : "Cancel"}
              </SpanCancel>
            </div>
          </Row>
        </DivForDltPopUp>
      </MainDiv>
    </>
  );
};

export default ConfirmationBox;
