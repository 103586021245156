import axios from "axios";
import { Field, Formik } from "formik";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import HidePassEye from "../../images/hidepasseye.svg";
import Logo from "../../images/logo.svg";
import OrLInes from "../../images/oricon.svg";
import ShowPassEye from "../../images/showpasseye.svg";
import Spinner from "../../images/spinner.svg";
import { parseErrorResponse } from "../utils/utills";

const ThirdSpan = styled.span`
  margin: 0 33px;
  padding: 1px 0 23px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9b9b9b;
  text-align: center;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 251px;
    height: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    color: #9b9b9b;
    text-align: center;
  }
`;

const DivForLoginWithCredintial = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 3%;
`;

const ButtonForCred = styled.button`
  width: 64%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #2f4c95;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #2f4c95;
  z-index: 11111;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const SpanAccount = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f4c95;
`;
const SpanSignup = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fc6601;
  margin-left: 7px;
  cursor: pointer;
`;

const DIV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const LogoImg = styled.img`
  width: 123px;
  margin-top: 16px;

  @media (max-width: 1199px) and (min-width: 390px) {
  }
`;

const DivForPara = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const LoginPara = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fc6601;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 43px;
    color: #fc6601;
  }
`;

const LoginSignupFieldsDiv = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding-left: 0;
    padding: 15px 0;
  }
`;
const Input = styled(Field)`
  width: 64%;
  height: 41px;
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #e3e3e3;
  margin: 8px;
  border-radius: 3px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    margin: 8px 0;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
  }
`;
const DivForLoginButton = styled.div`
  padding: 20px 0;
  width: 100%;
  text-align: center;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 0;
  }
`;
const ShowPasswordImgTag = styled.img`
  position: absolute;
  top: 42%;
  right: 21%;
  z-index: 1;

  @media (max-width: 1199px) and (min-width: 320px) {
    right: 5%;
  }
`;
const ShowConfimrPasswordImgTag = styled.img`
  position: absolute;
  top: 36%;
  right: 21%;
  z-index: 1;
  @media (max-width: 1199px) and (min-width: 320px) {
    right: 5%;
  }
`;
const LoginButton = styled.button`
  width: 64%;
  height: 50px;
  color: white;
  background: #2f4c95;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  &:hover {
    background: #0e2151;
    border: none;
  }
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const SpanErrorLoginSignup = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const SpinnerLoginImg = styled.img`
  height: 35px;
  margin-left: 15px;
`;

const DivForPassShowHide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;
const DivForOrLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OrlinesImgTag = styled.img`
  width: 64%;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;

const Signup = ({
  setToShowSignupPage,
  setToShowMagicLinkloginPage,
  setShowSignupMsgInLoginPage,
  setToShowForgetPassPage,
}: any) => {
  const [toShowPasswordSignup, setToShowPasswordSignup] =
    useState<boolean>(false);

  const [toShowPasswordConfirmSignup, setToShowPasswordConfimSignup] =
    useState<boolean>(false);
  const [signupLoader, setSignupLoader] = useState(false);

  const signUp = (values: any, resetForm: any) => {
    setSignupLoader(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/register`, {
        email: values.email,
        password: values.password,
      })
      .then((response: any) => {
        if (response && response.data) {
          if (response.data.success) {
            setSignupLoader(false);
            setShowSignupMsgInLoginPage(response.data?.message);
            setToShowMagicLinkloginPage(false);
            setToShowSignupPage(false);
            resetForm();
            if (response.data.message)
              NotificationManager.success(
                response.data.message
                  ? response.data.message
                  : " successfully registered!"
              );

            // Need to send signup details to mixpanel

            mixpanel.track("Sign Up", {
              source: "JetList SignUp ",
              email: values.email,
              "Opted out of email": true,
            });
          } else {
            NotificationManager.error(
              response.data.message
                ? response.data.message
                : "Some thing went wrong, Please try again!"
            );
            setSignupLoader(false);
          }
        } else {
          setSignupLoader(false);
          NotificationManager.error("Some thing went wrong, Please try again!");
        }
      })
      .catch((error: any) => {
        console.error("error", error);
        NotificationManager.error(parseErrorResponse(error));
        setSignupLoader(false);
      });
  };

  const toShowPasswordInPlainText = () => {
    var x: any = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const toShowPasswordInPlainTextConfirmBox = () => {
    var x: any = document.getElementById("myInputId");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return (
    <>
      <Container>
        <Row>
          <DIV>
            <LogoImg src={Logo} alt="logo" />

            <DivForPara>
              <LoginPara>Signup</LoginPara>
              {/* <SecondSpan>welcome to jetlist</SecondSpan> */}
              <ThirdSpan>
                Enter your login credentials below to start using the app.
              </ThirdSpan>
            </DivForPara>
          </DIV>

          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validate={(values: any) => {
              const errors: {
                email?: string;
                password?: string;
                confirmPassword?: string;
              } = {};

              if (!values.email) {
                errors.email = " email required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "password required";
              } else if (values.password.length <= 5) {
                errors.password = "minimum 6 characters required";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = " confirm password required ";
              }
              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "confrim password not matched ";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              signUp(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <div style={{display:"flex",justifyContent:"center"}}> */}
                <LoginSignupFieldsDiv>
                  <Input type="email" placeholder="email" name="email" />

                  {errors.email && (
                    <SpanErrorLoginSignup>
                      {errors.email && touched.email && errors.email}
                    </SpanErrorLoginSignup>
                  )}

                  <DivForPassShowHide>
                    <ShowPasswordImgTag
                      src={toShowPasswordSignup ? ShowPassEye : HidePassEye}
                      onClick={() => {
                        toShowPasswordInPlainText();
                        setToShowPasswordSignup(!toShowPasswordSignup);
                      }}
                    />
                    <Input
                      type="password"
                      id="myInput"
                      placeholder="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </DivForPassShowHide>

                  {errors.password && (
                    <SpanErrorLoginSignup>
                      {errors.password && touched.password && errors.password}
                    </SpanErrorLoginSignup>
                  )}

                  <DivForPassShowHide>
                    <ShowConfimrPasswordImgTag
                      src={
                        toShowPasswordConfirmSignup ? ShowPassEye : HidePassEye
                      }
                      onClick={() => {
                        toShowPasswordInPlainTextConfirmBox();
                        setToShowPasswordConfimSignup(
                          !toShowPasswordConfirmSignup
                        );
                      }}
                    />
                    <Input
                      type="password"
                      placeholder="confirm password"
                      id="myInputId"
                      name="confirmPassword"
                    />
                  </DivForPassShowHide>

                  {errors.confirmPassword && (
                    <SpanErrorLoginSignup>
                      {errors.confirmPassword}
                    </SpanErrorLoginSignup>
                  )}

                  <DivForLoginButton>
                    <LoginButton type="submit">
                      SIGNUP
                      {signupLoader && (
                        <SpinnerLoginImg src={Spinner} alt="Loading" />
                      )}
                    </LoginButton>
                  </DivForLoginButton>
                </LoginSignupFieldsDiv>
                {/* </div> */}
              </form>
            )}
          </Formik>
          <DivForOrLine>
            <OrlinesImgTag src={OrLInes} alt="or" />
          </DivForOrLine>

          <DivForLoginWithCredintial>
            <ButtonForCred
              onClick={() => {
                setToShowMagicLinkloginPage(true);
                setToShowSignupPage(false);
              }}
            >
              Login With Magic Link
            </ButtonForCred>
          </DivForLoginWithCredintial>

          <BottomDiv>
            <SpanAccount>Already have an account |</SpanAccount>
            <SpanSignup
              onClick={() => {
                setToShowMagicLinkloginPage(false);
                setToShowSignupPage(false);
                setToShowForgetPassPage(false);
              }}
            >
              LOGIN
            </SpanSignup>
          </BottomDiv>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
