import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DltrossSvg from "../../images/cross.svg";
import JetListSvg from "../../images/JetList.svg";
// import { UserContext } from "../../context/user-context";
import { Field, Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import { useMediaQuery } from "react-responsive";
import { UserProfileContext } from "../../context/user-profile-context";
import SearchIconSvg from "../../images/searchicon.svg";
import { Email } from "../../types";
import myAxios from "../../utils/myaxios";
import { parseErrorResponse } from "../utils/utills";
const MainDiv = styled.div`
  /* position: relative;
  display: block;
  width: 100%;
  height: 100%;

  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999; */
  /* position: relative;
  height:100vh; */
`;

const DivForDltPopUp = styled.div`
  position: fixed;
  width: 28%;
  height: 81%;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  z-index: 1111;
  left: 50%;
  top: 50%;
  padding-bottom: 15px;
  transform: translate(-50%, -50%);
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 93%;
    height: 94%;
  }
  @media (max-height: 400px) and (min-height: 220px) {
    overflow: scroll;
  }
`;

const DltHeader = styled.div`
  width: 100%;
  height: 35px;
  background: #fdf0e2;
  border-radius: 10px 10px 0px 0px;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const Arrow = styled.img`
  cursor: pointer;
  right: 0;
  z-index: 1111;
  position: absolute;
  @media (max-width: 1199px) and (min-width: 320px) {
    /* left: 90%; */
  }
`;

const SpanDltPopup = styled.div`
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #2f4c95;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const SpanTxt = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #2f4c95;
`;
const DivForAddGroup = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
`;
const Label = styled.label`
  padding: 2px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
`;

const Input = styled(Field)`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;

const SpanError = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #df5c04;
  width: 64%;
  display: flex;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
  }
`;
const SubmitBtn = styled.button`
  width: 209px;
  height: 40px;
  border: none;
  background: #2f4c95;
  border-radius: 5px;
  color: white;
  cursor: pointer;
`;
const BtnDiv = styled.div`
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: center;
`;

const BtnDivForMobile = styled.div`
  /* position: fixed; */
  bottom: 10px;
  display: flex;
  justify-content: center;
`;

const LineHori = styled.div`
  width: 100%;
  margin-top: 10px;
  border-bottom: 0.5px solid #797979;
`;

const SearchBoxInput = styled.input`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(60, 59, 91, 0.8);
    padding: 5px;
  }
`;
const SearchIconTag = styled.img`
  position: absolute;
  right: 4%;
  top: 44%;
`;

const EmailUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
  height: 200px;
  overflow-y: scroll;
`;
// const Label = styled.li`
//   color: #797979;
//   font-size: 14px;
//   font-weight: 400;
// `;

const EmailContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
const LabelEmailsPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  color: #797979;
  padding-left: 10px;
  margin: 0;
`;
const SpanSelectTxt = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2f4c95;
`;

const EditGroup = ({
  setShowGroupEditPopup,
  showGroupEditPopup,
  emails,
  group,
}: any) => {
  // const { userState } = useContext(UserContext);
  const { setGroups } = useContext(UserProfileContext);
  // const { allTeamMembersList } = useContext(TeamContext);
  const [emailsIds, setEmailsIds] = useState<any>([]);
  const [removedIds, setRemovedsIds] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  // const [checked, setChecked] = useState<boolean>(false);
  const [localEmails, setLocalEmails] = useState<Email[]>([
    ...emails.map((email: Email) => ({ ...email })),
  ]);
  const [filteredEmailList, setFilteredEmailList] = useState<any[]>([]);

  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  useEffect(() => {
    const filteredData = localEmails.filter((email: Email) => {
      return email.email.toLowerCase().includes(searchText.toLowerCase());
    });

    setFilteredEmailList(
      filteredData ? filteredData.map((email: Email) => ({ ...email })) : []
    );
  }, [searchText, localEmails]);

  const handleChange = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <MainDiv>
        <DivForDltPopUp>
          <DltHeader>
            <Arrow
              src={DltrossSvg}
              alt="dlt"
              onClick={() => {
                setShowGroupEditPopup(false);
              }}
            />

            <SpanDltPopup>
              <img src={JetListSvg} alt="logo" />
            </SpanDltPopup>
          </DltHeader>
          <DivForAddGroup>
            <SpanTxt>Add New Group</SpanTxt>

            <Formik
              enableReinitialize={true}
              initialValues={{
                groupName: group ? group.name : "",
              }}
              validate={(values: any) => {
                const errors: {
                  groupName?: string;
                } = {};

                if (!values.groupName) {
                  errors.groupName = "required field";
                } else if (values.groupName.length <= 3) {
                  errors.groupName = "minimun 4 character required";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                myAxios
                  .put(`/groups/${group._id}`, {
                    groupName: values.groupName,
                    deletedEmailIds: removedIds,
                    addedEmailIds: emailsIds,
                  })
                  .then((response: any) => {
                    if (response.data.success) {
                      NotificationManager.success("group updated successfully");
                      setEmailsIds([]);
                      setShowGroupEditPopup(false);
                      setGroups(response.data.data);
                    } else {
                      NotificationManager.error(
                        response.data?.message
                          ? response.data?.message
                          : "error"
                      );
                    }
                  })
                  .catch((error: any) => {
                    NotificationManager.error(parseErrorResponse(error));
                  });
                setSubmitting(false);
                resetForm();
              }}
            >
              {({ isSubmitting, touched, errors, setFieldValue }) => (
                <Form>
                  <Label>Enter Group Name</Label> <br />
                  <Input
                    type="text"
                    placeholder="Designing Group"
                    name="groupName"
                    onChange={(e: any) => {
                      setFieldValue("groupName", e.target.value.trim());
                    }}
                  />
                  {errors.groupName && (
                    <SpanError>
                      {errors.groupName &&
                        touched.groupName &&
                        errors.groupName}
                    </SpanError>
                  )}
                  <LineHori></LineHori>
                  <div style={{ position: "relative" }}>
                    <SearchBoxInput
                      type="text"
                      placeholder="Search email"
                      onChange={handleChange}
                    />
                    <SearchIconTag src={SearchIconSvg} />
                  </div>
                  <SpanSelectTxt>select more than one email</SpanSelectTxt>
                  <EmailUl>
                    {filteredEmailList &&
                      filteredEmailList.length > 0 &&
                      filteredEmailList.map((email: Email, index: number) => {
                        return (
                          <EmailContainer>
                            <input
                              key={Math.random()}
                              style={{ cursor: "pointer" }}
                              type="checkbox"
                              defaultChecked={email.checked ? true : false}
                              // checked={email.checked?true:false}
                              onChange={() => {
                                const flag = !filteredEmailList[index].checked;
                                filteredEmailList[index].checked = flag;

                                const ind = localEmails.findIndex(
                                  (email: Email) =>
                                    email.email ===
                                    filteredEmailList[index].email
                                );

                                if (ind > -1) {
                                  localEmails[ind].checked = flag;
                                }

                                if (email.checked === true) {
                                  setEmailsIds([...emailsIds, email._id]);

                                  const filetredData = removedIds.filter(
                                    (id: string) => {
                                      return email._id !== id;
                                    }
                                  );

                                  setRemovedsIds([...filetredData]);
                                } else {
                                  setRemovedsIds([...removedIds, email._id]);

                                  const filetredData = emailsIds.filter(
                                    (id: string) => {
                                      return email._id !== id;
                                    }
                                  );

                                  setEmailsIds([...filetredData]);
                                }
                              }}
                            />
                            <LabelEmailsPara>{email.email}</LabelEmailsPara>
                          </EmailContainer>
                        );
                      })}
                  </EmailUl>
                  {isDesktopScreen ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <BtnDiv>
                        <SubmitBtn type="submit" disabled={isSubmitting}>
                          Submit
                        </SubmitBtn>
                      </BtnDiv>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <BtnDivForMobile>
                        <SubmitBtn type="submit" disabled={isSubmitting}>
                          Submit
                        </SubmitBtn>
                      </BtnDivForMobile>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </DivForAddGroup>
        </DivForDltPopUp>
      </MainDiv>
    </>
  );
};

export default EditGroup;
