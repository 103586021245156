import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/user-context";
import BackArrow from "../../images/backarrow.svg";
import AddFeatureGroup from "./add-feature-group";
import AppFeatureList from "./app-feature-list";
import CheckEnableFeatures from "./check-enable-features";
import CustomDomainList from "./custom-domain-list";
import CustomEmailList from "./custom-email-list";
import Gpt3Settings from "./gpt-3-settings";

const Container = styled.div`
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "253px" : "60px")};

  @media (max-width: 1199px) and (min-width: 320px) {
    margin-left: ${(props: SidebarWidth) => (props.isOpen ? "0" : "0")};
  }
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 60px;
  background: #ffe6cc;

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
interface SidebarWidth {
  isOpen?: boolean;
}
const InnerDiv = styled.div`
  //padding: 21px 30px;
  padding-top: 15px;
  padding-left: 32px;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 10px 15px;
    display: flex;
    align-items: center;
  }
`;

const TemplateSpan = styled.span`
  //display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2f4c95;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding-left: 10px;
  }
`;
const HeaderContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderText = styled.h3`
  text-align: center;
  color: red;
`;
const TabContainer = styled.div`
  overflow: hidden;
  background-color: #fdf0e2;
  padding-left: 23px;
  padding-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
const Tab = styled.button<{
  selected?: boolean;
}>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.5px 16px;
  min-width: 100px;
  transition: 0.3s;
  font-size: 17px;
  position: relative;
  background-color: #fdf0e2;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #2F4C95" : "3px solid #FDF0E2"};
  border-top-left-radius: ${(props) => (props.selected ? "5px" : "0px")};
  border-top-right-radius: ${(props) => (props.selected ? "5px" : "0px")};
  color: ${(props) => (props.selected ? "#2F4C95" : "#ABAEC3")};
  font-weight: 700;
`;
const TestUserContainer = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: start;
  padding: 10px 0 0 0;
  /* margin: 0 33px; */
`;
const AppFeatureContainer = styled.div`
  display: flex;
  padding: 10px 50px 0 27px;
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 10px 18px 0 18px;
  }
`;
const DivForBack = styled.div`
  margin-left: 33px;
`;
const BackImgTag = styled.img`
  width: 13px;
  height: 13px;
  cursor: pointer;
`;
const BackSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(60, 59, 91, 0.8);
  cursor: pointer;
`;
const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
`;
const TestUserTab = () => {
  const { setSelectedTestGroupId } = useContext(AdminContext);
  const [toShowFeatureComponent, setShowFeatureComponent] = useState(false);

  return (
    <>
      {toShowFeatureComponent && (
        <DivForBack
          onClick={() => {
            setShowFeatureComponent(false);
            setSelectedTestGroupId("");
          }}
        >
          <BackImgTag src={BackArrow} />
          <BackSpan> Back To Group</BackSpan>
        </DivForBack>
      )}
      <TestUserContainer>
        {toShowFeatureComponent ? (
          <>
            <CheckEnableFeatures />
            <CustomEmailList />
            <CustomDomainList />
          </>
        ) : (
          <AddFeatureGroup setShowAnotherComponent={setShowFeatureComponent} />
        )}
      </TestUserContainer>
    </>
  );
};
const AppFeatureTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <AppFeatureList />
      </AppFeatureContainer>
    </>
  );
};
const Gpt3SettingsTab = () => {
  return (
    <>
      <AppFeatureContainer>
        <Gpt3Settings />
      </AppFeatureContainer>
    </>
  );
};
const AdminView = ({ isOpen }: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { userState } = useContext(UserContext);
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  return (
    <>
      <Container isOpen={isOpen}>
        {userState && userState.user && userState.user.type !== "admin" ? (
          <HeaderContainer>
            <HeaderText>You are not authorized to view this page</HeaderText>
          </HeaderContainer>
        ) : (
          <HeaderDiv>
            <InnerDiv>
              {!isDesktopScreen && (
                <BackArrowTag
                  src={BackArrow}
                  alt="backArrow"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              )}
              <TemplateSpan>Admin View</TemplateSpan>
              {/* <Para>Only use for admin.</Para> */}
            </InnerDiv>
          </HeaderDiv>
        )}

        <TabContainer>
          <Tab
            onClick={() => {
              navigate("/admin-panel");
            }}
            selected={location.pathname === "/admin-panel"}
          >
            Settings
          </Tab>
          <Tab
            onClick={() => {
              navigate("/admin-panel/app-feature");
            }}
            selected={location.pathname === "/admin-panel/app-feature"}
          >
            App Features
          </Tab>
          <Tab
            onClick={() => {
              navigate("/admin-panel/test-user");
            }}
            selected={location.pathname === "/admin-panel/test-user"}
          >
            Test group
          </Tab>
        </TabContainer>
        <Routes>
          <Route path="/" element={<Gpt3SettingsTab />} />
        </Routes>
        <Routes>
          <Route path="/app-feature" element={<AppFeatureTab />} />
        </Routes>
        <Routes>
          <Route path="/test-user" element={<TestUserTab />} />
        </Routes>
      </Container>
    </>
  );
};

export default AdminView;
