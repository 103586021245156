import { createContext, ReactElement, useState } from "react";
import { UserOnBaord, UserOnBoardProps, UserOnBoardValues } from "../types";

export const UserOnBoardContext = createContext({} as UserOnBoardValues);

const UserOnBoardProvider = (
  props: UserOnBoardProps
): ReactElement<UserOnBoardValues> => {
  const [userOnBoardList, setUserOnBoardList] = useState<UserOnBaord[]>([]);
  const [isShowUserOnBaording, setShowUserOnBoarding] =
    useState<boolean>(false);

  return (
    <UserOnBoardContext.Provider
      value={{
        isShowUserOnBaording,
        setShowUserOnBoarding,
        userOnBoardList,
        setUserOnBoardList,
      }}
    >
      {props.children}
    </UserOnBoardContext.Provider>
  );
};

export default UserOnBoardProvider;
