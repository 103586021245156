import { createContext, ReactElement, useState } from "react";
import {
  CommonContextValues,
  Email,
  Group,
  PreferenceData,
  UserProfileContextValues,
  UserProfileProps,
} from "../types";
export const UserProfileContext = createContext({} as UserProfileContextValues);

const UserProfileContextProvider = (
  props: UserProfileProps
): ReactElement<CommonContextValues> => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any>([]);

  const [preferenceData, setPreferenceData] = useState<PreferenceData>(
    {} as PreferenceData
  );

  const deleteGroup = (groupId: any) => {
    let updateGroupList = groups.filter((groupName: any) => {
      return groupName._id !== groupId;
    });

    setGroups(updateGroupList);
  };

  return (
    <UserProfileContext.Provider
      value={{
        emails,
        setEmails,
        groups,
        setGroups,
        deleteGroup,

        setSelectedGroups,
        selectedGroups,
        preferenceData,
        setPreferenceData,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContextProvider;
