import styled from "styled-components";
import { useContext } from "react";
import { ProjectTemplatesContext } from "../../context/project-template-context";
import CreateTemplateModel from "./create-template-model";
import TemplateList from "./template-list";
import { useMediaQuery } from "react-responsive";
import BackArrow from "../../images/backarrow.svg";
import JetListLogo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  /* position: relative; */
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 119px;
  background: #fdf0e2;
  @media (max-width: 1199px) and (min-width: 320px) {
    width: 100%;
    background: none;
  }
`;
interface SidebarWidth {
  isOpen?: boolean;
}
const InnerDiv = styled.div`
  padding: 21px 132px;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 10px 15px;
  }
`;

const TemplateSpan = styled.span`
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: #fc6601;
  padding-top: 20px;
`;
const Para = styled.span`
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
`;
const CreateTempButton = styled.button`
  width: 250px;
  height: 50px;
  color: white;
  background: #2f4c95;
  border-radius: 5px;
  border: none;
  &:hover {
    background: #0e2151;
    border: none;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    width: 47%;
    height: 45px;
    font-size: 14px;
    line-height: 17px;
  }
`;
const ButtonDiv = styled.div`
  padding: 6px 9%;
  display: flex;
  justify-content: space-between;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 7px 12px;
    position: fixed;
    bottom: 0;
    z-index: 11;
    width: 100%;
    display: flex;
    justify-content: center;

    background: #ffffff;
    border-top: 1px solid #ffe6cc;
    box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.15);
  }
`;

const TemplateListDivTag = styled.div`
  padding: 0px 9%;
  overflow-x: hidden;
  position: relative;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
`;
const DivForListOfTemplate = styled.div`
  padding: 3px 9%;
  /*  */
  overflow-y: scroll;
  height: 84vh;
  overflow-x: hidden;
  position: relative;
  margin-left: ${(props: SidebarWidth) => (props.isOpen ? "14%" : "0px")};
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1199px) and (min-width: 320px) {
    padding: 3px 3%;
  }
`;

const ListSpan = styled.span`
  display: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #2f4c95;

  @media (max-width: 1199px) and (min-width: 320px) {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #2f4c95;
  }
`;
const SecondSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  display: block;
`;
const ThirdSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
`;
const Header = styled.div`
  height: 50px;
  background: #ffe6cc;
`;

const LogoTag = styled.img`
  width: 75px;
  margin-left: 24px;
`;
const BackArrowTag = styled.img`
  width: 28px;
  padding-left: 10px;
`;
const TemplateView = ({ isOpen }: any) => {
  const {
    allTaskTemplates,
    openSidebarPopup,
    setSidebarPopup,
    setSelectedTaskTemplates,
  } = useContext(ProjectTemplatesContext);

  const isMobileView = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });
  const isDesktopScreen = useMediaQuery({
    query: "(max-width: 16000px ) and (min-width: 1199px)",
  });

  let navigate = useNavigate();
  return (
    <>
      <Container>
        {/* this is visible on mobile mode */}
        {!isDesktopScreen && (
          <>
           
            <Header>
              <div style={{ padding: "10px" }}>
                <BackArrowTag
                  src={BackArrow}
                  alt="backArrow"
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <LogoTag src={JetListLogo} alt="jetlist" />
              </div>
            </Header>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ListSpan>List of Templates</ListSpan>
            </div>
          </>
        )}

        <HeaderDiv>
          {/* this is visible on desktop mode */}
          {isDesktopScreen && (
            <InnerDiv isOpen={isOpen}>
              <TemplateSpan>Create Template</TemplateSpan>
              <Para>Below is the list of created templates.</Para>
              <Para>
                You can call this template using template key on task created
                field.
              </Para>
            </InnerDiv>
          )}
          {!openSidebarPopup && (
            <ButtonDiv isOpen={isOpen}>
              {isDesktopScreen && <ListSpan>List of Templates</ListSpan>}

              <CreateTempButton
                onClick={() => {
                  setSidebarPopup(!openSidebarPopup);
                  setSelectedTaskTemplates(null);
                }}
              >
                + Create New Template
              </CreateTempButton>
            </ButtonDiv>
          )}

          <TemplateListDivTag isOpen={isOpen}>
            {allTaskTemplates.length <= 0 ? (
              <>
                <SecondSpan>
                  Look like you didn’t create any template.
                </SecondSpan>
                <ThirdSpan>
                  Click on the above button to create a new template.
                </ThirdSpan>
              </>
            ) : null}
          </TemplateListDivTag>

          {/*   COMPONENT OF LIST ALL TASK TEMPLATE */}
          <DivForListOfTemplate isOpen={isOpen}>
            <TemplateList isOpen={isOpen} isMobileView={isMobileView} />
          </DivForListOfTemplate>
        </HeaderDiv>

        {/*COMPONENT OF CREATE TASK TEMPLATE POPUP */}
        <CreateTemplateModel isMobileView={isMobileView} />
      </Container>
    </>
  );
};

export default TemplateView;
